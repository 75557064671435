import React, { ChangeEvent, useEffect, useState } from 'react'; // let's also import Component
import { useForm, FormProvider } from "react-hook-form"


import DOMPurify from 'isomorphic-dompurify';
import HTMLParser from 'html-react-parser';

import { ASGateway, ASDtos } from 'api/AppServices';

import FormNewTask from 'components/Forms/FormNewTask';
import AutoCompleteTasks from 'components/AutoCompleteTasks';
import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box,
  Checkbox,
  FormControlLabel,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio
} from '@mui/material';

type IFormAddChangeToTaskProps = {
  changes: any[],
  formIsDone: (taskId: number | null, changes: number[]) => void;
};

enum TaskSelectionEntry {
  NEW,
  EXISTING
};

export default function FormAddChangeToTask(props: IFormAddChangeToTaskProps) {
  const methods = useForm({ mode: 'all' });

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [fieldTaskSelectionType, setFieldTaskSelectionType] = useState(TaskSelectionEntry[TaskSelectionEntry.EXISTING]);

  const [fieldTaskId, setFieldTaskId] = useState<number | null>(null);
  const [fieldTaskType, setFieldTaskType] = useState<ASDtos.TaskTypeDto | null>(null);
  const [fieldTaskPriority, setFieldTaskPriority] = useState<ASDtos.TaskPriorityDto | null>(null);
  const [fieldTaskTitle, setFieldTaskTitle] = useState("");
  const [fieldTaskDescription, setFieldTaskDescription] = useState('');
  const [fieldTaskEstimation, setFieldTaskEstimation] = useState(0);
  const [fieldTaskExecution, setFieldTaskExecution] = useState(0);

  const [fieldChanges, setFieldChanges] = useState([] as number[]);

  function fieldTaskValue(value: number | null) {
    //console.log("fieldTaskValue: ", value);
    setFieldTaskId(value);
  }

  //Handles
  function handleChangeSelection(e: ChangeEvent<HTMLInputElement>, changeId: number) {
    //console.log("handleChangeSelection changeId: ", changeId);

    var isChecked = e.target.checked;
    if (isChecked) {
      //Add to fieldChanges
      setFieldChanges([...fieldChanges, changeId]);
    }
    else {
      //Remove from fieldChanges
      setFieldChanges([...fieldChanges.filter((change) => change !== changeId)]);
    }
  }

  async function handleSubmitForm() {
    var taskIdAssigned = fieldTaskId;
    if (fieldTaskSelectionType === TaskSelectionEntry[TaskSelectionEntry.NEW]) {

      var taskCreateDto = {
        type: fieldTaskType,
        priority: fieldTaskPriority,
        title: fieldTaskTitle,
        description: fieldTaskDescription,
        estimation: fieldTaskEstimation,
        execution: fieldTaskExecution,
        boardColumns: [],
        nodes: [],
        changes: fieldChanges
      } as ASDtos.TaskCreateDto;

      //console.log("taskCreateDto: ", taskCreateDto);
      //CREATE NEW
      setIsButtonSubmiting(true);
      await ASGateway.Tasks.Create(taskCreateDto)
        .then((taskReadDto: ASDtos.TaskReadDto) => {
          //console.log('CreateTask: ', taskReadDto);
          taskIdAssigned = taskReadDto.id;
        })
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          setIsButtonSubmiting(false);
        });
    }
    if (fieldTaskSelectionType === TaskSelectionEntry[TaskSelectionEntry.EXISTING]) {
      //ForwardRef into AutoComplete Component for this field is not implemented
      //So hookForm is not working
      //So we must validate manually
      if (fieldTaskId !== null) {
        //ASSOCIATE TASK TO CHANGES
        setIsButtonSubmiting(true);
        await ASGateway.Tasks.AssignChanges(
          fieldTaskId,
          fieldChanges
        )
          .then((data: any) => {
            //console.log('UpdateTaskAssignChanges: ', data);
          })
          .catch((e: any) => {
            console.error(e);
          })
          .finally(() => {
            setIsButtonSubmiting(false);
          });
      }
      else {
        //Exit handleSubmit without closing form Dialog
        return;
      }
    }
    props.formIsDone(taskIdAssigned, fieldChanges);
  }

  // OnLoad
  useEffect(() => {
    //console.log("Props: ", props);
    //By Default all Changes are selected
    setFieldChanges([...props.changes.map(change => change.apiRef)]);

    //console.log("FieldChanges: ", fieldChanges);
  }, [props.changes]);

  // OnRender
  return (
    <FormProvider {...methods}>
      <Box id='addToTaskForm' component='form' noValidate autoComplete='off' onSubmit={methods.handleSubmit(handleSubmitForm)} sx={{ padding: 0, width: "100%", height: "100%" }}>
        <FormControl>
          <FormLabel id="task-selection-label">Task</FormLabel>
          <RadioGroup
            row
            name="task-selection-radio-group"
            value={fieldTaskSelectionType}
            onChange={(e) => {
              var value = e.target.value;
              var enmValue: TaskSelectionEntry = TaskSelectionEntry[value as keyof typeof TaskSelectionEntry];
              setFieldTaskSelectionType(TaskSelectionEntry[enmValue]);
            }}
          >
            <FormControlLabel value={TaskSelectionEntry[TaskSelectionEntry.EXISTING]} control={<Radio />} label="Existing" />
            <FormControlLabel value={TaskSelectionEntry[TaskSelectionEntry.NEW]} control={<Radio />} label="New" />
          </RadioGroup>
        </FormControl>
        {fieldTaskSelectionType === TaskSelectionEntry[TaskSelectionEntry.EXISTING] &&
          <AutoCompleteTasks
            required
            id="task"
            key="task"
            error={fieldTaskId === null ? true : false}
            helperText={fieldTaskId === null ? "Field is Required." : ""}
            valueGetter={(value) => fieldTaskValue(value)}
          />
        }
        {fieldTaskSelectionType === TaskSelectionEntry[TaskSelectionEntry.NEW] &&
          <FormNewTask 
            onChange={(task: ASDtos.TaskReadDto) => {
              //console.log("FormNewTask task: ", task);
              setFieldTaskType(task.type);
              setFieldTaskPriority(task.priority);
              setFieldTaskTitle(task.title);
              setFieldTaskDescription(task.description);
              setFieldTaskEstimation(task.estimation?task.estimation:0);
              setFieldTaskExecution(task.execution?task.execution:0);
            }}
          />
        }
        <br /><br />
        {props.changes.map((change) => {
          return (
            <Box key={change.apiRef}>
              <FormControlLabel
                label={HTMLParser(DOMPurify.sanitize(change.data.text))}
                control={
                  <Checkbox
                    checked={fieldChanges.some((changeId) => changeId === change.apiRef)}
                    onChange={(e) => handleChangeSelection(e, change.apiRef)}
                  />
                }
              />
            </Box>
          );
        })}
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <br /><br /><br />
          <SmartButton onSubmit={() => { props.formIsDone(null, []) }}>Cancel</SmartButton>
          <SmartButton isSubmiting={isButtonSubmiting} onSubmit={methods.handleSubmit(handleSubmitForm)}>Add Change</SmartButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}