import React, { useEffect, useState } from 'react'; // let's also import Component

import { DetailContainerEntry, ScreenWideEntry } from 'app/AppValues';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { setScreenWideDetailDrawer } from 'reducers/detailDrawerReducer';

import DetailContainerNode from 'components/DetailContainerNode';
import DetailContainerTask from 'components/DetailContainerTask';
import DetailContainerPackage from 'components/DetailContainerPackage';


import {
  Stack,
  Drawer,
  Box,
  Divider,
  IconButton,
} from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';

export default function DetailDrawer() {
  //Redux States
  const dispatch = useAppDispatch();
  const openDetailsScreenWide = useAppSelector((state) => state.detailDrawer.screenWide);
  const openBoardsScreenWide = useAppSelector((state) => state.boardDrawer.screenWide);
  const detailContainer = useAppSelector((state) => state.detailDrawer.detailContainer);
  const detailContentId = useAppSelector((state) => state.detailDrawer.detailContentId);
  //Redux States

  const [contentId, setContentId] = useState(0);

  const [drawerWidth, setDrawerWidth] = useState("25%");
  const [drawerHeight, setDrawerHeight] = useState("100%");

  //Handles
  function handleDrawerClose() {
    dispatch(setScreenWideDetailDrawer(ScreenWideEntry.HIDE));
  }
  function handleDrawerExpand() {
    if (openDetailsScreenWide === ScreenWideEntry.HIDE) {
      dispatch(setScreenWideDetailDrawer(ScreenWideEntry.QUARTER));
    }
    if (openDetailsScreenWide === ScreenWideEntry.QUARTER) {
      dispatch(setScreenWideDetailDrawer(ScreenWideEntry.HALF));
    }
    if (openDetailsScreenWide === ScreenWideEntry.HALF) {
      dispatch(setScreenWideDetailDrawer(ScreenWideEntry.THREEQUARTERS));
    }
    if (openDetailsScreenWide === ScreenWideEntry.THREEQUARTERS) {
      dispatch(setScreenWideDetailDrawer(ScreenWideEntry.FULL));
    }
  }

  function handleDrawerCollapse() {
    if (openDetailsScreenWide === ScreenWideEntry.FULL) {
      dispatch(setScreenWideDetailDrawer(ScreenWideEntry.THREEQUARTERS));
    }
    if (openDetailsScreenWide === ScreenWideEntry.THREEQUARTERS) {
      dispatch(setScreenWideDetailDrawer(ScreenWideEntry.HALF));
    }
    if (openDetailsScreenWide === ScreenWideEntry.HALF) {
      dispatch(setScreenWideDetailDrawer(ScreenWideEntry.QUARTER));
    }
    if (openDetailsScreenWide === ScreenWideEntry.QUARTER) {
      dispatch(setScreenWideDetailDrawer(ScreenWideEntry.HIDE));
    }
  }

  function handleDetailHeight() {
    //Adapt Details Height Inverting Boards Width
    switch (openBoardsScreenWide) {
      case ScreenWideEntry.HIDE:
        setDrawerHeight("100%");
        break;
      case ScreenWideEntry.QUARTER:
        setDrawerHeight("75%");
        break;
      case ScreenWideEntry.HALF:
        setDrawerHeight("50%");
        break;
      case ScreenWideEntry.THREEQUARTERS:
        setDrawerHeight("25%");
        break;
      case ScreenWideEntry.FULL:
        setDrawerHeight("0%");
        break;
    }

  }
  // OnLoad
  useEffect(() => {
    setContentId(detailContentId);
  }, [detailContentId]);

  //Whenever DetailScreenWide Value Changes Recalculate percentage for WIDTH
  useEffect(() => {
    //Adapt Width
    switch (openDetailsScreenWide) {
      case ScreenWideEntry.HIDE:
        setDrawerWidth("0%");
        break;
      case ScreenWideEntry.QUARTER:
        setDrawerWidth("25%");
        break;
      case ScreenWideEntry.HALF:
        setDrawerWidth("50%");
        break;
      case ScreenWideEntry.THREEQUARTERS:
        setDrawerWidth("75%");
        break;
      case ScreenWideEntry.FULL:
        setDrawerWidth("100%");
        break;
    }
    
    handleDetailHeight();
  }, [openDetailsScreenWide]);

  //Whenever BoardScreenWide Value Changes Recalculate percentage for HEIGH
  useEffect(() => {
    if (openDetailsScreenWide !== ScreenWideEntry.HIDE) {
      handleDetailHeight();
    }
  }, [openBoardsScreenWide]);

  // OnRender
  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          height: drawerHeight,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            height: drawerHeight
          },
        }}
        variant="persistent"
        anchor="right"
        open={openDetailsScreenWide !== ScreenWideEntry.HIDE}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
          sx={{ p: 1 }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box>
              <IconButton sx={{ ":hover": { backgroundColor: "#D4DBF5" } }}
                onClick={handleDrawerExpand}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton sx={{ ":hover": { backgroundColor: "#D4DBF5" } }}
                onClick={handleDrawerClose}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
              <IconButton sx={{ ":hover": { backgroundColor: "#D4DBF5" } }}
                onClick={handleDrawerCollapse}
              >
                <ChevronRightIcon />
              </IconButton>
            </Box>
          </Stack>
          <Divider />
          <Box>
            {(detailContainer === DetailContainerEntry.NODE) && <DetailContainerNode nodeId={contentId} />}
            {(detailContainer === DetailContainerEntry.TASK) && <DetailContainerTask taskId={contentId} />}
            {(detailContainer === DetailContainerEntry.PACKAGE) && <DetailContainerPackage packageId={contentId} />}

          </Box>
          <Divider />
          <Box>
            Footer
          </Box>
        </Stack>
      </Drawer>
    </>
  );
}