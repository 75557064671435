export class CanvasMap {
  private _planeOriginX!: number;
  private _planeOriginY!: number;
  private _planeWidth!: number;
  private _planeHeight!: number;
  
  private _originX!: number;
  private _originY!: number;

  private _zoom!: number;
  private _level!: number;

  public get planeOriginX(): number {
    return this._planeOriginX;
  }
  public get planeOriginY(): number {
    return this._planeOriginY;
  }

  public get planeWidth(): number {
    return this._planeWidth;
  }
  public get planeHeight(): number {
    return this._planeHeight;
  }

  public get originX(): number {
    return this._originX - this._planeOriginX;
  }
  public get originY(): number {
    return this._originY - this._planeOriginY;
  }
  public get zoom(): number {
    return this._zoom;
  }
  public get level(): number {
    return this._level;
  }

  constructor(planeOriginX: number = 0, planeOriginY: number = 0, planeWidth: number = 0, planeHeight: number = 0, zoom: number = 1, level: number = 1) {
    this._planeOriginX = planeOriginX;
    this._planeOriginY = planeOriginY;
    this._planeWidth = planeWidth;
    this._planeHeight = planeHeight;

    this._originX = planeOriginX;
    this._originY = planeOriginY;
    this._zoom = zoom;
    this._level = level;
  }

  zoomInOut(zoom: number) {
    this._zoom *= zoom;
  }

  drillLevelIn() {
    this._level += 1;
  }

  drillLevelOut() {
    this._level -= 1;
  }

  move(movementX: number, movementY: number) {
    this._originX = this._originX + movementX;
    this._originY = this._originY + movementY;
  }
}