import React, { ChangeEvent, useEffect, useState } from 'react'; // let's also import Component
import { useForm, FormProvider } from "react-hook-form"


import DOMPurify from 'isomorphic-dompurify';
import HTMLParser from 'html-react-parser';

import { ASGateway, ASDtos } from 'api/AppServices';

import AutoCompleteAgents from 'components/AutoCompleteAgents';
import SmartButton from 'components/SmartButton';

import {
  Stack,
  Box,
  Checkbox,
  FormControlLabel,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio
} from '@mui/material';

type IFormAddAgentToTeamProps = {
  teamId: number,
  formIsDone: (agentId: number | null) => void;
};

export default function FormAddAgentToTeam(props: IFormAddAgentToTeamProps) {
  const methods = useForm({ mode: 'all' });

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [fieldAgentId, setFieldAgentId] = useState<number | null>(null);

  //Handles
  async function handleSubmitForm() {
    var agentIdAssigned = fieldAgentId;
    if (agentIdAssigned !== null) {
      //ASSOCIATE TASK TO CHANGES
      setIsButtonSubmiting(true);
      await ASGateway.Teams.AddAgent(
        props.teamId,
        agentIdAssigned
      )
        .then((data: ASDtos.AgentReadDto) => {
          //console.log('UpdateTaskAssignChanges: ', data);
        })
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          setIsButtonSubmiting(false);
        });
    }
    else {
      //Exit handleSubmit without closing form Dialog
      return;
    }
    props.formIsDone(agentIdAssigned);
  }

  // OnLoad


  // OnRender
  return (
    <FormProvider {...methods}>
      <Box id='addAgentToTeamForm' component='form' noValidate autoComplete='off' onSubmit={methods.handleSubmit(handleSubmitForm)} sx={{ padding: 0, width: "100%", height: "100%" }}>
        <AutoCompleteAgents
          required
          id="task"
          key="task"
          error={fieldAgentId === null ? true : false}
          helperText={fieldAgentId === null ? "Field is Required." : ""}
          onChange={(value: number | null) => setFieldAgentId(value)}
        />
        <br /><br />
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <br /><br /><br />
          <SmartButton onSubmit={() => { props.formIsDone(null) }}>Cancel</SmartButton>
          <SmartButton isSubmiting={isButtonSubmiting} onSubmit={methods.handleSubmit(handleSubmitForm)}>Add Agent</SmartButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}