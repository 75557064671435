import { useEffect, useState, useMemo, ReactNode } from 'react';

import { useAppSelector } from 'hooks/StoreReduxHooks';
import useNotification, { INotification } from 'hooks/NotificationHooks';

import { ASGateway, ASDtos } from 'api/AppServices';
import { ContextSetup } from 'app/AppValues';

import FormNewEnvironment from 'components/Forms/FormNewEnvironment';
import SmartButton from 'components/SmartButton';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
} from '@mui/material';

import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowModesModel,
  GridRowParams,
  GridRowId,
  GridActionsCellItem,
} from '@mui/x-data-grid';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import 'App.css';

interface ISetupEnvironmentsProps {
  label: ReactNode;
  context: ContextSetup;
  defaultExpanded: boolean;
  filterTeams: number[];
};

export default function SetupEnvironments(props: ISetupEnvironmentsProps) {
  //Redux States
  const userSession = useAppSelector((state) => state.appSession.user);
  const companySession = useAppSelector((state) => state.appSession.company);
  //Redux States
  const notification = useNotification(); //Miliseconds
  const [loading, setLoading] = useState(true);

  const [environments, setEnvironments] = useState<ASDtos.EnvironmentReadDto[]>([]);

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel | undefined>(undefined);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({ actions: true });

  const [showCreateEnvironment, setShowCreateEnvironment] = useState<boolean>(false);

  function fetchEnvironments(): void {
      if (props.filterTeams[0]) {
        ASGateway.Environments.GetAll(props.filterTeams[0])
          .then((data: ASDtos.EnvironmentReadDto[]) => {
            setEnvironments(data);
          })
          .catch((e: any) => {
            console.error(e);
          });
      }
  }

  useEffect(() => {
    fetchEnvironments();
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchEnvironments();
  }, [props]);

  const columns: GridColDef<ASDtos.EnvironmentReadDto>[] = useMemo(() => [
    { field: 'id', headerName: 'Env. Id', headerClassName: 'sx-grid-header', width: 100, editable: false },
    { field: 'name', headerName: 'Name', headerClassName: 'sx-grid-header', width: 300, editable: false },
    {
      field: 'actions', headerName: 'Actions', headerClassName: 'sx-grid-header', type: 'actions', 
      getActions: (row: GridRowParams<ASDtos.EnvironmentReadDto>) => {
        var result: React.JSX.Element[] = [];
        result.push(<GridActionsCellItem label="Delete" icon={<Tooltip title="Delete"><DeleteForeverIcon /></Tooltip>} onClick={() => handleDeleteClick(row.id)} />);
        return result;
      }
    }
  ], [props.filterTeams]);

  function handleDeleteClick(id: GridRowId) {
    ASGateway.Environments.Delete(props.filterTeams[0], Number(id))
      .then((data: ASDtos.EnvironmentReadDto) => {
        notification.addNotification({
          severity: "success",
          message: "Environment has been sucessfully deleted.",
        } as INotification);
        fetchEnvironments();
      })
      .catch((e: any) => {
        console.error(e);
        notification.addNotification({
          severity: "error",
          message: e.message,
        } as INotification);
      });
  }

  return (
    <Box sx={{ p: 1 }}>
      <Accordion disableGutters defaultExpanded={props.defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="node-detail-summary"
          sx={{ backgroundColor: "rgba(212, 219, 245, 0.50)" }} //#D4DBF5 with 50% transparency
        >
          Environments
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
            sx={{ p: 1 }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <SmartButton onSubmit={() => { setShowCreateEnvironment(true); }}>New Environment</SmartButton>
            </Stack>
            {loading === false &&
              <DataGrid
                editMode="cell"
                getRowId={(row) => row.id}
                getRowHeight={() => 'auto'}
                rows={environments}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                rowModesModel={rowModesModel}

                slotProps={{
                  toolbar: { setEnvironments, setRowModesModel },
                }}

                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 20]}
                sx={{
                  '& .sx-grid-header': {
                    backgroundColor: '#F3F3F3',
                  },
                  '& .MuiDataGrid-filler': {
                    backgroundColor: '#F3F3F3',
                  },                  
                  '& .MuiDataGrid-filler.css-8yphpr': {
                    backgroundColor: '#FFFFFF',
                  },
                  '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '&.MuiDataGrid-root': {
                    border: 'none',
                  },
                }}
              />
            }
          </Stack>
        </AccordionDetails>
      </Accordion>


      {/* DIALOG FOR CREATE ENVIRONMENT */}
      <Dialog fullScreen={false} open={showCreateEnvironment} onClose={() => setShowCreateEnvironment(false)}>
        <DialogTitle>New Environment</DialogTitle>
        <DialogContent sx={{ p: 2, width: "500px", maxHeight: "500px" }}>
          <FormNewEnvironment
            teamId={props.filterTeams[0]}
            formIsDone={(agentId) => { setShowCreateEnvironment(false); fetchEnvironments(); }}
          />
        </DialogContent>
      </Dialog>

    </Box>
  );
}
