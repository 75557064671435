import React, { useEffect } from "react";
import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'hooks/StoreReduxHooks';

import FormAuthLogin from 'components/Forms/FormAuthLogin';
import FormAuthCredentialsReset from 'components/Forms/FormAuthCredentialsReset';

import {
  Box,
  CssBaseline,
  Paper,
  Stack,
} from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

export enum LoginMode {
  LOGIN,
  RESET
}

interface ILoginProps {
  mode: LoginMode
}

export default function Login(props: ILoginProps) {
  //Redux States
  const userSession = useAppSelector((state) => state.appSession.user);
  //Redux States

  useEffect(() => {
    if (props.mode === LoginMode.RESET) {
      //Load User Data????

    }
  }, []);

  if (userSession.hasActiveSession) {
    return <Navigate to="/" />;
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Stack direction="column" justifyContent="space-between" alignItems="center" spacing={20}>
        <Box />
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Paper
            sx={{
              width: {
                xs: "100%",
                sm: "450px"
              },
              align: "center",
              background: "#f5f5f5",
              p: 2, m: 2
            }}
          >
            {(props.mode === LoginMode.LOGIN) &&
              <FormAuthLogin />
            }
            {(props.mode === LoginMode.RESET) &&
              <FormAuthCredentialsReset />
            }
            </Paper>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};
