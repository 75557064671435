import { ICanvasInitiative } from 'middleware/canvas/ICanvasInitiative';
import { BaseCanvasObject } from 'middleware/canvas/BaseCanvasObject';
import { CanvasMap } from 'middleware/canvas/CanvasMap'
import { TextHelper } from 'middleware/canvas/TextHelper';

import { ASDtos } from 'api/AppServices';
import { IRoadMapColumn } from 'app/AppInterfaces';
import { AppUtils } from 'app/AppUtils';
import { DateUtils } from 'app/DateUtils';
import { CanvasNodeArea } from 'app/AppValues';

import iconDelete from 'images/icons/google-fonts/close.svg';
import iconAdd from 'images/icons/google-fonts/add.svg';
import iconMenu from 'images/icons/google-fonts/menu_open.svg';

export class InitiativeNode extends BaseCanvasObject implements ICanvasInitiative {
  private startDate?: Date;
  private endDate?: Date;
  private expanded: boolean = true;
  private roadmapColumns: IRoadMapColumn[]

  private changed: boolean;

  private includeStartDate: boolean = false;
  private includeEndDate: boolean = false;

  private textHelper = new TextHelper();

  private iconActionRemoveImg = new Image();
  private iconActionAddImg = new Image();
  private iconMenuImg = new Image();

  constructor(
    initiativeId: number,
    name: string,
    posY: number,
    roadmapColumns: IRoadMapColumn[],
    startDate?: Date,
    endDate?: Date,
  ) {

    super(
      initiativeId,
      name,
      initiativeId,
      { x: 0, y: 0, w: 0, h: 0 } //Must be reseted after dates calculation bellow
    );

    this.startDate = startDate;
    this.endDate = endDate;
    this.expanded = false;
    this.roadmapColumns = [...roadmapColumns];

    this.changed = false;

    this.iconActionRemoveImg.src = iconDelete;
    this.iconActionAddImg.src = iconAdd;
    this.iconMenuImg.src = iconMenu;

    //Calculate position options from Dates
    this.resetOptionsFromDates(posY);
  }

  //IBaseCanvasObject Override
  setActive(value: boolean): void {
    /*Always Reset when active prop is changed*/
    this.changed = false;
    super.setActive(value);
  }
  setNodeAreaFromPointer(canvasMap: CanvasMap, pointX: number, pointY: number): CanvasNodeArea {
    var result = super.setNodeAreaFromPointer(canvasMap, pointX, pointY);

    if (this.includeStartDate && this.nodeAreaPointer === CanvasNodeArea.SIZERLEFT) {
      result = CanvasNodeArea.DEFAULT;
      this.nodeAreaPointer = result;
    }
    if (this.includeEndDate && this.nodeAreaPointer === CanvasNodeArea.SIZERRIGHT) {
      result = CanvasNodeArea.DEFAULT;
      this.nodeAreaPointer = result;
    }
    return result;
  }

  //ICanvasInitiative
  getStartDate(): Date | undefined { return this.startDate; }
  getEndDate(): Date | undefined { return this.endDate; }

  getStartRoadmapColFromOptions(): IRoadMapColumn | undefined {
    return this.roadmapColumns.find(value => super.getOptions().x >= value.sX && super.getOptions().x <= value.eX);
  }
  getEndRoadmapColFromOptions(): IRoadMapColumn | undefined {
    return this.roadmapColumns.find(value => (super.getOptions().x + super.getOptions().w) >= value.sX && (super.getOptions().x + super.getOptions().w) <= value.eX);
  }
  getStartRoadmapColFromDate(paramDate?: Date): IRoadMapColumn | undefined {
    const result = this.getRoadmapColFromDate(paramDate);
    if (result === undefined && this.includeStartDate) {
      //Return first col
      return this.roadmapColumns[0];
    }
    // console.log("getRoadmapColFromDate result: ", result);
    return result;
  }
  getEndRoadmapColFromDate(paramDate?: Date): IRoadMapColumn | undefined {
    const result = this.getRoadmapColFromDate(paramDate);
    if (result === undefined && this.includeEndDate) {
      //Return last col
      return this.roadmapColumns[this.roadmapColumns.length - 1];
    }
    // console.log("getRoadmapColFromDate result: ", result);
    return result;
  }
  switchExpansion(): void { this.expanded = !this.expanded; }
  switchToFromBacklog(posy: number): void {
    this.changed = true;
    if (this.isInBacklog()) {
      this.startDate = this.roadmapColumns[0].startDate;
      this.endDate = DateUtils.addDays(this.startDate, 10);
    }
    else {
      this.startDate = undefined;
      this.endDate = undefined;
    }
    //reset options from dates
    this.resetOptionsFromDates(posy);
  }

  isInBacklog() {
    // console.log("startDate: ", this.startDate);
    // console.log("endDate: ", this.endDate);
    var foundStart = this.getStartRoadmapColFromDate(this.startDate);
    var foundEnd = this.getEndRoadmapColFromDate(this.endDate);
    // console.log("foundStart: ", foundStart);
    // console.log("foundEnd: ", foundEnd);

    if (foundStart || foundEnd) {
      //Found!!
      return false;
    }

    return true;
  }

  isExpanded() { return this.expanded; }
  hasChanged() { return this.changed; }

  move(movementX: number, movementY: number): void {
    const mX = Math.round(movementX);
    const mY = Math.round(movementY);
    const options = super.getOptions();
    var result = super.getOptions();

    // console.log("super.getName: ", super.getName());
    // console.log("super.getNodeAreaPointer: ", super.getNodeAreaPointer());
    // console.log("this.isInBacklog: ", this.isInBacklog());
    //First available actions exclusively for Itens that are NOT in BackLog
    if (this.isInBacklog() === false){
      if (super.getNodeAreaPointer() === CanvasNodeArea.DEFAULT) {
        result.x = options.x + mX;
        this.changed = true;
      }
      if (super.getNodeAreaPointer() === CanvasNodeArea.SIZERLEFT) {
        result.x = options.x + mX;
        result.w = options.w - mX;
        this.changed = true;
      }
      if (super.getNodeAreaPointer() === CanvasNodeArea.SIZERRIGHT) {
        result.w = options.w + mX;
        this.changed = true;
      }
    }

    //Available Actions for Itens on both timeline and backlog
    if (super.getNodeAreaPointer() === CanvasNodeArea.MOVEVERTICAL) {
      result.y = options.y + mY;
      this.changed = true;
    }

    super.setOptions(result);

    if (super.getNodeAreaPointer() === CanvasNodeArea.DEFAULT) {
      //When we are moving the object might have one of the ends outside the current roadmap timeline
      //if so correct the options to the orignal value
      if (this.includeStartDate) {
        //console.log("move includeStartDate this.endDate: ", this.endDate);
        //Get the column of the other end to calculate how many days has changed
        var resultEndDate = this.getEndRoadmapColFromOptions()?.endDate;

        //when both are outside boundaries set days diff = 1 day
        var daysStartDiff = 0;
        if (this.includeEndDate) {
          daysStartDiff = (movementX > 0) ? 1 : -1;
        }
        else {
          //console.log("move includeStartDate resultEndDate: ", resultEndDate);
          daysStartDiff = DateUtils.getDiff(this.endDate, resultEndDate);
          //console.log("move includeStartDate daysDiff: ", daysStartDiff);
        }

        const newStartDate = DateUtils.addDays(this.startDate!, daysStartDiff);
        //console.log("move includeStartDate newStartDate: ", newStartDate);
        const newStartDateString = DateUtils.toStringYYYYMMDD(newStartDate);
        const firstColumnDateString = DateUtils.toStringYYYYMMDD(this.roadmapColumns[0].startDate);
        if (newStartDateString < firstColumnDateString) {
          result.x = options.x; //Reset X
          //When both ends are outside we do not change width of object because it is already in maximum allowed
          if ((this.includeStartDate && this.includeEndDate) === false) {
            result.w = options.w + mX; //Extend the object in its size
          }
          this.startDate = newStartDate;
        }
        else {
          this.includeStartDate = false;
        }
      }

      if (this.includeEndDate) {
        //Get the column of the other end to calculate how many days has changed
        var resultStartDate = this.getStartRoadmapColFromOptions()?.startDate;
        //when both are outside boundaries set days diff = 1 day
        var daysEndDiff = 0;
        if (this.includeStartDate) {
          daysEndDiff = (movementX > 0) ? 1 : -1;
        }
        else {
          // console.log("move includeStartDate resultStartDate: ", resultStartDate);
          // console.log("move includeStartDate this.startDate: ", this.startDate);
          daysEndDiff = DateUtils.getDiff(this.startDate, resultStartDate);
          // console.log("move includeStartDate daysDiff: ", daysEndDiff);
          // console.log("move includeStartDate this.startDate: ", this.startDate);
        }


        const newEndDate = DateUtils.addDays(this.endDate!, daysEndDiff);
        // console.log("move includeStartDate newEndDate: ", newEndDate);
        const newEndDateString = DateUtils.toStringYYYYMMDD(newEndDate);
        const lastColumnDateString = DateUtils.toStringYYYYMMDD(this.roadmapColumns[this.roadmapColumns.length - 1].endDate);
        if (newEndDateString > lastColumnDateString) {
          //When both ends are outside we do not change width of object because it is already in maximum allowed
          if ((this.includeStartDate && this.includeEndDate) === false) {
            result.w = options.w + (mX * -1); //Extend the object in its size
          }
          this.endDate = newEndDate;
        }
        else {
          this.includeEndDate = false;
        }
      }
    }

    super.setOptions(result);

    //Recalculate Dates
    if (super.getNodeAreaPointer() === CanvasNodeArea.DEFAULT || super.getNodeAreaPointer() === CanvasNodeArea.SIZERLEFT) {
      //Check if Start Date is affected
      const resultStart = this.getStartRoadmapColFromOptions();
      if (resultStart && !this.includeStartDate) {
        this.startDate = resultStart.startDate;
      }
    }

    if (super.getNodeAreaPointer() === CanvasNodeArea.DEFAULT || super.getNodeAreaPointer() === CanvasNodeArea.SIZERRIGHT) {
      //Check if EndDate is affected
      var resultEnd = this.getEndRoadmapColFromOptions();
      if (resultEnd && !this.includeEndDate) {
        this.endDate = resultEnd.endDate;
      }
    }
  }

  moveAsChild(parentNodeArea: CanvasNodeArea, movementX: number, movementY: number): void {
    const mX = Math.round(movementX);
    const mY = Math.round(movementY);

    const options = super.getOptions();
    var result = super.getOptions();

    //console.log("moveChild getNodeAreaPointer: ", parent.getNodeAreaPointer());
    if (parentNodeArea === CanvasNodeArea.DEFAULT) {
      //Only move horizontally posX when not in backlog
      if (!this.isInBacklog()) {
        result.x = options.x + mX;
      }
    }

    if (parentNodeArea === CanvasNodeArea.MOVEVERTICAL) {
      //Only move horizontally posX when not in backlog
      result.y = options.y + mY;
    }

    super.setOptions(result);

    //Recalculate Dates
    if (parentNodeArea === CanvasNodeArea.DEFAULT) {
      //Check if Start Date is affected
      var resultStart = this.roadmapColumns.find(value => super.getOptions().x > value.sX && super.getOptions().x < value.eX);
      if (resultStart) {
        this.startDate = resultStart.startDate;
      }

      //Check if EndDate is affected
      var resultEnd = this.roadmapColumns.find(value => (super.getOptions().x + super.getOptions().w) > value.sX && (super.getOptions().x + super.getOptions().w) < value.eX);
      if (resultEnd) {
        this.endDate = resultEnd.endDate;
      }
    }
  }

  moveAsParent(childStartDate?: Date, childEndDate?: Date): void {
    const prevOptions = { ...super.getOptions() };
    const childStartTime = DateUtils.parse(childStartDate)?.getTime();
    const startTime = DateUtils.parse(this.startDate)?.getTime();
    const childEndTime = DateUtils.parse(childEndDate)?.getTime();
    const endTime = DateUtils.parse(this.endDate)?.getTime();

    //console.log("moveAsParent START");
    //When moving as Parent we need to make sure that Child start and end will inside start and end date of the child
    if (childStartTime && startTime && childStartTime < startTime) {
      //Setup new Parent Start Date
      this.startDate = childStartDate;
      const startRoadmapCol = this.getStartRoadmapColFromDate(childStartDate);

      //console.log("moveAsParent startRoadmapCol: ", startRoadmapCol);
      if (startRoadmapCol) {
        const startMidleCol = (startRoadmapCol.eX - startRoadmapCol.sX) / 2;

        //Correct X
        const newX = startRoadmapCol.sX + startMidleCol;
        //Calculate total pixels was changed +-
        const dif = Math.abs(newX - prevOptions.x);
        //Correct W accordingly
        const newW = prevOptions.w + dif;

        super.setOptions({ ...prevOptions, x: newX, w: newW });
        //console.log("moveAsParent super.getOptions: ", {...super.getOptions()});
      }
    }

    // console.log("moveAsParent childEndTime: ", childEndTime);
    // console.log("moveAsParent endTime: ", endTime);
    if (childEndTime && endTime && childEndTime > endTime) {
      //Setup new Parent End Date
      this.endDate = childEndDate;
      const endRoadmapCol = this.getStartRoadmapColFromDate(childEndDate);

      //console.log("moveAsParent endRoadmapCol: ", endRoadmapCol);
      if (endRoadmapCol) {
        const endMidleCol = (endRoadmapCol.eX - endRoadmapCol.sX) / 2;

        //Correct W
        const newW = (endRoadmapCol.sX + endMidleCol) - prevOptions.x;

        super.setOptions({ ...prevOptions, w: newW });
      }
    }
    //console.log("moveAsParent END");
  }

  draw(canvasMap: CanvasMap, ctx: CanvasRenderingContext2D | null) {
    if (ctx) {
      ctx.save();

      //console.log(`BEGIN Draw InitiativeNode[${super.getId()}].options : `, {...super.getOptions()});
      var x = super.getOptions().x + canvasMap.originX;
      var y = super.getOptions().y + canvasMap.originY;
      var w = super.getOptions().w;
      var h = super.getOptions().h;

      ctx.strokeStyle = "#000000";
      ctx.fillStyle = "#FFFFFF";
      // if (this.onHover) {
      //   ctx.fillStyle = "#6699CC";
      // }

      // if (hasTasksDependentForEnv) {
      //   ctx.fillStyle = "red";
      // }
      // console.log("super.getName(): ", super.getName());
      // console.log("super.getOptions(): ", super.getOptions());
      ctx.beginPath();
      ctx.setLineDash([]);
      ctx.lineWidth = 0.5;
      ctx.roundRect(x, y, w, h, 5);
      ctx.stroke();
      ctx.fill();

      //Needs to draw before rectangle to stay on top
      if (this.includeStartDate) {
        ctx.beginPath();
        ctx.strokeStyle = "#FFFFFF";
        ctx.fillStyle = "lightgrey";
        ctx.lineWidth = 0.5;
        ctx.roundRect(x, y - 1, this.sizers.hPad + this.sizers.width, h + 2, 2);
        ctx.stroke();
        ctx.fill();
        ctx.fillStyle = "darkgrey";
        ctx.fillText(DateUtils.toStringLocale(this.startDate), x, y - 5);
      }
      if (this.includeEndDate) {
        ctx.beginPath();
        ctx.strokeStyle = "#FFFFFF";
        ctx.fillStyle = "lightgrey";
        ctx.lineWidth = 0.5;
        ctx.roundRect((x + w) - (this.sizers.hPad + this.sizers.width), y - 1, this.sizers.hPad + this.sizers.width, h + 2, 5);
        ctx.stroke();
        ctx.fill();
        ctx.fillStyle = "darkgrey";
        ctx.fillText(DateUtils.toStringLocale(this.endDate), (x + w) - 50, y - 5);
      }

      if (super.isActive()) {

        //Actions Zone
        //Rigth Side
        // | .... | EDIT | REMOVE/ADD | VMOVER | sizer |

        //REMOVE/ADD from timeline into backlog
        ctx.beginPath();
        ctx.strokeStyle = "darkgrey";
        ctx.fillStyle = "#D4DBF5";
        ctx.roundRect(
          (x + w) - ((this.action.width + this.action.hPad) + (this.vMover.width + this.sizers.hPad) + (this.sizers.width + this.sizers.hPad)),
          y + this.action.vPad,
          this.action.width, 24,
          5
        );
        ctx.stroke();
        if (super.getNodeAreaPointer() === CanvasNodeArea.ACTION) {
          ctx.fill();
        }

        var imageAction = this.iconActionRemoveImg;
        if (this.isInBacklog()){
          imageAction = this.iconActionAddImg;
        }
        ctx.drawImage(imageAction,
          (x + w) - ((this.action.width + this.action.hPad) + (this.vMover.width + this.sizers.hPad) + (this.sizers.width + this.sizers.hPad)),
          y + this.action.vPad + 2,
          this.action.width, 20
        );

        //Vertical Mover
        ctx.beginPath();
        ctx.strokeStyle = "#D4DBF5";
        ctx.fillStyle = "#D4DBF5";

        ctx.roundRect(
          (x + w) - ((this.vMover.width) + (this.sizers.hPad + this.sizers.width + this.sizers.hPad)),
          y + this.vMover.vPad,
          this.vMover.width,
          h - this.vMover.vPad * 2, //Top and Bottom
          5
        );
        ctx.stroke();
        if (super.getNodeAreaPointer() === CanvasNodeArea.MOVEVERTICAL) {
          ctx.fill();
        }

        //Vertical mover dots
        // ctx.strokeStyle = "#D4DBF5";
        // ctx.fillStyle = "#D4DBF5";
        ctx.strokeStyle = "#FFFFFF";
        ctx.fillStyle = "darkgrey";
        ctx.beginPath();
        ctx.arc(
          (x + w) - (((this.vMover.width / 2) - 3) + (this.sizers.hPad + this.sizers.width + this.sizers.hPad)),
          y + this.vMover.vPad + 2 + 4,
          2, 0, 2 * Math.PI);
        ctx.arc(
          (x + w) - (((this.vMover.width / 2) + 3) + (this.sizers.hPad + this.sizers.width + this.sizers.hPad)),
          y + this.vMover.vPad + 2 + 4,
          2, 0, 2 * Math.PI);
        ctx.fill();

        ctx.beginPath();
        ctx.arc(
          (x + w) - (((this.vMover.width / 2) - 3) + (this.sizers.hPad + this.sizers.width + this.sizers.hPad)),
          y + this.vMover.vPad + 2 + 10,
          2, 0, 2 * Math.PI);
        ctx.arc(
          (x + w) - (((this.vMover.width / 2) + 3) + (this.sizers.hPad + this.sizers.width + this.sizers.hPad)),
          y + this.vMover.vPad + 2 + 10,
          2, 0, 2 * Math.PI);
        ctx.fill();

        ctx.beginPath();
        ctx.arc(
          (x + w) - (((this.vMover.width / 2) - 3) + (this.sizers.hPad + this.sizers.width + this.sizers.hPad)),
          y + this.vMover.vPad + 2 + 16,
          2, 0, 2 * Math.PI);
        ctx.arc(
          (x + w) - (((this.vMover.width / 2) + 3) + (this.sizers.hPad + this.sizers.width + this.sizers.hPad)),
          y + this.vMover.vPad + 2 + 16,
          2, 0, 2 * Math.PI);
        ctx.fill();

        if (!this.isInBacklog()) {
          //Selection Columns
          ctx.beginPath();
          ctx.setLineDash([3, 5]);
          ctx.lineWidth = 0.5;
          ctx.strokeStyle = 'blue';

          if (!this.includeStartDate) {
            //Start Column Line UP
            ctx.moveTo(x, canvasMap.originY + 70);
            ctx.lineTo(x, y);
          }

          if (!this.includeEndDate) {
            //End Column Line UP
            ctx.moveTo(x + w, canvasMap.originY + 70);
            ctx.lineTo(x + w, y);
          }
          ctx.stroke();

          //SIZERS
          ctx.setLineDash([]);
          ctx.lineWidth = 0.5;
          ctx.strokeStyle = 'red';
          ctx.fillStyle = "red";
          ctx.lineWidth = 0.5;

          //START sizer
          if (!this.includeStartDate) {
            ctx.beginPath();
            ctx.roundRect(
              x + this.sizers.hPad,
              y + this.sizers.vPad,
              this.sizers.width,
              h - this.sizers.vPad * 2, //Top and Bottom
              5
            );
            ctx.stroke();
            if (super.getNodeAreaPointer() === CanvasNodeArea.SIZERLEFT) {
              ctx.fill();
            }
          }
          //END sizer
          if (!this.includeEndDate) {
            ctx.beginPath();
            ctx.roundRect(
              (x + w) - (this.sizers.hPad + this.sizers.width),
              y + this.sizers.vPad,
              this.sizers.width,
              h - this.sizers.vPad * 2, //Top and Bottom
              5
            );
            ctx.stroke();
            if (super.getNodeAreaPointer() === CanvasNodeArea.SIZERRIGHT) {
              ctx.fill();
            }
          }
        }
      }
      // ctx.textAlign = "left";
      // ctx.textBaseline = "alphabetic";
      // ctx.fillStyle = "#000000";
      // ctx.fillText(`#${this.productId}`, x + 3, y + 13);

      ctx.beginPath();
      ctx.textAlign = "left";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#000000";

      //console.log(`Draw InitiativeNode[${this.initiativeId}] CHEGOU`);
      var dateDiff = "";

      const name = super.getName() + dateDiff;
      var sX = x + this.sizers.hPad + this.sizers.width + this.sizers.hPad;
      this.textHelper.drawTextDynamicSizeAndFont(ctx, name, sX, y, w, h);
      //console.log(`Draw InitiativeNode[${this.initiativeId}] PASSOU`);

      ctx.font = "8px sans-serif";
      const dateLocale = "en-GB";
      const dateFormat = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };

      // console.log("typeof this.startDate: ", typeof this.startDate);
      // console.log("this.startDate: ", this.startDate);
      // console.log("this.endDate: ", this.endDate);

      if (this.startDate) {
        //ctx.fillText(DateUtils.toStringLocale(this.startDate), x, y - 5);
      }
      if (this.endDate) {
        //ctx.fillText(DateUtils.toStringLocale(this.endDate), x + w, y - 5);
      }

      ctx.restore();
      //console.log(`END Draw InitiativeNode[${super.getId()}].options : `, {...super.getOptions()});
    }
  }

  private getRoadmapColFromDate(paramDate?: Date): IRoadMapColumn | undefined {
    const paramDateString = DateUtils.toStringYYYYMMDD(paramDate);
    const result = this.roadmapColumns.find(value => {

      const valueStartDatestring = DateUtils.toStringYYYYMMDD(value.startDate);
      const valueEndDatestring = DateUtils.toStringYYYYMMDD(value.endDate);
      // console.log("getRoadmapColFromDate name: ", super.getName());
      // console.log("getRoadmapColFromDate valueStartDatestring: ", valueStartDatestring);
      // console.log("getRoadmapColFromDate valueEndDatestring: ", valueEndDatestring);

      const resultFind = (paramDateString >= valueStartDatestring && paramDateString <= valueEndDatestring);
      //  console.log("getRoadmapColFromDate resultFind: ", resultFind);
      return resultFind;
    });
    // console.log("getRoadmapColFromDate result: ", result);
    return result;
  }
 private resetOptionsFromDates(posY: number){
  var startX: number = 0;
  var endX: number = 0;

  //Get cols from Raw Method without flag calculation.
  const foundStartUnflagged = this.getRoadmapColFromDate(this.startDate);
  const foundEndUnflagged = this.getRoadmapColFromDate(this.endDate);

  //Calculate flags
  //When we do not have START COL found check if we have END COL
  if (foundStartUnflagged === undefined && foundEndUnflagged) {
    //flag it to be included
    this.includeStartDate = true;
  }
  //When we do not have END COL found check if we have START COL
  //flag it
  if (foundEndUnflagged === undefined && foundStartUnflagged) {
    //flag it to be included
    this.includeEndDate = true;
  }

  // console.log("constructor: ", super.getName());
  // console.log("constructor this.startDate: ", this.startDate);
  // console.log("constructor foundStart: ", foundStart);
  // console.log("constructor this.endDate: ", this.endDate);
  // console.log("constructor foundEnd: ", foundEnd);
  //Get cols already with flags calculated.
  const foundStart = this.getStartRoadmapColFromDate(this.startDate);
  const foundEnd = this.getEndRoadmapColFromDate(this.endDate);

  if (foundStart) {
    startX = foundStart.sX + 1;
  }
  if (foundEnd) {
    endX = foundEnd.eX - 1;
  }

  super.setOptions({
    x: (this.isInBacklog()) ? 80 : startX,
    y: posY,
    w: (this.isInBacklog()) ? 155 : endX - startX,
    h: 30
  });

 }

}