import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'hooks/StoreReduxHooks';
import { useWindowSize } from 'hooks/WindowResize';

import { ContextSetup } from 'app/AppValues';

import MenuBar from 'components/MenuBar';
import TeamMainBar from 'components/Team/TeamMainBar';
import SetupContainer from 'components/Setup/SetupContainer';
import SetupAgents from 'components/Setup/SetupAgents';
import SetupEnvironments from 'components/Setup/SetupEnvironments';
import TabContainer from 'components/TabContainer';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

import 'App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

export default function Team() {
  //Redux States
  const userSession = useAppSelector((state) => state.appSession.user);
  const companySession = useAppSelector((state) => state.appSession.company);
  //Redux States

  const [width, height] = useWindowSize();

  const leftPadding = 300;
  const rightPadding = 20;
  const topBarPadding = 10;
  const topContainerPadding = 90;
  const barLeftRightPadding = leftPadding + rightPadding;
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);


  const [filterTeams, setFilterTeams] = useState<number[]>([]);

  function resizeWindow(): void {
    setContainerWidth(width - barLeftRightPadding);
    setContainerHeight(height - topContainerPadding);
  }

  useEffect(() => {
    resizeWindow();
  }, [width, height]);

  if (!userSession.hasActiveSession) {
    return <Navigate to="/login" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <MenuBar pinBar={true} />
      <TeamMainBar leftPadding={leftPadding} topPadding={topBarPadding}
        onChangeTeams={(teamsSelected: number[]) => {
          setFilterTeams(teamsSelected);
        }}
      />
      <SetupContainer topPadding={topContainerPadding} leftPadding={leftPadding} width={containerWidth} height={containerHeight}>
        <TabContainer height={containerHeight}>
          <SetupAgents label="Agents" context={ContextSetup.TEAM} defaultExpanded={true} filterTeams={filterTeams} />
          <SetupEnvironments label="Environments" context={ContextSetup.TEAM} defaultExpanded={true} filterTeams={filterTeams} />
        </TabContainer>
      </SetupContainer>
    </ThemeProvider >
  );
}
