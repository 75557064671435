import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';

export async function GetAll() : Promise<ASDtos.PackageReadDto[]> {
  var urlResource = `/packages`;
  //console.log('GetPackages URL: ', { url: urlResource });
  return await CallService<ASDtos.PackageReadDto[]>(urlResource);
}
export async function GetByStatus(status: ASDtos.PackageStatusDto) : Promise<ASDtos.PackageReadDto[]> {
  var urlResource = `/packages/status/${status}`;
  //console.log('GetPackages URL: ', { url: urlResource });
  return await CallService<ASDtos.PackageReadDto[]>(urlResource);
}

export async function GetById(packId: number) : Promise<ASDtos.PackageReadDto> {
  var urlResource = `/packages/${packId}`;
  //console.log('GetPackages URL: ', { url: urlResource });
  return await CallService<ASDtos.PackageReadDto>(urlResource);
}

export async function Create(versionId: number, pack: ASDtos.PackageCreateDto) : Promise<ASDtos.PackageReadDto> {
  var urlResource = `/versions/${versionId}/packages`;
  //console.log('CreatePackage URL: ', { url: urlResource });
  return await CallService<ASDtos.PackageReadDto>(urlResource, "POST", pack);
}

export async function Search(searchTerm: string) : Promise<ASDtos.PackageReadDto[]>{
  var urlResource = `/packages/search`;

    //Build Task payload
    const payload = {
      "searchTerms": searchTerm.split(" "),
    } as ASDtos.PackageSearchDto;
  
  //console.log('GetNodeById URL: ', { url: urlResource });
  return await CallService<ASDtos.PackageReadDto[]>(urlResource, "POST", payload);
}

export async function AddEnvironment(packageId: number, envId: number) : Promise<ASDtos.PackageReadDto> {
  var urlResource = `/packages/${packageId}/environment/${envId}`;
  //console.log('UpdateTaskAddToPackage URL: ', { url: urlResource });
  return await CallService<ASDtos.PackageReadDto>(urlResource, "PUT", null);
}

export async function Release(packageId: number) : Promise<ASDtos.PackageReadDto> {
  var urlResource = `/packages/${packageId}/environment/prod`;
  //console.log('UpdateTaskAddToPackage URL: ', { url: urlResource });
  return await CallService<ASDtos.PackageReadDto>(urlResource, "PUT", null);
}

export async function AddTask(
  packageId: number,
  taskId: number
) : Promise<ASDtos.PackageReadDto> {
  var urlResource = `/packages/${packageId}/tasks/${taskId}`;
  //console.log('UpdateTaskAddToPackage URL: ', { url: urlResource });
  return await CallService<ASDtos.PackageReadDto>(urlResource, "PUT", null);
}

