//https://react-redux.js.org/tutorials/typescript-quick-start
import { createSlice, PayloadAction  } from '@reduxjs/toolkit'
import { ASDtos } from 'api/AppServices';
import { CanvasViewEntry } from 'app/AppValues';

// Define a type for the slice state
interface ISystemBarState {
  teams: number[],
  version: number,
  environment: number,
  tasksSelected: ASDtos.NodeTaskReadDto[],
  canvasViewSelected: CanvasViewEntry,
  developerMode: boolean
}

// Define the initial state using that type
const initialState: ISystemBarState = {
  teams: [],
  version: 0,
  environment: 0,
  tasksSelected: [],
  canvasViewSelected: CanvasViewEntry.SINGLE,
  developerMode: false
}

export const systemBarReducer = createSlice({
  name: 'systemBarState',
  initialState: initialState,
  reducers: {
    setTeamsSelected: (state, teams: PayloadAction<number[]>) => {
      state.teams = teams.payload;
    },
    setVersionSelected: (state, version: PayloadAction<number>) => {
      state.version = version.payload;
    },
    setEnvironmentSelected: (state, env: PayloadAction<number>) => {
      state.environment = env.payload;
    },
    setTasksSelected: (state, tasks: PayloadAction<ASDtos.NodeTaskReadDto[]>) => {
      state.tasksSelected = tasks.payload;
    },
    setCanvasViewSelected: (state, view: PayloadAction<CanvasViewEntry>) => {
      state.canvasViewSelected = view.payload;
    },
    setDeveloperMode: (state, developerMode: PayloadAction<boolean>) => {
      state.developerMode = developerMode.payload;
    },

  },
})

// Action creators are generated for each case reducer function
export const { 
  setTeamsSelected,
  setVersionSelected,
  setEnvironmentSelected,
  setTasksSelected,
  setCanvasViewSelected,
  setDeveloperMode
} = systemBarReducer.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
export default systemBarReducer.reducer