//https://medium.com/@mateuszpalka/creating-your-custom-svg-icon-library-in-react-a5ff1c4c704a
//https://www.freecodecamp.org/news/react-suspense/

import type { HTMLAttributes } from "react";
import { createElement, Suspense } from "react";

import CircularProgress from '@mui/material/CircularProgress';
import SvgIcon, { SvgIconPropsColorOverrides } from '@mui/material/SvgIcon';

import { OverridableStringUnion } from "@mui/types";

import { IconSvgList } from "app/IconSvgList";
export type IconName = keyof typeof IconSvgList;

// Props can vary from project to project, some will require to have some specific variant passed for styling, 
// others will extend base css classes with custom prop class etc
interface Props extends HTMLAttributes<HTMLDivElement> {
  icon: IconName;
  color?: OverridableStringUnion<"info" | "disabled" | "action" | "inherit" | "primary" | "secondary" | "error" | "success" | "warning", SvgIconPropsColorOverrides> | undefined;
  className?: string;
}

/** 
 * @param icon string key icon name
 * @param color The color of the component. 
                It supports both default and custom theme colors, which can be added as shown in the
                [palette customization guide](https://mui.com/material-ui/customization/palette/#custom-colors).
                You can use the `htmlColor` prop to apply a color attribute to the SVG element.
 * @param className string classes for styling
 * @returns Icon react component
 */
export default function IconSvg ({ icon, color, className }: Props) {
  return (
    <SvgIcon color={color} className={className}>
      <Suspense fallback={<CircularProgress/>}>
        {createElement(IconSvgList[icon], {})}
      </Suspense>
    </SvgIcon>
  );
};
                                                         