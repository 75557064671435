/**
 * inspired from https://www.yld.io/blog/global-notifications-with-reacts-context-api/
 */
import React, { useState, useCallback, ReactNode } from 'react';

export interface INotification {
  __id?: Date,
  timeout?: number,
  severity: 'error' | 'info' | 'success'| 'warning',
  message: string
  // any props that come into the component
}

interface INotificationContextProviderProps {
  children?: ReactNode
  // any props that come into the component
}

export const NotificationContext = React.createContext({
    notifications: [] as INotification[],
    addNotification: (notification: INotification) => {},
    removeNotification: (notification: INotification) => {},
});

export default function NotificationContextProvider({ children, ...props }: INotificationContextProviderProps) {
    const [notifications, setNotifications] = useState<INotification[]>([]);

    function id(notification: INotification) {
        return notification.__id;
    }

    const addNotification = (notification: INotification) => {
        notification.__id ??= new Date();
        setNotifications( (notifications)=>[...notifications, notification]);
        return id(notification)
    }

    const removeNotification = (notification: INotification) => {
        setNotifications( (notificationList) => {
            return notificationList.filter((current) => id(current) !== id(notification))
        })
    }

    const contextValue = {
      notifications: notifications,
        addNotification: useCallback((notification: INotification) => addNotification(notification), []),
        removeNotification: useCallback((notification: INotification) => removeNotification(notification), [])
    };

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    );
}