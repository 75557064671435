import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';


export async function GetForNodeVersion(version: number, nodeId: number): Promise<ASDtos.DocumentReadDto[]> {
  var urlResource = `/versions/${version}/nodes/${nodeId}/documents`;
  //console.log('GetNodeDocumentsForVersion URL: ', { url: urlResource });
  return await CallService<ASDtos.DocumentReadDto[]>(urlResource);
}

export async function GetForNode(nodeId: number): Promise<ASDtos.DocumentReadDto> {
  var urlResource = `/nodes/${nodeId}/documents`;
  //console.log('GetNodeDocuments URL: ', { url: urlResource });

  return await CallService<ASDtos.DocumentReadDto>(urlResource);
}

export async function Create(versionId: number, agentId: number, documentName: string, nodeId: number | undefined, taskId: number | undefined): Promise<ASDtos.DocumentReadDto> {
  var urlResource = `/versions/${versionId}/documents`;
  //console.log('UpdateDocumentContent URL: ', { url: urlResource });

  var payload: ASDtos.DocumentCreateDto = {
    name: documentName,
    agent: agentId,
    node: nodeId,
    task: taskId
  };

  //console.log('UpdateDocumentContent PayLoad: ', payload);
  return await CallService<ASDtos.DocumentReadDto>(urlResource, "POST", payload);
}

export async function UpdateContent(
  version: number,
  documentId: number,
  blocks: ASDtos.DocumentBlockUpdateDto[]
): Promise<ASDtos.DocumentBlockReadDto[]> {

  var urlResource = `/versions/${version}/documents/${documentId}/blocks`;
  console.log('UpdateDocumentContent URL: ', { url: urlResource });

  //Build DocumentBlock payload
  const payload = blocks.map((block: ASDtos.DocumentBlockUpdateDto) => {
    block.data = JSON.stringify(block.data);
    return block;    
  })
  return await CallService<ASDtos.DocumentBlockReadDto[]>(urlResource, "PUT", payload);
}
