import React, { useEffect, useState } from 'react'; // let's also import Component
import { useForm, Controller } from 'react-hook-form';

import { ASGateway, ASDtos } from 'api/AppServices';
import { FormSprintModeEntry } from 'app/AppValues';

import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box,
  TextField,
} from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

dayjs.extend(utc);
dayjs.extend(timezone);

type IFormSprintProps = {
  boardId: number,
  formMode: FormSprintModeEntry,
  sprintDetail?: ASDtos.BoardIterationReadDto,
  formIsDone: (sprintResult: ASDtos.BoardIterationReadDto) => void;
};

export default function FormSprint(props: IFormSprintProps) {

  const { control, register, handleSubmit, formState: { errors } } =
    useForm({
      mode: 'all',
      defaultValues: {
        sprintName: props.sprintDetail?.name as string,
        sprintStartDate: (props.sprintDetail?.startDate !== null)?dayjs(props.sprintDetail?.startDate) as Dayjs : null,
        sprintEndDate: (props.sprintDetail?.endDate !== null)?dayjs(props.sprintDetail?.endDate) as Dayjs : null,
        sprintGoals: props.sprintDetail?.goals,
      },
    });

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);

  const [fieldName, setFieldName] = useState('');
  const [fieldStartDate, setFieldStartDate] = React.useState<Dayjs | null>(null);
  const [fieldEndDate, setFieldEndDate] = React.useState<Dayjs | null>(null);
  const [fieldGoals, setFieldGoals] = useState('');

  //Handles
  async function handleStartSprintSubmit() {
    var sprint: ASDtos.BoardIterationCreateDto = {
      name: fieldName,
      startDate: fieldStartDate?.toDate(),
      endDate: fieldEndDate?.toDate(),
      goals: fieldGoals
    };
    //console.log("sprint: ", sprint);

    setIsButtonSubmiting(true);
    ASGateway.BoardIterations.Start(
      props.boardId,
      props.sprintDetail!.id,
      sprint
    )
      .then((data: ASDtos.BoardIterationReadDto) => {
        //console.log('StartBoardIteration: ', data);
        props.formIsDone(data);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });
  }
  async function handleCompleteSprintSubmit() {
    var sprint: ASDtos.BoardIterationCreateDto = {
      name: fieldName,
      startDate: fieldStartDate?.toDate(),
      endDate: fieldEndDate?.toDate(),
      goals: fieldGoals
    };
    //console.log("sprint: ", sprint);

    setIsButtonSubmiting(true);
    ASGateway.BoardIterations.Complete(
      props.boardId,
      props.sprintDetail!.id,
      sprint
    )
      .then((data: ASDtos.BoardIterationReadDto) => {
        //console.log('CreateBoardColumn: ', data);
        props.formIsDone(data);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });
  }

  async function handleDeleteSprintSubmit() {
    // .finally(() => {
    //   setIsButtonSubmiting(false);
    // });
  }

  async function handleEditSprintSubmit() {
    var sprint: ASDtos.BoardIterationUpdateDto = {
      name: fieldName,
      status: props.sprintDetail!.status,
      position: props.sprintDetail!.position,
      startDate: fieldStartDate?.toDate(),
      endDate: fieldEndDate?.toDate(),
      goals: fieldGoals
    };

    setIsButtonSubmiting(true);
    ASGateway.BoardIterations.Update(
      props.boardId,
      props.sprintDetail!.id,
      sprint
    )
      .then((data: ASDtos.BoardIterationReadDto) => {
        console.log('UpdateBoardIteration: ', data);
        props.formIsDone(data);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });
  }

  async function handleNewSprintSubmit() {
    var sprint: ASDtos.BoardIterationCreateDto = {
      name: fieldName,
      startDate: fieldStartDate?.toDate(),
      endDate: fieldEndDate?.toDate(),
      goals: fieldGoals
    };
    //console.log("sprint: ", sprint);

    setIsButtonSubmiting(true);
    ASGateway.BoardIterations.Create(
      props.boardId,
      sprint
    )
      .then((data: ASDtos.BoardIterationReadDto) => {
        //console.log('CreateBoardColumn: ', data);
        props.formIsDone(data);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });
  }

  // OnLoad
  useEffect(() => {
    //console.log("useEffect[FormSprint] sprintDetail: ", props.sprintDetail);
    if (props.sprintDetail){
      setFieldName(props.sprintDetail.name);
      setFieldStartDate((props.sprintDetail.startDate !== null)?dayjs(props.sprintDetail.startDate) as Dayjs : null);
      setFieldEndDate((props.sprintDetail.endDate !== null)?dayjs(props.sprintDetail.endDate) as Dayjs : null);
      setFieldGoals(props.sprintDetail.goals);
    }
  }, []);

  // OnRender
  return (
    <Box id='sprintForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleNewSprintSubmit)} sx={{ padding: 0, width: "100%", height: "100%" }}>
      <TextField
        required
        id="sprintName"
        key="sprintName"
        label="Sprint Name"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('sprintName', {
          required: { value: true, message: 'Field is Required.' },
          maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
          onChange: (e) => setFieldName(e.target.value),
        })}
        value={fieldName}
        error={errors?.sprintName ? true : false}
        helperText={errors?.sprintName?.message?.toString()}
      />
        <Controller
          control={control}
          name="sprintStartDate"
          rules={{
            required: { value: props.formMode === FormSprintModeEntry.START, message: "Field is Required." },
          }}
          render={({ field }) => {
            return (
              <DatePicker
                label="Start Date"
                timezone="Europe/Lisbon"
                format="DD/MM/YYYY"
                value={field.value}
                inputRef={field.ref}
                onChange={(date) => {
                  setFieldStartDate(date);
                  field.onChange(date);
                }}
                slotProps={{
                  textField: {
                    required: props.formMode === FormSprintModeEntry.START,
                    fullWidth: true,
                    autoFocus: true,
                    margin: "dense",
                    variant: 'filled',
                    size: 'small',
                    error: errors?.sprintStartDate ? true : false,
                    helperText: errors?.sprintStartDate?.message?.toString()
                  },
                  field: {
                    clearable: true,
                    onClear: () => {
                      setFieldStartDate(null);
                      field.onChange(null);
                    }
                  },
                }}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="sprintEndDate"
          rules={{
            required: { value: props.formMode === FormSprintModeEntry.START, message: "Field is Required." },
          }}
          render={({ field }) => {
            return (
              <DatePicker
                label="End Date"
                timezone="Europe/Lisbon"
                format="DD/MM/YYYY"
                value={field.value}
                inputRef={field.ref}
                onChange={(date) => {
                  setFieldEndDate(date);
                  field.onChange(date);
                }}
                slotProps={{
                  textField: {
                    required: props.formMode === FormSprintModeEntry.START,
                    fullWidth: true,
                    autoFocus: true,
                    margin: "dense",
                    variant: 'filled',
                    size: 'small',
                    error: errors?.sprintEndDate ? true : false,
                    helperText: errors?.sprintEndDate?.message?.toString()
                  },
                  field: {
                    clearable: true,
                    onClear: () => {
                      setFieldEndDate(null);
                      field.onChange(null);
                    }
                  },
                }}
              />
            );
          }}
        />
      <TextField
        id="sprintGoals"
        key="sprintGoals"
        label="Sprint Goals"
        multiline rows={4}
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('sprintGoals', {
          //required: { value: true, message: 'Field is Required.' },
          maxLength: { value: 200, message: 'Maximum characters for field is 200.' },
          onChange: (e) => setFieldGoals(e.target.value),
        })}
        value={fieldGoals}
        error={errors?.sprintGoals ? true : false}
        helperText={errors?.sprintGoals?.message?.toString()}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <br /><br /><br />
        {props.formMode === FormSprintModeEntry.CREATE &&
          <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleNewSprintSubmit)}>Create</SmartButton>
        }
        {props.formMode === FormSprintModeEntry.EDIT &&
          <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleEditSprintSubmit)}>Edit</SmartButton>
        }
        {props.formMode === FormSprintModeEntry.START &&
          <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleStartSprintSubmit)}>Start</SmartButton>
        }
        {props.formMode === FormSprintModeEntry.DELETE &&
          <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleDeleteSprintSubmit)}>Delete</SmartButton>
        }
        {props.formMode === FormSprintModeEntry.COMPLETE &&
          <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleCompleteSprintSubmit)}>Complete</SmartButton>
        }
      </Stack>
    </Box >
  );
}