import React, { useEffect, useState } from 'react'; // let's also import Component
import { DndContext, useDroppable } from '@dnd-kit/core';

import { ASGateway, ASDtos } from 'api/AppServices';

import { useAppDispatch } from 'hooks/StoreReduxHooks';
import { setTaskIdIsDragging } from 'reducers/boardDrawerReducer';

import { CardTypeEntry, FormSprintModeEntry } from 'app/AppValues';
import { CardMapper } from 'app/AppMapper';


import AgileCard from 'components/AgileBoards/AgileCard';
import AgileBoardSprint from 'components/AgileBoards/AgileBoardSprintDragContainer';

import FormMoveTaskToBoard from 'components/Forms/FormMoveTaskToBoard';
import FormSprint from 'components/Forms/FormSprint';

import {
  Stack,
  Paper,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

interface IBoardViewBacklogProps {
  teamId: number;
  boardId: number;
  boardType: ASDtos.BoardTypeDto;
  boardViewId: number;
  setupBoardMode: boolean;
  maxHeightContainer: number;
  children?: any;
};

export default function BoardViewBacklog(props: IBoardViewBacklogProps) {
  const mapper = new CardMapper();
  //Redux States
  const dispatch = useAppDispatch();
  //Redux States

  const { setNodeRef } = useDroppable({ id: "b" + props.boardViewId });

  const [maxHeightContainer, setMaxHeightContainer] = useState<number>(0);

  //const [cards, setCards] = useState<ASDtos.TaskReadDto[]>([]);
  const [boardViewColumns, setBoardViewColumns] = useState<ASDtos.BoardViewColumnReadDto[]>([]);
  const [sprints, setSprints] = useState<ASDtos.BoardIterationReadDto[]>([]);

  const [showFormMoveToBoard, setShowFormMoveToBoard] = useState<boolean>(false);
  const [showFormCreateSprint, setShowFormCreateSprint] = useState<boolean>(false);

  function isTaskOnSprint(taskId: number): boolean {
    var sprint = sprints?.find((sprint) => {
      return sprint?.tasks?.some((task: any) => task.id === taskId);
    })!;

    return (sprint !== undefined);
  }

  //Handles
  function handleDragStart(event: any) {
    //console.log("handleDragStart(event): ", event);
    if (event.over) { //&& event.over.id === 'droppable'
      //var msg = "Card ID: " + event.active.id + " -> Dropped on Column: " + event.over.id;
    }
  }
  function handleDragMove(event: any) {
    //console.log("handleDragMove(event): ", event);
    if (event.active) {
      dispatch(setTaskIdIsDragging(event.active.id))
    }
  }
  function handleDragOver(event: any) {
    //console.log("handleDragOver(event): ", event);
    dispatch(setTaskIdIsDragging(0))
  }
  function handleDragEnd(event: any) {
    //console.log("handleDragEnd(event): ", event);
    //console.log("handleDragEnd(event.delta): ", event.delta);
    //console.log("handleDragEnd(event.over): ", event.over);

    if (event.over) {
      const columnType = event.over.id.substring(0, 1);
      const transitionToColumnId = Number(event.over.id.substring(1));
      const taskId = event.active.id;
      //alert("columnType: " + columnType + " transitionToColumnId: " + transitionToColumnId + " taskId:" + taskId);

      //=== Update Locally before saving to Database ===
      //ORIGIN AND DESTINY can live in 2 different Lists => Sprints or Backlog
      var currentSprints = [...sprints];
      var currentBacklog = [...boardViewColumns];

      var containerTypeOrigin = "s";
      var containerTypeDestiny = "s";

      //Get ORIGIN Container from which task was moved FROM
      //Try to find it on Sprints
      var containerOrigin: any = currentSprints?.find((sprint) => {
        return sprint?.tasks?.some((task: any) => task.id === taskId);
      })!;

      if (!containerOrigin) {
        //Try to find it on BacklogColumns
        containerTypeOrigin = "b";
        containerOrigin = currentBacklog?.find((column) => {
          return column?.tasks?.some((task: any) => task.id === taskId);
        })!;
      }

      //Get DESTINY Container from which task was moved INTO
      //Try to find it on Sprints
      var containerDestiny: any = currentSprints?.find((sprint) => {
        return sprint.id === transitionToColumnId;
      })!;

      if (!containerDestiny) {
        //Try to find it on BacklogColumns
        containerTypeDestiny = "b";
        containerDestiny = currentBacklog[0]!;
      }
      // console.log("containerOrigin: ", containerOrigin);
      // console.log("containerDestiny: ", containerDestiny);

      //Get Task to move
      var taskToMove = containerOrigin?.tasks?.find((task: any) => {
        return task.id === taskId;
      })!;

      //Remove task from Container Origin
      var containerOriginTasks = containerOrigin?.tasks?.filter((task: any) => task.id !== taskId);
      if (containerOriginTasks) {
        containerOrigin.tasks = containerOriginTasks;
      }

      //Add task to Container Destiny
      var containerDestinyTasks = containerDestiny?.tasks
      if (!containerDestinyTasks) {
        containerDestinyTasks = [];
      }
      containerDestiny.tasks = [...containerDestinyTasks, taskToMove];

      //Save UI Result
      setSprints(currentSprints);
      setBoardViewColumns(currentBacklog);

      //Update Database
      if (containerTypeOrigin === "s") {
        ASGateway.BoardIterations.UnassignTask(
          props.boardId,
          containerOrigin.id,
          taskId
        )
          .then((data: ASDtos.TaskReadDto) => {
            //console.log('UpdateBoardIterationUnassignTask: ', data);
          })
          .catch((e: any) => {
            console.error(e);
            handleLoadBoardView();
          });
      }

      if (containerTypeDestiny === "s") {
        ASGateway.BoardIterations.AssignTask(
          props.boardId,
          containerDestiny.id,
          taskId
        )
          .then((data: ASDtos.TaskReadDto) => {
            //console.log('UpdateBoardIterationAssignTask: ', data);
          })
          .catch((e: any) => {
            console.error(e);
            handleLoadBoardView();
          });
      }

    }
  }

  async function handleLoadBoardSprints() {
    setSprints([]);
    //console.log("Backlog BoardIterations.GetAllOpen props.boardType: ", props.boardType);
    if (props.boardType === ASDtos.BoardTypeDto.Scrum) {
      //console.log("Backlog BoardIterations.GetAllOpen props.boardId: ", props.boardId);
      //Call Service
      await ASGateway.BoardIterations.GetAllOpen(props.boardId)
        .then(async (data: ASDtos.BoardIterationReadDto[]) => {
          //console.log('GetAllBoardOpenIterations: ', data);
          setSprints(data);
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }

  async function handleLoadBoardView() {
    //console.log('Backlog handleLoadBoardView: ');
    //Call Service
    await ASGateway.BoardViews.GetColumns(props.boardId, props.boardViewId)
      .then(async (data: ASDtos.BoardViewColumnReadDto[]) => {
        //console.log('Backlog GetAllBoardViewColumns: ', data);
        setBoardViewColumns(data);
        //setCards(data[0].tasks);
        //Get all Sprints not closed
        await handleLoadBoardSprints();
      })
      .catch((e: any) => {
        console.error(e);
      });
  }


  // OnLoad
  useEffect(() => {
    //console.log("Backlog useEffect");
    //console.log("Backlog useEffect props.maxHeightContainer: ", props.maxHeightContainer);
    setMaxHeightContainer(props.maxHeightContainer);
    //Load BoardView
    handleLoadBoardView();
  //}, [props.maxHeightContainer, props.setupBoardMode]);
  }, [props]);

  // OnRender
  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <Box width="100%" height="100%">
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start"
          sx={{
            ml: 1,
            mr: 2,
            height: "95%",
          }}
          spacing={1}>
          <Box
            sx={{
              p: 0,
              textAlign: "left",
              //backgroundColor: "#F0F0F0",
              backgroundColor: "white",
              minWidth: 150,
              minHeight: `${maxHeightContainer - 100}px`,
              maxHeight: `${maxHeightContainer - 50}px`,
              width: "100%",
              height: "100%",
              overflowY: 'auto',
              overflowX: 'auto',
            }}
          >
            <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={0}>
              {sprints?.map((sprint: ASDtos.BoardIterationReadDto) => {
                //console.log("sprint: ", sprint);
                return (
                  <Box key={"sprint" + sprint.id!.toString()} sx={{ ml: 2, mr: 2 }}>
                    <AgileBoardSprint
                      key={"sprint" + sprint.id!.toString()}
                      boardId={props.boardId}
                      sprint={sprint}
                      updateParent={() => { return false; }}
                    />
                    <br />
                  </Box>
                )
              })}
              {boardViewColumns?.map((column: ASDtos.BoardViewColumnReadDto) => {
                return (
                  <Box key={"backlog" + column.id} sx={{ ml: 2, mr: 2 }}>
                    <Accordion key={"backlog" + column.id} expanded={true} disableGutters
                      sx={{
                        backgroundColor: "#F0F0F0",
                        "& .MuiAccordionSummary-root:hover": { cursor: "default !important" }
                      }}
                    >
                      <AccordionSummary id="backlog">
                        <Box width="100%">
                          <Stack id="backlog-backlog-summary-top-stack" direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                            <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                              <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                                <Box><b>BackLog</b></Box>
                              </Stack>
                              <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={0}>
                                <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={0} sx={{ ml: 2, mr: 2 }}>
                                  <Box>
                                    <IconButton
                                      size={'small'}
                                      sx={{
                                        size: "small",
                                        ":hover": { backgroundColor: "#D4DBF5" }
                                      }}
                                      onClick={() => setShowFormMoveToBoard(true)}
                                    >
                                      <DriveFileMoveIcon sx={{ transform: "scale(0.8)" }} />
                                    </IconButton>
                                  </Box>
                                </Stack>
                                {props.boardType === ASDtos.BoardTypeDto.Scrum &&
                                  <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
                                    <Button variant="outlined" onClick={() => { setShowFormCreateSprint(true); }}>
                                      create sprint
                                    </Button>
                                  </Stack>
                                }
                              </Stack>
                            </Stack>
                          </Stack>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Paper
                          sx={{ backgroundColor: "#F0F0F0" }}
                          ref={setNodeRef}
                        >
                          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                            {column.tasks?.map((task, index) => {
                              //console.log("Backlog card.type: ", Number(card.type));
                              var cardToLoad = mapper.mappTasksToCards([task], CardTypeEntry.BACKLOG)[0];
                              if (isTaskOnSprint(task.id)) {
                                //When task is already on a sprint we do not show it on backlog [skip]
                                return;
                              }

                              return (
                                <AgileCard
                                  key={"task" + cardToLoad.cardId!.toString()}
                                  card={cardToLoad}
                                  isSelectable={false}
                                  isFirst={index === 0}
                                  isLast={index === column.tasks.length - 1}
                                  onChangeSelect={(e) => { return }}
                                />
                              );
                            })}
                          </Stack>
                        </Paper>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )
              })}
            </Stack>
          </Box>

          {/* Move to Board Dialog */}
          <Dialog fullScreen={false} open={showFormMoveToBoard} onClose={() => setShowFormMoveToBoard(false)}>
            <DialogContent sx={{ p: 1, minWidth: "300px", maxWidth: "500px", }}>
              <FormMoveTaskToBoard
                teamId={props.teamId}
                boardId={props.boardId}
                viewId={props.boardViewId}
                columnId={0}
                tasks={boardViewColumns[0]?.tasks}
                formIsDone={() => { handleLoadBoardView(); setShowFormMoveToBoard(false); }}
              />
            </DialogContent>
          </Dialog>

          {/* Create Sprint Dialog */}
          <Dialog fullScreen={false} open={showFormCreateSprint} onClose={() => setShowFormCreateSprint(false)}>
            <DialogContent sx={{ p: 1, minWidth: "300px", maxWidth: "500px", }}>
              <FormSprint
                boardId={props.boardId}
                formMode={FormSprintModeEntry.CREATE}
                sprintDetail={undefined}
                formIsDone={() => { handleLoadBoardView(); setShowFormCreateSprint(false); }}
              />
            </DialogContent>
          </Dialog>

        </Stack>
      </Box>
    </DndContext>
  );
}