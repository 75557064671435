import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';

export async function GetAllOpen(boardId: number) : Promise<ASDtos.BoardIterationReadDto[]> {
  var urlResource = `/boards/${boardId}/iterations/open`;
  //console.log('GetAllBoardOpenIterations URL: ', { url: urlResource });
  return await CallService<ASDtos.BoardIterationReadDto[]>(urlResource);
}

export async function Create(boardId: number, boardIteration: ASDtos.BoardIterationCreateDto) : Promise<ASDtos.BoardIterationReadDto> {
  var urlResource = `/boards/${boardId}/iterations`;
  //console.log('CreateBoardIteration URL: ', { url: urlResource });
  return await CallService<ASDtos.BoardIterationReadDto>(urlResource, "POST", boardIteration);
}
export async function Start(boardId: number, boardIterationId: number, boardIteration: ASDtos.BoardIterationCreateDto) : Promise<ASDtos.BoardIterationReadDto> {
  var urlResource = `/boards/${boardId}/iterations/${boardIterationId}/start`;
  //console.log('CreateBoardIteration URL: ', { url: urlResource });
  return await CallService<ASDtos.BoardIterationReadDto>(urlResource, "POST", boardIteration);
}

export async function Complete(boardId: number, boardIterationId: number, boardIteration: ASDtos.BoardIterationCreateDto) : Promise<ASDtos.BoardIterationReadDto> {
  var urlResource = `/boards/${boardId}/iterations/${boardIterationId}/complete`;
  //console.log('CreateBoardIteration URL: ', { url: urlResource });
  return await CallService<ASDtos.BoardIterationReadDto>(urlResource, "POST", boardIteration);
}

export async function Update(boardId: number, boardIterationId: number, boardIteration: ASDtos.BoardIterationUpdateDto) : Promise<ASDtos.BoardIterationReadDto> {
  var urlResource = `/boards/${boardId}/iterations/${boardIterationId}`;
  //console.log('UpdateBoardIteration URL: ', { url: urlResource });
  return await CallService<ASDtos.BoardIterationReadDto>(urlResource, "PUT", boardIteration);
}

export async function AssignTask(boardId: number, boardIterationId: number, taskId: number) : Promise<ASDtos.TaskReadDto> {
  var urlResource = `/boards/${boardId}/iterations/${boardIterationId}/tasks/${taskId}`;
  //console.log('UpdateBoardIterationAssignTask URL: ', { url: urlResource });
  return await CallService<ASDtos.TaskReadDto>(urlResource, "POST", null);
}

export async function UnassignTask(boardId: number, boardIterationId: number, taskId: number) : Promise<ASDtos.TaskReadDto> {
  var urlResource = `/boards/${boardId}/iterations/${boardIterationId}/tasks/${taskId}`;
  //console.log('UpdateBoardIterationUnassignTask URL: ', { url: urlResource });
  return await CallService<ASDtos.TaskReadDto>(urlResource, "DELETE", null);
}
