import React, { useEffect, useState } from 'react'; // let's also import Component
import { Link as RouterLink } from 'react-router-dom';
import { useDraggable, DragOverlay, useDndMonitor } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import { DetailContainerEntry, CardTypeEntry } from 'app/AppValues';
import { IAgileCard } from 'app/AppInterfaces';

import { ASGateway, ASDtos } from 'api/AppServices';
import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { setOpenDetailDrawer, setDetailContainer, setDetailContentId } from 'reducers/detailDrawerReducer';
import { setTasksSelected } from 'reducers/systemBarReducer';

import {
  Stack,
  Box,
  Card,
  Chip,
  FormControlLabel,
  Checkbox,
  Link,
  MenuItem,
} from '@mui/material';

import Grid3x3Icon from '@mui/icons-material/Grid3x3';

import TaskType from 'components/TaskType';
import TaskPriority from 'components/TaskPriority';

type IAgileBoardCardProps = {
  children?: any,
  card: IAgileCard,
  isSelectable: boolean,
  isFirst: boolean,
  isLast: boolean,
  onChangeSelect: (selected: boolean) => void;
};

export default function AgileCard(props: IAgileBoardCardProps) {
  //Redux States
  const dispatch = useAppDispatch();
  const taskIdIsDragging = useAppSelector((state) => state.boardDrawer.taskIdIsDragging);
  const detailContainer = useAppSelector((state) => state.detailDrawer.detailContainer);
  const detailContentId = useAppSelector((state) => state.detailDrawer.detailContentId);
  //Redux States

  // const [isDragging, setIsDragging] = useState(false);
  const [cardSingleLine, setCardSingleLine] = useState<boolean>(false);
  const [cardSelected, setCardSelected] = useState<boolean>(false);

  const [paddingTop, setPaddingTop] = useState<number>(0);
  const [paddingBottom, setPaddingBottom] = useState<number>(0);

  const { attributes, listeners, setNodeRef, transform } = useDraggable({ id: props.card.cardId! });

  function isCardSelected() {
    if (props.card.cardId < 0) {
      return false;
    }

    return (
      (
        (detailContainer === DetailContainerEntry.TASK && props.card.cardType !== CardTypeEntry.PACKAGE) ||
        (detailContainer === DetailContainerEntry.PACKAGE && props.card.cardType === CardTypeEntry.PACKAGE)
      ) &&
      detailContentId === props.card.cardId
    );
  }

  function getTransform() {
    if (props.card.cardId < 0) {
      return undefined;
    }
    return CSS.Translate.toString(transform);
  }
  function getDraggableRef() {
    //console.log("getDraggableRef: ", props.card.cardId);
    if (props.card.cardId < 0) {
      //console.log("getDraggableRef NULL");
      return null;
    }

    //console.log("getDraggableRef setNodeRef: ", setNodeRef);
    return setNodeRef;
  }

  function getPadding() {
    switch (props.card.cardType) {
      default:
        {
          return 1;
        }
    }
  }
  function getMinHeight() {
    switch (props.card.cardType) {
      case CardTypeEntry.BACKLOG:
        {
          return 10;
        }
      default:
        {
          return 75;
        }
    }
  }
  function getBoxShadow() {
    if (props.card.cardId < 0) {
      return undefined;
    }

    switch (props.card.cardType) {
      case CardTypeEntry.BOARD:
      case CardTypeEntry.PACKAGE:
        {
          return 10
        }
      default:
        {
          return 0;
        }
    }
  }
  function getBorder() {
    if (props.card.cardId < 0) {
      return 1;
    }
    if (isCardSelected()) {
      return 1;
    }
    return 0;
  }
  function getBorderStyle() {
    if (props.card.cardId < 0) {
      return 'dashed';
    }

    return undefined;
  }
  function getCursor() {
    if (props.card.cardId < 0) {
      return '';
    }
    switch (props.card.cardType) {
      default:
        {
          return 'pointer';
        }
    }
  }
  function getBackground() {
    if (props.card.cardId < 0) {
      return undefined;
    }
    if (isCardSelected()) {
      return "#D4DBF5";
    }
    return "#FFFFFF";
  }
  function getOpacity() {
    if (props.card.cardId < 0) {
      return 0.7;
    }
    return 1;
  }
  //Handles

  // OnLoad
  useEffect(() => {
    //console.log("CARD Props: ", props);
    var ptVal = (props.isFirst) ? 1 : 0; //IsFirst then TopPadding = 1
    var pbVal = (props.isLast) ? 1 : 0;  //IsLast then BottomPadding = 1

    setPaddingTop(ptVal);
    setPaddingBottom(pbVal);
    setCardSingleLine(props.card.cardType === CardTypeEntry.BACKLOG);

  }, [props]);

  // OnRender
  return (
    <Card
      key={"card" + props.card.cardId.toString()}
      sx={{
        p: getPadding(),
        width: "100%",
        minHeight: getMinHeight(),
        transform: getTransform(),
        ':hover': {
          boxShadow: getBoxShadow(),
          border: getBorder(),
          borderStyle: getBorderStyle(),
          cursor: getCursor()
        },
        border: getBorder(),
        borderStyle: getBorderStyle(),
        backgroundColor: getBackground(),
        opacity: getOpacity(),
      }}
      //DND
      ref={getDraggableRef()} //When No ID is passed we do not Dragg the Card
      {...listeners}
      {...attributes}
      //DND
      onMouseUp={(e) => {
        //console.log("onMouseUp Props: ", props);
        if (props.card.cardId < 0) { //When No ID is passed we do not make the Card clickable
          return;
        }
        //console.log("onMouseUp PASSED");

        if (taskIdIsDragging === 0) {
          //Check if it is already selected and deselect it
          var selection = !isCardSelected(); //Switch current selection status before condiotions changes with Dispatchers

          if (isCardSelected()) {
            dispatch(setDetailContainer(DetailContainerEntry.NONE))
            dispatch(setTasksSelected([]))
            dispatch(setDetailContentId(0))
            dispatch(setOpenDetailDrawer(false))
          }
          else {
            //When a card is clicked but NOT Dragging then we want to highlight selected card and open detail
            //console.log("onMouseUp and NOT taskIdIsDragging [event]: ", e);
            if (props.card.cardType === CardTypeEntry.PACKAGE) {
              dispatch(setDetailContainer(DetailContainerEntry.PACKAGE));
            }
            else {
              dispatch(setDetailContainer(DetailContainerEntry.TASK))
            }

            dispatch(setTasksSelected((props.card.cardTasks) ? props.card.cardTasks : []))
            dispatch(setDetailContentId(props.card.cardId!))
            dispatch(setOpenDetailDrawer(true))
          }
          //props.onChangeSelection(selection);
        }
      }}
    // onMouseDown={(e) => {
    //   if (taskIdIsDragging === 0){
    //     //When a card is clicked but Dragging then we want to highlight selected card and open detail
    //     console.log("onMouseDown and NOT taskIdIsDragging");            
    //   }
    // }}
    // onClick={(e) => {
    //   if (taskIdIsDragging === 0){
    //     //When a card is clicked but Dragging then we want to highlight selected card and open detail
    //     console.log("onClick and NOT taskIdIsDragging");            
    //   }
    // }}
    >
      <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
        {props.isSelectable &&
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
            <Box sx={{ pl: 1, pt: paddingTop, pb: paddingBottom, width: "100%" }}>
              <FormControlLabel
                sx={{
                  width: "100%",
                  '.MuiTypography-root': {
                    width: "100%",
                  },
                }}
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >

                  </Box>
                }
                control={
                  <Checkbox
                    size="small"
                    disabled={!props.isSelectable}
                    checked={cardSelected}
                    onClick={() => { var current = !cardSelected; setCardSelected(current); props.onChangeSelect(current) }}
                  />
                }
              />
            </Box>
          </Stack>
        }
        <Stack direction="column" sx={{ width: "100%" }} justifyContent="space-evenly" alignItems="stretch" spacing={1} >
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
            <Stack direction="row" justifyContent={(props.card.cardId > 0) ? 'flex-start' : 'center'} alignItems="center" spacing={1}>
              <Box>
                {(props.card.cardType !== CardTypeEntry.PACKAGEENVIRONMENTSTATUS) &&
                  <Link key={props.card.cardId} component={RouterLink} to={`/detail/task/${props.card.cardId}`} sx={{ pr: 1 }}>
                    <Chip icon={<Grid3x3Icon fontSize="small" color="info" />} label={`${props.card.cardId}`} variant="outlined" clickable />
                  </Link>
                }
                {props.card.cardTitle}
              </Box>
            </Stack>
            {
              //When SingleLine Card details are in SAME LINE as Summary
              (cardSingleLine === true) &&
                <AgileCardDetails />
            }
          </Stack>
          {
            //When MultiLine Card details are in SECOND LINE
            (cardSingleLine === false) &&
              <AgileCardDetails />
          }
        </Stack>
      </Stack>
    </Card>
    /* <DragOverlay>
    {isDragging ? (
      <div>HOOO SHIT HERE I GO</div>
    ): null}
  </DragOverlay> */
  );

  // ******* Internal Components ******* 
  function AgileCardDetails() {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
        <Box>
          {props.card.cardVersion !== undefined && props.card.cardVersion !== "" &&
            <Chip label={`${props.card.cardVersion}`} variant="filled" color='info' size="small" />
          }
        </Box>
        <Box>
          {props.card.cardStatus !== undefined && props.card.cardStatus !== "" &&
            <Chip label={`${props.card.cardStatus}`} variant="outlined" color='info' size="small" />
          }
        </Box>
        {(props.card.cardType === CardTypeEntry.BACKLOG || props.card.cardType === CardTypeEntry.BOARD) &&
          <Box>
            <Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={1}>
              <TaskType type={props.card.cardTypeSource as unknown as ASDtos.TaskTypeDto} />
              <TaskPriority priority={ASDtos.TaskPriorityDto[Number(props.card.cardPriority)]} /> <br />
              <Chip label={props.card.cardEstimation ? props.card.cardEstimation : 0} size="small" />
            </Stack>
          </Box>
        }
      </Stack>
    );
  };  
}