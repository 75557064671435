import { ICanvasNode } from 'middleware/canvas/ICanvasNode';
import { BaseCanvasObject } from 'middleware/canvas/BaseCanvasObject';
import { BaseDrawOptions } from 'middleware/canvas/BaseDrawOptions'
import { CanvasMap } from 'middleware/canvas/CanvasMap'
import { TextHelper } from 'middleware/canvas/TextHelper';

import { ASDtos } from 'api/AppServices';
import { CanvasNodeArea } from 'app/AppValues';

import icon from 'images/icons/canvas-object-type-engineering-icon_15x13.png';

export class ProductNode extends BaseCanvasObject implements ICanvasNode {
  private level: number = -1;
  private enviromentForChanges: number[] = [];

  private onHover: boolean = false;
  private iconImg = new Image();
  private textHelper = new TextHelper();

  borderThickness: number = 1;
  selectedBorderThickness: number = 1;
  changesPadding: number = 2;
  defaultTextSizeAndFont: string = "10px sans-serif";

  constructor(
    productId: number,
    name: string,
    level: number,
    options: BaseDrawOptions,
    enviromentForChanges: number[],
    version: number | null = null
  ) {

    super(
      productId,
      name,
      version,
      options
    );

    this.level = level;
    this.enviromentForChanges = enviromentForChanges

    this.iconImg.src = icon;
  }

  setOnHover(value: boolean) { this.onHover = value; }

  move(movementX: number, movementY: number) {
    const options = super.getOptions();
    var result = super.getOptions();

    const layerX = options.x + movementX;
    const layerY = options.y + movementY;

    result.x = layerX;
    result.y = layerY;

    super.setOptions(result);
  }

  draw(canvasMap: CanvasMap, dependentTasksId: ASDtos.NodeTaskReadDto[], currentEnvironment: number, ctx: CanvasRenderingContext2D) {
    //console.log("detailBar: ", detailBar);
    if (ctx) {

      ctx.save();

      const options = super.getOptions();
      var x = options.x;
      var y = options.y;
      var w = options.w;
      var h = options.h;

      //Calculate coordinates depending on the current level of Canvas
      var layer = this.level - canvasMap.level;
      //console.log("layer: ", layer);
      //console.log("layer: ", layer);
      switch (layer) {
        case 0: //Layer is the current one
          x = options.x + canvasMap.originX;
          y = options.y + canvasMap.originY;
          w = options.w;
          h = options.h;
          break;
        case -1: //Layer is the current -1 => recoordinate to top
          x = options.x + canvasMap.originX;
          y = 95;
          w = 30;
          h = 30;
          break;
        case -2: //Layer is the current -2  => recoordinate to top
          x = options.x + canvasMap.originX;
          y = 95;
          w = 30;
          h = 30;
          break;
        default:
          // When it is positive 
          // or level lower than -2 
          //it must not be rendered
          return;
      }


      if (super.isActive()) {
        var sX = x - this.selectedBorderThickness;
        var sY = y - this.selectedBorderThickness;
        var sW = w + (this.selectedBorderThickness * 2);
        var sH = h + (this.selectedBorderThickness * 2);

        //Selection Rect
        ctx.strokeStyle = "blue";
        ctx.beginPath();
        ctx.roundRect(sX, sY, sW, sH, 0);
        ctx.stroke();

        //NW Corner
        //SW Corner
        //NE Corner
        //NE Corner

      }

      var hasChangesForEnv = false;
      var isVersionLive = (super.getVersion() === null || super.getVersion() === 0);
      var isEnvironmentLocal = (currentEnvironment === 0);

      //Check for Dependency Tasks on Node Creation
      hasChangesForEnv = this.enviromentForChanges.some((envForChange: number) => {
        return envForChange === currentEnvironment;
      });

      if (!isVersionLive && !isEnvironmentLocal && !hasChangesForEnv) {
        //When environment is not local and we do not have tasks for node creation then node must not render
        super.setVisible(false);
        return;
      }
      else {
        super.setVisible(true);
      }

      if (
        !isVersionLive && //Never print Orange changes signal when is liveVersion
        (!isEnvironmentLocal && hasChangesForEnv)
      ) {
        var sX = (x - this.changesPadding) - this.selectedBorderThickness;
        var sY = (y - this.changesPadding) - this.selectedBorderThickness;
        var sW = (w + this.changesPadding * 2) + (this.selectedBorderThickness * 2);
        var sH = (h + this.changesPadding * 2) + (this.selectedBorderThickness * 2);

        //Changes Rect
        ctx.strokeStyle = "orange";
        ctx.setLineDash([10, 5]);
        ctx.lineWidth = 1;

        ctx.beginPath();
        ctx.roundRect(sX, sY, sW, sH, 10);
        ctx.stroke();
        ctx.setLineDash([]);

        //NW Corner
        //SW Corner
        //NE Corner
        //NE Corner
      }

      if (isVersionLive) {
        ctx.strokeStyle = "#000000";
      } else {
        ctx.strokeStyle = "orange";
      }

      ctx.beginPath();
      ctx.roundRect(x, y, w, h, 10);
      ctx.stroke();

      //Fill It
      ctx.fillStyle = "#FFFFFF";
      if (this.onHover) {
        ctx.fillStyle = "#6699CC";
      }

      // if (hasTasksDependentForEnv) {
      //   ctx.fillStyle = "red";
      // }
      ctx.fill();

      ctx.textAlign = "left";
      ctx.textBaseline = "alphabetic";
      ctx.fillStyle = "#000000";
      ctx.fillText(`#${super.getId()}`, x + 3, y + 13);

      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#000000";
      this.textHelper.drawTextDynamicSizeAndFont(ctx, super.getName(), x, y, w, h);

      // //Reset properties
      // ctx.strokeStyle = "#000000";
      // ctx.font = this.defaultTextSizeAndFont;

      //Draw Icon
      ctx.drawImage(this.iconImg, x + 55, y + 3);

      ctx.restore();
    }
  }
}