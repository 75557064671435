import { CanvasNodeArea } from "app/AppValues";
import { IBaseCanvasObject } from "./IBaseCanvasObject";
import { BaseDrawOptions } from "./BaseDrawOptions";
import { CanvasMap } from "./CanvasMap";

export class BaseCanvasObject implements IBaseCanvasObject {
  private id: number = -1;
  private name: string = "";
  private version: number | null = null;
  private active: boolean = false;
  private visible: boolean = true;

  protected nodeAreaPointer: CanvasNodeArea = CanvasNodeArea.NONE;

  protected sizers = { vPad: 3, hPad: 3, width: 3 };
  protected vMover = { vPad: 3, hPad: 3, width: 15 };
  protected action = { vPad: 3, hPad: 3, width: 15 };

  private options: BaseDrawOptions = { x: 0, y: 0, w: 0, h: 0 };

  constructor(
    id: number,
    name: string,
    version: number | null,
    options: BaseDrawOptions
  ) {
    this.id = id;
    this.name = name;
    this.version = version;
    this.options = options;
  }

  getId(): number { return this.id; }

  getVersion(): number | null { return this.version; }

  getName(): string { return this.name; }
  setName(value: string): void { this.name = value; }

  getOptions(): BaseDrawOptions { return { ...this.options }; }
  setOptions(value: BaseDrawOptions): void { this.options = { ...value }; }

  isVisible(): boolean { return this.visible; }
  setVisible(value: boolean): void { this.visible = value; }

  isActive(): boolean { return this.active; }
  setActive(value: boolean): void { this.active = value; }

  getNodeAreaPointer(): CanvasNodeArea { return this.nodeAreaPointer; }
  setNodeAreaPointer(value: CanvasNodeArea) { this.nodeAreaPointer = value; }
  setNodeAreaFromPointer(canvasMap: CanvasMap, pointX: number, pointY: number): CanvasNodeArea {
    const { x, y, w, h } = this.options;

    this.nodeAreaPointer = CanvasNodeArea.NONE;

    if (
      pointX > (x + canvasMap.originX) &&
      pointX < (x + canvasMap.originX) + w &&

      pointY > (y + canvasMap.originY) &&
      pointY < (y + canvasMap.originY) + h
    ) {
      this.nodeAreaPointer = CanvasNodeArea.DEFAULT;
    }

    //ONLY ACTIVE AREAS [SIZERS, MOVERS]
    if (this.active) {
      const volumeSizer = (this.sizers.hPad + this.sizers.width);
      const volumeVMover = (this.vMover.hPad + this.vMover.width);
      const volumeAction = (this.action.hPad + this.action.width);

      //CHECK ACTION AREA
      if (
        pointX > (x + canvasMap.originX + w) - (volumeAction + volumeVMover + volumeSizer) &&
        pointX < (x + canvasMap.originX + w) - (volumeVMover + volumeSizer) &&

        pointY > (y + canvasMap.originY) &&
        pointY < (y + canvasMap.originY) + h
      ) {
        this.nodeAreaPointer = CanvasNodeArea.ACTION;
      }

      //CHECK MOVER AREA
      if (
        pointX > (x + canvasMap.originX + w) - (volumeVMover + volumeSizer) &&
        pointX < (x + canvasMap.originX + w) - (volumeSizer) &&

        pointY > (y + canvasMap.originY) &&
        pointY < (y + canvasMap.originY) + h
      ) {
        this.nodeAreaPointer = CanvasNodeArea.MOVEVERTICAL;
      }

      //CHECK SIZER AREA
      if (
        pointX > (x + canvasMap.originX) &&
        pointX < (x + canvasMap.originX) + volumeSizer &&

        pointY > (y + canvasMap.originY) &&
        pointY < (y + canvasMap.originY) + h
      ) {
        this.nodeAreaPointer = CanvasNodeArea.SIZERLEFT;
      }

      if (
        pointX > (x + canvasMap.originX + w) - volumeSizer &&
        pointX < (x + canvasMap.originX + w) &&

        pointY > (y + canvasMap.originY) &&
        pointY < (y + canvasMap.originY) + h
      ) {
        this.nodeAreaPointer = CanvasNodeArea.SIZERRIGHT;
      }
    }

    //console.log("this.pointerArea: ", this.pointerArea);
    return this.nodeAreaPointer;
  }

  setCursor(ref: React.RefObject<any>): void {
    switch (this.nodeAreaPointer) {
      case CanvasNodeArea.DEFAULT:
      case CanvasNodeArea.MOVEHORIZONTAL:
      case CanvasNodeArea.MOVEVERTICAL:
        {
          ref.current.style.cursor = "all-scroll";
          break;
        }
      case CanvasNodeArea.SIZERLEFT:
      case CanvasNodeArea.SIZERRIGHT:
        {
          ref.current.style.cursor = "e-resize";
          break;
        }
      case CanvasNodeArea.ACTION:
        {
          ref.current.style.cursor = "pointer";
          break;
        }
      default:
        {
          ref.current.style.cursor = "default";
          break;
        }
    }
  }
}