import React from 'react'; // let's also import Component

import { Chip } from '@mui/material';

import BugReportIcon from '@mui/icons-material/BugReport';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AssignmentIcon from '@mui/icons-material/Assignment';

import IconSvg from 'components/IconSvg';


type ITaskPriorityProps = {
  priority: string
};

export default function TaskPriority(props: ITaskPriorityProps) {

  function getColorFromTaskPriority(priority: string): any {
    switch (priority) {
      case "Story":
        return 'success';
      case "Task":
        return 'info';
      case "Bug":
        return 'error';
      case "Spike":
        return 'action';
    }
  }

  function getIconFromTaskPriority(priority: string): any {
    switch (priority) {
      case "Story":
        return <HistoryEduIcon color={getColorFromTaskPriority(priority)}/>;
      case "Task":
        return <AssignmentIcon color={getColorFromTaskPriority(priority)} />;
      case "Bug":
        return <BugReportIcon color={getColorFromTaskPriority(priority)} />;
      case "Spike":
        return <IconSvg icon='Spike' color={getColorFromTaskPriority(priority)} />
    }
  }

  // OnRender
  return (
    <Chip
      label={props.priority}
      icon={getIconFromTaskPriority(props.priority)}
      size="small"
    />
  );
}