import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { setOpenDetailDrawer, setDetailContainer, setDetailContentId } from 'reducers/detailDrawerReducer';

import {
  CanvasBarEntry,
  MouseEventEntry,
  DetailContainerEntry,
  CanvasNodeArea
} from 'app/AppValues';

import { ASGateway, ASDtos } from 'api/AppServices';


import { ICanvasNode } from 'middleware/canvas/ICanvasNode';
import { ICanvasLink } from 'middleware/canvas/ICanvasLink';

import { CanvasLoader } from 'middleware/canvas/CanvasLoader';
import { ProductNode } from 'middleware/canvas/ProductNode';
import { ConnectorLink } from 'middleware/canvas/ConnectorLink';
import { MouseEvents } from 'middleware/canvas/MouseEvents';
import { CanvasMap } from 'middleware/canvas/CanvasMap';

import CanvasBar from 'components/Canvas/CanvasView/CanvasViewBar';
import CanvasViewDetailBarNode from 'components/Canvas/CanvasView/CanvasViewDetailBarNode';

import {
  Paper,
  Popper,
} from '@mui/material';

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

//import 'App.css';
type ICanvasViewProps = {
  agentViewId: number,
  canvasViewId: number,
  canvasViewName: string,
  planeOriginX: number,
  planeOriginY: number,
  canvasWidth: number,
  canvasHeight: number,
  onChangeView: (canvasViewId: number) => void;
};

export default function CanvasView(props: ICanvasViewProps) {
  const canvasElement: React.RefObject<HTMLCanvasElement> = useRef(null);

  //Redux States
  const dispatch = useAppDispatch();
  const detailContentId = useAppSelector((state) => state.detailDrawer.detailContentId);
  const version = useAppSelector((state) => state.systemBar.version);
  const environment = useAppSelector((state) => state.systemBar.environment);
  const tasksSelected = useAppSelector((state) => state.systemBar.tasksSelected);
  const canvasBarDeveloperMode = useAppSelector((state) => state.systemBar.developerMode);
  const packageDeployed = useAppSelector((state) => state.boardDrawer.packageIdDeployed);
  //Redux States

  const detailBarNodeRef = useRef<HTMLInputElement>(null);
  const [openDetailBarNode, setOpenDetailBarNode] = useState<boolean>(false);

  const mouseEvents = new MouseEvents();
  const [mouseCodePressed, setMouseCodePressed] = useState<MouseEventEntry>(MouseEventEntry.VOID);
  const [canvasBarSelection, setCanvasBarSelection] = useState<CanvasBarEntry>(CanvasBarEntry.POINTER);

  const [objects, setObjects] = useState<Map<number, Array<ICanvasNode>>>(new Map());
  const [connectors, setConnectors] = useState<Map<number, Array<ICanvasLink<ICanvasNode>>>>(new Map());

  const [selectedObject, setSelectedObject] = useState<ICanvasNode | null>(null);
  const [newConnector, setNewConnector] = useState<ICanvasLink<ICanvasNode> | null>(null);

  const [agentViewId, setAgentViewId] = useState<number>(props.agentViewId);
  const [canvasViewId, setCanvasViewId] = useState<number>(props.canvasViewId);
  const [canvasViewName, setCanvasViewName] = useState<string>(props.canvasViewName);
  const [canvasMap, setCanvasMap] = useState<CanvasMap>(new CanvasMap(props.planeOriginX, props.planeOriginY, props.canvasWidth, props.canvasHeight));

  async function fetchAllObjects() {
    //Call Service
    //console.log('canvasViewId: ', canvasViewId);
    if (agentViewId && canvasViewId) {

      var canvasLoader = new CanvasLoader(getDrawingContext(), props.canvasWidth, props.canvasHeight);
      canvasLoader.startLoading();

      objects.clear();
      connectors.clear();

      await ASGateway.Nodes.GetAll(agentViewId, canvasViewId, version)
        .then((data: ASDtos.NodeReadDto[]) => {
          //console.log('GetAllNodes: ', data);

          data?.forEach((node: ASDtos.NodeReadDto) => {
            //console.log("fetchAllObjects Node: ", node);
            const nodeLevel = node.level;
            var currentNodesForLevel = objects.get(nodeLevel) || [];

            objects.set(
              nodeLevel,
              [...currentNodesForLevel,
              new ProductNode(
                node.id,
                node.name,
                nodeLevel,
                {
                  x: node.nodeView.posX,
                  y: node.nodeView.posY,
                  w: node.nodeView.width,
                  h: node.nodeView.height
                },
                node.enviromentForChanges,
                node.version,
              )]
            );
          });

          setObjects(objects);
          fetchAllLinks();
        })
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          canvasLoader.stopLoading();
          drawCanvas("fetchNodeTasks");
        })
        ;
    }

  }

  function fetchAllLinks() {
    ASGateway.Links.GetAllForNetwork(canvasViewId)
      .then((data: ASDtos.LinkReadDto[]) => {
        //console.log('GetAllLinksForNetwork: ', data);
        //Clean up all
        connectors.clear();

        data?.forEach((link: ASDtos.LinkReadDto) => {
          var tailId = link.tail;
          var headId = link.head;

          Array.from(objects.keys()).forEach((renderLevel) => {
            var objectsFromLevel = objects.get(renderLevel) || [];

            var tail = objectsFromLevel.find(obj => obj.getId() === tailId);
            var head = objectsFromLevel.find(obj => obj.getId() === headId);

            //console.log('tail: ', tail);
            //console.log('head: ', head);
            if (tail && head) {
              var connectorsFromLevel = connectors.get(renderLevel) || [];
              connectors.set(renderLevel, [...connectorsFromLevel, new ConnectorLink(link.id, link.name, tail, head, link.version)]);
            }
          });

        });

        setConnectors(connectors);
        drawCanvas("fetchAllLinks");
      })
      .catch((e: any) => {
        console.error(e);
      });

  }

  //On Load
  useEffect(() => {
    //console.log("Canvas useEffect[props]: ", props);
    setCanvasMap(
      new CanvasMap(
        props.planeOriginX,
        props.planeOriginY,
        props.canvasWidth,
        props.canvasHeight,
        canvasMap.zoom,
        canvasMap.level
      ));
    //drawCanvas("useEffect[props]");
  }, [props]);

  useEffect(() => {
    //console.log("Canvas useEffect[version]: ", version);
    fetchAllObjects();
    //drawCanvas("useEffect[version] fetchAllObjects");

  }, [version]); //Trigger when Version Changes

  useEffect(() => {
    //console.log("Canvas useEffect[packageDeployed]: ", packageDeployed);
    fetchAllObjects();
    //drawCanvas("useEffect[packageDeployed] fetchAllObjects");

  }, [packageDeployed]); //Trigger when Package has been deployed

  useEffect(() => {
    //console.log("Canvas useEffect[environment]: ", environment);
    drawCanvas("useEffect[environment]");
  }, [environment]); //Trigger when Environment Changes

  //OnRefresh Objects and Connectors
  useEffect(() => {
    //console.log("Canvas useEffect[objects, connectors, canvasBarDeveloperMode, canvasMap, detailContentId]");
    //console.log("Canvas useEffect[canvasMap]: ", canvasMap);
    //console.log("Refreshed Objects");
    drawCanvas("useEffect[objects, connectors, canvasBarDeveloperMode, canvasMap, detailContentId]");
  }, [objects, connectors, canvasBarDeveloperMode, canvasMap, detailContentId]);

  function sanetizeMouseEvent(pointerX: number, pointerY: number): { X: number, Y: number } {
    var result = {
      X: pointerX - canvasMap.planeOriginX,
      Y: pointerY - canvasMap.planeOriginY
    };

    return result;
  }

  function isPointerHoverObject(pointerX: number, pointerY: number): ICanvasNode | undefined {
    var objectsFromLevel = objects.get(canvasMap.level);
    const detectedObject = objectsFromLevel?.find((canvasObject) => {
      const resultPointer = canvasObject.setNodeAreaFromPointer(canvasMap, pointerX, pointerY);
      return resultPointer !== CanvasNodeArea.NONE
    });
    return detectedObject;
  }

  function getDrawingContext(): CanvasRenderingContext2D | null {
    //console.log("canvasElement.current: ", canvasElement.current);
    if (canvasElement.current) {
      //console.log("canvasMap.planeWidth: ", canvasMap.planeWidth);
      //console.log("canvasMap.planeHeight: ", canvasMap.planeHeight);

      canvasElement.current.width = canvasMap.planeWidth;
      canvasElement.current.height = canvasMap.planeHeight;
      var canvasContext = canvasElement.current.getContext('2d');

      return canvasContext;
    }
    return null;
  }

  async function drawCanvas(trackOrigin: string = "N/A"): Promise<boolean> {
    //console.log("drawCanvas Origin: ", trackOrigin);
    var canvasContext = getDrawingContext();

    //console.log("canvasContext: ", canvasContext);
    if (canvasContext) {
      //SETUP Zoom
      canvasContext.translate(canvasMap.planeWidth / 2, canvasMap.planeHeight / 2);
      canvasContext.scale(canvasMap.zoom, canvasMap.zoom);
      canvasContext.translate(-canvasMap.planeWidth / 2, -canvasMap.planeHeight / 2);
      //SETUP Zoom
      canvasContext.clearRect(0, 0, canvasMap.planeWidth, canvasMap.planeHeight);

      /******* CANVAS OBJECTS *******/
      var objectCurrentDrawAlreadyActivated = false;

      //console.log("drawCanvas Objects:", objects);
      Array.from(objects.keys()).forEach((renderLevel) => {
        var objectsFromLevel = objects.get(renderLevel);

        objectsFromLevel?.forEach((canvasObject) => {
          canvasObject.draw(canvasMap, tasksSelected, environment, canvasContext);

          /******** REDUX TRIGGERING ********/
          //Cannot trigger redux inside class so we need to do it here
          if (canvasObject.isActive()) {
            objectCurrentDrawAlreadyActivated = true;

            //Refresh options with Canvas Map before Dispatch
            var anchorOptions = { ...canvasObject.getOptions() };
            anchorOptions.x = anchorOptions.x + canvasMap.planeOriginX + canvasMap.originX;
            anchorOptions.y = anchorOptions.y + canvasMap.planeOriginY + canvasMap.originY;

            //console.log("REFRESH ANCHOR: ", anchorOptions);
            //Position the anchor element for the selected object
            if (detailBarNodeRef.current) {
              detailBarNodeRef.current.style.left = `${anchorOptions.x + (anchorOptions.w / 2)}px`;
              detailBarNodeRef.current.style.top = `${anchorOptions.y - (anchorOptions.h)}px`;
            }

            setOpenDetailBarNode(true);
          }
          else {
            //Only hide if in this round of drawing there is no object that already activated it!!
            if (objectCurrentDrawAlreadyActivated === false) {
              setOpenDetailBarNode(false);
            }

          }
          /******** REDUX TRIGGERING ********/

        });
      });
      /******* CANVAS OBJECTS *******/
      /******* CANVAS CONNECTORS *******/
      var connectorsFromLevel = connectors.get(canvasMap.level);
      connectorsFromLevel?.forEach((canvasConnector) => {
        var isAnyConnectorObjectActive = true;
        canvasConnector.setDebugMode(canvasBarDeveloperMode);
        canvasConnector.draw(canvasMap, isAnyConnectorObjectActive, canvasContext);
      });

      if (newConnector) {
        newConnector.setDebugMode(canvasBarDeveloperMode);
        newConnector.draw(canvasMap, false, canvasContext);
      }
      /******* CANVAS CONNECTORS *******/

      //RESET Zoom
      canvasContext.translate(canvasMap.planeWidth / 2, canvasMap.planeHeight / 2);
      canvasContext.scale(1, 1);
      canvasContext.translate(-canvasMap.planeWidth / 2, -canvasMap.planeHeight / 2);
      canvasContext.save();
      //RESET Zoom

      /******* CANVAS LEVEL Working Area *******/
      if (canvasMap.level > 1) {
        canvasContext.save();
        canvasContext.setLineDash([10, 5]);
        canvasContext.lineWidth = 0.5;
        canvasContext.strokeRect(70, 80, canvasMap.planeWidth - (70 + 10), 55);
        canvasContext.restore();
      }
      /******* CANVAS LEVEL Working Area *******/

      /******* CANVAS NAME *******/
      canvasContext.save();

      canvasContext.textAlign = "center";
      canvasContext.textBaseline = "middle";
      canvasContext.fillStyle = "#000000";

      var canvasNameMetrics = canvasContext.measureText(canvasViewName);

      var canvasNameWidth = canvasNameMetrics.actualBoundingBoxLeft + canvasNameMetrics.actualBoundingBoxRight;
      var canvasNameHeight = canvasNameMetrics.actualBoundingBoxAscent + canvasNameMetrics.actualBoundingBoxDescent;
      var canvasNameX = (canvasMap.planeWidth / 2) - (canvasNameWidth / 2); //(Middle Canvas) - (Half Box Width)
      var canvasNameY = canvasMap.planeHeight - (10 + canvasNameHeight); //Total Canvas - (Padding Bottom + Box Height)

      var canvasNameBoxPadding = 10;

      canvasContext.beginPath();
      canvasContext.lineWidth = 1;
      canvasContext.strokeStyle = "#D4DBF5";
      canvasContext.roundRect(
        canvasNameX - ((canvasNameWidth / 2) + canvasNameBoxPadding),
        canvasNameY - canvasNameBoxPadding,
        canvasNameWidth + (canvasNameBoxPadding * 2),
        canvasNameHeight + canvasNameBoxPadding,
        10);
      canvasContext.stroke();

      canvasContext.globalAlpha = 0.4;
      canvasContext.fillStyle = "#D4DBF5";
      canvasContext.fill();

      canvasContext.globalAlpha = 1;
      canvasContext.fillStyle = "#000000";
      canvasContext.fillText(canvasViewName, canvasNameX, canvasNameY);

      canvasContext.restore();
      /******* CANVAS NAME *******/

    }
    return true;
  }

  // var loadingCanvasProgress = 0;
  // var loadingCanvasAnimationId = -1;

  // async function stopLoadingCanvasAnimation() {
  //   if (loadingCanvasAnimationId !== -1) {
  //     cancelAnimationFrame(loadingCanvasAnimationId);
  //     loadingCanvasAnimationId = -1;
  //   }
  // }
  // async function startLoadingCanvasAnimation() {
  //   var ctx = getDrawingContext();
  //   if (ctx) {
  //     ctx.clearRect(0, 0, canvasMap.planeWidth, canvasMap.planeHeight);


  //     loadingCanvasProgress += 0.01;
  //     if (loadingCanvasProgress > 1) {
  //       loadingCanvasProgress = 0;
  //     }

  //     drawCircle(ctx, bigCircle, loadingCanvasProgress);
  //     drawCircle(ctx, midCircle, loadingCanvasProgress);
  //     drawCircle(ctx, smallCirlce, loadingCanvasProgress);

  //     loadingCanvasAnimationId = requestAnimationFrame(startLoadingCanvasAnimation);
  //   }
  // }
  // interface ICircle {
  //   center: {
  //     x: number,
  //     y: number
  //   },
  //   radius: number,
  //   speed: number
  // }

  // function drawCircle(ctx: CanvasRenderingContext2D, circle: ICircle, progress: number) {
  //   ctx.save();

  //   var start = accelerateInterpolator(progress) * circle.speed;
  //   var end = decelerateInterpolator(progress) * circle.speed;

  //   ctx.beginPath();

  //   ctx.lineWidth = 3;
  //   ctx.strokeStyle = "#FFFFFF";
  //   ctx.fillStyle = "#D4DBF5";
  //   //ctx.fillStyle = "#EDEDED";

  //   ctx.arc(circle.center.x, circle.center.y, circle.radius, (start - 0.5) * Math.PI, (end - 0.5) * Math.PI);
  //   ctx.fill();
  //   ctx.stroke();

  //   ctx.restore();
  // }
  // function accelerateInterpolator(x: number) {
  //   return x * x;
  // }
  // function decelerateInterpolator(x: number) {
  //   return 1 - ((1 - x) * (1 - x));
  // }

  // var bigCircle = {
  //   center: {
  //     x: canvasMap.planeWidth / 2,
  //     y: canvasMap.planeHeight / 2
  //   },
  //   radius: 40,
  //   speed: 4
  // } as ICircle

  // var midCircle = {
  //   center: {
  //     x: canvasMap.planeWidth / 2,
  //     y: canvasMap.planeHeight / 2
  //   },
  //   radius: 30,
  //   speed: 3
  // } as ICircle

  // var smallCirlce = {
  //   center: {
  //     x: canvasMap.planeWidth / 2,
  //     y: canvasMap.planeHeight / 2
  //   },
  //   radius: 20,
  //   speed: 2
  // } as ICircle



  function onMouseDown(event: React.MouseEvent<Element, MouseEvent>) {
    var eventMouseCodePressed = mouseEvents.getMouseEventEntry(event);
    setMouseCodePressed(eventMouseCodePressed);

    //console.log("onMouseDown event.pageX: ", event.pageX);
    //console.log("onMouseDown event.pageY: ", event.pageY);
    var mousePointer = sanetizeMouseEvent(event.pageX, event.pageY);
    //console.log("eventX: ", eventX);
    //console.log("eventY: ", eventY);

    const detectedObject = isPointerHoverObject(mousePointer.X, mousePointer.Y);
    if (canvasBarSelection === CanvasBarEntry.CONNECTOR) {
      if (detectedObject) {
        if (!newConnector) {
          const pointerObject: ICanvasNode = new ProductNode(-1, "", canvasMap.level, { x: mousePointer.X, y: mousePointer.Y, w: 1, h: 1 }, []);
          const connector: ICanvasLink<ICanvasNode> = new ConnectorLink(-1, "Connector Name", detectedObject, pointerObject, version);
          setNewConnector(connector);
        }
      }
    }
    else {
      if (selectedObject) {
        selectedObject.setActive(false);
        setSelectedObject(null);
      }

      if (detectedObject) {
        detectedObject.setActive(true);
        setSelectedObject(detectedObject);
      }
    }
  }

  function onMouseMove(event: React.MouseEvent<Element, MouseEvent>) {
    var mousePointer = sanetizeMouseEvent(event.pageX, event.pageY);

    //console.log("onMouseMove Mouse Code: ", mouseCodePressed);
    if (mouseCodePressed === MouseEventEntry.RIGHTCLICK) {
      // if(canvasElement !== null) {
      //   canvasElement.style.cursor = 'grabbing';
      // }
      canvasMap.move(event.movementX, event.movementY);
      setCanvasMap(canvasMap);
      drawCanvas("onMouseMove[RIGHTCLICK]");
    }

    if (mouseCodePressed === MouseEventEntry.LEFTCLICK) {
      //Check which Mode is selected
      if (canvasBarSelection === CanvasBarEntry.POINTER) {
        if (selectedObject) {
          selectedObject.move(event.movementX, event.movementY);
          //console.log("drawCanvas('onMouseMove[LEFTCLICK-POINTER]')");
          drawCanvas("onMouseMove[LEFTCLICK-POINTER]");
        }
      }

      if (canvasBarSelection === CanvasBarEntry.CONNECTOR) {
        if (newConnector) {
          const startObject = newConnector.getStartObject();
          const endObject = newConnector.getEndObject();

          const detectedObject = isPointerHoverObject(mousePointer.X, mousePointer.Y);
          if (detectedObject) {
            if (detectedObject.getId() !== startObject.getId()) {
              detectedObject.setOnHover(true);
              newConnector.setEndObject(detectedObject);
            }
            else {
              endObject.setOnHover(false);
              newConnector.setEndObject(endObject);
            }
          }
          else {
            endObject.setOnHover(false);
            const pointerObject: ICanvasNode = new ProductNode(-1, "", canvasMap.level, { x: mousePointer.X, y: mousePointer.Y, w: 1, h: 1 }, []);
            newConnector.setEndObject(pointerObject);
          }

          setNewConnector(newConnector);
          drawCanvas("onMouseMove[LEFTCLICK-CONNECTOR]");
        }
      }
    }

    if (mouseCodePressed === MouseEventEntry.VOID) {
      //When not clicked and we are in CONNECTOR MODE 
      //check if mouse is hover an object
      if (canvasBarSelection === CanvasBarEntry.CONNECTOR) {
        const detectedObject = isPointerHoverObject(mousePointer.X, mousePointer.Y);
        if (detectedObject) {
          detectedObject.setOnHover(true);
        }
        else {
          //When not hovering Reset hover on all objects
          var objectsFromLevel = objects.get(canvasMap.level);
          objectsFromLevel?.forEach((canvasObject) => {
            canvasObject.setOnHover(false);
          });
        }
        drawCanvas("onMouseMove[VOID-CONNECTOR]");
      }
    }
  }

  function onMouseUp(event: React.MouseEvent<Element, MouseEvent>) {
    var mousePointer = sanetizeMouseEvent(event.pageX, event.pageY);

    const detectedObject = isPointerHoverObject(mousePointer.X, mousePointer.Y);
    if (detectedObject) {
      if (canvasBarSelection === CanvasBarEntry.CONNECTOR) {

        if (newConnector) {
          if (detectedObject.getId() === newConnector.getEndObject().getId()) {
            var connectorsFromLevel = connectors.get(canvasMap.level);
            if (connectorsFromLevel === undefined)
              connectorsFromLevel = new Array<ICanvasLink<ICanvasNode>>();

            connectors.set(canvasMap.level, [...connectorsFromLevel, newConnector]);
            setConnectors(connectors);

            //Save it
            ASGateway.Links.Create(
              newConnector.getVersion()!,
              newConnector.getName(),
              newConnector.getStartObject().getId(),
              newConnector.getEndObject().getId() //Head
            )
              .then((data) => {
                //console.log('CreateLink: ', data);
              })
              .catch((e: any) => {
                console.error(e);
              });

          }
          setNewConnector(null);
        }
      }
      else {
        if (mouseCodePressed === MouseEventEntry.LEFTCLICK) {
          //Update new position for Object
          //TEMP TODO: Verify if object was moved. We do not need to update every time we click in a Object
          ASGateway.Nodes.UpdateView(
            agentViewId,
            canvasViewId,
            detectedObject.getId(),
            version,
            detectedObject.getOptions().x,
            detectedObject.getOptions().y,
            detectedObject.getOptions().w,
            detectedObject.getOptions().h
          )
            .then((data) => {
              //console.log('UpdateNodeView: ', data);
            })
            .catch((e: any) => {
              console.error(e);
            });

        }

        if (selectedObject) {
          selectedObject.setActive(false);
          setSelectedObject(null);
        }

        detectedObject.setActive(true);
        setSelectedObject(detectedObject);
      }
    }
    else {
      //console.log("[onMouseUp] RightClick pressed? ", mouseEvents.isRightClickCode(mouseCodePressed));
      if (mouseCodePressed === MouseEventEntry.LEFTCLICK) {
        switch (canvasBarSelection) {
          case CanvasBarEntry.PRODUCT:
            {
              //For correct render inside objects where we are adding canvasMap values
              //we need to create object with coordinates in clean format without canvasMap values
              const options = {
                x: mousePointer.X - canvasMap.originX,
                y: mousePointer.Y - canvasMap.originY,
                w: 75,
                h: 75,
              };
              var objectsFromLevel = objects.get(canvasMap.level);
              if (objectsFromLevel === undefined)
                objectsFromLevel = new Array<ICanvasNode>();

              const product: ICanvasNode = new ProductNode((canvasMap.level * 10) + objectsFromLevel.length + 1, "Name Node", canvasMap.level, options, [environment], version);
              objects.set(canvasMap.level, [...objectsFromLevel, product]);

              setObjects(objects);

              //Save it
              ASGateway.Nodes.Create(
                agentViewId,
                canvasViewId,
                version,
                canvasMap.level,
                "Name Node",
                options.x,
                options.y,
                options.w,
                options.h
              )
                .then((data) => {
                  //console.log('CreateNode: ', data);
                })
                .catch((e: any) => {
                  console.error(e);
                });

              break;
            }
          case CanvasBarEntry.CONNECTOR:
            {
              setNewConnector(null);
              break;
            }
          default:
            {
              selectedObject?.setActive(false);
              setSelectedObject(null);
            }
        }
      }
    }

    if (mouseCodePressed !== MouseEventEntry.VOID) {
      setMouseCodePressed(MouseEventEntry.VOID);
    }
    drawCanvas("onMouseUp");
  }

  function onContextMenu(event: React.MouseEvent<Element, MouseEvent>) {
    //console.log("onContextMenu");
    event.preventDefault();
  }

  function onDoubleClick(event: React.MouseEvent<Element, MouseEvent>) {
    var mousePointer = sanetizeMouseEvent(event.pageX, event.pageY);

    //console.log("onDoubleClick");
    //event.preventDefault();
    const detectedObject = isPointerHoverObject(mousePointer.X, mousePointer.Y);

    //console.log("onDoubleClick detectedObject: ", detectedObject);
    if (detectedObject) {

      //console.log("onDoubleClick mouseCodePressed: ", mouseCodePressed);
      // if (mouseCodePressed === MouseEventEntry.RIGHTCLICK) {
      //   selectedObject?.setActive(false);
      //   setSelectedObject(null);
      //   //Drill In One level in Canvas Map
      //   canvasMap.drillLevelIn();
      //   setCanvasMap(canvasMap);
      // }
      // if (mouseCodePressed === MouseEventEntry.LEFTCLICK) {
      dispatch(setDetailContentId(detectedObject.getId()));
      dispatch(setDetailContainer(DetailContainerEntry.NODE));
      dispatch(setOpenDetailDrawer(true));
      // }
      drawCanvas("onDoubleClick");
    }
  }

  function onWheel(event: React.WheelEvent<Element>) {
    //Only zoom if Shift is pressed
    if (event.shiftKey) {
      //console.log("onScroll");
      var context = getDrawingContext();
      if (context) {

        var wheel = event.deltaY / 120; //n or -n

        //console.log("onScroll wheel: ", wheel);
        //according to Chris comment
        var zoom = Math.pow(1 + Math.abs(wheel) / 2, wheel > 0 ? 1 : -1);
        //console.log("onScroll zoom: ", zoom);
        canvasMap.zoomInOut(zoom);
        setCanvasMap(canvasMap);
        drawCanvas("onWheel");
      }

    }
  }


  function onDrag(event: React.DragEvent<Element>) {
    //console.log("onDrag");
  }
  function onDragStart(event: React.DragEvent<Element>) {
    //console.log("onDragStart");
  }
  function onDragEnd(event: React.DragEvent<Element>) {
    //console.log("onDragEnd");
  }

  return (
    <>
      <canvas
        ref={canvasElement}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onContextMenu={onContextMenu}
        onDoubleClick={onDoubleClick}
        onWheel={onWheel}
        onDrag={onDrag}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      //onTouchStart={}
      />

      {/* Drill IN Map bar => drill level out action */}
      <Paper
        sx={{
          backgroundColor: "#FFFFFF",
          border: 0,
          padding: 0.5,
          width: 40,
          position: "absolute",
          left: "71px",
          top: "81px",
          display: (canvasMap.level > 1) ? '' : 'none'
        }}
      >
        <Paper
          sx={{
            p: 1,
            boxShadow: "none",
            backgroundColor: "#FFFFFF",
            ":hover": {
              backgroundColor: "#D4DBF5",
            }
          }}
          onClick={() => {
            canvasMap.drillLevelOut();
            setCanvasMap(canvasMap);
            drawCanvas("Menu-onClick-drillLevelOut");
          }}
        >
          <ArrowCircleUpIcon />
        </Paper>
      </Paper>
      {canvasBarDeveloperMode &&
        <Paper
          sx={{
            backgroundColor: "red",
            border: 0,
            padding: 0.5,
            //width: 40,
            position: "absolute",
            left: "50%",
            top: "10px"
          }}
        >
          DEVELOPER MODE: Level: {canvasMap.level} OriginX: {canvasMap.originX} OriginY: {canvasMap.originY} Zoom: {canvasMap.zoom}
        </Paper>
      }

      <div ref={detailBarNodeRef} style={{ position: "absolute" }} />
      <Popper
        id="canvas-view-node-update-popover"
        open={openDetailBarNode}
        anchorEl={detailBarNodeRef.current}
      >
        <CanvasViewDetailBarNode
          agentViewId={agentViewId}
          canvasViewId={canvasViewId}
          versionId={version}
          node={selectedObject}
          onChangeView={(canvasViewId) => {
            //console.log('HOME::CanvasView::DetailBarNode::onChangeView: ', canvasViewId);
            setCanvasViewId(canvasViewId);
            props.onChangeView(canvasViewId);
          }}
          onChangeNode={(node: ASDtos.NodeReadDto) => {
            //FindNode to Update
            var objectsFromLevel = objects.get(canvasMap.level);
            if (objectsFromLevel === undefined) {
              objectsFromLevel = new Array<ICanvasNode>();
            }

            var objectToUpdate = objectsFromLevel.find(obj => obj.getId() === node.id);
            objectToUpdate?.setName(node.name);

            objects.set(canvasMap.level, objectsFromLevel);
            setObjects(objects);

            //DrawCanvas
            drawCanvas();
            setOpenDetailBarNode(false);
          }}
        />
      </Popper>

      <CanvasBar
        canvasViewId={canvasViewId}
        coordX={canvasMap.planeOriginX}
        coordY={canvasMap.planeOriginY}
        onChangeSelection={(selection: CanvasBarEntry) => { setCanvasBarSelection(selection) }}
        onChangeView={(canvasViewId) => {
          //console.log('HOME::CanvasView::CanvasBar::onChangeView: ', canvasViewId);
          setCanvasViewId(canvasViewId);
          props.onChangeView(canvasViewId);
        }}
        onChangeViewNodeAdded={(nodeAdded: ASDtos.NodeReadDto) => {
          const options = {
            x: nodeAdded.nodeView.posX,
            y: nodeAdded.nodeView.posY,
            w: nodeAdded.nodeView.width,
            h: nodeAdded.nodeView.height
          };
          var objectsFromLevel = objects.get(canvasMap.level);
          if (objectsFromLevel === undefined)
            objectsFromLevel = new Array<ICanvasNode>();

          const product: ICanvasNode = new ProductNode(nodeAdded.id, nodeAdded.name, canvasMap.level, options, nodeAdded.enviromentForChanges, version);
          objects.set(canvasMap.level, [...objectsFromLevel, product]);

          setObjects(objects);
        }}
      />
    </>
  );
}