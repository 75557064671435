import React, { useEffect, useState } from 'react'; // let's also import Component
import { useForm } from 'react-hook-form';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import { ASGateway, ASDtos } from 'api/AppServices';

import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';

type IFormNewAgentProps = {
  formIsDone: (newAgentCreated: ASDtos.AgentReadDto) => void;
};

export default function FormNewAgent(props: IFormNewAgentProps) {
    //Redux States
    const dispatch = useAppDispatch();
    const teams = useAppSelector((state) => state.systemBar.teams);
    //Redux States

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'all' });

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [fieldName, setFieldName] = useState<string>("");
  const [fieldAccount, setFieldAccount] = useState<string>("");
  const [fieldEmail, setFieldEmail] = useState<string>("");

  //Handles
  async function handleNewAgentSubmit() {
    setIsButtonSubmiting(true);
    ASGateway.Agents.Create(
      fieldName,
      fieldAccount,
      fieldEmail
    )
      .then((data: ASDtos.AgentReadDto) => {
        //console.log('Create Agent: ', data);
        props.formIsDone(data);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });
  }

  // OnRender
  return (
    <Box id='newAgentForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleNewAgentSubmit)} sx={{ width: "100%", height: "100%" }}>
      <TextField
        required
        id="fieldName"
        key="fieldName"
        label="Name"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('fieldName', {
          required: { value: true, message: 'Field is Required.' },
          maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
          onChange: (e) => {
            //buildAccountFromName(e.target.value);
            setFieldName(e.target.value);
          },
        })}
        value={fieldName}
        error={errors?.fieldName ? true : false}
        helperText={errors?.fieldName?.message?.toString()}
      />
      <TextField
        required
        id="fieldAccount"
        key="fieldAccount"
        label="Account"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('fieldAccount', {
          required: { value: true, message: 'Field is Required.' },
          maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
          onChange: (e) => setFieldAccount(e.target.value),
        })}
        value={fieldAccount}
        error={errors?.fieldAccount ? true : false}
        helperText={errors?.fieldAccount?.message?.toString()}
      />
        <TextField
          required
          id="fieldEmail"
          key="fieldEmail"
          label="Email"
          fullWidth autoFocus margin="dense" variant='filled' size='small'
          {...register("fieldEmail", {
            required: { value: true, message: 'Field Required' },
            pattern: {
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: 'Invalid email address',
            },
            onChange: (e) => setFieldEmail(e.target.value),
          })}
          value={fieldEmail}
          error={errors.fieldEmail ? true : false}
          helperText={errors.fieldEmail?.message?.toString()}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position="start">
          //       <MailOutlineIcon />
          //     </InputAdornment>
          //   )
          // }}
        />

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <br /><br /><br />
        <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleNewAgentSubmit)}>Create</SmartButton>
      </Stack>
    </Box>
  );
}