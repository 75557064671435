import React, { useEffect, useState } from 'react'; // let's also import Component

import {
  setTeamsSelected,
  setEnvironmentSelected,
  setVersionSelected,
  setCanvasViewSelected,
  setDeveloperMode
} from 'reducers/systemBarReducer';
import { setOpenBoardDrawer } from 'reducers/boardDrawerReducer';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import { ScreenWideEntry, CanvasViewEntry } from 'app/AppValues';
import { ASGateway, ASDtos } from 'api/AppServices';

import {
  Stack,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Button,
  IconButton
} from '@mui/material';

import MainBarDrawer from 'components/MainBarDrawer';
import FormNewVersion from 'components/Forms/FormNewVersion'
import FormNewEnvironment from 'components/Forms/FormNewEnvironment'
import SelectorTeams from 'components/SelectorTeams';

import IconSvg from "components/IconSvg";
import CropSquareIcon from '@mui/icons-material/CropSquare';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import DashboardIcon from '@mui/icons-material/Dashboard';


export default function CanvasViewMainBar() {
  //Redux States
  const dispatch = useAppDispatch();
  const userSession = useAppSelector((state) => state.appSession.user);
  const teams = useAppSelector((state) => state.systemBar.teams);
  const version = useAppSelector((state) => state.systemBar.version);
  const environment = useAppSelector((state) => state.systemBar.environment);
  const canvasViewSelected = useAppSelector((state) => state.systemBar.canvasViewSelected);
  const canvasBarDeveloperMode = useAppSelector((state) => state.systemBar.developerMode);
  const boardDrawerScreenWide = useAppSelector((state) => state.boardDrawer.screenWide);
  //Redux States

  const [versions, setVersions] = useState<ASDtos.VersionReadDto[]>([]);
  const [environments, setEnvironments] = useState<ASDtos.EnvironmentReadDto[]>([]);

  const [showNewVersionForm, setShowNewVersionForm] = useState<boolean>(false);

  //Handles
  function handleChangeVersion(event: SelectChangeEvent<number>) {
    dispatch(setEnvironmentSelected(0)); //Reset Environment selection to Local
    dispatch(setVersionSelected(Number(event.target.value))); //Set new version
  }

  function handleChangeCanvasView(canvasView: CanvasViewEntry) {
    dispatch(setCanvasViewSelected(canvasView));
  }

  // OnLoad
  useEffect(() => {
    if (teams[0] > 0) {
      ASGateway.Versions.GetAll(teams[0])
        .then((data: ASDtos.VersionReadDto[]) => {
          //console.log('GetAllVersions: ', data);
          setVersions(data);
        })
        .catch((onrejected) => {
          if (onrejected) {
            setVersions([]);
          }
        })
        .catch((e: any) => {
          console.error(e);
        });

      ASGateway.Environments.GetAll(teams[0])
        .then((data: ASDtos.EnvironmentReadDto[]) => {
          //console.log('GetAllEnvironments: ', data);
          setEnvironments(data);
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }, [teams]);

  function GetVersionElements(versions: ASDtos.VersionReadDto[], level: number) {
    let returnValues: any = [];
    versions.map((v: ASDtos.VersionReadDto) => {
      const spacing = Array.from(Array(4 * level), () => '\u00A0');

      returnValues.push(
        <MenuItem key={v.id} value={v.id}>{spacing}{v.name}</MenuItem>
      );

      if (v.childs.length > 0) {
        returnValues.push(...GetVersionElements(v.childs, level + 1));
      }
    });
    return returnValues;
  }

  // OnRender
  return (
    <MainBarDrawer leftPadding={70} topPadding={10}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      //spacing={1}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        //spacing={1}
        >
          <Box sx={{ p: 0.2, border: 1, borderColor: "lightgray" }}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            //spacing={1}
            >

              <IconButton
                sx={{ p: 0.5, m: 0, backgroundColor: (canvasViewSelected === CanvasViewEntry.SINGLE) ? "#D4DBF5" : "#FFFFFF", ":hover": { backgroundColor: "#D4DBF5" } }}
                onClick={() => { handleChangeCanvasView(CanvasViewEntry.SINGLE) }}
              >
                <CropSquareIcon />
              </IconButton>
              {/* <Divider orientation="horizontal" /> */}
              <IconButton
                sx={{ p: 0.5, m: 0, backgroundColor: (canvasViewSelected === CanvasViewEntry.COLDOUBLE) ? "#D4DBF5" : "#FFFFFF", ":hover": { backgroundColor: "#D4DBF5" } }}
                onClick={() => { handleChangeCanvasView(CanvasViewEntry.COLDOUBLE) }}
              >
                <IconSvg icon='ViewCanvasDouble' />
              </IconButton>
              <IconButton
                sx={{ p: 0.5, m: 0, backgroundColor: (canvasViewSelected === CanvasViewEntry.COLTRIPLE) ? "#D4DBF5" : "#FFFFFF", ":hover": { backgroundColor: "#D4DBF5" } }}
                onClick={() => { handleChangeCanvasView(CanvasViewEntry.COLTRIPLE) }}
              >
                <ViewWeekIcon />
              </IconButton>
            </Stack>
          </Box>

          <Box sx={{ p: 1 }}>
            <FormControl >
              <InputLabel sx={{ height: 30 }} id="version-select-label">Version</InputLabel>
              <Select
                sx={{
                  height: 30,
                  width: 300
                }}
                labelId="version-select-label"
                id="version-select"
                value={version}
                label="Version"
                onChange={(event: any) => {
                  if (event.target.value === "") {
                    setShowNewVersionForm(true);
                  }
                  else {
                    handleChangeVersion(event)
                  }
                }}
              >
                <MenuItem key={0} value={0}>LIVE</MenuItem>
                {GetVersionElements(versions, 0)}
                <Divider />
                <MenuItem key={""} value={""} sx={{ m: 0 }}><Button sx={{ p: 0, m: 0 }} fullWidth size='small'>New Version</Button></MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ p: 0.2, border: 1, borderColor: "lightgray" }}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <IconButton
                sx={{ p: 0.5, m: 0, backgroundColor: (boardDrawerScreenWide !== ScreenWideEntry.HIDE) ? "#D4DBF5" : "#FFFFFF", ":hover": { backgroundColor: "#D4DBF5" } }}
                onClick={() => dispatch(setOpenBoardDrawer(!(boardDrawerScreenWide !== ScreenWideEntry.HIDE)))}
              >
                <DashboardIcon />
              </IconButton>
              <Divider orientation="vertical" flexItem sx={{ display: "inline", pl: 1 }} />
              <IconButton
                sx={{ p: 0.5, m: 0, backgroundColor: (canvasBarDeveloperMode) ? "#D4DBF5" : "#FFFFFF", ":hover": { backgroundColor: "#D4DBF5" } }}
                onClick={() => dispatch(setDeveloperMode(!canvasBarDeveloperMode))}
              >
                <DeveloperModeIcon />
              </IconButton>
            </Stack>
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <SelectorTeams agentId={userSession.userId} onChangeTeams={(teamsSelected) => dispatch(setTeamsSelected(teamsSelected))} />
          <FormControl>
            <InputLabel id="environment-select-label" sx={{ height: 30 }}>Enviroment</InputLabel>
            <Select
              sx={{
                height: 30,
                width: 150
              }}
              labelId="environment-select-label"
              id="environment-select"
              value={environment}
              label="Enviroment"
              onChange={(event: any) => {
                  dispatch(setEnvironmentSelected(Number(event.target.value)));
              }}
            >
              <MenuItem key={0} value={0}>Local</MenuItem>
              {environments.sort((a, b) => a.id > b.id ? 1 : -1).map((env: ASDtos.EnvironmentReadDto) => {
                return (
                  <MenuItem key={env.id} value={env.id}>{env.name}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      {/* Create New VERSION Dialog */}
      <Dialog fullScreen={false} open={showNewVersionForm} onClose={() => setShowNewVersionForm(false)}>
        <DialogTitle>New Version</DialogTitle>
        <DialogContent sx={{ p: 1, maxWidth: "500px", maxHeight: "500px" }}>
          <FormNewVersion
            formIsDone={(newVersion) => {
              setVersions([...versions, newVersion]);
              setShowNewVersionForm(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </MainBarDrawer>
  );
}
