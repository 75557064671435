import React, { useEffect, useMemo, useState } from 'react'; // let's also import Component
import parse from 'autosuggest-highlight/parse';

import { ASGateway, ASDtos } from '../api/AppServices';

import {
  Box,
  TextField,
  Autocomplete,
  Grid,
  debounce
} from '@mui/material';

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

type IAutoCompletePackagesProps = {
  id: string,
  required?: boolean | undefined,
  valueGetter: (value: number | null) => void,
  error?: boolean,
  helperText?: string | undefined
};


export default function AutoCompletePackages(
  packTypeProps: IAutoCompletePackagesProps,
  ...props: any[]
) {
  const [pack, setPack] = useState<ASDtos.PackageReadDto | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly ASDtos.PackageReadDto[]>([]);

  const fetchPacks = useMemo(
    () =>
      debounce(
        (
          request: { searchTerm: string },
          callback: (results?: ASDtos.PackageReadDto[]) => void,
        ) => {
          ASGateway.Packages.Search(request.searchTerm)
            .then((packs: ASDtos.PackageReadDto[]) => {
              callback(packs);
            })
            .catch((e: any) => {
              console.error(e);
            });
        },
        400,
      ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(pack ? [pack] : []);
      return undefined;
    }

    fetchPacks(
      { searchTerm: inputValue }, // Input
      (results?: readonly ASDtos.PackageReadDto[]) => { //CallBack
        //console.log("AutoCompletePacks results: ", results);
        if (active) {
          let newOptions: readonly ASDtos.PackageReadDto[] = [];

          if (pack) {
            newOptions = [pack];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });

    return () => {
      active = false;
    };
  }, [pack, inputValue, fetch]);

  useEffect(() => {
    let active = true;
    return () => {
      active = false;
    };
  }, [packTypeProps]);

  return (
    <Autocomplete
      id={packTypeProps.id}
      key={packTypeProps.id}
      sx={{ width: "100%" }}
      getOptionLabel={(option: ASDtos.PackageReadDto) =>
        typeof option === 'string' ? option : option.name
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={pack}
      //value={packTypeProps.value}
      noOptionsText="No Package found. Please refine your search."
      onChange={(event: any, newValue: ASDtos.PackageReadDto | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setPack(newValue);
        packTypeProps.valueGetter((newValue) ? newValue.id : null);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} 
          required={packTypeProps.required}
          fullWidth 
          label="Search a Package"
          error={packTypeProps.error}
          helperText={packTypeProps.helperText}
        />
      )}
      renderOption={(props, option: ASDtos.PackageReadDto) => {
        //TODO Have Matches parts from Service //option.structured_formatting.main_text_matched_substrings || [];
        //FOR NOW WE MATCH EVERY RESULT FULL TEXT
        //const matches: any[] = [{ offset: 0, length: option.title.length }];
        const matches: any[] = [{ offset: 0, length: 0 }];
        const parts = parse(
          option.name,
          matches,
        );

        return (
          <li 
            {...props}
          >
            <Grid container alignItems="center">
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                {/* <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography> */}
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
