//https://react-redux.js.org/tutorials/typescript-quick-start
import { createSlice, PayloadAction  } from '@reduxjs/toolkit'
import { ASDtos } from "api/AppServices";
import { AppVars } from "app/AppVars";

interface IUserSessionState {
  hasActiveSession: boolean,
  userId: number,
  isAdmin: boolean
  //expirationDate: Date
}
interface ICompanySessionState {
  companyId: number,
  companyName: string,
  companyDomain: string
}

// Define a type for the slice state
interface IAppSessionState {
  user: IUserSessionState,
  company: ICompanySessionState
}

// Define the initial state from Store using that type
const initialEmptyState: IAppSessionState = {
  user: {
    hasActiveSession: false,
    userId: 0,
    isAdmin: false,
    //expirationDate:     "",
  },
  company: {
    companyId: 0,
    companyName: "",
    companyDomain: ""
  },
}

const appSessionLocalWithDefault: IAppSessionState = JSON.parse(localStorage.getItem("appSession") || JSON.stringify(initialEmptyState));
//const userSessionLocalExpiration = userSessionLocal ? new Date(userSessionLocal.expirationDate)  : new Date();

// Define the initial state using that type
const initialState: IAppSessionState = {
  user: {
    hasActiveSession: appSessionLocalWithDefault.user.hasActiveSession,
    //hasActiveSession: userSessionLocal ? (userSessionLocal.userId && userSessionLocalExpiration > new Date())     : false,
    userId: appSessionLocalWithDefault.user.userId,
    isAdmin: appSessionLocalWithDefault.user.isAdmin,
    //expirationDate: userSessionLocalExpiration,
  },
  company: {
    companyId: appSessionLocalWithDefault.company.companyId,
    companyName: appSessionLocalWithDefault.company.companyName,
    companyDomain: appSessionLocalWithDefault.company.companyDomain
  },
}

export const appSessionReducer = createSlice({
  name: 'appSessionState',
  initialState: initialState,
  reducers: {
    createUserSession: (state, authSession: PayloadAction<ASDtos.AuthenticationReadDto>) => {

      state.user.hasActiveSession = true;
      state.user.userId = authSession.payload.user.id;
      state.user.isAdmin = false;


      state.company.companyId = authSession.payload.company.id;
      state.company.companyDomain = authSession.payload.company.domain;
      state.company.companyName = authSession.payload.company.name;

      //Setup new Expiration date 
      //Add 6 days from new Session Creation
      // var tempDate = new Date();
      // tempDate.setDate(tempDate.getDate() + 6);
      // state.expirationDate = tempDate;

      //Setup AppVars for Global Usage in App
      AppVars.CompanyId = authSession.payload.company.id;

      //Save session into localStorage
      localStorage.setItem("appSession", JSON.stringify(state));
    },
    removeUserSession: (state) => {
      state.user.hasActiveSession = false;
      localStorage.removeItem("appSession");
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  createUserSession,
  removeUserSession
} = appSessionReducer.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
export default appSessionReducer.reducer