import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { removeUserSession } from 'reducers/appSessionReducer';

import { DetailContainerEntry } from 'app/AppValues';
import { AppUtils } from 'app/AppUtils';
import { AppVars } from "app/AppVars";


import NotificationBar from 'components/NotificationBar';

import Home from 'pages/Home';
import Roadmap from 'pages/Roadmap';
import Team from 'pages/Team';
import Login, { LoginMode } from 'pages/Login';
import Detail from 'pages/Detail';
import Setup from 'pages/Setup';
import Wiki from 'pages/Wiki';

export default function App() {
  //Redux States
  const dispatch = useAppDispatch();
  const userSession = useAppSelector((state) => state.appSession.user);
  const companySession = useAppSelector((state) => state.appSession.company);
  //Redux States

  //************ RUN BEFORE useEffect
  const domain = AppUtils.GetCompanyDomain();
  const toolName = AppUtils.GetToolName(domain);
  document.title = `${domain} @${toolName}`;

  if (userSession.hasActiveSession){
    AppVars.CompanyId = companySession.companyId;
  }
  if (userSession.hasActiveSession && domain !== companySession?.companyDomain) {
    //When user is Logged and domain from URL is diferent from domain saved then LoggOut User
    dispatch(removeUserSession());
  }
//************ RUN BEFORE useEffect

  //On Load
  useEffect(() => {
    // console.log("App domain: ", domain);
    // console.log("App userSession.hasActiveSession: ", userSession.hasActiveSession);
    // console.log("App companySession?.companyDomain: ", companySession?.companyDomain);
  }, []);

  return (
    <div className="App">
      <Router>
        {/* 
          ANY GLOBAL MenuBar GOES HERE
          <MenuBar />
         */}
        <NotificationBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/login" element={<Login mode={LoginMode.LOGIN}/>} />
          <Route path="/reset-credentials" element={<Login mode={LoginMode.RESET}/>} />
          <Route path='/roadmap' element={<Roadmap />} />
          <Route path='/team' element={<Team />} />
          <Route path='/wiki' element={<Wiki />} />
          <Route path='/setup' element={<Setup />} />
          <Route path="/detail/node/:contentId" element={<Detail type={DetailContainerEntry.NODE} />} />
          <Route path="/detail/task/:contentId" element={<Detail type={DetailContainerEntry.TASK} />} />
          {/* 
          <Route path="/account" element={<Account />} />
          <Route path='/products' element={<Products />} /> 
          */}
        </Routes>
      </Router>
    </div>
  );
}