import { useState, SyntheticEvent } from "react";

import TabPanel from "components/TabPanel";

import {
  Box,
  Tabs,
  Tab
} from "@mui/material";

interface ITabContainerProps {
  height: number;
  children: React.ReactNode[];
}

export default function TabContainer(props: ITabContainerProps) {
  const { children, height } = props;

  const [innerValue, setInnerValue] = useState<number>(0);

  function handleChange(event: SyntheticEvent, newValue: number) {
    setInnerValue(newValue);
  }
  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: (height / 2) }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={innerValue}
        onChange={handleChange}
        aria-label="global-setup-tabs"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {children.map((node: any, index: number) => {
          return (<Tab label={node.props.label} />)
        })}
      </Tabs>
      {children.map((node: React.ReactNode, index: number) => {
        return (
          <TabPanel value={innerValue} index={index}>
            {node}
          </TabPanel>
        )
      })}
    </Box>);
}