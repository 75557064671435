import React, { ChangeEvent, useEffect, useState } from 'react'; // let's also import Component
import { useForm } from 'react-hook-form';

import { ASGateway, ASDtos } from 'api/AppServices';
import { DocumentTypeEntry } from 'app/AppValues';

import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box,
  TextField,
  MenuItem,
  Button
} from '@mui/material';

interface IFormNewDocumentProps {
  versionId: number;
  agentId: number;
  nodeId: number | undefined;
  taskId: number | undefined;
  formIsDone: (newDocCreated: any) => void;
};

export default function FormNewDocument(props: IFormNewDocumentProps) {
  //Redux States
  //const dispatch = useAppDispatch();
  //Redux States

  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'all' });

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [documentTypes, setDocumentTypes] = useState([] as DocumentTypeEntry[]);
  const [fieldDocumentTypeSelection, setFieldDocumentTypeSelection] = useState("");
  const [fieldDocumentName, setFieldDocumentName] = useState("");

  //Handles
  function handleChangeDocumentTypeSelection(e: ChangeEvent<HTMLInputElement>) {
    var documentType: any = e.target.value;
    setFieldDocumentTypeSelection(documentType);
  }

  async function handleSubmitForm() {
    setIsButtonSubmiting(true);
    //CREATE NEW
    await ASGateway.Documents.Create(
      props.versionId,
      props.agentId,
      fieldDocumentName,
      props.nodeId,
      props.taskId
    )
      .then((data: ASDtos.DocumentReadDto) => {
        props.formIsDone(data);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });      
  }

  // OnLoad
  useEffect(() => {
    //console.log("useEffect[FormNewDocument]");
    //Load DocumentTypes
    setDocumentTypes(
      Object.keys(DocumentTypeEntry)
        .filter(value => isNaN(Number(value)) === false) //Filter out all keys that are numbers
        .map((key) => DocumentTypeEntry[key as keyof typeof DocumentTypeEntry])
    );
  }, []);

  // OnRender
  return (
    <Box id='newDocumentForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleSubmitForm)} sx={{ padding: 0, width: "100%", height: "100%" }}>
      <TextField
        required
        select
        id="documentType"
        key="documentType"
        label="Document Type"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('documentType', {
          validate: {
            required: (value) => value !== "" || "Field is Required.",
          },
          onChange: (e) => handleChangeDocumentTypeSelection(e),
        })}
        value={fieldDocumentTypeSelection}
        error={errors?.documentType ? true : false}
        helperText={errors?.documentType?.message?.toString()}
      >
        <MenuItem key={""} value={""} disabled>Select Document Type</MenuItem>
        {
          documentTypes.map((docType: any) => {
              return (
                <MenuItem
                  key={DocumentTypeEntry[docType]}
                  value={docType}
                >
                  {docType}
                </MenuItem>
              )
          })
        }
      </TextField>
      <TextField
        required
        id="documentName"
        key="documentName"
        label="Document Name"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('documentName', {
          required: { value: true, message: 'Field is Required.' },
          maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
          onChange: (e) => setFieldDocumentName(e.target.value),
        })}
        value={fieldDocumentName}
        error={errors?.documentName ? true : false}
        helperText={errors?.documentName?.message?.toString()}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <br /><br /><br />
        <SmartButton onSubmit={() => {props.formIsDone(null)}}>Cancel</SmartButton>
        <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleSubmitForm)}>Create</SmartButton>
      </Stack>
    </Box>
  );
}