import React, { useEffect, useState } from 'react'; // let's also import Component
import { DndContext } from '@dnd-kit/core';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { setTaskIdIsDragging } from 'reducers/boardDrawerReducer';

import { ASGateway, ASDtos } from 'api/AppServices';
import { CardTypeEntry } from 'app/AppValues';
import { CardMapper } from 'app/AppMapper';

import AgileBoardColumn from 'components/AgileBoards/AgileBoardColumnDragContainer';

import {
  Stack,
  Box,
} from '@mui/material';

interface IBoardViewSprintProps {
  teamId: number;
  boardId: number;
  boardViewId: number;
  maxHeightContainer: number;
  setupBoardMode: boolean;
  children?: any;
};

export default function BoardViewSprint(props: IBoardViewSprintProps) {
  const mapper = new CardMapper();
  //Redux States
  const dispatch = useAppDispatch();
  //Redux States

  const [boardViewColumns, setBoardViewColumns] = useState<ASDtos.BoardViewColumnReadDto[]>([]);
  const [sprint, setSprint] = useState<ASDtos.BoardIterationReadDto | null>(null);

  //Handles
  // function handleDragStart(event: DragStartEvent) {
  function handleDragStart(event: any) {
    //console.log("handleDragStart(event): ", event);
    if (event.over) { //&& event.over.id === 'droppable'
      //var msg = "Card ID: " + event.active.id + " -> Dropped on Column: " + event.over.id;
      //alert(msg);
    }
  }
  // function handleDragMove(event: DragMoveEvent) {
  function handleDragMove(event: any) {
    //console.log("handleDragMove(event): ", event);
    if (event.active) {
      dispatch(setTaskIdIsDragging(event.active.id))
    }
  }
  // function handleDragOver(event: DragOverEvent) {
  function handleDragOver(event: any) {
    //console.log("handleDragOver(event): ", event);

    dispatch(setTaskIdIsDragging(0))
  }
  // function handleDragEnd(event: DragEndEvent) {
  function handleDragEnd(event: any) {
    //console.log("handleDragEnd(event): ", event);
    //console.log("event.delta): ", event.delta);

    if (event.over) {
      const transitionToColumnId = event.over.id;
      const taskId = event.active.id;

      //=== Update Locally before saving to Database ===
      //Get ORIGIN Column from which task was moved FROM
      var currentBoardViewColumns = [...boardViewColumns];
      var columnOriginTask = currentBoardViewColumns.find((column) => {
        return column.tasks.some((task: any) => task.id === taskId);
      })!;

      //Get DESTINY Column from which task was moved INTO
      var columnDestinyTask = currentBoardViewColumns.find((column) => {
        return column.id === transitionToColumnId;
      })!;

      //Get Task to move
      var taskToMove = columnOriginTask?.tasks.find((task: any) => {
        return task.id === taskId;
      })!;

      //Remove task from Column Origin
      columnOriginTask.tasks = columnOriginTask?.tasks.filter((task: any) => task.id !== taskId);
      //Add task to Column Destiny
      columnDestinyTask.tasks = [...columnDestinyTask.tasks, taskToMove];

      //Save UI Result
      setBoardViewColumns(currentBoardViewColumns);

      //Update Database
      ASGateway.Tasks.AssignToColumn(
        props.boardId,
        props.boardViewId,
        columnOriginTask?.id,
        taskId,
        {
          assignToBoardId: props.boardId, //Same Board
          assignToViewId: props.boardViewId, //Same Board View
          assignToColumnId: transitionToColumnId, //New Column
        }
      )
        .then((data: ASDtos.TaskReadDto) => {
          //console.log('UpdateTaskTransitionToColumn: ', data);
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }


  async function handleLoadBoardView() {
    //Call Service
    await ASGateway.BoardViews.GetColumns(props.boardId, props.boardViewId)
      .then(async (data: ASDtos.BoardViewColumnReadDto[]) => {
        //console.log('GetAllBoardViewColumns: ', data);
        setBoardViewColumns(data);
        await handleLoadBoardSprints(); //Get Sprint Data and Tasks
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  async function handleLoadBoardSprints() {
    setSprint(null);
    //Call Service
    await ASGateway.BoardIterations.GetAllOpen(props.boardId)
      .then(async (data: ASDtos.BoardIterationReadDto[]) => {
        //console.log('GetAllBoardOpenIterations: ', data);
        data.forEach((sprint: ASDtos.BoardIterationReadDto) => {
          if (sprint.status === ASDtos.BoardIterationStatusDto.Active) {
            setSprint(sprint);
          }
        });
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  // OnLoad
  useEffect(() => {
    //console.log('useEffect Begin');
    handleLoadBoardView(); //Get Board Columns and Tasks
    //console.log('useEffect End');
  }, [props]);

  // OnRender
  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <Box width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
          sx={{
            ml: 1,
            mr: 2,
            height: "95%",
          }}
        >
          {!sprint && 
            <>THERE IS NO ACTIVE SPRINT</>
          }

          {sprint && boardViewColumns.sort((a, b) => a.position > b.position ? 1 : -1).map((column, index) => {
            // console.log("index: ", index );
            // console.log("boardColumns.length: ", boardColumns.length );
            // console.log("columnIsFirst: ", index >= 1 );
            // console.log("columnIsLast: ", index < (boardColumns.length - 2) );
            
            //Check if tasks for Column are inside the Active Sprint
            var tasksForColumn : ASDtos.TaskReadDto[] = [];
            column.tasks.forEach((task: ASDtos.TaskReadDto) => {
              var isTaskToInclude = sprint?.tasks.some((t) => {return t.id === task.id});
              if (isTaskToInclude){
                tasksForColumn = [...tasksForColumn, task];
              }
            });

            return (
              <AgileBoardColumn
                key={column.id}
                teamId={props.teamId}
                boardId={props.boardId}
                viewId={props.boardViewId}
                columnId={column.id}
                columnName={column.name}
                columnBlocked={column.isBlocked}
                columnIsFirst={index === 1} //Zero Based
                columnIsLast={index === (boardViewColumns.length - 2)} //Zero Based
                columnMinHeight={props.maxHeightContainer - 100}
                cards={mapper.mappTasksToCards(tasksForColumn, CardTypeEntry.BOARD)}
                setupBoardMode={props.setupBoardMode}
                updateParent={handleLoadBoardView}
              />
            );
          })}
        </Stack>
      </Box>
    </DndContext>
  );
}