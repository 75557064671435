import { useEffect, useState } from 'react'; // let's also import Component

import {
  Stack,
  Paper,
  Divider,
} from '@mui/material';

import SkaloAppIcon from 'images/logos/logo_blue_nobackground_467x467.png';
import MenuBarDrawer from 'components/MenuBarDrawer';

type IMenuBarProps = {
  pinBar: boolean,
};

export default function MenuBar(props: IMenuBarProps) {

  const [showMenu, setShowMenu] = useState<boolean>(props.pinBar);

  // OnLoad
  useEffect(() => {
  }, []);

  // OnRender
  return (
    <>
      {props.pinBar === false &&
        <Paper
          sx={{
            backgroundColor: "#FFFFFF",
            border: 0,
            padding: 0.5,
            //width: 40,
            position: "absolute",
            left: `10px`,
            top: "10px"
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
          >
            <Paper
              sx={{
                p: 1,
                boxShadow: "none",
                ":hover": {
                  backgroundColor: "#D4DBF5",
                  cursor: "pointer"
                }
              }}
              onClick={() => setShowMenu(true)}
            >
              <img src={SkaloAppIcon} width="24" height="24" />
            </Paper>
          </Stack>
        </Paper>
      }
      <MenuBarDrawer pinMenu={props.pinBar} showMenu={showMenu} onClose={() => setShowMenu(false)} />
    </>
  );
}
