import dayjs from "dayjs";
import { DateUnit } from "./AppValues";

export const DateUtils = {
  getDiff(iniDate?: Date, endDate?: Date, resultUnit: DateUnit = DateUnit.DAY): number {
    // console.log("getDiff iniDate:", iniDate);
    // console.log("getDiff endDate:", endDate);
    var result = 0;
    const firstDate = DateUtils.toStringYYYYMMDD(iniDate);
    const secondDate = DateUtils.toStringYYYYMMDD(endDate);

    if (firstDate && secondDate) {
      const firstDateNumber = Number(firstDate);
      const secondDateNumber = Number(secondDate);

      const resultInDays = secondDateNumber - firstDateNumber;

      switch (resultUnit) {
        case DateUnit.YEAR:
          result = Math.round(resultInDays / 365);
          break;
        case DateUnit.MONTH:
          result = Math.round(resultInDays / 30);
          break;
        case DateUnit.DAY:
          result = resultInDays;
          break;
        default:
          result = resultInDays; //Day as default
          break;
      }
    }
    return result;
  },
  getLastDayOfMonth(date: Date): Date {
    //console.log("getLastDayOfMonth date: ", date);

    const valueDate = dayjs(date);
    const monthIndex = valueDate.month();
    const result = new Date(valueDate.year(), monthIndex + 1, 0); //Add one month and put day as 0 to force Date calculate previous month last day

    //console.log("getLastDayOfMonth result: ", result);
    return result
  },
  addDays(date: Date, days: number) {
    var newDate = DateUtils.parse(date)!;
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  },
  toStringLocale(date?: Date) {
    if (date) {
      return DateUtils.parse(date)!.toLocaleDateString();
    }
    else {
      return "";
    }
  },

  toStringYYYYMMDD(date?: Date): string {
    if (date) {
      const dateParsed = DateUtils.parse(date)!;

      var year = `${dateParsed.getFullYear()}`;
      var month = `${dateParsed.getMonth() + 1}`;
      var day = `${dateParsed.getDate()}`;

      month = month.padStart(2, "0");
      day = day.padStart(2, "0");

      return `${year}${month}${day}`;
    }
    else {
      return "";
    }
  },
  parse(date?: Date): Date | undefined {
    if (date) {
      // if (date instanceof Date) {
        return new Date(date); //Force new Ref of a date
      // }
      // else {
      //   return new Date(date);
      // }
    }
    else {
      return undefined;
    }
  }

}