import React, { ChangeEvent, useEffect, useState } from 'react'; // let's also import Component
import { useForm } from 'react-hook-form';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import { ASGateway, ASDtos } from 'api/AppServices';

import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box,
  Typography,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio
} from '@mui/material';
import AutoCompletePackages from 'components/AutoCompletePackages';

type IFormAddTaskToPackageProps = {
  boardId: number,
  columnId: number,
  tasks: ASDtos.TaskReadDto[],
  formIsDone: () => void;
};

enum PackageSelectionEntry {
  NEW,
  EXISTING
};

export default function FormAddTaskToPackage(props: IFormAddTaskToPackageProps) {
    //Redux States
    const dispatch = useAppDispatch();
    const teams = useAppSelector((state) => state.systemBar.teams);
    //Redux States
  
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'all' });

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [versions, setVersions] = useState<ASDtos.VersionReadDto[]>([]);
  const [fieldVersion, setFieldVersion] = useState<number | "">("");

  const [fieldPackageSelectionType, setFieldPackageSelectionType] = useState(PackageSelectionEntry[PackageSelectionEntry.EXISTING]);
  const [fieldPackage, setFieldPackage] = useState<number | null>(null);
  const [fieldPackageName, setFieldPackageName] = useState<string>("");

  const [fieldTasks, setFieldTasks] = useState<number[]>([]);

  //Handles
  function fieldTaskValue(value: number | null) {
    //console.log("fieldTaskValue: ", value);
    setFieldPackage(value);
  }

  function handleChangeTaskSelection(e: ChangeEvent<HTMLInputElement>, taskId: number) {
    var isChecked = e.target.checked;
    if (isChecked) {
      //Add to fieldTasks
      setFieldTasks([...fieldTasks, taskId]);
    }
    else {
      //Remove from fieldTasks
      setFieldTasks([...fieldTasks.filter((task) => task !== taskId)]);
    }
  }

  async function handleSubmitForm() {
    if (fieldPackageSelectionType === PackageSelectionEntry[PackageSelectionEntry.NEW]) {
      //CREATE NEW
      //console.log('Call CreatePackage');
      setIsButtonSubmiting(true);
      await ASGateway.Packages.Create(
        Number(fieldVersion),
        {
          name: fieldPackageName,
          tasks: fieldTasks
        } as ASDtos.PackageCreateDto
      )
        .then((data: ASDtos.PackageReadDto) => {
          console.log('CreatePackage: ', data);
          //setFieldPackage(data.id);
        })
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          setIsButtonSubmiting(false);
        });
    }

    if (fieldPackageSelectionType === PackageSelectionEntry[PackageSelectionEntry.EXISTING]) {
      setIsButtonSubmiting(true);

      fieldTasks?.forEach(async (taskId: any) => {
        //console.log('FOREACH taskId: ', taskId);
        await ASGateway.Packages.AddTask(
          Number(fieldPackage),
          taskId
        )
          .then((data: ASDtos.PackageReadDto) => {
            //console.log('UpdateTaskAssignToColumn: ', data);
          })
          .catch((e: any) => {
            console.error(e);
          });
      });
    }

    setIsButtonSubmiting(true);
    props.formIsDone();
  }

  // OnLoad
  useEffect(() => {
    //console.log("Props: ", props);
    //By Default all Tasks are selected
    setFieldTasks([...props.tasks.map(task => task.id)]);

    ASGateway.Versions.GetAll(teams[0])
      .then((data: ASDtos.VersionReadDto[]) => {
        //console.log('GetAllVersions: ', data);
        setVersions(data);
      })
      .catch((e: any) => {
        console.error(e);
      });

  }, [props]);

  // OnRender
  return (
    <Box id='addToPackageForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleSubmitForm)} sx={{ padding: 0, width: "100%", height: "100%" }}>
      <Typography variant="h5" sx={{ p: 1 }}>
        Add To Package
      </Typography>
      <FormControl>
        <FormLabel id="package-selection-label">Package</FormLabel>
        <RadioGroup
          row
          name="package-selection-radio-group"
          value={fieldPackageSelectionType}
          onChange={(e) => {
            var value = e.target.value;
            var enmValue: PackageSelectionEntry = PackageSelectionEntry[value as keyof typeof PackageSelectionEntry];
            setFieldPackageSelectionType(PackageSelectionEntry[enmValue]);
          }}
        >
          <FormControlLabel value={PackageSelectionEntry[PackageSelectionEntry.EXISTING]} control={<Radio />} label="Existing" />
          <FormControlLabel value={PackageSelectionEntry[PackageSelectionEntry.NEW]} control={<Radio />} label="New" />
        </RadioGroup>
      </FormControl>
      {fieldPackageSelectionType === PackageSelectionEntry[PackageSelectionEntry.EXISTING] &&
        <AutoCompletePackages
          required
          id="package"
          key="package"
          error={fieldPackage === null ? true : false}
          helperText={fieldPackage === null ? "Field is Required." : ""}
          valueGetter={(value) => fieldTaskValue(value)}
        />
      }
      {fieldPackageSelectionType === PackageSelectionEntry[PackageSelectionEntry.NEW] &&
        <>
          <TextField
            required
            select
            id="packageVersion"
            key="packageVersion"
            label="Version"
            fullWidth autoFocus margin="dense" variant='filled' size='small'
            {...register('packageVersion', {
              validate: {
                required: (value) => value !== "" || "Field is Required.",
              },
              onChange: (e) => setFieldVersion(Number(e.target.value)),
            })}
            value={fieldVersion}
            error={errors?.packageVersion ? true : false}
            helperText={errors?.packageVersion?.message?.toString()}
          >
            {versions.map((version: ASDtos.VersionReadDto) => {
              return (
                <MenuItem key={version.id} value={version.id}>{version.name}</MenuItem>
              )
            })}
          </TextField>
          <TextField
            required
            id="packageName"
            key="packageName"
            label="Package Name"
            fullWidth autoFocus margin="dense" variant='filled' size='small'
            {...register('packageName', {
              required: { value: true, message: 'Field is Required.' },
              maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
              onChange: (e) => setFieldPackageName(e.target.value),
            })}
            value={fieldPackageName}
            error={errors?.packageName ? true : false}
            helperText={errors?.packageName?.message?.toString()}
          />
        </>
      }
      <br /><br />
      {props.tasks.map((task) => {
        return (
          <Box key={task.id}>
            <FormControlLabel
              label={`#${task.id} ${task.title}`}
              control={
                <Checkbox
                  checked={fieldTasks.some(taskId => taskId === task.id)}
                  onChange={(e) => handleChangeTaskSelection(e, task.id)}
                />
              }
            />
          </Box>
        );
      })}
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <br /><br /><br />
        <SmartButton fullWidth isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleSubmitForm)}>Add</SmartButton>
      </Stack>
    </Box>
  );
}