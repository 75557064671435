//https://react-redux.js.org/tutorials/typescript-quick-start
import { createSlice, PayloadAction  } from '@reduxjs/toolkit'

// Define a type for the slice state
interface ICanvasBarState {
  developerModeDeprecated: boolean
}

// Define the initial state using that type
const initialState: ICanvasBarState = {
  developerModeDeprecated: false
}

export const canvasBarReducer = createSlice({
  name: 'canvasBarState',
  initialState: initialState,
  reducers: {
    setDeveloperModeDeprecated: (state, developerMode: PayloadAction<boolean>) => {
      state.developerModeDeprecated = developerMode.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  setDeveloperModeDeprecated
} = canvasBarReducer.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
export default canvasBarReducer.reducer