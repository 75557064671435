import { useEffect, useState, useMemo, ReactNode } from 'react';

import { useAppSelector } from 'hooks/StoreReduxHooks';
import useNotification, { INotification } from 'hooks/NotificationHooks';

import { ASGateway, ASDtos } from 'api/AppServices';
import { ContextSetup } from 'app/AppValues';

import FormNewTeam from 'components/Forms/FormNewTeam';
import SmartButton from 'components/SmartButton';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
} from '@mui/material';

import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowModesModel,
  GridRowParams,
  GridRowId,
  GridActionsCellItem,
} from '@mui/x-data-grid';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Close';

import 'App.css';

interface ISetupTeamsProps {
  label: ReactNode;
  context: ContextSetup;
  defaultExpanded: boolean;
};

export default function SetupTeams(props: ISetupTeamsProps) {
  //Redux States
  const userSession = useAppSelector((state) => state.appSession.user);
  const companySession = useAppSelector((state) => state.appSession.company);
  //Redux States
  const notification = useNotification(); //Miliseconds

  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState<ASDtos.TeamReadDto[]>([]);

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel | undefined>(undefined);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({ actions: true });

  const [showAddAgentToTeamForm, setShowAddAgentToTeamForm] = useState(false);

  function fetchTeams(): void {
    ASGateway.Teams.GetAll()
      .then((data: ASDtos.TeamReadDto[]) => {
        setTeams(data);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  useEffect(() => {
    fetchTeams();
    setLoading(false);
  }, []);

  const columns: GridColDef<ASDtos.TeamReadDto>[] = useMemo(() => [
    { field: 'id', headerName: 'Team Id', headerClassName: 'sx-grid-header', width: 100, editable: false },
    { field: 'account', headerName: 'Account', headerClassName: 'sx-grid-header', width: 200, editable: false },
    { field: 'name', headerName: 'Name', headerClassName: 'sx-grid-header', width: 300, editable: false },
    { field: 'email', headerName: 'E-Mail', headerClassName: 'sx-grid-header', width: 300, editable: false },
    {
      field: 'actions', headerName: 'Actions', headerClassName: 'sx-grid-header', type: 'actions',
      getActions: (row: GridRowParams<ASDtos.AgentReadDto>) => {
        var result = [<GridActionsCellItem label="Delete" icon={<Tooltip title="Delete"><CancelIcon /></Tooltip>} onClick={() => { handleRemoveClick(row.id) }}/>];
        return result;
      }
    }
  ], []);

  function handleRemoveClick(id: GridRowId) {
    ASGateway.Teams.Delete(Number(id))
      .then((data: ASDtos.TeamReadDto) => {
        notification.addNotification({
          severity: "success",
          message: "Team has been sucessfully deleted.",
        } as INotification);
        fetchTeams();
      })
      .catch((e: any) => {
        console.error(e);
        notification.addNotification({
          severity: "error",
          message: e.message,
        } as INotification);
      });
  }

  return (
    <Box sx={{ p: 1 }}>
      <Accordion disableGutters defaultExpanded={props.defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="node-detail-summary"
          sx={{ backgroundColor: "rgba(212, 219, 245, 0.50)" }} //#D4DBF5 with 50% transparency
        >
          Teams
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
            sx={{ p: 1 }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <SmartButton onSubmit={() => { setShowAddAgentToTeamForm(true) }}>Create Team</SmartButton>
            </Stack>
            {loading === false &&
              <DataGrid
                editMode="cell"
                getRowId={(row) => row.id}
                getRowHeight={() => 'auto'}
                rows={teams}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                rowModesModel={rowModesModel}

                slotProps={{
                  toolbar: { setTeams, setRowModesModel },
                }}

                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 20]}
                sx={{
                  '& .sx-grid-header': {
                    backgroundColor: '#F3F3F3',
                  },
                  '& .MuiDataGrid-filler': {
                    backgroundColor: '#F3F3F3',
                  },                  
                  '& .MuiDataGrid-filler.css-8yphpr': {
                    backgroundColor: '#FFFFFF',
                  },
                  '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '&.MuiDataGrid-root': {
                    border: 'none',
                  },
                }}
              />
            }
          </Stack>
        </AccordionDetails>
      </Accordion>

      {/* DIALOG FOR ADD AGENT */}
      <Dialog fullScreen={false} open={showAddAgentToTeamForm} onClose={() => setShowAddAgentToTeamForm(false)}>
        <DialogTitle>New Team</DialogTitle>
        <DialogContent sx={{ p: 2, width: "500px", maxHeight: "500px" }}>
          <FormNewTeam
            formIsDone={(team) => { setShowAddAgentToTeamForm(false); fetchTeams(); }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
