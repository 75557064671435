import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'hooks/StoreReduxHooks';
import { useWindowSize } from 'hooks/WindowResize';

import { StoreRedux as reduxWorkaroud } from 'reducers/StoreRedux';

import MenuBar from 'components/MenuBar';

import CanvasViewMainBar from 'components/Canvas/CanvasView/CanvasViewMainBar';
import CanvasView from 'components/Canvas/CanvasView/CanvasView';

import DetailDrawer from 'components/DetailDrawer';
import AgileBoardDrawer from 'components/AgileBoards/AgileBoardDrawer';

import { CanvasViewEntry, ScreenWideEntry } from 'app/AppValues';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import {
  Box,
  Stack
} from '@mui/material';

import 'App.css';
import { ASGateway, ASDtos } from 'api/AppServices';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

type ICanvasView = {
  id: number,
  name: string,
  bucket: number,
  planeOriginX: number,
  planeOriginY: number,
  canvasWidth: number,
  canvasHeight: number
};


export default function Home() {
  //Redux States
  const userSession = useAppSelector((state) => state.appSession.user);
  const companySession = useAppSelector((state) => state.appSession.company);
  const canvasViewSelected = useAppSelector((state) => state.systemBar.canvasViewSelected);
  const openDetailsScreenWide = useAppSelector((state) => state.detailDrawer.screenWide);
  const boardDrawerScreenWide = useAppSelector((state) => state.boardDrawer.screenWide);
  //Redux States

  const [agentView, setAgentView] = useState<ASDtos.AgentViewReadDto | null>(null);
  const [canvasViewList, setCanvasViewList] = useState<ICanvasView[]>([]);

  const [width, height] = useWindowSize();

  const [canvasWidth, setCanvasWidth] = useState<number>(window.innerWidth);
  const [canvasHeight, setCanvasHeight] = useState<number>(window.innerHeight);


  function loadCanvasViewList(
    canvasViewSelection: CanvasViewEntry,
    openDetailsScreen: ScreenWideEntry,
    boardDrawerScreen: ScreenWideEntry
  ) {

    var result: ICanvasView[] = [];

    var currentWidth = canvasWidth;
    var canvasWidthQuarter = currentWidth / 4;

    var currentHeight = canvasHeight;
    var canvasHeightQuarter = currentHeight / 4;

    //When we have Board Open we need to recalculate Window Height.
    switch (boardDrawerScreen) {
      case ScreenWideEntry.FULL:
        currentHeight = 0;
        break;
      case ScreenWideEntry.THREEQUARTERS:
        currentHeight = canvasHeightQuarter * (4 - 3); //4 Quarters - 3 Quarters Hidden by Board
        break;
      case ScreenWideEntry.HALF:
        currentHeight = canvasHeightQuarter * (4 - 2); //4 Quarters - 2 Quarters Hidden by Board
        break;
      case ScreenWideEntry.QUARTER:
        currentHeight = canvasHeightQuarter * (4 - 1); //4 Quarters - 1 Quarter Hidden by Board
        break;
    }

    //When we have Details Open we need to recalculate Window Width.
    switch (openDetailsScreen) {
      case ScreenWideEntry.FULL:
        currentWidth = 0;
        break;
      case ScreenWideEntry.THREEQUARTERS:
        currentWidth = canvasWidthQuarter * (4 - 3); //4 Quarters - 3 Quarters Hidden by Details
        break;
      case ScreenWideEntry.HALF:
        currentWidth = canvasWidthQuarter * (4 - 2); //4 Quarters - 2 Quarters Hidden by Details
        break;
      case ScreenWideEntry.QUARTER:
        currentWidth = canvasWidthQuarter * (4 - 1); //4 Quarters - 1 Quarter Hidden by Details
        break;
    }

    if (canvasViewSelection === CanvasViewEntry.SINGLE) {
      const bucketOne = agentView?.buckets.find((bucket) => { return bucket.number === 1; });
      const bucketOneId = (bucketOne) ? bucketOne.network.id : -1;
      const bucketOneName = (bucketOne) ? bucketOne.network.name : "N/A";

      result = [...result, {
        id: bucketOneId,
        name: bucketOneName,
        bucket: 1,
        planeOriginX: 0, planeOriginY: 0,
        canvasWidth: currentWidth, canvasHeight: currentHeight
      }
      ];
    }

    if (canvasViewSelection === CanvasViewEntry.COLDOUBLE) {
      const bucketOne = agentView?.buckets.find((bucket) => { return bucket.number === 1; });
      const bucketOneId = (bucketOne) ? bucketOne.network.id : -1;
      const bucketOneName = (bucketOne) ? bucketOne.network.name : "N/A";
      const bucketTwo = agentView?.buckets.find((bucket) => { return bucket.number === 2; });
      const bucketTwoId = (bucketTwo) ? bucketTwo.network.id : -2;
      const bucketTwoName = (bucketTwo) ? bucketTwo.network.name : "N/A";

      currentWidth = currentWidth / 2;
      result = [...result, {
        id: bucketOneId,
        name: bucketOneName,
        bucket: 1,
        planeOriginX: 0, planeOriginY: 0,
        canvasWidth: currentWidth, canvasHeight: currentHeight
      },
      {
        id: bucketTwoId,
        name: bucketTwoName,
        bucket: 2,
        planeOriginX: currentWidth, planeOriginY: 0,
        canvasWidth: currentWidth, canvasHeight: currentHeight
      }
      ];
    }

    if (canvasViewSelection === CanvasViewEntry.COLTRIPLE) {
      const bucketOne = agentView?.buckets.find((bucket) => { return bucket.number === 1; });
      const bucketOneId = (bucketOne) ? bucketOne.network.id : -1;
      const bucketOneName = (bucketOne) ? bucketOne.network.name : "N/A";
      const bucketTwo = agentView?.buckets.find((bucket) => { return bucket.number === 2; });
      const bucketTwoId = (bucketTwo) ? bucketTwo.network.id : -2;
      const bucketTwoName = (bucketTwo) ? bucketTwo.network.name : "N/A";
      const bucketThree = agentView?.buckets.find((bucket) => { return bucket.number === 3; });
      const bucketThreeId = (bucketThree) ? bucketThree.network.id : -3;
      const bucketThreeName = (bucketThree) ? bucketThree.network.name : "N/A";

      currentWidth = currentWidth / 3;
      result = [...result, {
        id: bucketOneId,
        name: bucketOneName,
        bucket: 1,
        planeOriginX: 0, planeOriginY: 0,
        canvasWidth: currentWidth, canvasHeight: currentHeight
      },
      {
        id: bucketTwoId,
        name: bucketTwoName,
        bucket: 2,
        planeOriginX: currentWidth, planeOriginY: 0,
        canvasWidth: currentWidth, canvasHeight: currentHeight
      },
      {
        id: bucketThreeId,
        name: bucketThreeName,
        bucket: 3,
        planeOriginX: currentWidth * 2, planeOriginY: 0,
        canvasWidth: currentWidth, canvasHeight: currentHeight
      }
      ];
    }

    //console.log("loadCanvasViewList canvasViewSelection: ", canvasViewSelection);
    //console.log("loadCanvasViewList result: ", result);
    setCanvasViewList(result);
  }

  function resizeWindow() {
    setCanvasWidth(width);
    setCanvasHeight(height);

    //console.log("Home Canvas resizeWindow");
    loadCanvasViewList(
      reduxWorkaroud.getState().systemBar.canvasViewSelected,
      reduxWorkaroud.getState().detailDrawer.screenWide,
      reduxWorkaroud.getState().boardDrawer.screenWide
    );
  };

  useEffect(() => {
    resizeWindow();
  }, [width, height]);

  useEffect(() => {
    // window.addEventListener("resize", resizeWindow);

    //Load Agent View Default
    ASGateway.Agents.GetViewDefault(userSession.userId)
      .then((data: ASDtos.AgentViewReadDto) => {
        //console.log('GetViewDefault: ', data);
        setAgentView(data);
      })
      .catch((e: any) => {
        console.error(e);
      });

    // return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    //console.log("useEffect[canvasViewSelected] canvasViewSelected: ", canvasViewSelected);
    loadCanvasViewList(canvasViewSelected, openDetailsScreenWide, boardDrawerScreenWide);
  }, [canvasViewSelected, openDetailsScreenWide, boardDrawerScreenWide, agentView]); //When canvas View Change Reload Canvas views

  if (!userSession.hasActiveSession) {
    return <Navigate to="/login" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: "#EDEDED" }}>

        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0}>
          <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
            {canvasViewList.map((canvasView: ICanvasView) => {
              if (agentView) {
                return (
                  <Box key={canvasView.id} sx={{ border: 0.1 }} >
                    <CanvasView
                      key={canvasView.id}
                      agentViewId={agentView.id}
                      canvasViewId={canvasView.id}
                      canvasViewName={canvasView.name}
                      planeOriginX={canvasView.planeOriginX} planeOriginY={canvasView.planeOriginY}
                      canvasWidth={canvasView.canvasWidth} canvasHeight={canvasView.canvasHeight}

                      onChangeView={(canvasViewId: number) => {
                        //console.log('HOME::CanvasView::onChangeView: ', canvasViewId);
                        //Update Agent View Bucket
                        ASGateway.Agents.UpdateViewBucket(
                          userSession.userId,   //AgentId
                          agentView.id,         //AgentViewId
                          canvasView.bucket,    //AgentView Bucket to Update
                          canvasViewId          //AgentViewNetworkId to add in the Bucket
                        )
                          .then((data: ASDtos.AgentViewReadDto) => {
                            //console.log('UpdateViewBucket: ', data);
                            setAgentView(data);
                          })
                          .catch((e: any) => {
                            console.error(e);
                          });
                      }}
                    />
                  </Box>
                )

              }
            })}
          </Stack>
        </Stack>

        <MenuBar pinBar={false}/>
        <CanvasViewMainBar />
        <DetailDrawer />
        <AgileBoardDrawer />
        
      </Box>
    </ThemeProvider>
  );
}
