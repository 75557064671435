import React, { useEffect, useState } from 'react';

import { ASGateway, ASDtos } from 'api/AppServices';
import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import {
  Box,
  Card,
} from '@mui/material';

import TaskType from 'components/TaskType';
import TaskPriority from 'components/TaskPriority';

type IDetailContainerTaskProps = {
  taskId: number
};

export default function DetailContainerTask(props: IDetailContainerTaskProps) {
  //Redux States
  const dispatch = useAppDispatch();
  //Redux States

  const [taskData, setTaskData] = useState<ASDtos.TaskReadDto>({} as ASDtos.TaskReadDto);
  const [showEditorResult, setShowEditorResult] = useState(false);
  const [editorResult, setEditorResult] = useState({} as any);

  //Handles

  // OnLoad
  useEffect(() => {
    if (props.taskId > 0) {
      //console.log("Props: ", props);
      //Reset Container Data
      setTaskData({} as any);

      //Load Node
      ASGateway.Tasks.GetById(props.taskId)
        .then((data: ASDtos.TaskReadDto) => {
          //console.log('GetTaskById: ', data);
          if (data) {
            setTaskData(data);
          }
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }, [props.taskId]);


  // OnRender
  return (
    <>
      <Card
        sx={{
          p: 1,
          minHeight: 50,
        }}
      >
        DETAIL CONTAINER TASK
        <Box style={{ textAlign: "left" }}>
          ID: #{taskData.id}                   <br />
          {/* {[1].map((column: any, index: number) => {
            console.log("Detailcontainer taskData.type: ", taskData.type);
            return (
              <></>
            )
          })} */}
          <TaskType type={taskData.type}/> 
          <TaskPriority priority={ASDtos.TaskPriorityDto[taskData.priority]} /> <br />
          Title: {taskData?.title}             <br />
          Description: {taskData?.description} <br />
          Estimation: {taskData?.estimation}   <br />
          Execution: {taskData?.execution}     <br />
          == Status per Board ==               <br />
          {taskData?.boardColumns?.map((column: any, index: number) => {
            return (
              <Box key={index}>Column ID: {column}            <br /></Box>
            )
          })}

        </Box>
      </Card>
    </>
  );
}