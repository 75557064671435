import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';

export async function GetAll(teamId: number) : Promise<ASDtos.EnvironmentReadDto[]> {
  var urlResource = `/teams/${teamId}/environments`;
  //console.log('GetAllEnvironments URL: ', { url: urlResource });
  return await CallService<ASDtos.EnvironmentReadDto[]>(urlResource);
}
export async function Create(teamId: number, name: string) : Promise<ASDtos.EnvironmentReadDto> {
  var urlResource = `/teams/${teamId}/environments`;
  const payload = {
    "name": name,
  } as ASDtos.EnvironmentCreateDto

  //console.log('CreateEnvironment URL: ', { url: urlResource });
  return await CallService<ASDtos.EnvironmentReadDto>(urlResource, "POST", payload);
}

export async function Delete(teamId: number, environmentId: number) : Promise<ASDtos.EnvironmentReadDto> {
  var urlResource = `/teams/${teamId}/environments/${environmentId}`;

  //console.log('UpdateViewBucket URL: ', { url: urlResource });
  return await CallService<ASDtos.EnvironmentReadDto>(urlResource, "DELETE", null);
}
