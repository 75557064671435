import React, { ReactNode } from 'react'; // let's also import Component

import {
  Box,
} from '@mui/material';

interface ISetupContainerProps {
  topPadding: number,
  leftPadding: number,
  width: number,
  height: number,
  children?: ReactNode
}

export default function SetupContainer(props: ISetupContainerProps) {

  // OnRender
  return (
    <Box
      sx={{
        position: "absolute",
        top: `${props.topPadding}px`,
        left: `${props.leftPadding}px`,
        width: `${props.width}px`,
        height: `${props.height}px`,
        backgroundColor: "#FFFFFF",
        border: 0,
        padding: 0.5,
        overflowY: 'auto',
        overflowX: 'auto',
      }}
    >
      {props.children}
    </Box>
  );
}
