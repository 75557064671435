import React, { ReactNode, useEffect, useState } from 'react'; // let's also import Component

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { ScreenWideEntry } from 'app/AppValues';

import {
  Paper,
  Box,
} from '@mui/material';

interface IMainBarDrawerProps {
  leftPadding: number,
  topPadding: number,
  children?: ReactNode
}

export default function MainBarDrawer(propsBar: IMainBarDrawerProps, ...props: any[]) {
  //Redux States
  const dispatch = useAppDispatch();
  const openDetailsScreenWide = useAppSelector((state) => state.detailDrawer.screenWide);
  //Redux States

  const rightPadding = 20;
  const [barLeftRightPadding, setBarLeftRightPadding] = useState<number>(propsBar.leftPadding + rightPadding);
  const [barWidth, setBarWidth] = useState<number>(0);

  //Handles
  function getBarWidth(screenWide: ScreenWideEntry): number {
    switch (screenWide) {
      case ScreenWideEntry.HIDE:
        return 0;
      case ScreenWideEntry.QUARTER:
        return (window.innerWidth - (window.innerWidth * 0.75)) - barLeftRightPadding;
      case ScreenWideEntry.HALF:
        return (window.innerWidth - (window.innerWidth * 0.50)) - barLeftRightPadding;
      case ScreenWideEntry.THREEQUARTERS:
        return (window.innerWidth - (window.innerWidth * 0.25)) - barLeftRightPadding;
      case ScreenWideEntry.FULL:
        return window.innerWidth - barLeftRightPadding;
    }
  }

  // OnLoad
  // useEffect(() => {
  //   const barwith = window.innerWidth - (barWidthOffSet + 1290);
  //   console.log("barwith: ", barwith);
  //   setBarWidth(barwith);
  // }, []);

  //Whenever DetailScreenWide Value Changes Recalculate percentage for WIDTH
  useEffect(() => {
    //Adapt SystemBar Width Inverting Details Width
    switch (openDetailsScreenWide) {
      case ScreenWideEntry.HIDE:
        setBarWidth(getBarWidth(ScreenWideEntry.FULL));
        break;
      case ScreenWideEntry.QUARTER:
        setBarWidth(getBarWidth(ScreenWideEntry.THREEQUARTERS));
        break;
      case ScreenWideEntry.HALF:
        setBarWidth(getBarWidth(ScreenWideEntry.HALF));
        break;
      case ScreenWideEntry.THREEQUARTERS:
        setBarWidth(getBarWidth(ScreenWideEntry.QUARTER));
        break;
      case ScreenWideEntry.FULL:
        setBarWidth(getBarWidth(ScreenWideEntry.HIDE));
        break;
    }
  }, [openDetailsScreenWide]);

  // OnRender
  return (
      <Paper
        sx={{
          position: "absolute",
          top: `${propsBar.topPadding}px`,
          left: `${propsBar.leftPadding}px`,
          width: `${barWidth}px`,
          backgroundColor: "#FFFFFF",
          border: 0,
          padding: 0.5,
        }}
      >
        {propsBar.children}
      </Paper>
  );
}
