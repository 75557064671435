import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useNotification, { INotification } from 'hooks/NotificationHooks';
import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { createUserSession } from "reducers/appSessionReducer";

import { AppUtils } from 'app/AppUtils';
import { ASDtos, ASGateway } from "api/AppServices";

import SmartButton from 'components/SmartButton';
import {
  Avatar,
  Box,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import KeyIcon from '@mui/icons-material/Key';

export default function FormAuthLogin() {
  //Redux States
  const dispatch = useAppDispatch();
  const userSession = useAppSelector((state) => state.appSession.user);
  //Redux States
  const notification = useNotification(); //Miliseconds
  const redirect = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'all' });

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  function onChangeEmail(e: any) {
    const userEmail = e.target.value;
    setEmail(userEmail);
  };

  function onChangePassword(e: any) {
    const userPassword = e.target.value;
    setPassword(userPassword);
  };

  function handleLogin() {
    const domain = AppUtils.GetCompanyDomain();

    setIsButtonSubmiting(true);
    ASGateway.Authentication.AuthenticateAgent({
      companyDomain: domain,
      userEmail: email,
      userPassword: password
    } as ASDtos.AuthenticationCreateDto
    )
      .then((data) => {
        if (data.user.resetPassword) {
          redirect("/reset-credentials");
        }
        else {
          dispatch(createUserSession(data));
          redirect("/");
        }
      })
      .catch((e) => {
        notification.addNotification({
          severity: "error",
          message: e.message,
        } as INotification);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });
  };

  useEffect(() => {
  }, []);

  if (userSession.hasActiveSession) {
    return <Navigate to="/" />;
  }
  return (
    <Box id='loginForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleLogin)} style={{ padding: 0 }}>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
        <Avatar alt="profile-img">
          <PersonIcon />
        </Avatar>
        <TextField variant="outlined" fullWidth={true}
          id="email"
          key="email"
          placeholder="Email"
          sx={{
            input: {
              px: 1,
              backgroundColor: "#ffffff"
            },
          }}
          {...register("email", {
            required: { value: true, message: 'Field Required' },
            pattern: {
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: 'Invalid email address',
            },
            onChange: (e) => onChangeEmail(e),
          })}
          value={email}
          error={errors.email ? true : false}
          helperText={errors.email?.message?.toString()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            )
          }}
        />
        <TextField variant="outlined" fullWidth={true}
          type={showPassword ? 'text' : 'password'}
          id="password"
          key="password"
          placeholder="Password"
          sx={{
            input: {
              px: 1,
              background: "#ffffff"
            }
          }}
          {...register("password", {
            required: { value: true, message: 'Field Required' },
            maxLength: { value: 20, message: 'Max Length 20' },
            onChange: (e) => onChangePassword(e),
          })}
          value={password}
          error={errors.password ? true : false}
          helperText={errors.password?.message?.toString()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ p: 0, }}>
                <KeyIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment sx={{ backgroundColor: "#ffffff" }} position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((current) => !current)}
                  edge="end"
                  sx={{
                    background: "#ffffff"
                  }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <SmartButton fullWidth isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleLogin)}>Login</SmartButton>
      </Stack>
    </Box>
  );
};
