import React, { useEffect, useState } from 'react'; // let's also import Component

import DOMPurify from 'isomorphic-dompurify';
import HTMLParser from 'html-react-parser';

import { ASDtos } from 'api/AppServices';
import { DateUtils } from 'app/DateUtils';

import Editor from 'components/Editor';
import SmartButton from 'components/SmartButton';

import {
  Stack,
  Box,
  Grid,
  Chip,
} from '@mui/material';

interface IEditorBlockStatusProps {
  block: ASDtos.DocumentBlockReadDto;
};

export default function EditorBlockStatus(props: IEditorBlockStatusProps) {

  function getStatusFromEditorBlockResult(block: ASDtos.DocumentBlockReadDto) {
    var isChanged = block.isChanged;
    var isDeleted = block.isDeleted;
    var isNew = (block.apiRef === -1 || block.isNew);

    if (isNew) {
      return "New";
    }
    if (isChanged) {
      return "Changed";
    }
    if (isDeleted) {
      return "Deleted";
    }

    return "Not Changed";
  }

  function getColorFromEditorBlockResult(block: ASDtos.DocumentBlockReadDto) {
    var isChanged = block.isChanged;
    var isDeleted = block.isDeleted;
    var isNew = (block.apiRef === -1 || block.isNew);

    if (isNew) {
      return "success";
    }
    if (isChanged) {
      return "warning";
    }
    if (isDeleted) {
      return "warning";
    }

    return "info";
  }

  // OnLoad
  // OnRender
  return (
    <Chip
      variant="outlined"
      size="small"
      label={getStatusFromEditorBlockResult(props.block)}
      color={getColorFromEditorBlockResult(props.block)}
    />

  );
}