import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';

export async function GetAllForNetwork(networkId: number): Promise<ASDtos.LinkReadDto[]> {
  var urlResource = `/networks/${networkId}/links`;
  //console.log('GetAllLinks URL: ', { url: urlResource });
  return await CallService<ASDtos.LinkReadDto[]>(urlResource);
}
export async function Create(
  versionId: number,
  name: string = "",
  tail: number,
  head: number
) {
  var urlResource = `/versions/${versionId}/links`;
  //console.log('CreateLink URL: ', { url: urlResource });
  //Build Node payload
  const payload = {
    "name": name,
    "tail": tail,
    "head": head,
  };

  var result = await CallService(urlResource, "POST", payload);
  return result;
}