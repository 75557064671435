import React, { useState, useEffect } from "react";
import { Navigate, useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import { DetailContainerEntry } from 'app/AppValues';

import DetailContainerNode from 'components/DetailContainerNode';
import DetailContainerTask from 'components/DetailContainerTask';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

import {
  CssBaseline,
  Paper,
  Stack,
} from '@mui/material';



const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

type IDetailPageProps = {
  type: DetailContainerEntry
};

export default function Detail(props: IDetailPageProps) {
  //Redux States
  const userSession = useAppSelector((state) => state.appSession.user);
  //Redux States

  let {
    contentId
  } = useParams();

  useEffect(() => {
    // console.log("contentId: ", contentId);
    // console.log("props.type: ", props.type);
  }, []);

  if (!userSession.hasActiveSession) {
    return <Navigate to="/login" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <br /><br />
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
        <Paper
          sx={{
            width: "90%",
            height: "100%",
            align: "center",
            background: "#f5f5f5",
            p: 2, m: 2
          }}
        >
          {(props.type === DetailContainerEntry.NODE) && <DetailContainerNode nodeId={Number(contentId)} />}
          {(props.type === DetailContainerEntry.TASK) && <DetailContainerTask taskId={Number(contentId)} />}
        </Paper>
      </Stack>
    </ThemeProvider>
  );
};
