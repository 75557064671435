import { ICanvasNode } from './ICanvasNode';
import { ICanvasLink } from './ICanvasLink';

import { CanvasMap } from './CanvasMap'

enum ArrowDirection {
  TOP,
  BOTTOM,
  LEFT,
  RIGHT
};

export class ConnectorLink<T extends ICanvasNode> implements ICanvasLink<T>
{
  private connectorId: number = -1;
  private name: string = "";
  private version: number | null = null;
  private startObject: T = {} as T;
  private endObject: T = {} as T;

  private borderThickness: number = 1;
  private selectedBorderThickness: number = 1;
  private modeDebug = true;
  private showDirection = false; 

  constructor(connectorId: number, name: string, startObject: T, endObject: T, version: number | null = null) {
    this.connectorId = connectorId;
    this.version = version;
    this.name = name;
    this.startObject = startObject; 
    this.endObject = endObject;
  }

  private getChannels() {
    return {
      vc1: (this.startObject.getOptions().w / 2) + (this.startObject.getOptions().w * 0.25),
      vc2: (this.startObject.getOptions().w / 2) + (this.startObject.getOptions().w * 0.25),
      vc3: this.startObject.getOptions().w + (this.startObject.getOptions().w * 0.25),
      //vc4: Infinity
      hc1: (this.startObject.getOptions().h / 2) + (this.startObject.getOptions().h * 0.1),
      hc2: (this.startObject.getOptions().h / 2) + (this.startObject.getOptions().h * 0.1),
      //hc3: Infinity
    }
  }
  private getStartToDraw(canvasMap: CanvasMap) {
    return {
      x0: this.startObject.getOptions().x + canvasMap.originX,
      y0: this.startObject.getOptions().y + canvasMap.originY,
      x: (this.startObject.getOptions().x + (this.startObject.getOptions().w / 2)) + canvasMap.originX,
      y: (this.startObject.getOptions().y + (this.startObject.getOptions().h / 2)) + canvasMap.originY,
      w: this.startObject.getOptions().w,
      h: this.startObject.getOptions().h
    }
  }

  private getEndToDraw(canvasMap: CanvasMap) {
    return {
      x0: this.endObject.getOptions().x + canvasMap.originX,
      y0: this.endObject.getOptions().y + canvasMap.originY,
      x: (this.endObject.getOptions().x + (this.endObject.getOptions().w / 2)) + canvasMap.originX,
      y: (this.endObject.getOptions().y + (this.endObject.getOptions().h / 2)) + canvasMap.originY,
      w: this.endObject.getOptions().w,
      h: this.endObject.getOptions().h
    }
  }

  getId(): number {
    return this.connectorId;
  }
  getVersion(): number | null {
    return this.version;
  }
  getName(): string {
    return this.name;
  }

  getStartObject(): T {
    return this.startObject;
  }
  getEndObject(): T {
    return this.endObject;
  }

  setStartObject(startObject: T) {
    this.startObject = startObject;
  }
  setEndObject(endObject: T) {
    this.endObject = endObject;
  }

  setDebugMode(modeDebug: boolean) {
    this.modeDebug = modeDebug;
  }

  draw(canvasMap: CanvasMap, selected: boolean, ctx: CanvasRenderingContext2D | null) {
    if (ctx) {
      ctx.save();

      //Draw Debug Mode for this connector
      //Debug is drawn first so it goes on background
      if (this.modeDebug) {
        this.drawDebugMode(ctx, canvasMap);
      }

      //When one of the Nodes is not Visible skip draw Link
      if (!this.getStartObject().isVisible() || !this.getEndObject().isVisible()) {
        return;
      }

      //Get Draw Objects Coordinates
      const infinite = true;
      const channels = this.getChannels();
      var start = this.getStartToDraw(canvasMap);
      var end = this.getEndToDraw(canvasMap);

      //When startX is after endX switch coordinates to Draw
      if (start.x > end.x) {
        // console.log("Start: ", start);
        // console.log("End: ", end);

        end = this.getStartToDraw(canvasMap);
        start = this.getEndToDraw(canvasMap);

        // console.log("Inverse Start: ", start);
        // console.log("Inverse End: ", end);
      }

      if (selected) {
        //Selection Rect
        // ctx.strokeStyle = "blue";
        // ctx.beginPath();
        // ctx.roundRect(
        //   options.x - this.selectedBorderThickness,
        //   options.y - this.selectedBorderThickness,
        //   options.w + (this.selectedBorderThickness*2),
        //   options.h + (this.selectedBorderThickness*2),
        //   0
        // );
        // ctx.stroke();

        //NW Corner
        //SW Corner
        //NE Corner
        //NE Corner
      }

      /*
           VC1    VC2     VC3     VC4
         |     |       |       |
         |     |       |       |
         |     |       |       |
         |     |       |       |
         |     |       |       |
  HC3    | 1   |   2   |   3   |   4
         |     |       |       |
         |-----┼-------┼-------┼----------------
         |     |       |       |
  HC2    | 5   |   6   |   7   |   8 
         |     |       |       |
         |-----┼-------┼-------┼----------------
  HC1 ######   |       |       |
      ######-9-┼---10--┼---11--┼---12-----------
  HC1 ######   |       |       |
         |-----┼-------┼-------┼----------------
         |     |       |       |
  HC2    | 13  |   14  |   15  |   16
         |     |       |       |
         |-----┼-------┼-------┼----------------
         |     |       |       |
  HC3    | 17  |   18  |   19  |   20
         |     |       |       |
         |     |       |       |
         |     |       |       |
         |     |       |       |
         |     |       |       |
      */
      //****** Calculate Area
      var area = 0;

      //Area 1: 
      if (
        //X Axis
        (end.x >= start.x) && //leftLimit
        (end.x < (start.x + channels.vc1)) && //rightLimit
        //Y Axis
        (end.y <= (start.y - (channels.hc1 + channels.hc2))) && //bottomLimit
        infinite //topLimit
      ) {
        area = 1;
      }

      //Area 2: 
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1)) && //leftLimit
        (end.x < (start.x + channels.vc1 + channels.vc2)) && //rightLimit
        //Y Axis
        (end.y <= (start.y - (channels.hc1 + channels.hc2))) && //bottomLimit
        infinite //topLimit
      ) {
        area = 2;
      }

      //Area 3: 
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1 + channels.vc2)) && //leftLimit
        (end.x < (start.x + channels.vc1 + channels.vc2 + channels.vc3)) && //rightLimit
        //Y Axis
        (end.y <= (start.y - (channels.hc1 + channels.hc2))) && //bottomLimit
        infinite //topLimit
      ) {
        area = 3;
      }

      //Area 4: 
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1 + channels.vc2 + channels.vc3)) && //leftLimit
        infinite && //rightLimit
        //Y Axis
        (end.y <= (start.y - (channels.hc1 + channels.hc2))) && //bottomLimit
        infinite //topLimit
      ) {
        area = 4;
      }


      //Area 5: 
      if (
        //X Axis
        (end.x >= start.x) && //leftLimit
        (end.x < (start.x + channels.vc1)) && //rightLimit
        //Y Axis
        (end.y <= (start.y - (channels.hc1))) && //bottomLimit
        (end.y > (start.y - (channels.hc1 + channels.hc2))) //topLimit
      ) {
        area = 5;
      }

      //Area 6: 
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1)) && //leftLimit
        (end.x < (start.x + channels.vc1 + channels.vc2)) && //rightLimit
        //Y Axis
        (end.y <= (start.y - (channels.hc1))) && //bottomLimit
        (end.y > (start.y - (channels.hc1 + channels.hc2))) //topLimit
      ) {
        area = 6;
      }

      //Area 7:
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1 + channels.vc2)) && //leftLimit
        (end.x < (start.x + channels.vc1 + channels.vc2 + channels.vc3)) && //rightLimit
        //Y Axis
        (end.y <= (start.y - (channels.hc1))) && //bottomLimit
        (end.y > (start.y - (channels.hc1 + channels.hc2))) //topLimit
      ) {
        area = 7;
      }

      //Area 8:
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1 + channels.vc2 + channels.vc3)) && //leftLimit
        infinite && //rightLimit
        //Y Axis
        (end.y <= (start.y - (channels.hc1))) && //bottomLimit
        (end.y > (start.y - (channels.hc1 + channels.hc2))) //topLimit
      ) {
        area = 8;
      }

      //Area 9:
      if (
        //X Axis
        (end.x >= start.x) && //leftLimit
        (end.x < (start.x + channels.vc1)) && //rightLimit
        //Y Axis
        (end.y >= (start.y - (channels.hc1))) && //topLimit
        (end.y < (start.y + (channels.hc1))) //bottomLimit
      ) {
        area = 9;
      }

      //Area 10:
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1)) && //leftLimit
        (end.x < (start.x + channels.vc1 + channels.vc2)) && //rightLimit
        //Y Axis
        (end.y >= (start.y - (channels.hc1))) && //topLimit
        (end.y < (start.y + (channels.hc1))) //bottomLimit
      ) {
        area = 10;
      }

      //Area 11:
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1 + channels.vc2)) && //leftLimit
        (end.x < (start.x + channels.vc1 + channels.vc2 + channels.vc3)) && //rightLimit
        //Y Axis
        (end.y >= (start.y - (channels.hc1))) && //topLimit
        (end.y < (start.y + (channels.hc1))) //bottomLimit
      ) {
        area = 11;
      }


      //Area 12:
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1 + channels.vc2 + channels.vc3)) && //leftLimit
        infinite && //rightLimit
        //Y Axis
        (end.y >= (start.y - (channels.hc1))) && //topLimit
        (end.y < (start.y + (channels.hc1))) //bottomLimit
      ) {
        area = 12;
      }

      //Area 13:
      if (
        //X Axis
        (end.x >= start.x) && //leftLimit
        (end.x < (start.x + channels.vc1)) && //rightLimit
        //Y Axis
        (end.y >= (start.y + (channels.hc1))) && //topLimit
        (end.y < (start.y + (channels.hc1 + channels.hc2))) //bottomLimit
      ) {
        area = 13;
      }

      //Area 14:
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1)) && //leftLimit
        (end.x < (start.x + channels.vc1 + channels.vc2)) && //rightLimit
        //Y Axis
        (end.y >= (start.y + (channels.hc1))) && //topLimit
        (end.y < (start.y + (channels.hc1 + channels.hc2))) //bottomLimit
      ) {
        area = 14;
      }

      //Area 15:
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1 + channels.vc2)) && //leftLimit
        (end.x < (start.x + channels.vc1 + channels.vc2 + channels.vc3)) && //rightLimit
        //Y Axis
        (end.y >= (start.y + (channels.hc1))) && //topLimit
        (end.y < (start.y + (channels.hc1 + channels.hc2))) //bottomLimit
      ) {
        area = 15;
      }

      //Area 16:
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1 + channels.vc2)) && //leftLimit
        infinite && //rightLimit
        //Y Axis
        (end.y >= (start.y + (channels.hc1))) && //topLimit
        (end.y < (start.y + (channels.hc1 + channels.hc2))) //bottomLimit
      ) {
        area = 16;
      }

      //Area 17:
      if (
        //X Axis
        (end.x >= start.x) && //leftLimit
        (end.x < (start.x + channels.vc1)) && //rightLimit
        //Y Axis
        (end.y >= (start.y + (channels.hc1 + channels.hc2))) && //topLimit
        infinite //bottomLimit
      ) {
        area = 17;
      }

      //Area 18:
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1)) && //leftLimit
        (end.x < (start.x + channels.vc1 + channels.vc2)) && //rightLimit
        //Y Axis
        (end.y >= (start.y + (channels.hc1 + channels.hc2))) && //topLimit
        infinite //bottomLimit
      ) {
        area = 18;
      }

      //Area 19:
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1 + channels.vc2)) && //leftLimit
        (end.x < (start.x + channels.vc1 + channels.vc2 + channels.vc3)) && //rightLimit
        //Y Axis
        (end.y >= (start.y + (channels.hc1 + channels.hc2))) && //topLimit
        infinite //bottomLimit
      ) {
        area = 19;
      }

      //Area 20:
      if (
        //X Axis
        (end.x >= (start.x + channels.vc1 + channels.vc2 + channels.vc3)) && //leftLimit
        infinite && //rightLimit
        //Y Axis
        (end.y >= (start.y + (channels.hc1 + channels.hc2))) && //topLimit
        infinite //bottomLimit
      ) {
        area = 20;
      }

      //console.log(`Object[${this.getId()}] area: `, area);
      //console.log("Connector version: ", this.version);
      if (this.version !== null && this.version > 0) {
        ctx.strokeStyle = "orange";
      } else {
        ctx.strokeStyle = "#000000";
      }

      switch (area) {
        case 1:
        case 2:
          {
            //Get coordinates
            const sx = start.x;
            const sy = start.y0;

            const ex = end.x;
            const ey = end.y0 + end.h;

            const p1x = sx;
            const p1y = sy - ((sy - ey) * 0.50);

            const p2x = ex;
            const p2y = ey + ((sy - ey) * 0.50);

            ctx.beginPath();
            ctx.moveTo(sx, sy);
            ctx.bezierCurveTo(p1x, p1y, p2x, p2y, ex, ey);
            ctx.stroke();

            //console.log("DRAW ARROW Area:  1, 2");
            this.drawArrow(ctx, ex, ey, ArrowDirection.BOTTOM);

            // Control points
            if (this.modeDebug && this.endObject.isActive()) {
              ctx.fillStyle = "blue";
              ctx.beginPath();
              ctx.arc(p1x, p1y, 5, 0, 2 * Math.PI);
              ctx.fill();

              ctx.fillStyle = "red";
              ctx.beginPath();
              ctx.arc(p2x, p2y, 5, 0, 2 * Math.PI);
              ctx.fill();
            }
            break;
          }
        case 3:
        case 6:
          {
            // //Get coordinates
            const sx = start.x;
            const sy = start.y0;

            const ex = end.x0;
            const ey = end.y;

            const cpx = sx;
            const cpy = ey;

            //draw a quadratic curve
            ctx.beginPath();
            ctx.moveTo(sx, sy);
            ctx.quadraticCurveTo(cpx, cpy, ex, ey);
            ctx.stroke();

            //console.log("DRAW ARROW Area:  3, 6");
            this.drawArrow(ctx, ex, ey, ArrowDirection.LEFT);

            if (this.modeDebug && this.endObject.isActive()) {
              ctx.fillStyle = "blue";
              ctx.beginPath();
              ctx.arc(cpx, cpy, 5, 0, 2 * Math.PI);
              ctx.fill();
            }

            break;
          }
        case 4:
        case 7:
        case 8:
        case 10:
        case 11:
        case 12:
        case 15:
        case 16:
        case 20:
          {
            //Get coordinates
            const sx = start.x0 + start.w;
            const sy = start.y;

            const ex = end.x0;
            const ey = end.y;

            const p1x = sx + ((ex - sx) * 0.50);
            const p1y = sy;

            const p2x = sx + ((ex - sx) * 0.50);
            const p2y = ey;

            ctx.beginPath();
            ctx.moveTo(sx, sy);
            ctx.bezierCurveTo(p1x, p1y, p2x, p2y, ex, ey);
            ctx.stroke();

            //console.log("DRAW ARROW Area:  4, 7, 8, 10, 11, 12, 15, 16, 20");
            this.drawArrow(ctx, ex, ey, ArrowDirection.LEFT);

            //Control points
            if (this.modeDebug && this.endObject.isActive()) {
              ctx.fillStyle = "blue";
              ctx.beginPath();
              ctx.arc(p1x, p1y, 5, 0, 2 * Math.PI);
              ctx.fill();

              ctx.fillStyle = "red";
              ctx.beginPath();
              ctx.arc(p2x, p2y, 5, 0, 2 * Math.PI);
              ctx.fill();
            }
            break;
          }
        case 5:
        case 9:
          {
            ctx.beginPath();
            ctx.moveTo(start.x, start.y0);
            ctx.lineTo(end.x, end.y0 + end.h);
            ctx.stroke();

            //console.log("DRAW ARROW Area: 5, 9");
            this.drawArrow(ctx, end.x, end.y0 + end.h, ArrowDirection.LEFT);
            break;
          }
        case 13:
          {
            ctx.beginPath();
            ctx.moveTo(start.x, start.y0 + start.h);
            ctx.lineTo(end.x, end.y0);
            ctx.stroke();

            //console.log("DRAW ARROW Area: 13");
            this.drawArrow(ctx, end.x, end.y0, ArrowDirection.TOP);
            break;
          }
        case 14:
        case 19:
          {
            // //Get coordinates
            const sx = start.x0 + start.w;
            const sy = start.y;

            const ex = end.x;
            const ey = end.y0;

            const cpx = ex;
            const cpy = sy;

            //draw a quadratic curve
            ctx.beginPath();
            ctx.moveTo(sx, sy);
            ctx.quadraticCurveTo(cpx, cpy, ex, ey);
            ctx.stroke();

            //console.log("DRAW ARROW Area: 14, 19");
            this.drawArrow(ctx, ex, ey, ArrowDirection.TOP);

            if (this.modeDebug && this.endObject.isActive()) {
              ctx.fillStyle = "blue";
              ctx.beginPath();
              ctx.arc(cpx, cpy, 5, 0, 2 * Math.PI);
              ctx.fill();
            }
            break;
          }
        case 17:
        case 18:
          {
            //Get coordinates
            const sx = start.x;
            const sy = start.y0 + start.h;

            const ex = end.x;
            const ey = end.y0;

            const p1x = sx;
            const p1y = sy - ((sy - ey) * 0.50);

            const p2x = ex;
            const p2y = ey + ((sy - ey) * 0.50);

            ctx.beginPath();
            ctx.moveTo(sx, sy);
            ctx.bezierCurveTo(p1x, p1y, p2x, p2y, ex, ey);
            ctx.stroke();

            //console.log("DRAW ARROW Area: 17, 18");
            this.drawArrow(ctx, ex, ey, ArrowDirection.TOP);

            // Control points
            if (this.modeDebug && this.endObject.isActive()) {
              ctx.fillStyle = "blue";
              ctx.beginPath();
              ctx.arc(p1x, p1y, 5, 0, 2 * Math.PI);
              ctx.fill();

              ctx.fillStyle = "red";
              ctx.beginPath();
              ctx.arc(p2x, p2y, 5, 0, 2 * Math.PI);
              ctx.fill();
            }
            break;
          }
        default:
          {
            //Get coordinates
            const sx = start.x;
            const sy = start.y0;

            const ex = end.x;
            const ey = end.y0 + end.h;

            ctx.strokeStyle = "blue";
            ctx.beginPath();
            ctx.moveTo(sx, sy);
            ctx.lineTo(ex, ey);
            ctx.stroke();

            console.log("DRAW ARROW Area: Default");
            this.drawArrow(ctx, ex, ey, ArrowDirection.LEFT);
            break;
          }
      }
      ctx.restore();
    }
  }

  drawArrow(ctx: CanvasRenderingContext2D, x: number, y: number, arrowDirection: ArrowDirection) {
    if (this.showDirection === false){ return; }

    //console.log("DRAW ARROW: ", this.getId());
    ctx.fillStyle = ctx.strokeStyle;
    const height = 8;
    var v0 = { x: x, y: y }; //Vertice 0 [Initial point]
    var v1 = { x: x, y: y }; //Vertice 1
    var v2 = { x: x, y: y }; //Vertice 2

    switch (arrowDirection) {
      case ArrowDirection.TOP:
        {
          v0.y = v0.y - 2;

          v1.x = v1.x - height;
          v1.y = v1.y - 2 - height;

          v2.x = v2.x + height;
          v2.y = v2.y - 2 - height;
          break;
        }
      case ArrowDirection.BOTTOM:
        {
          v0.y = v0.y + 2;

          v1.x = v1.x - height;
          v1.y = v1.y + 2 + height;

          v2.x = v2.x + height;
          v2.y = v2.y + 2 + height;
          break;
        }
      case ArrowDirection.LEFT:
        {
          v0.x = v0.x - 2;

          v1.x = v1.x - 2 - height;
          v1.y = v1.y - height;

          v2.x = v2.x - 2 - height;
          v2.y = v2.y + height;
          break;
        }
      case ArrowDirection.RIGHT:
        {
          v0.x = v0.x + 2;

          v1.x = v1.x + 2 + height;
          v1.y = v1.y - height;

          v2.x = v2.x + 2 + height;
          v2.y = v2.y + height;
          break;
        }

    }

    ctx.beginPath();
    ctx.moveTo(v0.x, v0.y);
    ctx.lineTo(v1.x, v1.y);
    ctx.lineTo(v2.x, v2.y);
    ctx.closePath();
    ctx.fill();
  }

  drawDebugMode(ctx: CanvasRenderingContext2D, canvasMap: CanvasMap) {
    if (this.endObject.isActive()) {
      ctx.save();

      //Get Draw Objects Coordinates
      const start = this.getStartToDraw(canvasMap);
      const end = this.getEndToDraw(canvasMap);
      const channels = this.getChannels();
      const channelExt = start.w * 2;

      ctx.setLineDash([10, 5]);
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#B4B4B4';

      /*
           VC1    VC2     VC3     VC4
         |     |       |       |
         |     |       |       |
         |     |       |       |
         |     |       |       |
         |     |       |       |
  HC3    | 1   |   2   |   3   |   4
         |     |       |       |
         |-----┼-------┼-------┼----------------
         |     |       |       |
  HC2    | 5   |   6   |   7   |   8 
         |     |       |       |
         |-----┼-------┼-------┼----------------
  HC1 ######   |       |       |
      ######-9-┼---10--┼---11--┼---12-----------
  HC1 ######   |       |       |
         |-----┼-------┼-------┼----------------
         |     |       |       |
  HC2    | 13  |   14  |   15  |   16
         |     |       |       |
         |-----┼-------┼-------┼----------------
         |     |       |       |
  HC3    | 17  |   18  |   19  |   20
         |     |       |       |
         |     |       |       |
         |     |       |       |
         |     |       |       |
         |     |       |       |
      */
      ctx.beginPath();

      //Vertical line for CENTER
      // ctx.moveTo(start.x, start.y - (channels.c1.h + channels.c2.h + channelExt));
      // ctx.lineTo(start.x, start.y + (channels.c1.h + channels.c2.h + channelExt));

      //Vertical line for Right VC1
      ctx.moveTo(start.x + (channels.vc1), start.y - (channels.hc1 + channels.hc2 + channelExt));
      ctx.lineTo(start.x + (channels.vc1), start.y + (channels.hc1 + channels.hc2 + channelExt));

      //Vertical line for Right VC2
      ctx.moveTo(start.x + (channels.vc1 + channels.vc2), start.y - (channels.hc1 + channels.hc2 + channelExt));
      ctx.lineTo(start.x + (channels.vc1 + channels.vc2), start.y + (channels.hc1 + channels.hc2 + channelExt));

      //Vertical line for Right VC3
      ctx.moveTo(start.x + (channels.vc1 + channels.vc2 + channels.vc3), start.y - (channels.hc1 + channels.hc2 + channelExt));
      ctx.lineTo(start.x + (channels.vc1 + channels.vc2 + channels.vc3), start.y + (channels.hc1 + channels.hc2 + channelExt));

      //Vertical line for Left VC1 Mirror
      ctx.moveTo(start.x - (channels.vc1), start.y - (channels.hc1 + channels.hc2 + channelExt));
      ctx.lineTo(start.x - (channels.vc1), start.y + (channels.hc1 + channels.hc2 + channelExt));

      //Vertical line for Left VC2 Mirror
      ctx.moveTo(start.x - (channels.vc1 + channels.vc2), start.y - (channels.hc1 + channels.hc2 + channelExt));
      ctx.lineTo(start.x - (channels.vc1 + channels.vc2), start.y + (channels.hc1 + channels.hc2 + channelExt));

      //Vertical line for Left VC3 Mirror
      ctx.moveTo(start.x - (channels.vc1 + channels.vc2 + channels.vc3), start.y - (channels.hc1 + channels.hc2 + channelExt));
      ctx.lineTo(start.x - (channels.vc1 + channels.vc2 + channels.vc3), start.y + (channels.hc1 + channels.hc2 + channelExt));

      // //Horizontal line for CENTER
      // ctx.moveTo(start.x - (channels.c1.w + channels.c2.w + channelExt), start.y);
      // ctx.lineTo(start.x + (channels.c1.w + channels.c2.w + channelExt), start.y);

      //Horizontal line for Top HC1
      ctx.moveTo(start.x - (channels.vc1 + channels.vc2 + channels.vc3 + channelExt), start.y - (channels.hc1));
      ctx.lineTo(start.x + (channels.vc1 + channels.vc2 + channels.vc3 + channelExt), start.y - (channels.hc1));

      //Horizontal line for Top HC2
      ctx.moveTo(start.x - (channels.vc1 + channels.vc2 + channels.vc3 + channelExt), start.y - (channels.hc1 + channels.hc2));
      ctx.lineTo(start.x + (channels.vc1 + channels.vc2 + channels.vc3 + channelExt), start.y - (channels.hc1 + channels.hc2));

      //Horizontal line for Bottom C1 Mirror
      ctx.moveTo(start.x - (channels.vc1 + channels.vc2 + channels.vc3 + channelExt), start.y + (channels.hc1));
      ctx.lineTo(start.x + (channels.vc1 + channels.vc2 + channels.vc3 + channelExt), start.y + (channels.hc1));

      //Horizontal line for Bottom C2 Mirror
      ctx.moveTo(start.x - (channels.vc1 + channels.vc2 + channels.vc3 + channelExt), start.y + (channels.hc1 + channels.hc2));
      ctx.lineTo(start.x + (channels.vc1 + channels.vc2 + channels.vc3 + channelExt), start.y + (channels.hc1 + channels.hc2));

      ctx.stroke();
      ctx.setLineDash([]);

      ctx.font = "10px serif";
      ctx.fillStyle = "#000000";
      // ctx.fillText("x:" + startX + " y:" + startY, startX - 50, startY + 10);
      // ctx.fillText("x:" + (startX + startW) + " y:" + startY, startX + startW + 4, startY + 10);
      // ctx.fillText("x:" + startX + " y:" + (startY + startH), startX - 50, startY + startH - 5);
      // ctx.fillText("x:" + (startX + startW) + " y:" + (startY + startH), startX + startW + 4, startY + startH - 5);

      // ctx.fillText("x:" + endX + " y:" + endY, endX - 50, endY + 10);
      // ctx.fillText("x:" + (endX + endW) + " y:" + endY, endX + endW + 4, endY + 10);
      // ctx.fillText("x:" + endX + " y:" + (endY + endH), endX - 50, endY + endH - 5);
      // ctx.fillText("x:" + (endX + endW) + " y:" + (endY + endH), endX + endW + 4, endY + endH - 5);

      //console.log(`DEBUG MODE canvasMap: OriginX: ${canvasMap.originX} OriginY: ${canvasMap.originY} Zoom: ${canvasMap.zoom}`);
      ctx.restore();
    }
  }
}