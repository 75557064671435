import { ASDtos } from 'api/AppServices/index';
import { CallServiceRaw} from 'api/AppServices/AppServicesClient';

export async function AuthenticateAgent(authentication: ASDtos.AuthenticationCreateDto) : Promise<ASDtos.AuthenticationReadDto> {
  var urlResource = `/authentication`;
  //console.log('GetNodeById URL: ', { url: urlResource });
  return await CallServiceRaw<ASDtos.AuthenticationReadDto>(urlResource, "POST", authentication);
}
export async function ResetCredentialsAgent(authentication: ASDtos.AuthenticationCredentialsResetDto) : Promise<ASDtos.AuthenticationReadDto> {
  var urlResource = `/authentication/credentials/reset`;
  //console.log('GetNodeById URL: ', { url: urlResource });
  return await CallServiceRaw<ASDtos.AuthenticationReadDto>(urlResource, "POST", authentication);
}
