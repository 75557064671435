import { useEffect, useState, useMemo } from 'react'; // let's also import Component

import { ASGateway, ASDtos } from 'api/AppServices';

import {
  Box,
  Chip,
  MenuItem,
  Button,
  Divider,
  TextField,
  debounce
} from '@mui/material';

import Grid3x3Icon from '@mui/icons-material/Grid3x3';

type ICanvasViewNodeSearchProps = {
  canvasViewId: number,
  version: number,
  onSelectNode: (nodeSelected: ASDtos.NodeReadDto) => void;
};

export default function CanvasViewNodeSearch(props: ICanvasViewNodeSearchProps) {
  const [nodes, setNodes] = useState<ASDtos.NodeSearchReadDto[]>([]);

  const [inputValue, setInputValue] = useState<string>('');


  const fetchNodes = useMemo(() =>
      debounce((
          request: { networkId: number, searchTerm: string },
          callback: (results?: ASDtos.NodeSearchReadDto[]) => void,
        ) => {
          //console.log("debounce.request: ", request);
          ASGateway.Nodes.Search(request.networkId, request.searchTerm)
            .then((nodes: ASDtos.NodeSearchReadDto[]) => {
              callback(nodes);
            })
            .catch((e: any) => {
              console.error(e);
            });
        },
        1000 //Debounce ms
      )
      ,[]);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setNodes([]);
      return undefined;
    }

    fetchNodes(
      //debounce.request
      { networkId: props.canvasViewId, searchTerm: inputValue },
      //debounce.callBack
      (results?: ASDtos.NodeSearchReadDto[]) => { 
        //console.log("AutoCompleteTasks results: ", results);
        if (active) {
          let newNodes: ASDtos.NodeSearchReadDto[] = [];

          if (results) {
            newNodes = [...results];
          }

          setNodes(newNodes);
        }
      });

    return () => {
      active = false;
    };
  }, [inputValue]);

  // OnRender
  return (
    <Box id="node-select" sx={{ p: 1 }}>
      <TextField
        fullWidth
        label="Search a Node"
        onChange={(e) => {
          //console.log("Onchange: ", e.target.value);
          setInputValue(e.target.value)
        }}
      />
      <Divider />
      {nodes.map((node: ASDtos.NodeSearchReadDto) => {
        return (
          <MenuItem
            key={node.id}
            value={node.id}
            onClick={() => {
              ASGateway.Nodes.GetById(node.id)
                .then((data: ASDtos.NodeReadDto) => {
                  //console.log("Networks: ", data);
                  props.onSelectNode(data)
                })
                .catch((e) => {
                  console.error(e);
                });
            }}
          ><Chip sx={{ mr: 1 }} icon={<Grid3x3Icon fontSize="small" color="info" />} label={`${node.id}`} variant="outlined" /> {node.name}</MenuItem>
        )
      })}
      <Divider />
      <MenuItem sx={{ m: 0 }} key={""} value={""}><Button sx={{ p: 0, m: 0 }} fullWidth size='small' onClick={() => { return false; }}>XPTO</Button></MenuItem>
    </Box>
  );
}