import { AppVars } from "app/AppVars";

export default async function CallService<T>(url: string, method: string = "GET", payload: any = null): Promise<T> {

  //Add Company to the Url (comes with / in the start of URL)
  const appServicesUrl = `/companies/${AppVars.CompanyId}${url}`;

  return await CallServiceRaw(appServicesUrl, method, payload)
}

export async function CallServiceRaw<T>(urlRaw: string, method: string = "GET", payload: any = null): Promise<T> {
  //const PortalServicesUrl = `${process.env.REACT_APP_SERVICES_ENDPOINT}/api/company/${appVars.CompanyId}`;
  const AppServicesUrl = `${process.env.REACT_APP_SERVICES_ENDPOINT}/api`;
  var fullUrl = `${AppServicesUrl}${urlRaw}`;
  //console.log('=> ', method, ' ', url, ' ' , payload);

  var body: BodyInit | null = null;
  if (payload !== null && method != "GET") {
    body = JSON.stringify(payload);
  }

  const requestOptions: RequestInit = {
    method: method,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      //"Accept-Language": appVars.Language
    },
    body: body
  };

  var data = null;
  //if (method === "GET"){
  //console.log('CallService InputOptions: ' + JSON.stringify(requestOptions));

  // fetch('flowers.jpg').then(function (response) {
  //   if (response.ok) {
  //     return response.blob();
  //   }
  //   throw new Error('Network response was not ok.');
  // }).then(function (myBlob) {
  //   var objectURL = URL.createObjectURL(myBlob);
  //   myImage.src = objectURL;
  // }).catch(function (error) {
  //   console.log('There has been a problem with your fetch operation: ',
  //     error.message);
  // });

  const response = await fetch(fullUrl, requestOptions);

  // console.log("response.ok2: ", response?.ok);
  if (!response.ok) {
    var error = await response.json();
    //console.log("!response.ok: ", error);
    throw new Error(error as string);
  }

  if (response.status !== 204) { //204: NO CONTENT
    data = await response.json()
  }
  // }
  // else {
  //   //Add to Queue
  // }
    
  //console.log(`response[${urlRaw}]: `, data);
  return data;
}