import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';


export async function GetAll() : Promise<ASDtos.AgentReadDto[]> {
  var urlResource = `/agents`;
  
  //console.log('GetNodeById URL: ', { url: urlResource });
  return await CallService<ASDtos.AgentReadDto[]>(urlResource);
}

export async function Search(searchTerm: string) : Promise<ASDtos.AgentReadDto[]> {
  var urlResource = `/agents/search`;

    //Search Agent payload
    const payload: ASDtos.AgentSearchDto = {
      "searchTerms": searchTerm.split(" "),
    } ;
  
  //console.log('GetNodeById URL: ', { url: urlResource });
  return await CallService<ASDtos.AgentReadDto[]>(urlResource, "POST", payload);
}

export async function Create(name: string, account: string, email: string) : Promise<ASDtos.AgentReadDto> {
  var urlResource = `/agents`;

    //Create Agent payload
    const payload: ASDtos.AgentCreateDto = {
      "name": name,
      "account": account,
      "email": email
    } ;
  
  //console.log('UpdateViewBucket URL: ', { url: urlResource });
  return await CallService<ASDtos.AgentReadDto>(urlResource, "POST", payload);
}

export async function Delete(agentId: number) : Promise<ASDtos.AgentReadDto> {
  var urlResource = `/agents/${agentId}`;

  //console.log('UpdateViewBucket URL: ', { url: urlResource });
  return await CallService<ASDtos.AgentReadDto>(urlResource, "DELETE", null);
}

export async function GetViewDefault(agentId: number) : Promise<ASDtos.AgentViewReadDto> {
  var urlResource = `/agents/${agentId}/views/default`;
  //console.log('GetViewDefault URL: ', { url: urlResource });
  return await CallService<ASDtos.AgentViewReadDto>(urlResource);
}

export async function UpdateViewBucket(agentId: number, agentViewId: number, bucketNumber: number, networkId: number) : Promise<ASDtos.AgentViewReadDto> {
  var urlResource = `/agents/${agentId}/views/${agentViewId}`;
  var payload = {
    number: bucketNumber,
    networkId: networkId
  } as ASDtos.AgentViewBucketUpdateDto;

  //console.log('UpdateViewBucket URL: ', { url: urlResource });
  return await CallService<ASDtos.AgentViewReadDto>(urlResource, "PUT", payload);
}

export async function RequestCredentialsReset(agentId: number) : Promise<ASDtos.AgentReadDto> {
  var urlResource = `/agents/${agentId}/reset`;

  //console.log('UpdateViewBucket URL: ', { url: urlResource });
  return await CallService<ASDtos.AgentReadDto>(urlResource, "DELETE", null);
}