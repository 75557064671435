import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import DOMPurify from 'isomorphic-dompurify';
import HTMLParser from 'html-react-parser';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import { ASGateway, ASDtos } from 'api/AppServices';

import Editor from 'components/Editor';
import EditorBlockStatus from 'components/EditorBlockStatus';
import EditorViewDocumentHistory from 'components/EditorViewDocumentHistory';
import FormNewDocument from 'components/Forms/FormNewDocument';
import FormAddChangeToTask from 'components/Forms/FormAddChangeToTask';
import IconSvg from "components/IconSvg";

import {
  Stack,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Chip,
  Badge,
  Divider,
  Button,
  Tabs,
  Tab,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Menu,
  MenuItem,
} from '@mui/material';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LaunchIcon from '@mui/icons-material/Launch';
import HistoryIcon from '@mui/icons-material/History';

type IDetailContainerNodeProps = {
  nodeId: number
};

enum DataBlockChangesFilter {
  ALL,
  TASKSASSIGNED,
  TASKSUNASSIGNED
};

export default function DetailContainerNode(props: IDetailContainerNodeProps) {
  //Redux States
  // const dispatch = useAppDispatch();
  const version = useAppSelector((state) => state.systemBar.version);
  const appSession = useAppSelector((state) => state.appSession);

  //Redux States

  const [nodeData, setNodeData] = useState({} as any);

  const [documents, setDocuments] = useState<ASDtos.DocumentReadDto[]>([]);
  const [documentSelectedIndex, setDocumentSelectedIndex] = useState<number>(0);

  const [showAddChangeToTaskForm, setShowAddChangeToTaskForm] = useState<boolean>(false);
  const [showNewDocumentForm, setShowNewDocumentForm] = useState<boolean>(false);
  const [showDocumentHistoryView, setShowDocumentHistoryView] = useState<boolean>(false);
  
  //const [showEditorResult, setShowEditorResult] = useState<boolean>(false);
  //const [editorResult, setEditorResult] = useState<ASDtos.DocumentBlockUpdateDto[]>([]);

  const [checkedUnassigned, setCheckedUnassigned] = useState([0]);
  const [anchorTasksAssignedToChange, setAnchorTasksAssignedToChange] = useState<null | HTMLElement>(null);
  const [taskIdAssignedToChange, setTaskIdAssignedToChange] = useState<null | number>(null);

  //let haveChangesOnSave = false;

  // function hasChangedStatusFromEditorBlockResult(block: ASDtos.DocumentBlockUpdateDto) {
  //   var isChanged = block.isChanged;
  //   var isDeleted = block.isDeleted;
  //   var isNew = (block.apiRef === -1);
  //   return (isNew || isChanged || isDeleted);
  // }

  function getAllChanges(filter: DataBlockChangesFilter): ASDtos.DocumentBlockReadDto[] {
    //console.log("dataDocuments: ", documents);
    var result: ASDtos.DocumentBlockReadDto[] = [];
    documents?.forEach((document: ASDtos.DocumentReadDto) => {
      // console.log("document.blocks: ", document.blocks);
      // console.log("parsedBlocks: ", parsedBlocks);
      document.blocks.forEach((dataBlock: ASDtos.DocumentBlockReadDto) => {
        //console.log("getAllChanges dataBlock: ", dataBlock);
        if (dataBlock.isChanged || dataBlock.isNew) {
          switch (filter) {
            case DataBlockChangesFilter.TASKSASSIGNED:
              {
                if (dataBlock.history && dataBlock.history.length > 0) {
                  result = [...result, dataBlock];
                }
                break;
              }
            case DataBlockChangesFilter.TASKSUNASSIGNED:
              {
                //console.log("getAllChanges TASKSUNASSIGNED dataBlock: ", dataBlock);
                //console.log("getAllChanges TASKSUNASSIGNED dataBlock.history: ", dataBlock.history);
                // console.log("getAllChanges TASKSUNASSIGNED Add: ", (dataBlock.tasks === null || dataBlock.tasks.length === 0));
                // console.log("getAllChanges TASKSUNASSIGNED Cond dataBlock.tasks.lenght: ", dataBlock.tasks.length);
                if (!dataBlock.history || dataBlock.history.length === 0) {
                  result = [...result, dataBlock];
                }
                break;
              }
            case DataBlockChangesFilter.ALL:
              {
                result = [...result, dataBlock];
                break;
              }
            default:
              {
                break;
              }
          }
        }
      });
    });
    return result;
  }

  function getTotalChangesOnDocument() {
    var documentsWithChanges = undefined;
    if (documents[documentSelectedIndex]) {
      documentsWithChanges = documents[documentSelectedIndex].blocks.filter((block: ASDtos.DocumentBlockReadDto) => {
        return (block.isChanged || block.isNew || block.isDeleted);
      });
    }
    return (documentsWithChanges) ? documentsWithChanges.length : 0;
  }

  //Handles
  function handleChangesAssigned(taskId: number | null, changes: number[]) {
    if (taskId !== null) {
      //console.log("handleChangesAssigned documents: ", documents);
      var documentsUpdated = [...documents];
      //Find Document for all Changes Assigned
      documentsUpdated.forEach((document: any, index: number) => {
        document.blocks.forEach((block: any, index: number) => {
          var blockId = block.apiRef;
          var isBlockChanged = changes.some(change => change === blockId);
          if (isBlockChanged) {
            //Add task into the block
            block.tasks = [...block.tasks, taskId];
          }
        });
      });

      //console.log("handleChangesAssigned documentsUpdated: ", documentsUpdated);
      setDocuments(documentsUpdated);

      // const documentSelectedUpdated = documents.find(document => document.id === documentSelected.id);
      // if (documentSelectedUpdated) {
      //   setDocumentSelected(documentSelectedUpdated);
      // }
    }
    setShowAddChangeToTaskForm(false);
  }

  function handleNewDocCreated(newDocCreated: any) {
    if (newDocCreated) {
      var newDocuments = [...documents, newDocCreated];
      var newIndex = newDocuments.length - 1;

      setDocuments(newDocuments);
      // setDocumentSelected(newDocuments[newIndex]);
      setDocumentSelectedIndex(newIndex);
    }
    setShowNewDocumentForm(false);
  }

  function handleDocumentSelected(event: React.SyntheticEvent<Element, Event>, value: any) {
    setDocumentSelectedIndex(value);
  }

  function handleSave(data: ASDtos.DocumentBlockUpdateDto[]) {
    const documentSelected = documents[documentSelectedIndex];
    console.log("Save Content: ", data);
    
    ASGateway.Documents.UpdateContent(
      version,
      documentSelected.id,
      data
    )
      .then((dataDocumentContent: ASDtos.DocumentBlockReadDto[]) => {
        // console.log('UpdateDocumentContent: ', dataDocumentContent);
        // console.log('UpdateDocumentContent documents: ', documents);
        var updatedDocuments = [...documents];

        var documentUpdated = updatedDocuments[documentSelectedIndex];
        if (documentUpdated) {
          documentUpdated.blocks = dataDocumentContent;
        }

        setDocuments(updatedDocuments);
        //setShowEditorResult(false);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }


  // OnLoad
  // useEffect(() => {
  // }, [documentSelectedIndex]);

  useEffect(() => {
    // console.log("useEffect [DetailContainerNode]");
    // console.log("Props: ", props);
    //Reset Container Data
    if (props.nodeId > 0) {
      setNodeData({} as any);

      //Load Node
      ASGateway.Nodes.GetById(props.nodeId)
        .then((dataNode) => {
          //console.log('GetNodeById: ', dataNode);
          if (dataNode) {
            setNodeData(dataNode);

            ASGateway.Documents.GetForNodeVersion(version, dataNode.id)
              .then((dataDocuments: ASDtos.DocumentReadDto[]) => {
                console.log('GetNodeDocumentsForVersion: ', dataDocuments);
                setDocuments(dataDocuments);
                setDocumentSelectedIndex(0);
              })
              .catch((e: any) => {
                console.error(e);
              });
          }
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }, [props.nodeId, version]);

  useEffect(() => {
    // console.log("dataNode: ", nodeData);
    //console.log("dataDocuments: ", documents);
    // console.log("documentSelected: ", documentSelected);
    //console.log("documentSelectedData: ", documentSelectedData);
  })

  // OnRender
  return (
    <>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="node-detail-summary"
          >
            <Box sx={{ p: 0, width: "100%", height: "100%" }}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                <Box>
                  <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                    <Box>
                      <Link component={RouterLink} to={`/detail/node/${nodeData.id}`}>
                        <Chip icon={<Grid3x3Icon fontSize="small" color="info" />} label={`${nodeData.id}`} variant="outlined" clickable />
                      </Link>
                    </Box>
                  </Stack>
                </Box>
                <Box sx={{ width: "100%" }}>
                  {nodeData?.name}
                </Box>
                <Box />
              </Stack>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
              <Box>
                == Details ==                         <br />
                - Included in Networks:             <br />
                * Network: %Name%                 <br />
                * Network: %Name%                 <br />
                - Child Networks:                   <br />
                * Network: %Name%                 <br />
                * Network: %Name%                 <br />
              </Box>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="node-detail-documents"
          >
            <Box sx={{ p: 1, width: "100%", height: "100%" }}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                <Box>
                  <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                    <Box>
                      <Badge badgeContent={documents.length} color="info">
                        <DescriptionIcon color="action" />
                      </Badge>
                    </Box>
                  </Stack>
                </Box>
                <Box sx={{ width: "100%" }}>
                  Documents
                </Box>
              </Stack>
            </Box>
            <Divider />
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Box sx={{ p: 0, width: "100%", height: "100%" }}>
                <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                  <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0}>

                    <Tabs variant="scrollable" scrollButtons="auto"
                      value={documentSelectedIndex} //Always Index Tab
                      onChange={handleDocumentSelected}
                    >
                      {documents.map((document) => {
                        return (
                          <Tab id={document.id.toString()} key={document.id.toString()} label={document.name} />
                        );
                      })
                      }
                    </Tabs>

                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={0}>

                      <IconButton size={'small'} sx={{ ":hover": { backgroundColor: "#D4DBF5" } }}
                        onClick={() => setShowNewDocumentForm(true)}
                      >
                        <NoteAddIcon sx={{ transform: "scale(0.7)" }} />
                      </IconButton>
                      <IconButton size={'small'} sx={{ ":hover": { backgroundColor: "#D4DBF5" } }}
                      //onClick={() => setShowMoveToBoardForm(true)}
                      >
                        <DeleteForeverIcon sx={{ transform: "scale(0.7)" }} />
                      </IconButton>
                      <IconButton size={'small'} sx={{ ":hover": { backgroundColor: "#D4DBF5" } }}
                      onClick={() => setShowDocumentHistoryView(true)}
                      >
                        <HistoryIcon sx={{ transform: "scale(0.7)" }} />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Stack>

                <Divider />
                {documents[documentSelectedIndex] && documents[documentSelectedIndex].blocks &&
                  <Editor
                    editorId="nodeContainer"
                    contentId={nodeData.id}
                    readOnly={version === 0} //When version is LIVE should block edit
                    markChanges={true}
                    showActions={true}
                    data={documents[documentSelectedIndex].blocks}
                    onSave={(result: ASDtos.DocumentBlockUpdateDto[]) => {
                      if (result) {
                        //setEditorResult(result);
                        handleSave(result);
                        //setShowEditorResult(true);
                      }
                    }}
                  />
                }
              </Box>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="node-detail-changes"
          >
            <Box sx={{ p: 0, width: "100%", height: "100%" }}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                <Box>
                  <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                    <Box>
                      <Badge badgeContent={getTotalChangesOnDocument()} color="info">
                        <FormatListNumberedIcon color="action" />
                      </Badge>
                    </Box>
                  </Stack>
                </Box>
                <Box sx={{ width: "100%" }}>
                  Changes
                </Box>
              </Stack>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                  <Box sx={{ p: 1, width: "100%" }}>
                    Unassigned
                    <Divider />
                  </Box>
                </Stack>
                <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                  <List dense={true}>
                    {getAllChanges(DataBlockChangesFilter.TASKSUNASSIGNED).map((dataBlock: ASDtos.DocumentBlockReadDto) => {
                      var blockJSon = JSON.parse(dataBlock.data);
                      var blockHtml = HTMLParser(DOMPurify.sanitize(blockJSon.text));
                      //var elemText = React.createElement("span", { key: dataBlock.apiRef }, blockHtml);
                      // console.log("dataBlock.data.text: ", dataBlock.data.text);
                      // console.log("blockHtml: ", blockHtml);
                      return (
                        <ListItem
                          key={dataBlock.apiRef}
                          dense
                          sx={{ pl: 1, pr: 1, pt: 0, pb: 0 }}
                        >
                          <ListItemButton
                            dense
                            sx={{ pl: 1, pr: 1, pt: 0, pb: 0 }}
                            onClick={() => { return; }}
                          >
                            <ListItemIcon>
                              <IconSvg icon='StatZero' />
                            </ListItemIcon>
                            <ListItemText id={String(dataBlock.apiRef)} primary={blockHtml} />
                            <EditorBlockStatus block={dataBlock}/>                            
                            <LaunchIcon fontSize="small"
                              sx={{
                                pl: 1, pr: 1,
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                  <Button
                    onClick={() => setShowAddChangeToTaskForm(true)}
                  >Assign Task</Button>
                </Stack>
              </Card>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                  <Box sx={{ p: 1, width: "100%" }}>
                    Assigned
                    <Divider />
                  </Box>
                </Stack>
                <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                  <List
                    dense
                  >
                    {getAllChanges(DataBlockChangesFilter.TASKSASSIGNED).map((dataBlock: any, indexBlock: number) => {
                      var blockHtml = HTMLParser(DOMPurify.sanitize(dataBlock.data.text));
                      //console.log("dataBlock.tasks.lenght: ", dataBlock.tasks.length);
                      return (
                        <ListItem
                          key={dataBlock.apiRef}
                          dense
                          sx={{ pl: 1, pr: 1, pt: 0, pb: 0 }}
                        >
                          <ListItemButton
                            dense
                            sx={{ pl: 1, pr: 1, pt: 0, pb: 0 }}
                            onClick={(e) => {
                              setAnchorTasksAssignedToChange(e.currentTarget);
                              setTaskIdAssignedToChange(dataBlock.apiRef);
                            }}
                          >
                            <ListItemIcon>
                              <IconSvg icon='StatZero' />
                            </ListItemIcon>
                            <ListItemText id={String(dataBlock.apiRef)} primary={blockHtml} />
                            <EditorBlockStatus block={dataBlock}/>                            
                            <Badge badgeContent={dataBlock.tasks?.length} color="info"
                              sx={{
                                pl: 1, pr: 1,
                                '& .MuiBadge-badge': {
                                  right: 7,
                                  top: 10,
                                },
                              }}
                            >
                              <ListAltIcon fontSize="small" />
                            </Badge>
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Stack>
              </Card>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
      {/* MENU TO SHOW TASKS ASSIGNED TO CHANGE */}
      <Menu
        anchorEl={anchorTasksAssignedToChange}
        id="tasks-assigned-to-change"
        open={Boolean(anchorTasksAssignedToChange)}
        onClose={() => { setAnchorTasksAssignedToChange(null); setTaskIdAssignedToChange(null); }}
        onClick={() => { setAnchorTasksAssignedToChange(null); setTaskIdAssignedToChange(null); }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
          Tasks
          <Box sx={{ p: 0, width: "100%" }}>
            <Divider />
          </Box>
        </Stack>

        {getAllChanges(DataBlockChangesFilter.TASKSASSIGNED).map((dataBlock: any, indexBlock: number) => {
          //Check if Change is the one selected
          if (dataBlock.apiRef === taskIdAssignedToChange) {
            //console.log("dataBlock: ", dataBlock);
            return (
              <Box key={dataBlock.apiRef}>
                {dataBlock.tasks?.map((dataBlockTaskId: number, indexBlock: number) => {
                  return (
                    <Link key={dataBlockTaskId} component={RouterLink} to={`/detail/task/${dataBlockTaskId}`}>
                      <MenuItem onClick={() => { return; }}>
                        <Chip icon={<Grid3x3Icon fontSize="small" color="info" />} label={`${dataBlockTaskId}`} variant="outlined" clickable />
                      </MenuItem>
                    </Link>
                  )
                })}
              </Box>
            );
          }
        })}
      </Menu>

      {/* DIALOG FOR NEW DOCUMENT */}
      <Dialog fullScreen={false} open={showNewDocumentForm} onClose={() => setShowNewDocumentForm(false)}>
        <DialogTitle>New Document</DialogTitle>
        <DialogContent sx={{ p: 2, width: "500px", maxHeight: "500px" }}>
          <FormNewDocument
            versionId={version}
            agentId={appSession.user.userId}
            nodeId={nodeData.id}
            taskId={undefined}
            formIsDone={(newDocCreated: any) => handleNewDocCreated(newDocCreated)}
          />
        </DialogContent>
      </Dialog>

      {/* DIALOG FOR DOCUMENT HISTORY */}
      <Dialog fullWidth maxWidth={"lg"} sx={{ p: 2, }} fullScreen={false} open={showDocumentHistoryView} onClose={() => setShowDocumentHistoryView(false)}>
        <DialogTitle>Document History</DialogTitle>
        <DialogContent>
          <EditorViewDocumentHistory
            blocks={(documents[documentSelectedIndex])?documents[documentSelectedIndex].blocks : []}
            formIsDone={() => setShowDocumentHistoryView(false)}
          />
        </DialogContent>
      </Dialog>
      

      {/* DIALOG FOR ADD CHANGE TO TASK */}
      <Dialog fullScreen={false} open={showAddChangeToTaskForm} onClose={() => setShowAddChangeToTaskForm(false)}>
        <DialogTitle>Add Changes to Task</DialogTitle>
        <DialogContent sx={{ p: 2, width: "500px", maxHeight: "500px" }}>
          <FormAddChangeToTask
            changes={getAllChanges(DataBlockChangesFilter.TASKSUNASSIGNED)}
            formIsDone={(taskId, changes) => handleChangesAssigned(taskId, changes)}
          />
        </DialogContent>
      </Dialog>

      {/* DIALOG FOR Editor RESULT APPLY CHANGES */}
      {/* <Dialog fullScreen={false} open={showEditorResult} onClose={() => setShowEditorResult(false)} >
        <DialogTitle>Apply changes</DialogTitle>
        <DialogContent sx={{ p: 2, width: "500px", maxHeight: "500px" }}>

          <Fade in={showEditorResult}>
            <Stack direction="column" justifyContent="center" alignItems="stretch" sx={{ p: 0 }} spacing={0}>
              {editorResult.map((block: ASDtos.DocumentBlockUpdateDto, index: number) => {
                //console.log("OnSave block:", block);
                if (index === 0) {
                  haveChangesOnSave = false;
                }
                if (hasChangedStatusFromEditorBlockResult(block)) {
                  haveChangesOnSave = true;
                  //Only render the ones with Changed Status
                  return (
                    <Stack
                      id={block.apiRef.toString()}
                      key={block.apiRef}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={0}
                    >
                      <Box>
                        <Checkbox size="small" defaultChecked />
                        {HTMLParser(DOMPurify.sanitize(block.data))}
                      </Box>
                      <Box>
                        <Chip
                          variant="outlined"
                          size="small"
                          label={getStatusFromEditorBlockResult(block)}
                          color={getColorFromEditorBlockResult(block)}
                        />
                      </Box>
                    </Stack>
                  );
                }
              })}

              {(!haveChangesOnSave) &&
                <Box>
                  No changes Available
                </Box>
              }
            </Stack>
          </Fade>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEditorResult(false)}>{(haveChangesOnSave) ? "Cancel" : "Continue"}</Button>
          {(haveChangesOnSave) && <Button onClick={handleSave}>Save</Button>}
        </DialogActions>
      </Dialog> */}
    </>
  );
}