import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';


export async function GetAll(): Promise<ASDtos.TeamReadDto[]> {
  var urlResource = `/teams`;
  //console.log('GetAllVersions URL: ', { url: urlResource });

    return await CallService<ASDtos.TeamReadDto[]>(urlResource);
}
export async function GetAllForAgent(agentId: number): Promise<ASDtos.TeamReadDto[]> {
  var urlResource = `/teams/agents/${agentId}`;
  //console.log('GetAllVersions URL: ', { url: urlResource });

    return await CallService<ASDtos.TeamReadDto[]>(urlResource);
}

export async function Create(userId: number, name: string) : Promise<ASDtos.TeamReadDto> {
  var urlResource = `/teams`;

    //Create Agent payload
    const payload: ASDtos.TeamCreateDto = {
      "name": name,
      "owner": userId
    } ;
  
  //console.log('UpdateViewBucket URL: ', { url: urlResource });
  return await CallService<ASDtos.TeamReadDto>(urlResource, "POST", payload);
}

export async function Delete(teamId: number) : Promise<ASDtos.TeamReadDto> {
  var urlResource = `/teams/${teamId}`;

  //console.log('UpdateViewBucket URL: ', { url: urlResource });
  return await CallService<ASDtos.TeamReadDto>(urlResource, "DELETE", null);
}


export async function GetAgents(teamId: number): Promise<ASDtos.AgentReadDto[]> {
  var urlResource = `/teams/${teamId}/agents`;
  //console.log('GetAllVersions URL: ', { url: urlResource });

    return await CallService<ASDtos.AgentReadDto[]>(urlResource);
}

export async function RemoveAgent(teamId: number, agentId: number): Promise<ASDtos.AgentReadDto> {
  var urlResource = `/teams/${teamId}/agents/${agentId}`;
  //console.log('GetAllVersions URL: ', { url: urlResource });
  return await CallService<ASDtos.AgentReadDto>(urlResource, "DELETE", null);
}
export async function AddAgent(teamId: number, agentId: number): Promise<ASDtos.AgentReadDto> {
  var urlResource = `/teams/${teamId}/agents/${agentId}`;
  //console.log('GetAllVersions URL: ', { url: urlResource });

  return await CallService<ASDtos.AgentReadDto>(urlResource, "PUT", null);
}