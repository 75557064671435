import { useEffect, useState } from 'react'; // let's also import Component

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { CanvasBarEntry } from 'app/AppValues';
import { ASGateway, ASDtos } from 'api/AppServices';

import CanvasViewNetworkSelector from 'components/Canvas/CanvasView/CanvasViewNetworkSelector';
import CanvasViewNodeSearch from 'components/Canvas/CanvasView/CanvasViewNodeSearch';

import {
  Stack,
  Paper,
  Divider,
  Popover,
} from '@mui/material';

import NearMeIcon from '@mui/icons-material/NearMe';
import SquareIcon from '@mui/icons-material/CropSquare';
import CircleIcon from '@mui/icons-material/RadioButtonUnchecked';
import ConnectorIcon from '@mui/icons-material/SwapCalls';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SearchIcon from '@mui/icons-material/Search';

type ICanvasViewBarProps = {
  canvasViewId: number,
  coordX: number,
  coordY: number,
  onChangeSelection: (canvasBarSelection: CanvasBarEntry) => void;
  onChangeView: (canvasViewId: number) => void;
  onChangeViewNodeAdded: (nodeAdded: ASDtos.NodeReadDto) => void;
};

export default function CanvasViewBar(props: ICanvasViewBarProps) {
  //Redux States
  const dispatch = useAppDispatch();
  const version = useAppSelector((state) => state.systemBar.version);
  const environment = useAppSelector((state) => state.systemBar.environment);
  //Redux States


  const [canvasViewId, setCanvasViewId] = useState<number>(props.canvasViewId);
  const [canvasBarSelection, setCanvasBarSelection] = useState<CanvasBarEntry>(CanvasBarEntry.POINTER);

  const [anchorCanvasViewSelector, setAnchorCanvasViewSelector] = useState<HTMLElement | null>(null);
  const openCanvasViewSelector = Boolean(anchorCanvasViewSelector);

  const [anchorCanvasViewNodeSearch, setAnchorCanvasViewNodeSearch] = useState<HTMLElement | null>(null);
  const openCanvasViewNodeSearch = Boolean(anchorCanvasViewNodeSearch);

  function handleCanvasViewSelectorOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorCanvasViewSelector(event.currentTarget);
  }
  function handleCanvasViewSelectorClose() {
    setAnchorCanvasViewSelector(null);
  }

  function handleCanvasViewNodeSearchOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorCanvasViewNodeSearch(event.currentTarget);
  }
  function handleCanvasViewNodeSearchClose() {
    setAnchorCanvasViewNodeSearch(null);
  }


  function isDisabled() {
    if (version === 0) {
      return true;
    }
    if (version !== 0 && environment !== 0) {
      return true;
    }

    return false;
  }
  // OnLoad
  useEffect(() => {
    //console.log("version: ", version);
  }, [version]);

  useEffect(() => {
    props.onChangeSelection(canvasBarSelection);
  }, [canvasBarSelection]);

  // OnRender
  return (
    <>
      <Paper
        sx={{
          backgroundColor: "#FFFFFF",
          border: 0,
          padding: 0.5,
          //width: 40,
          position: "absolute",
          left: `${props.coordX + 10}px`,
          top: `${props.coordY + 80}px`,
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
        >
          <Paper
            sx={{
              p: 1,
              boxShadow: "none",
              ":hover": {
                backgroundColor: "#D4DBF5",
              }
            }}
            onClick={(e) => { handleCanvasViewSelectorOpen(e) }}
          >
            <SettingsEthernetIcon />
          </Paper>
          <Divider flexItem />
          <Paper
            sx={{
              p: 1,
              boxShadow: "none",
              backgroundColor: (canvasBarSelection === CanvasBarEntry.POINTER) ? "#D4DBF5" : "#FFFFFF",
              ":hover": {
                backgroundColor: "#D4DBF5",
              }
            }}
            onClick={() => setCanvasBarSelection(CanvasBarEntry.POINTER)}
          >
            <NearMeIcon />
          </Paper>
          <Paper
            sx={{
              p: 1,
              boxShadow: "none",
              ":hover": {
                backgroundColor: "#D4DBF5",
              },
              opacity: (isDisabled()) ? 0.20 : 1,
            }}
            //onClick={() => { return; }}
            onClick={(e) => { handleCanvasViewNodeSearchOpen(e) }}
          >
            <SearchIcon />
          </Paper>
          <Paper
            sx={{
              p: 1,
              boxShadow: "none",
              backgroundColor: (canvasBarSelection === CanvasBarEntry.PRODUCT) ? "#D4DBF5" : "#FFFFFF",
              ":hover": {
                backgroundColor: "#D4DBF5",
              },
              opacity: (isDisabled()) ? 0.20 : 1,
            }}
            onClick={() => { if (isDisabled()) { return; } setCanvasBarSelection(CanvasBarEntry.PRODUCT); }}
          >
            <SquareIcon />
          </Paper>
          <Paper
            sx={{
              p: 1,
              boxShadow: "none",
              backgroundColor: (canvasBarSelection === CanvasBarEntry.ENGINEER) ? "#D4DBF5" : "#FFFFFF",
              ":hover": {
                backgroundColor: "#D4DBF5",
              },
              opacity: (isDisabled()) ? 0.20 : 1,
            }}
            onClick={() => { if (isDisabled()) { return; } setCanvasBarSelection(CanvasBarEntry.ENGINEER); }}
          >
            <CircleIcon />
          </Paper>
          <Paper
            sx={{
              p: 1,
              boxShadow: "none",
              backgroundColor: (canvasBarSelection === CanvasBarEntry.CONNECTOR) ? "#D4DBF5" : "#FFFFFF",
              ":hover": {
                backgroundColor: "#D4DBF5",
              },
              opacity: (isDisabled()) ? 0.20 : 1,
            }}
            onClick={() => { if (isDisabled()) { return; } setCanvasBarSelection(CanvasBarEntry.CONNECTOR); }}
          >
            <ConnectorIcon />
          </Paper>
        </Stack>
      </Paper>

      <Popover
        id="canvas-view-selector-popover"
        open={openCanvasViewSelector}
        anchorEl={anchorCanvasViewSelector}
        onClose={handleCanvasViewSelectorClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CanvasViewNetworkSelector
          networkSelectedId={canvasViewId}
          onChangeSelection={(networkSelectedId) => {
            setCanvasViewId(networkSelectedId);
            handleCanvasViewSelectorClose();  
            props.onChangeView(networkSelectedId);
          }}
        />
      </Popover>

      <Popover
        id="canvas-view-node-search-popover"
        open={openCanvasViewNodeSearch}
        anchorEl={anchorCanvasViewNodeSearch}
        onClose={handleCanvasViewNodeSearchClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CanvasViewNodeSearch
          canvasViewId={canvasViewId}
          version={version}
          onSelectNode={(nodeSelected: ASDtos.NodeReadDto) => {
            //Add node to Canvas
            ASGateway.Networks.AddNode(canvasViewId, nodeSelected.id)
              .then(() => {
                handleCanvasViewNodeSearchClose();
                props.onChangeViewNodeAdded(nodeSelected);
              })
              .catch((e: any) => {
                console.error(e);
              });

          }}
        />
      </Popover>
    </>
  );
}