type ITextDynamicSizeAndFontResult = {
  textFont: string,
  textSize: number,
  textValues: string[]
};

export class TextHelper{
  constructor(){

  }
  private textSizeMax: number = 15;
  private textSizeMin: number = 8;
  private textPadding: number = 4;


  private getTextDynamicSizeAndFont(
    ctx: CanvasRenderingContext2D, 
    textValue: string, 
    objectWidth: number, 
    objectHeight: number, 
    textFont: string
  ): ITextDynamicSizeAndFontResult {

    var textSize = this.textSizeMax;
    
    ctx.font = textSize + "px " + textFont;
    var textMetrics = ctx.measureText(textValue);
    var actualHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
    var actualWidth = textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight;
    var textRatio = 1.1;
    while (actualWidth * textRatio > objectWidth || actualHeight * textRatio > objectHeight) {
      textSize = textSize - 1;
      ctx.font = textSize + "px " + textFont;
      textMetrics = ctx.measureText(textValue);
      actualHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
      actualWidth = textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight;
    }

    return {
      textFont: ctx.font,
      textSize: textSize,
      textValues: [textValue]
    } as ITextDynamicSizeAndFontResult;
  };

  drawTextDynamicSizeAndFont(
    ctx: CanvasRenderingContext2D, 
    textValue: string, 
    objectX: number, 
    objectY: number, 
    objectWidth: number, 
    objectHeight: number, 
    textFont: string = "sans-serif"
  ) : void{
    
    var isFirstRun = true;
    var currentSize = this.textSizeMax; //Initial Value
    var currentFont = ""; //Initial Value
    var currentTextValues = [{textValue: textValue, textSize: currentSize}] as {textValue: string, textSize: number}[];
    // var prevSize = currentSize + 1; //Initial value must be greater then initial current value to pass first run on Recursive Cycle
    var renderTexts = [] as {renderText: string, renderSize: number, renderFont: string}[];

    while (currentSize < this.textSizeMin || isFirstRun){ //&& prevSize > currentSize
      if(!isFirstRun){
        //When we have more than one run we must split text values array
        var splitResult: {textValue: string, textSize: number}[] = [];
        currentTextValues.forEach((currentTextValue: any, index: number) => {
          if (currentTextValue.textSize < this.textSizeMin){
            var splitBySpaces: any[] = currentTextValue.textValue.split(" ");
            var indexToSplit = Math.ceil(splitBySpaces.length / 2);

            var sliceHeadText = splitBySpaces.slice(0, indexToSplit) .map((value) => value).join(' ');
            var sliceTailText = splitBySpaces.slice(indexToSplit) .map((value) => value).join(' ');

            //Calculate the 2 new entries on the rendering text arrays
            //When index is not to split we acumulate iteration object directly into accumulate result
            splitResult = [...splitResult, 
                            {textValue: sliceHeadText, textSize: currentTextValue.textSize}, 
                            {textValue: sliceTailText, textSize: currentTextValue.textSize},
                          ];

          }
          else{
            //When index is not to split we acumulate iteration object directly into accumulate result
            splitResult = [...splitResult, currentTextValue];
          }
        });

        currentTextValues = splitResult;
      }

      //Calculate Dynamic Result for all entries of text
      renderTexts = [];
      var currentMinimumSizeFromValues = this.textSizeMax+1;
      var currentMinimumSizeFont = "";
      currentTextValues.forEach((currentTextValue: any, index: number) => {
        var dynamicTextResult = this.getTextDynamicSizeAndFont(
          ctx, 
          currentTextValue.textValue, 
          objectWidth, 
          objectHeight, 
          textFont
        );

        //Update Current Text Values
        currentTextValues[index] = {textValue: currentTextValue.textValue, textSize: dynamicTextResult.textSize};
        if (dynamicTextResult.textSize < currentMinimumSizeFromValues){
          //At first entry less then current minimum then break
          currentMinimumSizeFromValues = dynamicTextResult.textSize;
          currentMinimumSizeFont = dynamicTextResult.textFont;
        }

        //Acumulate values to render
        renderTexts = [...renderTexts, {renderText: currentTextValue.textValue, renderSize: dynamicTextResult.textSize, renderFont: dynamicTextResult.textFont}]
      });
      
      //Update values to have Stoping case recalculated
      isFirstRun = false;
      currentSize = currentMinimumSizeFromValues; //New Current is the result from calculation
      currentFont = currentMinimumSizeFont;
    }

    //Render Final results from Dynamic calculation
    var totalLines = renderTexts.length;
    var blockFactor = ((totalLines-1) * 0.7); //Serves to push up the startY value depending on the number of lines we have
    var startY = objectY + (objectHeight / ((totalLines+1) - blockFactor));

    //console.log("ctx.textAlign: ", ctx.textAlign);
    renderTexts.forEach((text: any, index: number) => {
      ctx.font = currentFont; //Last found font equals the minimum font to render all texts
      var renderX = objectX;
      if (ctx.textAlign === "center"){
        renderX = objectX + (objectWidth / 2);
      }
      var renderY = startY + ((currentSize + this.textPadding) * index);
      ctx.fillText(text.renderText, renderX, renderY);
    });
  }
}
