import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'

import { StoreRedux } from 'reducers/StoreRedux'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import NotificationContextProvider from 'components/ContextProviders/NotificationContextProvider';

import App from 'App';
import 'index.css';
import reportWebVitals from 'reportWebVitals';

import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/de';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={StoreRedux}>

    <LocalizationProvider 
      dateAdapter={AdapterDayjs} 
      adapterLocale={'en-gb'}
      //dateFormats={{ normalDate: "yyyy MM dd" }}
      //dateFormats={{ year: 'YY'}}
      // dateFormats={{
      //   fullDate: 'DD.MM.YYYY'
      // }}
    >
    
      <NotificationContextProvider>
        <App />
      </NotificationContextProvider>
    
    </LocalizationProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
