import React, { useEffect, useState } from 'react'; // let's also import Component
import { SubmitHandler } from "react-hook-form"

import { OverridableStringUnion } from '@mui/types';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  ButtonPropsColorOverrides,
  ButtonPropsVariantOverrides,
} from '@mui/material';

// export type UseFormHandleSubmit = (
//   onValid: () => unknown | Promise<unknown>
// ) => (e?: React.BaseSyntheticEvent) => Promise<void>;

type ISmartButtonProps = {
  children?: any,
  id?: string,
  disabled?: boolean,
  padding?: number,
  fullWidth?: boolean,
  color?: OverridableStringUnion<"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning", ButtonPropsColorOverrides> | undefined,
  variant?: OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides> | undefined,

  isSubmiting?: boolean,
  endIcon?: React.ReactNode,

  //onSubmitBefore?(): void,
  //onSubmit?(): unknown | Promise<unknown>;
  //onSubmitAfter?(): void,
  onSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void,
  //submitHandler?: () => unknown | Promise<unknown>;
  //onSubmit?: UseFormHandleSubmit
};

export default function SmartButton(props: ISmartButtonProps) {
  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [variant, setVariant] = useState<OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides> | undefined>("outlined");

  useEffect(() => {
    if (props.isSubmiting !== undefined) {
      setIsButtonSubmiting(props.isSubmiting);
    }
  }, [props.isSubmiting]);

  useEffect(() => {
    if (props.variant !== undefined) {
      setVariant(props.variant);
    }
  });

  return (
    <Box id={props.id} sx={{ p: 0.5 }}>
      <LoadingButton
        sx={{ p: (props.padding) ? props.padding : 0.5 }}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        color={props.color}
        variant={variant}

        loading={isButtonSubmiting}
        loadingPosition={(isButtonSubmiting) ? "center" : undefined}
        endIcon={props.endIcon}
        onClick={async (e) => {
          // console.log("setIsButtonSubmiting(true): ", isButtonSubmiting);
          // setIsButtonSubmiting(true);
          // if (props.onSubmit) {
          //   console.log("props.SubmitHandler: ", props.onSubmit);
          //   //await props.onSubmit();
          //   props.onSubmit();
          // }
          // console.log("setIsButtonSubmiting(false): ", isButtonSubmiting);
          // setIsButtonSubmiting(false);

          //console.log("setIsButtonSubmiting(true): ", isButtonSubmiting);
          //if (props.onSubmitBefore) props.onSubmitBefore();
          if (props.onSubmit) props.onSubmit(e);
          //if (props.onSubmitAfter) props.onSubmitAfter();
          //console.log("setIsButtonSubmiting(false): ", isButtonSubmiting);

        }}
      >
        <span>{props.children}</span>
      </LoadingButton>
    </Box>
  );
}