import React, { useEffect, useState } from 'react'; // let's also import Component

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import {
  Stack,
  Box,
} from '@mui/material';

import MainBarDrawer from 'components/MainBarDrawer';
import SelectorTeams from 'components/SelectorTeams';

type ITeamMainBarProps = {
  leftPadding: number,
  topPadding: number,
  onChangeTeams: (teamsSelected: number[]) => void
};

export default function TeamMainBar(props: ITeamMainBarProps) {
  //Redux States
  const dispatch = useAppDispatch();
  const userSession = useAppSelector((state) => state.appSession.user);
  // const version = useAppSelector((state) => state.systemBar.version);
  // const boardDrawerScreenWide = useAppSelector((state) => state.boardDrawer.screenWide);
  //Redux States

  // OnRender
  return (
    <MainBarDrawer leftPadding={props.leftPadding} topPadding={props.topPadding}>
      <Stack direction="row" justifyContent="center" alignItems="center">
          <SelectorTeams agentId={userSession.userId} onChangeTeams={(teamsSelected) => { props.onChangeTeams(teamsSelected); }} />
      </Stack>
    </MainBarDrawer>
  );
}