import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';

export async function GetAll() : Promise<ASDtos.NetworkReadDto[]> {
  var urlResource = `/networks`;
  return await CallService<ASDtos.NetworkReadDto[]>(urlResource);
}
export async function GetAllChildForNode(networkId: number, nodeId: number) : Promise<ASDtos.NetworkReadDto[]> {
  var urlResource = `/networks/${networkId}/nodes/${nodeId}/networks`;
  //console.log('Nodes.GetAll URL: ', { url: urlResource });
  return await CallService<ASDtos.NetworkReadDto[]>(urlResource);
}
export async function GetById(networkId: number) : Promise<ASDtos.NetworkReadDto> {
  var urlResource = `/networks/${networkId}`;
  //console.log('GetNodeById URL: ', { url: urlResource });
  return await CallService<ASDtos.NetworkReadDto>(urlResource);
}
export async function Create(networkCreateDto: ASDtos.NetworkCreateDto) : Promise<ASDtos.NetworkReadDto> {
  var urlResource = `/networks`;
  //console.log('CreateNode URL: ', { url: urlResource });
  return await CallService<ASDtos.NetworkReadDto>(urlResource, "POST", networkCreateDto);
}

export async function AddNode(networkId: number, nodeId: number) : Promise<ASDtos.NetworkReadDto> {
  var urlResource = `/networks/${networkId}/addnode/${nodeId}`;
  //console.log('CreateNode URL: ', { url: urlResource });
  return await CallService<ASDtos.NetworkReadDto>(urlResource, "POST", null);
}