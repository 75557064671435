import React, { useEffect, useState } from 'react'; // let's also import Component
import { useForm } from 'react-hook-form';

import { ASGateway, ASDtos } from 'api/AppServices';

import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box,
  TextField,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';

type IFormNewCanvasViewProps = {
  parentNodeId?: number,
  formIsDone: (newCanvasViewCreated: ASDtos.NetworkReadDto) => void;
};

export default function FormNewCanvasView(props: IFormNewCanvasViewProps) {
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'all' });

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [typeOptions, setTypeOptions] = useState<{ type: ASDtos.NetworkTypeDto, title: string, imgSrc: string }[]>([]);

  const [fieldType, setFieldType] = useState<ASDtos.NetworkTypeDto>(ASDtos.NetworkTypeDto.Services);
  const [fieldName, setFieldName] = useState<string>('');


  //Handles
  async function handleNewCanvasViewSubmit() {
    setIsButtonSubmiting(true);
    ASGateway.Networks.Create({
      type: fieldType,
      name: fieldName,
      parentNodeId: props.parentNodeId
    } as ASDtos.NetworkCreateDto)
      .then((data: ASDtos.NetworkReadDto) => {
        //console.log('CreateBoardColumn: ', data);
        props.formIsDone(data);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });      
  }

  // OnLoad
  useEffect(() => {
    //console.log("useEffect[FormNewVersion]");

    setTypeOptions([
      {
        type: ASDtos.NetworkTypeDto.Services,
        title: ASDtos.NetworkTypeDto[ASDtos.NetworkTypeDto.Services],
        imgSrc: "https://media.adeo.com/marketplace/MKP/82716603/025e9a965926057974860286badc1bbe.jpeg?width=650&height=650&format=jpg&quality=80&fit=bounds",
      },
      {
        type: ASDtos.NetworkTypeDto.Product,
        title: ASDtos.NetworkTypeDto[ASDtos.NetworkTypeDto.Product],
        imgSrc: "https://media.adeo.com/marketplace/MKP/82716165/9a8595218b18ab82a90ab24e481bd84a.jpeg?width=650&height=650&format=jpg&quality=80&fit=bounds", 
      },
      // {
      //   type: ASDtos.NetworkTypeDto.Product,
      //   title: ASDtos.NetworkTypeDto[ASDtos.NetworkTypeDto.Product],
      //   imgSrc: "https://media.adeo.com/marketplace/MKP/82716165/9a8595218b18ab82a90ab24e481bd84a.jpeg?width=650&height=650&format=jpg&quality=80&fit=bounds", 
      // },
      // {
      //   type: ASDtos.NetworkTypeDto.Product,
      //   title: ASDtos.NetworkTypeDto[ASDtos.NetworkTypeDto.Product],
      //   imgSrc: "https://media.adeo.com/marketplace/MKP/82716165/9a8595218b18ab82a90ab24e481bd84a.jpeg?width=650&height=650&format=jpg&quality=80&fit=bounds", 
      // },
    ]);

  }, []);

  // OnRender
  return (
    <Box id='canvasViewForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleNewCanvasViewSubmit)} sx={{ padding: 0, width: "100%", height: "100%" }}>
      <TextField
        required
        id="canvasViewName"
        key="canvasViewName"
        label="View Name"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('canvasViewName', {
          required: { value: true, message: 'Field is Required.' },
          maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
          onChange: (e) => setFieldName(e.target.value),
        })}
        value={fieldName}
        error={errors?.canvasViewName ? true : false}
        helperText={errors?.canvasViewName?.message?.toString()}
      />
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
      >
        <ImageList sx={{ width: "100%", height: "100%" }} cols={typeOptions.length}>
          {typeOptions.map((item) => {
            var isSelected = fieldType === item.type;
            return (
            <ImageListItem 
              key={item.imgSrc}
              sx={{ 
                border: (isSelected)?3:0,
                borderColor: "#D4DBF5"
              }}
              onClick={(e) => { e.stopPropagation(); e.preventDefault(); setFieldType(item.type) }}              
            >
              <img
                srcSet={`${item.imgSrc} 2x`}
                src={`${item.imgSrc}`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                sx={{ 
                  height: 50,
                  backgroundColor: (isSelected)?"rgba(212, 219, 245, 0.60)": "rgba(0, 0, 0, 0.15)",
                  "& .MuiImageListItemBar-title": { color: (isSelected)?"black":"black" },
                }}
                title={item.title}
              />
            </ImageListItem>
          )})}
        </ImageList>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <br /><br /><br />
        <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleNewCanvasViewSubmit)}>Create</SmartButton>
      </Stack>
    </Box>
  );
}