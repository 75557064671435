import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';

export async function GetAll(teamId: number) : Promise<ASDtos.BoardReadDto[]> {
  var urlResource = `/teams/${teamId}/boards`;
  //console.log('GetAllBoards URL: ', { url: urlResource });
  return await CallService<ASDtos.BoardReadDto[]>(urlResource);
}

export async function Create(teamId: number, boardDto: ASDtos.BoardCreateDto) : Promise<ASDtos.BoardReadDto> {
  var urlResource = `/teams/${teamId}/boards`;
  //console.log('CreateBoard URL: ', { url: urlResource });
  return await CallService<ASDtos.BoardReadDto>(urlResource, "POST", boardDto);
}

