import React, { useEffect, useState } from 'react'; // let's also import Component
import { useForm } from 'react-hook-form';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import { ASGateway, ASDtos } from 'api/AppServices';

import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';

type IFormNewVersionProps = {
  formIsDone: (newVersionCreated: ASDtos.VersionReadDto) => void;
};

export default function FormNewVersion(props: IFormNewVersionProps) {
    //Redux States
    const dispatch = useAppDispatch();
    const teams = useAppSelector((state) => state.systemBar.teams);
    //Redux States

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'all' });

  const [versions, setVersions] = useState<ASDtos.VersionReadDto[]>([]);

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [fieldParentVersion, setFieldParentVersion] = useState<number | "">("");
  const [fieldVersionName, setFieldVersionName] = useState('');

  //Handles
  async function handleNewVersionSubmit() {
    setIsButtonSubmiting(true);
    ASGateway.Versions.Create(
      teams[0],
      Number(fieldParentVersion),
      fieldVersionName
    )
      .then((data: ASDtos.VersionReadDto) => {
        //console.log('CreateBoardColumn: ', data);
        props.formIsDone(data);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });
  }

  useEffect(() => {
    ASGateway.Versions.GetAll(teams[0])
      .then((data: ASDtos.VersionReadDto[]) => {
        //console.log('GetAllVersions: ', data);
        setVersions(data);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }, []);

  function GetVersionElements(versions: ASDtos.VersionReadDto[], level: number) {
    let returnValues: any = [];
    versions.map((v: ASDtos.VersionReadDto) => {
      const spacing = Array.from(Array(4 * level), () => '\u00A0');

      returnValues.push(
        <MenuItem key={v.id} value={v.id}>{spacing}{v.name}</MenuItem>
      );

      if (v.childs.length > 0) {
        returnValues.push(...GetVersionElements(v.childs, level + 1));
      }
    });
    return returnValues;
  }

  // OnRender
  return (
    <Box id='versionForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleNewVersionSubmit)} sx={{ width: "100%", height: "100%" }}>
      <FormControl 
        fullWidth autoFocus margin="dense" variant='filled' size='small'
      >
        <InputLabel id="version-select-label">Version</InputLabel>
        <Select
          labelId="version-select-label"
          id="version-select"
          value={fieldParentVersion}
          label="Version"
          onChange={(event: any) => {
            setFieldParentVersion(Number(event.target.value));
          }}
        >
          {GetVersionElements(versions, 0)}
        </Select>
      </FormControl>

      <TextField
        required
        id="versionName"
        key="versionName"
        label="Version Name"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('versionName', {
          required: { value: true, message: 'Field is Required.' },
          maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
          onChange: (e) => setFieldVersionName(e.target.value),
        })}
        value={fieldVersionName}
        error={errors?.versionName ? true : false}
        helperText={errors?.versionName?.message?.toString()}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <br /><br /><br />
        <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleNewVersionSubmit)}>Create</SmartButton>
      </Stack>
    </Box>
  );
}