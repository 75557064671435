import { useContext } from 'react';
import { NotificationContext, INotification } from 'components/ContextProviders/NotificationContextProvider';

export function useNotification(timeout?: number | undefined) {
    const {notifications, addNotification: originalAddNotification, removeNotification} = useContext(NotificationContext);

    function addNotification(notification: INotification) {
      originalAddNotification(notification)
        let appliedTimeout = notification.timeout ?? timeout;
        if (appliedTimeout && appliedTimeout > 0){
          setTimeout(() => removeNotification(notification), appliedTimeout)
        }
    }

    return {notifications, addNotification, removeNotification};
};

export type { INotification }
export default useNotification;