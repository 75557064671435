import useNotification from 'hooks/NotificationHooks';

import {
  Stack,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';


export default function NotificationBar() {
  const { notifications, removeNotification } = useNotification();

  return (
    <Stack>
      {notifications.map((notification, index) => {
        return (
          <Box key={index}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={true}
              autoHideDuration={5000} //Default timeout (5sec) for App if  toast timeout is defined it will be overriden
              onClose={() => removeNotification(notification)}
            >
              <Alert
                severity={notification.severity}
                onClose={() => removeNotification(notification)}
              >
                {notification.message}
              </Alert>
            </Snackbar>
          </Box>
        )
      }
      )}
    </Stack>
  );
}