//https://dev.to/soorajsnblaze333/queuing-api-25oa
export default class RequestQueue {
  static workingOnPromise: boolean = false;
  static pendingPromise = false;
  static current_queue: any[] = [];
  static retryTimeout: any = null;


  static queue<T>(request: any) {
    const result = new Promise<T>((resolve, reject) => {
      RequestQueue.current_queue.push({
        request,
        resolve,
        reject,
      });
      RequestQueue.dequeue();
    });
    //console.log("QUEUE current_queue: ", [...RequestQueue.current_queue]);
    return result;
  }

  static retry() {
    clearTimeout(RequestQueue.retryTimeout);
    console.log("Trying to connect to the internet!");
    RequestQueue.retryTimeout = setTimeout(() => RequestQueue.dequeue(), 5000);
  }

  static afterPromise = (item: any, resolve = false, data: any) => {
    this.workingOnPromise = false;
    if (resolve) item.resolve(data);
    else item.reject(data);
    RequestQueue.dequeue();
  }

  static dequeue() {
    if (this.workingOnPromise) {
      //console.log("DEQUEUE workingOnPromise current_queue: ", [...RequestQueue.current_queue]);
      return false;
    }
    if (window.navigator && !window.navigator.onLine) {
      return RequestQueue.retry();
    }
    const item = RequestQueue.current_queue.shift();
    //console.log("DEQUEUE shift current_queue: ", [...RequestQueue.current_queue]);
    if (!item) {
      return false;
    }
    try {
      this.workingOnPromise = true;
      item.request()
        .then((value: any) => RequestQueue.afterPromise(item, true, value))
        .catch((error: any) => RequestQueue.afterPromise(item, false, error))
    } catch (error) {
      RequestQueue.afterPromise(item, false, error);
    }
    return true;
  }
  // static debug() {
  //   return new Promise((res) => setTimeout(
  //     () => res("Completed")
  //   , 2000))
  // }
}