import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';

export async function GetAll(boardId: number) : Promise<ASDtos.BoardViewReadDto[]> {
  var urlResource = `/boards/${boardId}/views`;
  //console.log('GetAllBoardColumns URL: ', { url: urlResource });
  return await CallService<ASDtos.BoardViewReadDto[]>(urlResource);
}

export async function GetColumns(boardId: number, boardViewId: number) : Promise<ASDtos.BoardViewColumnReadDto[]> {
  var urlResource = `/boards/${boardId}/views/${boardViewId}/columns`;
  //console.log('GetAllBoardViewColumns URL: ', { url: urlResource });
  return await CallService<ASDtos.BoardViewColumnReadDto[]>(urlResource);
}

export async function CreateColumn(
  boardId: number, 
  boardViewId: number, 
  boardColumnDto: ASDtos.BoardViewColumnCreateDto
) : Promise<ASDtos.BoardViewColumnReadDto> {

  var urlResource = `/boards/${boardId}/views/${boardViewId}/columns`;
  //console.log('CreateBoardColumn URL: ', { url: urlResource });
  return await CallService<ASDtos.BoardViewColumnReadDto>(urlResource, "POST", boardColumnDto);
}

export async function UpdateColumnPosition(
  boardId: number,
  viewId: number,
  columnId: number,
  increment: number
): Promise<ASDtos.BoardViewColumnReadDto> {

  var incrementResource = '';
  if (increment > 0) {
    incrementResource = 'upposition';
  }
  else {
    incrementResource = 'downposition';
  }

  var urlResource = `/boards/${boardId}/views/${viewId}/columns/${columnId}/${incrementResource}`;
  //console.log('UpdateBoardColumnPosition URL: ', { url: urlResource });
  return await CallService<ASDtos.BoardViewColumnReadDto>(urlResource, "PUT", {});
}

export async function DeleteColumn(
  boardId: number,
  viewId: number,
  columnId: number,
  newColumnId: number
) : Promise<ASDtos.BoardViewColumnReadDto> {
  var urlResource = `/boards/${boardId}/views/${viewId}/columns/${columnId}/tasksinto/${newColumnId}`;
  //console.log('DeleteBoardColumn URL: ', { url: urlResource });
  return await CallService<ASDtos.BoardViewColumnReadDto>(urlResource, "DELETE");
}

export async function GetTasksForColumn(boardId: number, boardViewId: number, boardViewColumnId: number) : Promise<ASDtos.TaskReadDto[]> {
  var urlResource = `/boards/${boardId}/views/${boardViewId}/columns/${boardViewColumnId}/tasks`;
  //console.log('GetAllTasksForBoardColumn URL: ', { url: urlResource });
  return await CallService<ASDtos.TaskReadDto[]>(urlResource);
}