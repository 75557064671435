export const AppUtils = {
  GetCompanyDomain() {
    const host = window.location.host;
    const parts = host.split(".");
    var domain = parts[0];

    if (domain.startsWith("localhost")){
      domain = "localdev";
    }

    return domain;
  },
  GetToolName(domain: string){
    var name = "Skalo.me";
    if (domain === "localdev"){
      name = "Skalo.dev";
    }
    return name;
  },
  toPascalCase(text: string): string{
    return text.replace(/\w+/g, //separate by words and apply function
        function(w){
          //Each word 
          //gets first Char and apply UpperCase
          //gets rest of word and apply LowerCase
          return w[0].toUpperCase() + w.slice(1).toLowerCase();
        });
  },
}