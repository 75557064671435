import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import useNotification, { INotification } from 'hooks/NotificationHooks';

import { createUserSession } from "reducers/appSessionReducer";

import { AppUtils } from 'app/AppUtils';
import { ASDtos, ASGateway } from "api/AppServices";


import SmartButton from 'components/SmartButton';
import {
  Avatar,
  Box,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Divider
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import KeyIcon from '@mui/icons-material/Key';

export default function FormAuthCredentialsReset() {
  //Redux States
  const dispatch = useAppDispatch();
  const userSession = useAppSelector((state) => state.appSession.user);
  //Redux States
  const notification = useNotification(); //Miliseconds
  const redirect = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'all' });

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [email, setEmail] = useState("");

  const [passwordCurrent, setPasswordCurrent] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [passwordNewConfirm, setPasswordNewconfirm] = useState("");

  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordNewConfirm, setShowPasswordNewConfirm] = useState(false);

  function onChangeEmail(e: any) {
    const userEmail = e.target.value;
    setEmail(userEmail);
  };
  function onChangePasswordCurrent(e: any) {
    const userPassword = e.target.value;
    setPasswordCurrent(userPassword);
  };
  function onChangePasswordNew(e: any) {
    const userPassword = e.target.value;
    setPasswordNew(userPassword);
  };
  function onChangePasswordNewConfirm(e: any) {
    const userPassword = e.target.value;
    setPasswordNewconfirm(userPassword);
  };


  function handleReset() {
    setIsButtonSubmiting(true);
    const domain = AppUtils.GetCompanyDomain();

    ASGateway.Authentication.ResetCredentialsAgent({
      companyDomain: domain,
      userEmail: email,
      userPasswordCurrent: passwordCurrent,
      userPasswordNew: passwordNew,
      userPasswordNewConfirm: passwordNewConfirm
    } as ASDtos.AuthenticationCredentialsResetDto
    )
      .then((data) => {
        dispatch(createUserSession(data));
        redirect("/");
      })
      .catch((e) => {
        notification.addNotification({
          severity: "error",
          message: e.message,
        } as INotification);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });
  };

  useEffect(() => {
  }, []);

  return (
    <Box id='resetForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleReset)} style={{ padding: 0 }}>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
        <Avatar alt="profile-img">
          <PersonIcon />
        </Avatar>
        <TextField variant="outlined" fullWidth={true}
          id="email"
          key="email"
          placeholder="Email"
          sx={{
            input: {
              px: 1,
              backgroundColor: "#ffffff"
            },
          }}
          {...register("email", {
            required: { value: true, message: 'Field Required' },
            pattern: {
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: 'Invalid email address',
            },
            onChange: (e) => onChangeEmail(e),
          })}
          value={email}
          error={errors.email ? true : false}
          helperText={errors.email?.message?.toString()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            )
          }}
        />
        <TextField variant="outlined" fullWidth={true}
          type={showPasswordCurrent ? 'text' : 'password'}
          id="passwordCurrent"
          key="passwordCurrent"
          placeholder="Current password"
          sx={{
            input: {
              px: 1,
              background: "#ffffff"
            }
          }}
          {...register("passwordCurrent", {
            required: { value: true, message: 'Field Required' },
            maxLength: { value: 20, message: 'Max Length 20' },
            onChange: (e) => onChangePasswordCurrent(e),
          })}
          value={passwordCurrent}
          error={errors.passwordCurrent ? true : false}
          helperText={errors.passwordCurrent?.message?.toString()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ p: 0, }}>
                <KeyIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment sx={{ backgroundColor: "#ffffff" }} position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPasswordCurrent((current) => !current)}
                  edge="end"
                  sx={{
                    background: "#ffffff"
                  }}
                >
                  {showPasswordCurrent ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Divider />
        <TextField variant="outlined" fullWidth={true}
          type={showPasswordNew ? 'text' : 'password'}
          id="passwordNew"
          key="passwordNew"
          placeholder="New password"
          sx={{
            input: {
              px: 1,
              background: "#ffffff"
            }
          }}
          {...register("passwordNew", {
            required: { value: true, message: 'Field Required' },
            maxLength: { value: 20, message: 'Max Length 20' },
            onChange: (e) => onChangePasswordNew(e),
          })}
          value={passwordNew}
          error={errors.passwordNew ? true : false}
          helperText={errors.passwordNew?.message?.toString()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ p: 0, }}>
                <KeyIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment sx={{ backgroundColor: "#ffffff" }} position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPasswordNew((current) => !current)}
                  edge="end"
                  sx={{
                    background: "#ffffff"
                  }}
                >
                  {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField variant="outlined" fullWidth={true}
          type={showPasswordNewConfirm ? 'text' : 'password'}
          id="passwordNewConfirm"
          key="passwordNewConfirm"
          placeholder="Confirm New password"
          sx={{
            input: {
              px: 1,
              background: "#ffffff"
            }
          }}
          {...register("passwordNewConfirm", {
            required: { value: true, message: 'Field Required' },
            maxLength: { value: 20, message: 'Max Length 20' },
            onChange: (e) => onChangePasswordNewConfirm(e),
          })}
          value={passwordNewConfirm}
          error={errors.passwordNewConfirm ? true : false}
          helperText={errors.passwordNewConfirm?.message?.toString()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ p: 0, }}>
                <KeyIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment sx={{ backgroundColor: "#ffffff" }} position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPasswordNewConfirm((current) => !current)}
                  edge="end"
                  sx={{
                    background: "#ffffff"
                  }}
                >
                  {showPasswordNewConfirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <SmartButton fullWidth isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleReset)}>Reset Password</SmartButton>
      </Stack>
    </Box>
  );
};
