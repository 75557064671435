import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';
import AppServicesQueue from 'api/AppServices/AppServicesQueue';


export async function GetAll(teamId: number, runOnBackground: boolean = false): Promise<ASDtos.VersionReadDto[]> {
  var urlResource = `/teams/${teamId}/versions`;
  //console.log('GetAllVersions URL: ', { url: urlResource });

  var result: ASDtos.VersionReadDto[] = [];

  if (runOnBackground) {
    //Use Queue
    // console.log("GetAllVersions QUEUE");
    await AppServicesQueue.queue<ASDtos.VersionReadDto[]>(() =>
      CallService<ASDtos.VersionReadDto[]>(urlResource)
        .then((data: ASDtos.VersionReadDto[]) => {
          result = unNuliffyDates(data);
        })
    );
  }
  else {
    //Call Directly
    // console.log("GetAllVersions REQUEST");
    await CallService<ASDtos.VersionReadDto[]>(urlResource)
      .then((data: ASDtos.VersionReadDto[]) => {
        result = unNuliffyDates(data);
      })
  }

  return new Promise<ASDtos.VersionReadDto[]>((resolve, reject) => {
    if (result.length > 0) {
      resolve(result);
    }
    else {
      reject("Empty Result");
    }
  });

  //Private function for GetAll
  function unNuliffyDates(data: ASDtos.VersionReadDto[]): ASDtos.VersionReadDto[] {
    var resultUnNuliffy: ASDtos.VersionReadDto[] = [];

    data.forEach((version: ASDtos.VersionReadDto) => {
      //console.log(`version[${version.name}]: `, version);
      if (!version.startDate) {
        version.startDate = undefined;
      }
      if (!version.endDate) {
        version.endDate = undefined;
      }
      resultUnNuliffy = [...resultUnNuliffy, version];
    });

    return resultUnNuliffy;
  }
}

export async function Create(teamId: number, parentVersion: number, name: string): Promise<ASDtos.VersionReadDto> {
  var urlResource = `/teams/${teamId}/versions`;
  const payload = {
    "parentVersion": parentVersion,
    "name": name,
  } as ASDtos.VersionCreateDto

  //console.log('CreateVersion URL: ', { url: urlResource });
  return await CallService(urlResource, "POST", payload);
}
export async function Update(
  teamId: number,
  versionId: number,
  name: string,
  rank: number,
  versionsToUpdateRank: ASDtos.VersionUpdateRankDto[],
  changeToFromBacklog: boolean,
  startDate?: Date,
  endDate?: Date
): Promise<ASDtos.VersionReadDto> {

  var urlResource = `/teams/${teamId}/versions/${versionId}`;
  const payload = {
    "name": name,
    "rank": rank,
    "startDate": (startDate) ? startDate : null,
    "endDate": (endDate) ? endDate : null,
    "versionsToUpdateRank": versionsToUpdateRank,
    "changeToFromBacklog": changeToFromBacklog
  } as ASDtos.VersionUpdateDto

  //console.log('Version Update Request: ', { url: urlResource, payload: payload });
  return await AppServicesQueue.queue<ASDtos.VersionReadDto>(() => CallService(urlResource, "PUT", payload));
}
