//https://react-redux.js.org/tutorials/typescript-quick-start
import { createSlice, PayloadAction  } from '@reduxjs/toolkit'
import { DetailContainerEntry, ScreenWideEntry } from 'app/AppValues';

// Define a type for the slice state
interface IDetailDrawerState {
  screenWide: ScreenWideEntry,
  detailContainer: DetailContainerEntry,
  detailContentId: number
}

// Define the initial state using that type
const initialState: IDetailDrawerState = {
  screenWide: ScreenWideEntry.HIDE,
  detailContainer: DetailContainerEntry.NONE,
  detailContentId: 0
}

export const detailDrawerState = createSlice({
  name: 'detailDrawerState',
  initialState: initialState,
  reducers: {
    setOpenDetailDrawer: (state, open: PayloadAction<boolean>) => {
      if(open.payload){
        state.screenWide = ScreenWideEntry.QUARTER;
      }
      else{
        state.screenWide = ScreenWideEntry.HIDE;
      }
    },
    setScreenWideDetailDrawer: (state, open: PayloadAction<ScreenWideEntry>) => {
      state.screenWide = open.payload;
    },
    setDetailContainer: (state, detailContainer: PayloadAction<DetailContainerEntry>) => {
      state.detailContainer = detailContainer.payload;
    },
    setDetailContentId: (state, detailContentId: PayloadAction<number>) => {
      state.detailContentId = detailContentId.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  setOpenDetailDrawer, 
  setScreenWideDetailDrawer,
  setDetailContainer, 
  setDetailContentId 
} = detailDrawerState.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
export default detailDrawerState.reducer