//https://react-redux.js.org/tutorials/typescript-quick-start
import { createSlice, PayloadAction  } from '@reduxjs/toolkit'
import { ScreenWideEntry } from 'app/AppValues'

// Define a type for the slice state
interface IBoardDrawerState {
  screenWide: ScreenWideEntry,
  taskIdIsDragging: number,
  packageIdDeployed: number
}

// Define the initial state using that type
const initialState: IBoardDrawerState = {
  screenWide: ScreenWideEntry.HIDE,
  taskIdIsDragging: 0,
  packageIdDeployed: 0
}

export const boardDrawerState = createSlice({
  name: 'boardDrawerState',
  initialState: initialState,
  reducers: {
    setOpenBoardDrawer: (state, open: PayloadAction<boolean>) => {
      if(open.payload){
        state.screenWide = ScreenWideEntry.HALF;
      }
      else{
        state.screenWide = ScreenWideEntry.HIDE;
      }
    },
    setScreenWideBoardDrawer: (state, open: PayloadAction<ScreenWideEntry>) => {
      state.screenWide = open.payload;
    },
    setTaskIdIsDragging: (state, taskId: PayloadAction<number>) => {
      state.taskIdIsDragging = taskId.payload;
    },
    setPackageIdDeployed: (state, packId: PayloadAction<number>) => {
      var newState = packId.payload;
      if (state.packageIdDeployed === newState){
        newState = newState * -1; //Trick to always trigger an event
      }
      state.packageIdDeployed = newState;
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  setOpenBoardDrawer, 
  setScreenWideBoardDrawer,
  setTaskIdIsDragging,
  setPackageIdDeployed
} = boardDrawerState.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
export default boardDrawerState.reducer