import React, { useEffect, useState } from 'react'; // let's also import Component

import DOMPurify from 'isomorphic-dompurify';
import HTMLParser from 'html-react-parser';

import { ASDtos } from 'api/AppServices';
import { DateUtils } from 'app/DateUtils';

import Editor from 'components/Editor';
import EditorBlockStatus from 'components/EditorBlockStatus';
import SmartButton from 'components/SmartButton';

import {
  Stack,
  Box,
  Grid,
  Chip,
} from '@mui/material';

interface IEditorViewDocumentHistoryProps {
  blocks: ASDtos.DocumentBlockReadDto[];
  formIsDone: () => void;
};

interface IHistoryLog extends ASDtos.DocumentBlockChangeReadDto {
  blockRef: ASDtos.DocumentBlockReadDto
}
export default function EditorViewDocumentHistory(props: IEditorViewDocumentHistoryProps) {

  const [history, setHistory] = useState<IHistoryLog[]>([]);

  // OnLoad
  useEffect(() => {
    let result: IHistoryLog[] = [];
    props.blocks?.forEach((block: ASDtos.DocumentBlockReadDto) => {
      block.history?.forEach((change: ASDtos.DocumentBlockChangeReadDto) => {
        result.push({ ...change, blockRef: block } as IHistoryLog);
      });
    });
    setHistory(result);
  }, [props.blocks])

  // OnRender
  return (
    <Box width={"100%"}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid container direction="row" item xs={12} sx={{ border: '1px solid', backgroundColor: "#D4DBF5" }}>
            <Grid item xs={1}>
              Date
            </Grid>
            <Grid item xs={2}>
              Agent
            </Grid>
            <Grid item xs={1}>
              Action
            </Grid>
            <Grid item xs={4}>
              Old Value
            </Grid>
            <Grid item xs={4}>
              New Value
            </Grid>
          </Grid>
          {history.map((historyLog: IHistoryLog, index: number) => {
            return (
              <Grid key={historyLog.apiRef} container direction="row" item xs={12} sx={{ borderBottom: '1px solid' }}>
                <Grid item xs={1}>
                  {DateUtils.toStringLocale(historyLog.created)}
                </Grid>
                <Grid item xs={2}>
                  {historyLog.creator}
                </Grid>
                <Grid item xs={1}>
                  <EditorBlockStatus block={historyLog.blockRef}/>
                </Grid>
                <Grid item xs={4}>
                  <Editor
                    editorId={"oldContent-" + index.toString()}
                    contentId={historyLog.apiRef}
                    readOnly={true}
                    markChanges={false}
                    showActions={false}
                    data={[1].map(() => {
                      return {
                        apiRef: 1,
                        data: historyLog.oldContent,
                        history: [],
                        isChanged: false,
                        isDeleted: false,
                        isNew: false,
                        order: 0,
                        type: historyLog.blockRef.type
                      } as ASDtos.DocumentBlockReadDto;
                    })}
                    onSave={() => { }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Editor
                    editorId={"newContent-" + index.toString()}
                    contentId={historyLog.apiRef}
                    readOnly={true}
                    markChanges={false}
                    showActions={false}
                    data={[1].map(() => {
                      return {
                        apiRef: 1,
                        data: historyLog.newContent,
                        history: [],
                        isChanged: false,
                        isDeleted: false,
                        isNew: false,
                        order: 0,
                        type: historyLog.blockRef.type
                      } as ASDtos.DocumentBlockReadDto;
                    })}
                    onSave={() => { }}
                  />
                </Grid>
              </Grid>
            )
          })
          }
        </Grid>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <br /><br /><br />
          <SmartButton onSubmit={() => { props.formIsDone() }}>Close</SmartButton>
        </Stack>
      </Stack>
    </Box>
  );
}