import { lazy as _lazy } from "react";

function lazy(importFn: Function) {
  return _lazy(async () => {
    const m = await importFn();
    return { default: m.ReactComponent };
  });
}

export const IconSvgList = {
  SettingsTimelapse: lazy(async () => import("../images/icons/google-fonts/settings_timelapse.svg")),
  StatZero: lazy(async () => import("../images/icons/google-fonts/stat_0.svg")),
  ViewKanban: lazy(async () => import("../images/icons/google-fonts/view_kanban.svg")),
  ViewCanvasDouble: lazy(async () => import("../images/icons/google-fonts/view_column_double.svg")),
  Backlog: lazy(async () => import("../images/icons/google-fonts/view_list.svg")),
  Package: lazy(async () => import("../images/icons/google-fonts/deployed_code.svg")),
  Board: lazy(async () => import("../images/icons/google-fonts/width_normal.svg")),
  MenuOpen: lazy(async () => import("../images/icons/google-fonts/menu_open.svg")),
  Spike: lazy(async () => import("../images/icons/google-fonts/data_exploration.svg")),
  Backspace:  lazy(async () => import("../images/icons/google-fonts/backspace.svg")),
  Close:  lazy(async () => import("../images/icons/google-fonts/close.svg")),
  Cancel:  lazy(async () => import("../images/icons/google-fonts/cancel.svg")),
  Add:  lazy(async () => import("../images/icons/google-fonts/add.svg")),

  StarBullet: lazy(async () => import("../images/icons/iconduck/star-medium.svg")),
};