import { useEffect, useState } from 'react'; // let's also import Component

import { ASGateway, ASDtos } from 'api/AppServices';

import FormNewCanvasView from 'components/Forms/FormNewCanvasView'

import {
  Box,
  MenuItem,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';

type ICanvasViewNetworkSelectorProps = {
  networkSelectedId: number,
  parentNodeId?: number,
  onChangeSelection: (networkSelectedId: number) => void;
};

export default function CanvasViewNetworkSelector(props: ICanvasViewNetworkSelectorProps) {
  const [networkSelectedId, setNetworkSelectedId] = useState<number>(props.networkSelectedId);
  const [parentNodeId, setParentNodeId] = useState<number | undefined>(props.parentNodeId);
  const [networks, setNetworks] = useState<ASDtos.NetworkReadDto[]>([]);
  const [showNewCanvasViewForm, setShowNewCanvasViewForm] = useState<boolean>(false);
  
  const newCanvasTitle: string = (props.parentNodeId)?"New Child":"New View";

  // OnLoad
  useEffect(() => {
    //console.log("CanvasViewSelector useEffect[canvasViewSelectedId]: ", props.canvasViewSelectedId);
    if (parentNodeId) {
      ASGateway.Networks.GetAllChildForNode(networkSelectedId, parentNodeId)
        .then((data: ASDtos.NetworkReadDto[]) => {
          //console.log("Networks: ", data);
          setNetworks(data);
        })
        .catch((e) => {
          console.error(e);
        });

    }
    else {
      ASGateway.Networks.GetAll()
        .then((data: ASDtos.NetworkReadDto[]) => {
          //console.log("Networks: ", data);
          setNetworks(data);
        })
        .catch((e) => {
          console.error(e);
        });
    }

  }, []);

  // OnRender
  return (
    <Box id="network-select">
      {networks.map((network: ASDtos.NetworkReadDto) => {
        return (
          <MenuItem
            key={network.id}
            value={network.id}
            selected={network.id === networkSelectedId}
            disabled={network.id === networkSelectedId}
            onClick={() => {
              //console.log('HOME::CanvasView::CanvasBar::CanvasViewNetworkSelector::MenuItem::network.id: ', network.id);
              //console.log('HOME::CanvasView::CanvasBar::CanvasViewNetworkSelector::MenuItem::networkSelectedId: ', networkSelectedId);
              //console.log('HOME::CanvasView::CanvasBar::CanvasViewNetworkSelector::MenuItem::onClick: ', network.id !== networkSelectedId);

              if (network.id !== networkSelectedId) {
                //console.log("Trigger onChangeSelection");
                props.onChangeSelection(network.id)
              }
            }}
          >{network.name}</MenuItem>
        )
      })}
      <Divider />
      <MenuItem sx={{ m: 0 }} key={""} value={""}><Button sx={{ p: 0, m: 0 }} fullWidth size='small' onClick={() => setShowNewCanvasViewForm(true)}>{newCanvasTitle}</Button></MenuItem>

      {/* Create New CANVAS VIEW Dialog */}
      <Dialog fullScreen={false} open={showNewCanvasViewForm} onClose={() => setShowNewCanvasViewForm(false)}>
        <DialogTitle>{newCanvasTitle}</DialogTitle>
        <DialogContent sx={{ p: 1, width: "500px", maxHeight: "500px" }}>
          <FormNewCanvasView
            parentNodeId={parentNodeId}
            formIsDone={(newNetworkCreated) => {
              //Refresh List
              setNetworks([...networks, newNetworkCreated]);

              //Make new Empty Canvas View as new selection
              props.onChangeSelection(newNetworkCreated.id)
              setShowNewCanvasViewForm(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}