import React, { useEffect, useMemo, useState } from 'react'; // let's also import Component
import parse from 'autosuggest-highlight/parse';

import { ASGateway, ASDtos } from '../api/AppServices';

import {
  Box,
  TextField,
  Autocomplete,
  Grid,
  debounce
} from '@mui/material';

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

type IAutoCompleteTasksProps = {
  id: string,
  required?: boolean | undefined,
  valueGetter: (value: number | null) => void,
  error?: boolean,
  helperText?: string | undefined
};


export default function AutoCompleteTasks(
  taskTypeProps: IAutoCompleteTasksProps,
  ...props: any[]
) {
  const [task, setTask] = useState<ASDtos.TaskReadDto | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly ASDtos.TaskReadDto[]>([]);

  const fetchTasks = useMemo(
    () =>
      debounce(
        (
          request: { searchTerm: string },
          callback: (results?: ASDtos.TaskReadDto[]) => void,
        ) => {
          ASGateway.Tasks.Search(request.searchTerm)
            .then((tasks: ASDtos.TaskReadDto[]) => {
              callback(tasks);
            })
            .catch((e: any) => {
              console.error(e);
            });
        },
        400,
      ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(task ? [task] : []);
      return undefined;
    }

    fetchTasks(
      //debounce.request
      { searchTerm: inputValue }, 
      //debounce.callBack
      (results?: readonly ASDtos.TaskReadDto[]) => { 
        //console.log("AutoCompleteTasks results: ", results);
        if (active) {
          let newOptions: readonly ASDtos.TaskReadDto[] = [];

          if (task) {
            newOptions = [task];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });

    return () => {
      active = false;
    };
  }, [task, inputValue, fetch]);

  useEffect(() => {
    let active = true;
    return () => {
      active = false;
    };
  }, [taskTypeProps]);

  return (
    <Autocomplete
      id={taskTypeProps.id}
      key={taskTypeProps.id}
      sx={{ width: "100%" }}
      getOptionLabel={(option: ASDtos.TaskReadDto) =>
        typeof option === 'string' ? option : option.title
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={task}
      //value={taskTypeProps.value}
      noOptionsText="No Tasks found. Please refine your search."
      onChange={(event: any, newValue: ASDtos.TaskReadDto | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setTask(newValue);
        taskTypeProps.valueGetter((newValue) ? newValue.id : null);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} 
          required={taskTypeProps.required}
          fullWidth 
          label="Search a Task"
          error={taskTypeProps.error}
          helperText={taskTypeProps.helperText}
        />
      )}
      renderOption={(props, option: ASDtos.TaskReadDto) => {
        //TODO Have Matches parts from Service //option.structured_formatting.main_text_matched_substrings || [];
        //FOR NOW WE MATCH EVERY RESULT FULL TEXT
        //const matches: any[] = [{ offset: 0, length: option.title.length }];
        const matches: any[] = [{ offset: 0, length: 0 }];
        const parts = parse(
          option.title,
          matches,
        );

        return (
          <li 
            {...props}
          >
            <Grid container alignItems="center">
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                {/* <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography> */}
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
