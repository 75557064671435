import React, { useEffect, useState } from 'react'; // let's also import Component
import { useForm } from 'react-hook-form';

import { ASGateway, ASDtos } from 'api/AppServices';

import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box,
  TextField,
} from '@mui/material';

type IFormNewBoardColumnProps = {
  boardId: number,
  boardViewId: number,
  formIsDone: (newBoardColumnCreated: ASDtos.BoardViewColumnReadDto) => void;
};

export default function FormNewBoardColumn(props: IFormNewBoardColumnProps) {
  //Redux States
  //const dispatch = useAppDispatch();
  //Redux States

  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'all' });

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [fieldColumnName, setFieldColumnName] = useState('');

  //Handles
  async function handleNewColumnSubmit() {
    setIsButtonSubmiting(true);
    ASGateway.BoardViews.CreateColumn(
      props.boardId,
      props.boardViewId,
      { name: fieldColumnName, position: 2 } as ASDtos.BoardViewColumnCreateDto
    )
      .then((data: ASDtos.BoardViewColumnReadDto) => {
        //console.log('CreateBoardColumn: ', data);
        props.formIsDone(data);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });
  }

  // OnLoad
  useEffect(() => {
    //console.log("useEffect[FormNewBoardColumn]");
  }, []);

  // OnRender
  return (
    <Box id='columnForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleNewColumnSubmit)} sx={{ padding: 0, width: "100%", height: "100%" }}>
      <TextField
        required
        id="columnName"
        key="columnName"
        label="Column Name"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('columnName', {
          required: { value: true, message: 'Field is Required.' },
          maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
          onChange: (e) => setFieldColumnName(e.target.value),
        })}
        value={fieldColumnName}
        error={errors?.columnName ? true : false}
        helperText={errors?.columnName?.message?.toString()}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <br /><br /><br />
        <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleNewColumnSubmit)}>Create</SmartButton>
      </Stack>
    </Box>
  );
}