import React, { useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import { ASGateway, ASDtos } from 'api/AppServices';

import {
  Box,
  Card,
} from '@mui/material';
import { Divider } from '@material-ui/core';

type IDetailContainerPackageProps = {
  packageId: number
};

export default function DetailContainerPackage(props: IDetailContainerPackageProps) {
  //Redux States
  const dispatch = useAppDispatch();
  //Redux States

  const [packData, setPackData] = useState<ASDtos.PackageReadDto | null>(null);
  const [showEditorResult, setShowEditorResult] = useState<boolean>(false);
  const [editorResult, setEditorResult] = useState<any>({});

  //Handles

  // OnLoad
  useEffect(() => {
    if (props.packageId > 0) {

      //console.log("Props: ", props);
      //Reset Container Data
      setPackData(null);

      //Load Node
      ASGateway.Packages.GetById(props.packageId)
        .then((data: ASDtos.PackageReadDto) => {
          //console.log('GetTaskById: ', data);
          if (data) {
            setPackData(data);
          }
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }, [props.packageId]);


  // OnRender
  return (
    <>
      <Card
        sx={{
          p: 1,
          minHeight: 50,
        }}
      >
        DETAIL CONTAINER PACKAGE
        {packData &&
          <Box style={{ textAlign: "left" }}>
            ID: #{packData.id}<br />
            Version: #{packData.version.id}{" => "}<b>{packData.version.name}</b>
            <br /><br />
            == Package Tasks ==               <br />
            {packData.tasks?.map((task: number) => {
              return (
                <Box key={task}>Task ID: {task}<br /></Box>
              )
            })}
            <br />            
            == Package Environments ==               <br />
            {packData.environments.sort((a, b) => a.id > b.id ? 1 : -1).map((env: ASDtos.EnvironmentReadDto) => {
              return (
                <Box key={env.id}>
                  <Box> Env. ID: {env.id}{" => "}<b>{env.name}</b><br /></Box>
                  <Divider/>
                </Box>
              )
            })}

          </Box>
        }
      </Card>
    </>
  );
}