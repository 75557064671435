import React, { useState } from 'react'; // let's also import Component
import { useForm } from 'react-hook-form';

import { useAppSelector } from 'hooks/StoreReduxHooks';
import { ASGateway, ASDtos } from 'api/AppServices';

import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box,
  TextField,
} from '@mui/material';

type IFormNewTeamProps = {
  formIsDone: (newTeamCreated: ASDtos.TeamReadDto) => void;
};

export default function FormNewTeam(props: IFormNewTeamProps) {
  //Redux States
  const userSession = useAppSelector((state) => state.appSession.user);
  //Redux States

  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'all' });

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [fieldName, setFieldName] = useState<string>("");

  //Handles
  async function handleNewTeamSubmit() {
    setIsButtonSubmiting(true);
    ASGateway.Teams.Create(
      userSession.userId,
      fieldName
    )
      .then((data: ASDtos.TeamReadDto) => {
        //console.log('CreateBoardColumn: ', data);
        props.formIsDone(data);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });
  }

  // OnRender
  return (
    <Box id='newTeamForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleNewTeamSubmit)} sx={{ width: "100%", height: "100%" }}>
      <TextField
        required
        id="fieldName"
        key="fieldName"
        label="Name"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('fieldName', {
          required: { value: true, message: 'Field is Required.' },
          maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
          onChange: (e) => setFieldName(e.target.value),
        })}
        value={fieldName}
        error={errors?.fieldName ? true : false}
        helperText={errors?.fieldName?.message?.toString()}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <br /><br /><br />
        <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleNewTeamSubmit)}>Create</SmartButton>
      </Stack>
    </Box>
  );
}