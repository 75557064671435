import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';

export async function GetAll(agentViewId: number, networkId: number, versionId: number) : Promise<ASDtos.NodeReadDto[]> {
  var urlResource = `/networks/${networkId}/versions/${versionId}/views/${agentViewId}/nodes`;
  //console.log('Nodes.GetAll URL: ', { url: urlResource });
  return await CallService<ASDtos.NodeReadDto[]>(urlResource);
}
export async function GetById(nodeId: number) : Promise<ASDtos.NodeReadDto> {
  var networkId = 0;
  var urlResource = `/networks/${networkId}/nodes/${nodeId}`;
  //console.log('GetNodeById URL: ', { url: urlResource });
  return await CallService<ASDtos.NodeReadDto>(urlResource);
}
export async function Create(agentViewId: number, networkId: number, versionId: number,
  level: number,
  name: string = "",
  posX: number,
  posY: number,
  width: number,
  height: number
): Promise<ASDtos.NodeReadDto> {
  var urlResource = `/networks/${networkId}/versions/${versionId}/nodes`;
  //console.log('CreateNode URL: ', { url: urlResource });
  //Build Node payload
  const nodeView = {
    posX: posX,
    posY: posY,
    width: width,
    height: height
  } as ASDtos.NodeViewCreateDto;

  const payload = {
    level: level,
    name: name,
    agentViewId: agentViewId,
    nodeView: nodeView
  } as ASDtos.NodeCreateDto;

  return await CallService<ASDtos.NodeReadDto>(urlResource, "POST", payload);
}

export async function Update(agentViewId: number, networkId: number, versionId: number, nodeId: number,
  name: string,
) : Promise<ASDtos.NodeReadDto> {

  var urlResource = `/networks/${networkId}/versions/${versionId}/nodes/${nodeId}`;
  //console.log('UpdateNodeView URL: ', { url: urlResource });
  //Build Node payload
  const payload = {
    name: name
  } as ASDtos.NodeUpdateDto;

  return await CallService<ASDtos.NodeReadDto>(urlResource, "PUT", payload);
}


export async function UpdateView(agentViewId: number, networkId: number, nodeId: number,
  versionId: number,
  posX: number,
  posY: number,
  width: number,
  height: number) : Promise<ASDtos.NodeViewReadDto> {
  var urlResource = `/networks/${networkId}/versions/${versionId}/nodes/${nodeId}/view`;
  //console.log('UpdateNodeView URL: ', { url: urlResource });
  //Build Node payload
  const payload = {
    posX: posX,
    posY: posY,
    width: width,
    height: height,
    agentViewId: agentViewId
  } as ASDtos.NodeViewUpdateDto;

  return await CallService(urlResource, "PUT", payload);
}

export async function Search(networkId: number, searchTerm: string)  : Promise<ASDtos.NodeSearchReadDto[]>{
  var urlResource = `/networks/${networkId}/nodes/search`;

    //console.log("Nodes.Search[searchTerm]: ", searchTerm);

    //Build Task payload
    const payload = {
      "searchTerms": searchTerm.split(" "),
    } as ASDtos.NodeSearchDto;;
  
  console.log('GetNodeById URL: ', { url: urlResource });
  return await CallService(urlResource, "POST", payload);
}

export async function GetAllTasksForNode(networkId: number, versionId: number, nodeId: number) : Promise<ASDtos.NodeTaskReadDto[]> {
  var urlResource = `/networks/${networkId}/versions/${versionId}/nodes/${nodeId}/tasks`;
  return await CallService(urlResource);
}
