import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';

export async function GetById(taskId: number) : Promise<ASDtos.TaskReadDto> {
  var urlResource = `/tasks/${taskId}`;
  //console.log('GetNodeById URL: ', { url: urlResource });
  return await CallService<ASDtos.TaskReadDto>(urlResource);
}

export async function Search(searchTerm: string) : Promise<ASDtos.TaskReadDto[]> {
  var urlResource = `/tasks/search`;

    //Build Task payload
    const payload = {
      "searchTerms": searchTerm.split(" "),
    };
  
  //console.log('GetNodeById URL: ', { url: urlResource });
  return await CallService<ASDtos.TaskReadDto[]>(urlResource, "POST", payload);
}

export async function Create(taskCreateDto: ASDtos.TaskCreateDto) : Promise<ASDtos.TaskReadDto> {
  var urlResource = `/tasks`;
  //console.log('CreateTask URL: ', { url: urlResource });
  return await CallService<ASDtos.TaskReadDto>(urlResource, "POST", taskCreateDto);
}

export async function AssignChanges(taskId: number, changes: number[]) : Promise<ASDtos.TaskReadDto> {
  var urlResource = `/tasks/${taskId}/changes`;

  //Build Node payload
  const payload : ASDtos.TaskUpdateAssignChangesDto = {
    "changes": changes
  };

  return await CallService<ASDtos.TaskReadDto>(urlResource, "PUT", payload);
}

export async function AssignNodes(taskId: number, nodes: number[]) : Promise<ASDtos.TaskReadDto> {
  var urlResource = `/tasks/${taskId}/nodes`;

  //Build Node payload
  const payload: ASDtos.TaskUpdateAssignNodesDto = {
    "nodes": nodes
  };

  return await CallService<ASDtos.TaskReadDto>(urlResource, "PUT", payload);
}


export async function AssignToColumn(
  assignFromBoardId: number,
  assignFromViewId: number,
  assignFromColumnId: number,
  taskId: number,
  assignToDto: ASDtos.TaskUpdateAssignToColumnDto
) : Promise<ASDtos.TaskReadDto> {
  
  var urlResource = `/boards/${assignFromBoardId}/views/${assignFromViewId}/columns/${assignFromColumnId}/tasks/${taskId}`;
  //console.log('UpdateTaskTransitionToColumn URL: ', { url: urlResource });

  //Build Node payload
  return await CallService<ASDtos.TaskReadDto>(urlResource, "PUT", assignToDto);
}