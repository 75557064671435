export enum CanvasBarEntry {
  NETWORK,
  POINTER,
  PRODUCT,
  ENGINEER,
  CONNECTOR,
};

export enum MouseEventEntry {
  VOID,
  RIGHTCLICK,
  LEFTCLICK,
  MIDDLECLICK,
};

export enum DetailContainerEntry {
  NONE,
  NODE,
  TASK,
  PACKAGE,
};

export enum ScreenWideEntry {
  HIDE,
  QUARTER,
  HALF,
  THREEQUARTERS,
  FULL
};

export enum DocumentTypeEntry {
  CONTENT,
  API
};

export enum CardTypeEntry {
  BACKLOG,
  BOARD,
  PACKAGE,
  PACKAGEENVIRONMENTSTATUS
};

export enum FormSprintModeEntry {
  CREATE,
  EDIT,
  DELETE,
  START,
  COMPLETE
};

export enum CanvasViewEntry {
  SINGLE,
  COLDOUBLE,
  COLTRIPLE,
  ROWDOUBLE,
  ROWTRIPLE,
  COLDOUBLE_ROWBOTTOM,
  COLDOUBLE_ROWTOP,
};

export enum CanvasNodeArea {
  NONE,
  DEFAULT,
  MOVEHORIZONTAL,
  MOVEVERTICAL,
  ACTION,
  SIZERLEFT,
  SIZERRIGHT,
  SIZERTOP,
  SIZERBOTTOM,
};

export enum DateUnit {
  YEAR,
  MONTH,
  DAY,
  HOUR,
  MINUTE,
  SECOND
};

export enum ContextSetup {
  GLOBAL,
  TEAM
}