/*
A typical strategic roadmap consists of several critical components:

Strategic objectives: These are the high-level goals your organization aims to achieve. They serve as the guiding north star for your roadmap.
Initiatives: These are major efforts or projects your organization will undertake to achieve the strategic objectives.
Actions: These are specific tasks related to each initiative. They represent the "how" of achieving each initiative and, ultimately, the strategic objectives.
Timelines: Timelines offer a temporal view of when initiatives and actions are expected to start and end, indicating progress and helping manage expectations.
Milestones: Significant events or achievements along the roadmap that indicate progress towards strategic objectives.
Dependencies and risks: These outline potential obstacles or factors that could impact the progression or success of your initiatives.
*/
import React, { useState, useEffect, useRef } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { setOpenDetailDrawer, setDetailContainer, setDetailContentId } from 'reducers/detailDrawerReducer';

import { MouseEventEntry, CanvasNodeArea } from 'app/AppValues';
import { ICalendarRangeProps, IRoadMapColumn } from 'app/AppInterfaces';
import { AppUtils } from 'app/AppUtils';
import { DateUtils } from 'app/DateUtils';

import { ASGateway, ASDtos } from 'api/AppServices';

import { ICanvasInitiative } from 'middleware/canvas/ICanvasInitiative';
import { ICanvasLink } from 'middleware/canvas/ICanvasLink';

import { CanvasLoader } from 'middleware/canvas/CanvasLoader';
import { InitiativeNode } from 'middleware/canvas/InitiativeNode';
import { ConnectorLink } from 'middleware/canvas/ConnectorLink';
import { MouseEvents } from 'middleware/canvas/MouseEvents';
import { CanvasMap } from 'middleware/canvas/CanvasMap';

import CanvasRoadmapBar from 'components/Canvas/CanvasRoadmap/CanvasRoadmapBar';
import CanvasViewDetailBarNode from 'components/Canvas/CanvasView/CanvasViewDetailBarNode';

import { Popper } from '@mui/material';

dayjs.extend(utc);
dayjs.extend(timezone);

type IRoadmapItem = {
  key: number,
  parentKey: number | null,
  rank: number,
  posY: number,
  initiative: ICanvasInitiative;
};
type ICanvasRoadmapFiltersProps = {
  teams: number[],
  versions: number[]
}

type ICanvasRoadmapProps = {
  canvasViewName: string,
  canvasWidth: number,
  canvasHeight: number,
  calendarRange: ICalendarRangeProps,
  filters: ICanvasRoadmapFiltersProps
  //onChangeView: (canvasViewId: number) => void;
};

export default function CanvasRoadmap(props: ICanvasRoadmapProps) {
  const canvasNodes: React.RefObject<HTMLCanvasElement> = useRef(null);
  const canvasBackground: React.RefObject<HTMLCanvasElement> = useRef(null);

  //Redux States
  const dispatch = useAppDispatch();
  const detailContentId = useAppSelector((state) => state.detailDrawer.detailContentId);
  const version = useAppSelector((state) => state.systemBar.version);
  const environment = useAppSelector((state) => state.systemBar.environment);
  //const packageDeployed = useAppSelector((state) => state.boardDrawer.packageIdDeployed);
  //Redux States

  const detailBarNodeRef = useRef<HTMLInputElement>(null);
  const [openDetailBarNode, setOpenDetailBarNode] = useState<boolean>(false);

  const mouseEvents = new MouseEvents();
  const [mouseCodePressed, setMouseCodePressed] = useState<MouseEventEntry>(MouseEventEntry.VOID);
  //const [canvasBarSelection, setCanvasBarSelection] = useState<CanvasBarEntry>(CanvasBarEntry.POINTER);

  const [roadmapTimeline, setRoadmapTimeline] = useState<IRoadmapItem[]>([]);
  const [roadmapBacklog, setRoadmapBacklog] = useState<IRoadmapItem[]>([]);
  const [versions, setVersions] = useState<ASDtos.VersionReadDto[]>([]);

  const [selectedObject, setSelectedObject] = useState<ICanvasInitiative | null>(null);
  const [newConnector, setNewConnector] = useState<ICanvasLink<ICanvasInitiative> | null>(null);

  const [canvasViewName, setCanvasViewName] = useState<string>(props.canvasViewName);
  const [canvasMap, setCanvasMap] = useState<CanvasMap>(new CanvasMap(0, 0, props.canvasWidth, props.canvasHeight));

  const [totalMonths, setTotalMonths] = useState<number>(3);
  const [gridDayW, setGridDayW] = useState<number>(1);

  const roadmapLineHeight = 40;
  const roadmapInitialPosX = 250;
  const [roadmapColumns, setRoadmapColumns] = useState<IRoadMapColumn[]>([]);

  const [calendarRange, setCalendarRange] = useState<ICalendarRangeProps>({ startDate: dayjs(), endDate: dayjs().add(totalMonths - 1, 'month') } as ICalendarRangeProps);

  function getRoadmap(isInBacklog: boolean): IRoadmapItem[] {
    if (isInBacklog) {
      return [...roadmapBacklog];
    } else {
      return [...roadmapTimeline];
    }
  }

  function setRoadmap(roadmap: IRoadmapItem[], isInBacklog: boolean) {
    if (isInBacklog) {
      setRoadmapBacklog(roadmap);
    } else {
      setRoadmapTimeline(roadmap);
    }
  }
  function reorderRoadmap(roadmapToOrder: IRoadmapItem[], isInBacklog: boolean, currentRoadmapItem?: IRoadmapItem): IRoadmapItem[] {
    //Recalculate posY and rank
    const reRankParentKey = currentRoadmapItem?.parentKey;
    var roadmap = [...roadmapToOrder];
    var reOrderPosY = canvasMap.planeOriginY + roadmapLineHeight;
    var rank = 1; //Default value for timeline (they should be always first because already have planned dates)
    if (isInBacklog){
      //if we are reordering the backlog we must start on next rank of timeline max rank
      var roadmapTimeline = getRoadmap(false);
      var maxRank = 0;
      if (roadmapTimeline && roadmapTimeline.length > 0){
        maxRank = roadmapTimeline.reduce((a,b)=> (a.rank > b.rank)? a : b).rank;
      }
      rank = maxRank + 1;
    }

    roadmap.forEach((reorderItem) => {
      reorderItem.posY = reOrderPosY;

      //When Item is not the selected one then we must Update Options inside
      if (
        !currentRoadmapItem ||                                              // 1 - currentRoadmapItem is not passed then all Item options are to be changed
        (currentRoadmapItem && reorderItem.key !== currentRoadmapItem.key)  // 2 - currentRoadmapItem is passed then all Item options Execpt the one selected are to be changed
      ) {
        const reOrderOptions = reorderItem.initiative.getOptions();
        reOrderOptions.y = reOrderPosY;
        reorderItem.initiative.setOptions(reOrderOptions);
      }

      if (reRankParentKey === reorderItem.parentKey) {
        reorderItem.rank = rank;
        rank++;
      }
      reOrderPosY += roadmapLineHeight;
    })

    //console.log("reorderRoadmap backlog: ", getRoadmap(true));
    //console.log("reorderRoadmap timeline: ", getRoadmap(false));
    return roadmap;
  }


  function buildRoadmapColumns(): IRoadMapColumn[] {
    var calcRange = calendarRange;

    // console.log("buildRoadmapColumns props.calendarRange: ", props.calendarRange);
    //console.log("buildRoadmapColumns : ", totalMonths);
    if (props.calendarRange.startDate && props.calendarRange.endDate) {
      calcRange = props.calendarRange;
    }

    var resultTotalMonths = calcRange.endDate.diff(calcRange.startDate, 'month');
    setTotalMonths(resultTotalMonths);
    setCalendarRange(calcRange);


    //console.log("buildRoadmapColumns while canvasMap.planeWidth: ", canvasMap.planeWidth);
    // console.log("buildRoadmapColumns calcRange: ", calcRange);
    // console.log("buildRoadmapColumns resultTotalMonths: ", resultTotalMonths);
    const gridMonthW = (canvasMap.planeWidth - (roadmapInitialPosX + 300)) / resultTotalMonths;

    var result: IRoadMapColumn[] = [];
    var currentPosX = roadmapInitialPosX;

    //console.log("buildRoadmapColumns calendarRange: ", calendarRange);

    var month = calcRange.startDate.month() + 1;
    var year = calcRange.startDate.year();

    const endMonth = calcRange.endDate.month() + 1;
    const endYear = calcRange.endDate.year();

    while (year < endYear || (year === endYear && month <= endMonth)) {
      const totalDays = new Date(year, month, 0).getDate();
      // console.log("buildRoadmapColumns while month: ", month);
      // console.log("buildRoadmapColumns while endMonth: ", endMonth);
      // console.log("buildRoadmapColumns while year: ", year);
      // console.log("buildRoadmapColumns while endYear: ", endYear);
      // console.log("buildRoadmapColumns while totalDays: ", totalDays);


      //for sans serif 10px we need 13px per day max 16px
      var resDayWidth = 1;
      var daysPerCol = 0;
      // console.log("buildRoadmapColumns while gridMonthW: ", gridMonthW);
      while (resDayWidth < 13) {
        // console.log("buildRoadmapColumns while resDayWidth: ", resDayWidth);
        daysPerCol++;
        resDayWidth = gridMonthW / (totalDays / daysPerCol);
      }

      if (resDayWidth > 16) {
        resDayWidth = 16;
      }
      if (daysPerCol === 0) {
        daysPerCol = 1;
      }
      //console.log("buildRoadmapColumns while daysPerCol: ", daysPerCol);
      for (let day = 1; day <= totalDays; day += daysPerCol) {
        var startX = currentPosX;
        currentPosX += resDayWidth;
        var endX = currentPosX - 1;

        const colStartDate = DateUtils.parse(new Date(year, month - 1, day))!;
        var colEndDate = colStartDate;
        if (daysPerCol > 1) {
          colEndDate = DateUtils.parse(new Date(year, month - 1, day + daysPerCol))!;
        }
        const newCol = { sX: startX, eX: endX, startDate: colStartDate, endDate: colEndDate };
        //console.log("buildRoadmapColumns while newCol: ", newCol);
        result = [...result, newCol];
      }

      setGridDayW(resDayWidth);

      //Update loop conditions
      month++;
      if (month > 12) {
        month = 1;
        year++;
      }
    }

    //Need to check if last end date is greater than the last month to be rendered
    //This can happen when column has more than one day per column
    //Remove last column of the roadmap matrix that is already on overfloat month
    const lastDate = dayjs(result[result.length - 1].endDate);
    const lastDateMonth = lastDate.month() + 1;
    if (lastDateMonth > endMonth) {
      //remove last column
      result.splice(-1);
    }
    // console.log("buildRoadmapColumns result gridDayW: ", gridDayW);
    // console.log("buildRoadmapColumns result: ", result);
    // console.log("buildRoadmapColumns result[result.length-1].endDate: ", result[result.length-1].endDate);
    setRoadmapColumns(result);
    return result;
  }

  function buildRoadmapTimeline(pRoadmapColumns: IRoadMapColumn[]) {
    var result: IRoadmapItem[] = [];
    var resultBacklog: IRoadmapItem[] = [];
    var backlogPosY = canvasMap.planeOriginY + roadmapLineHeight;
    var roadmapPosY = canvasMap.planeOriginY + roadmapLineHeight;

    //console.log("buildRoadmap versions: ", versions);
    versions?.forEach((versionData: ASDtos.VersionReadDto, index: number) => {
      //console.log("buildRoadmap versionData: ", versionData);
      const initiative = new InitiativeNode(
        versionData.id,
        versionData.name,
        0, //Must be redifined bellow after object created where we can check if is in backlog or in roadmap
        pRoadmapColumns,
        versionData.startDate,
        versionData.endDate,
      );

      if (initiative.isInBacklog()) {
        const optionsBackLog = initiative.getOptions();
        optionsBackLog.y = backlogPosY;
        initiative.setOptions(optionsBackLog);

        var newItem: IRoadmapItem = {
          key: versionData.id,
          parentKey: null,
          posY: backlogPosY,
          rank: versionData.rank,
          initiative: initiative
        };
        const correctedItem = normalizeOptionsToColumns(newItem);

        resultBacklog = [...resultBacklog, correctedItem];
        backlogPosY += roadmapLineHeight;

      } else {
        const options = initiative.getOptions();
        options.y = roadmapPosY;
        initiative.setOptions(options);

        var newItem: IRoadmapItem = {
          key: versionData.id,
          parentKey: null,
          posY: roadmapPosY,
          rank: versionData.rank,
          initiative: initiative
        };
        const correctedItem = normalizeOptionsToColumns(newItem);

        result = [...result, correctedItem];
        roadmapPosY += roadmapLineHeight;
      }
    });

    setRoadmap(result, false);
    setRoadmap(resultBacklog, true);
  }

  async function fetchAllObjects(runOnBackground: boolean) { //: Promise<ASDtos.VersionReadDto[]>
    const teamId = props.filters.teams[0];
    //console.log("fetchAllObjects teamId: ", teamId);
    //var result: ASDtos.VersionReadDto[] = [];

    if (teamId) {
      //Call Service
      var canvasLoader: CanvasLoader | undefined = undefined;
      if (!runOnBackground) {
        canvasLoader = new CanvasLoader(getNodesContext(), canvasMap.planeWidth, canvasMap.planeHeight);
        canvasLoader.startLoading();
      }

      //console.log("Call Versions.GetAll");
      await ASGateway.Versions.GetAll(teamId, runOnBackground)
        .then((data: ASDtos.VersionReadDto[]) => {
          //console.log("Versions.GetAll: ", [...data]);
          setVersions(data)
          // result = data;
        })
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          if (!runOnBackground) {
            canvasLoader!.stopLoading();
          }
        });
    } //End TeamId

    // return new Promise<ASDtos.VersionReadDto[]>((resolve, reject) => {
    //   if (result.length > 0) {
    //     resolve(result);
    //   }
    //   else {
    //     reject("Empty Result");
    //   }
    // });
  }

  //On Load
  useEffect(() => {
    //console.log("CanvasRoadmap useEffect[]: ", props);

    setCanvasMap(
      new CanvasMap(
        0,
        70,
        props.canvasWidth,
        props.canvasHeight,
        canvasMap.zoom,
        canvasMap.level
      ));

    const resultColumns = buildRoadmapColumns();
    buildRoadmapTimeline(resultColumns);
  
    fetchAllObjects(false);
  }, []);

  useEffect(() => {
    // console.log("CanvasRoadmap useEffect[props.filters]: ", props.filters);
     
    //Need to reset so when version are fetched from the service the timeline can be reconstructed
    setRoadmapTimeline([]);

    //buildRoadmapColumns();
    fetchAllObjects(false);

  }, [props.filters]);

  useEffect(() => {
    // console.log("CanvasRoadmap useEffect[props.calendarRange]: ", props.calendarRange);

    const resultColumns = buildRoadmapColumns();
    buildRoadmapTimeline(resultColumns);

    fetchAllObjects(false);
  }, [props.calendarRange]);
  
  useEffect(() => {
    // console.log("CanvasRoadmap useEffect[props.canvasWidth]: ", props.canvasWidth);
    // console.log("CanvasRoadmap useEffect[props.canvasHeight]: ", props.canvasHeight);
    setCanvasMap(
      new CanvasMap(
        0,
        70,
        props.canvasWidth,
        props.canvasHeight,
        canvasMap.zoom,
        canvasMap.level
      ));

      drawCanvasBackground();
      drawCanvas("useEffect[props]");
  }, [props.canvasWidth, props.canvasHeight]);

  //OnRefresh versions
  useEffect(() => {
    // console.log("Canvas useEffect[versions]");
    // console.log("Canvas useEffect[versions] roadmapTimeline.length: ", roadmapTimeline.length)

    if (roadmapTimeline.length === 0) {
      // console.log("Canvas useEffect[versions] roadmapColumns: ", roadmapColumns)
      buildRoadmapTimeline(roadmapColumns);
    }
    else {
      drawCanvas("useEffect[versions]");
    }
  }, [versions]);

  useEffect(() => {
    //console.log("Canvas useEffect[roadmapColumns]");
    drawCanvasBackground();
  }, [roadmapColumns]);

  //OnRefresh roadmap
  useEffect(() => {
    //console.log("Canvas useEffect[roadmapTimeline, roadmapBacklog]");
    // console.log("Canvas useEffect[roadmapTimeline] roadmapTimeline: ", roadmapTimeline);
    // console.log("Canvas useEffect[roadmapBacklog] roadmapBacklog: ", roadmapBacklog);
    drawCanvas("useEffect[roadmap]");
  }, [roadmapTimeline, roadmapBacklog]);

  function findVersion(versionId: number): ASDtos.VersionReadDto | undefined {

    return findVersionRecursive([...versions], versionId);

    //Recursive function to use only inside findVersion
    function findVersionRecursive(versionTree: ASDtos.VersionReadDto[], versionId: number): ASDtos.VersionReadDto | undefined {
      var result: ASDtos.VersionReadDto | undefined = undefined;

      versionTree.forEach((version: ASDtos.VersionReadDto) => {
        //Stopage case
        if (version.id == versionId) {
          result = version;
        }

        if (!result) { //Not found yet
          //Search one more level
          result = findVersionRecursive(version.childs, versionId);
        }
      });

      return result;
    }
  }


  function sanetizeMouseEvent(pointerX: number, pointerY: number): { X: number, Y: number } {
    var result = {
      X: pointerX - canvasMap.planeOriginX,
      Y: pointerY - canvasMap.planeOriginY
    };

    return result;
  }

  function isPointerHoverObject(pointerX: number, pointerY: number): ICanvasInitiative | undefined {
    //var result: ICanvasInitiative[] = [];
    var detectedObject: IRoadmapItem | undefined;

    detectedObject = roadmapTimeline?.find((roadMapItem) => {
      const resultPointer = roadMapItem.initiative.setNodeAreaFromPointer(canvasMap, pointerX, pointerY);
      return resultPointer !== CanvasNodeArea.NONE;
    });

    if (!detectedObject) {
      detectedObject = roadmapBacklog?.find((roadMapItem) => {
        const resultPointer = roadMapItem.initiative.setNodeAreaFromPointer(canvasMap, pointerX, pointerY);
        return resultPointer !== CanvasNodeArea.NONE
      });
    }

    return detectedObject?.initiative;
  }

  function getNodesContext(): CanvasRenderingContext2D | null {
    //console.log("canvasElement.current: ", canvasElement.current);
    if (canvasNodes.current) {
      //console.log("canvasMap.planeWidth: ", canvasMap.planeWidth);
      //console.log("canvasMap.planeHeight: ", canvasMap.planeHeight);

      canvasNodes.current.width = canvasMap.planeWidth;
      canvasNodes.current.height = canvasMap.planeHeight;
      canvasNodes.current.style.position = "absolute";
      canvasNodes.current.style.left = `${canvasMap.planeOriginX}px`;
      canvasNodes.current.style.top = `${canvasMap.planeOriginY}px`;
      canvasNodes.current.style.backgroundColor = "transparent";
      canvasNodes.current.style.zIndex = "2";
      // canvasNodes.current.style.cursor = "pointer";
      var canvasContext = canvasNodes.current.getContext('2d');

      return canvasContext;
    }
    return null;
  }
  function getBackgroundContext(): CanvasRenderingContext2D | null {
    //console.log("canvasElement.current: ", canvasElement.current);
    if (canvasBackground.current) {
      //console.log("canvasMap.planeWidth: ", canvasMap.planeWidth);
      //console.log("canvasMap.planeHeight: ", canvasMap.planeHeight);

      canvasBackground.current.width = canvasMap.planeWidth;
      canvasBackground.current.height = canvasMap.planeHeight;
      canvasBackground.current.style.zIndex = "1";
      var canvasContext = canvasBackground.current.getContext('2d');

      return canvasContext;
    }
    return null;
  }

  function refreshDetailBarNodePosition(x: number, y: number, w: number, h: number) {
    if (detailBarNodeRef.current) {
      detailBarNodeRef.current.style.left = `${x + (w / 2)}px`;
      detailBarNodeRef.current.style.top = `${y - h}px`;
    }
  }

  function normalizeOptionsToColumns(roadmapItem: IRoadmapItem): IRoadmapItem {
    var result = { ...roadmapItem };
    var startRoadmapCol = result.initiative.getStartRoadmapColFromOptions();
    var endRoadmapCol = result.initiative.getEndRoadmapColFromOptions();

    if (startRoadmapCol) {
      const startPrevOptions = result.initiative.getOptions();
      const startMidleCol = (startRoadmapCol.eX - startRoadmapCol.sX) / 2;

      //Correct X
      const newX = startRoadmapCol.sX + startMidleCol;
      //Calculate total pixels was changed +-
      const dif = newX - startPrevOptions.x;
      //Correct W accordingly
      const newW = startPrevOptions.w + dif;

      result.initiative.setOptions({ ...startPrevOptions, x: newX, w: newW });
    }

    if (endRoadmapCol) {
      const endPrevOptions = result.initiative.getOptions();
      const endMidleCol = (endRoadmapCol.eX - endRoadmapCol.sX) / 2;

      //Correct W
      const newW = (endRoadmapCol.sX + endMidleCol) - endPrevOptions.x;

      result.initiative.setOptions({ ...endPrevOptions, w: newW });
    }

    return result;
  }
  function findPreviousParentIndex(roadmap: IRoadmapItem[], startIndex: number, currentIndex: number): number {
    //Find Previous Item at same level of roadmap 
    const currentRoadmapItem = roadmap[currentIndex];
    var previousParentIndex = startIndex;
    var previousParent = roadmap[startIndex];
    while (currentRoadmapItem.parentKey !== previousParent.parentKey) {
      previousParentIndex--;
      previousParent = roadmap[previousParentIndex];

      //Sometimes we arrive at begining of List in this case add one and break cycle
      if (previousParent === undefined) {
        previousParentIndex++;
        break;
      }
    }

    return previousParentIndex;
  }

  function findNextParentIndex(roadmap: IRoadmapItem[], startIndex: number, currentIndex: number): number {
    //Find Next Item at same level of roadmap 
    const currentRoadmapItem = roadmap[currentIndex];
    var nextParentIndex = startIndex;
    var nextParent = roadmap[startIndex];

    if (nextParent) { //When next item is the last in list so the cuurent startindex is the return result

      while (currentRoadmapItem.parentKey !== nextParent.parentKey) {
        nextParentIndex++;
        nextParent = roadmap[nextParentIndex];

        //Sometimes we arrive at end of List in this case remove one and break cycle
        if (nextParent === undefined) {
          nextParentIndex--;
          break;
        }
      }

    }

    return nextParentIndex;
  }

  function moveUpRoadmapBlock(roadmapResult: IRoadmapItem[], startBlockIndex: number, endBlockIndex: number): IRoadmapItem[] {

    for (let j = startBlockIndex; j <= endBlockIndex; j++) {
      const itemUp = roadmapResult[j];
      const itemDown = roadmapResult[j - 1];

      roadmapResult.splice(j - 1, 2, itemUp, itemDown);
    }

    return roadmapResult;
  }

  function moveDownRoadmapBlock(roadmapResult: IRoadmapItem[], startBlockIndex: number, endBlockIndex: number): IRoadmapItem[] {
    for (let j = startBlockIndex; j <= endBlockIndex; j++) {
      const itemDown = roadmapResult[j];
      const itemUp = roadmapResult[j + 1];

      roadmapResult.splice(j, 2, itemUp, itemDown);
    }

    return roadmapResult;
  }

  function drawCanvasBackground(): void {
    //console.log("drawCanvasBackground roadmapColumns.length: ", roadmapColumns.length);
    var ctx = getBackgroundContext();
    if (ctx && roadmapColumns.length > 0) {
      //console.log("drawCanvasBackground :: ctx");
      ctx.clearRect(0, 0, canvasMap.planeWidth, canvasMap.planeHeight);

      /******* CANVAS NAME *******/
      ctx.save();

      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#000000";

      var canvasNameMetrics = ctx.measureText(canvasViewName);

      var canvasNameWidth = canvasNameMetrics.actualBoundingBoxLeft + canvasNameMetrics.actualBoundingBoxRight;
      var canvasNameHeight = canvasNameMetrics.actualBoundingBoxAscent + canvasNameMetrics.actualBoundingBoxDescent;
      var canvasNameX = (canvasMap.planeWidth / 2) - (canvasNameWidth / 2); //(Middle Canvas) - (Half Box Width)
      var canvasNameY = canvasMap.planeHeight - (10 + canvasNameHeight); //Total Canvas - (Padding Bottom + Box Height)

      var canvasNameBoxPadding = 10;

      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#D4DBF5";
      ctx.roundRect(
        canvasNameX - ((canvasNameWidth / 2) + canvasNameBoxPadding),
        canvasNameY - canvasNameBoxPadding,
        canvasNameWidth + (canvasNameBoxPadding * 2),
        canvasNameHeight + canvasNameBoxPadding,
        10);
      ctx.stroke();

      ctx.globalAlpha = 0.4;
      ctx.fillStyle = "#D4DBF5";
      ctx.fill();

      ctx.globalAlpha = 1;
      ctx.fillStyle = "#000000";
      ctx.fillText(canvasViewName, canvasNameX, canvasNameY);

      ctx.restore();
      /******* CANVAS NAME *******/

      /******* ROADMAP GRID *******/
      ctx.save();

      //Draw Grid
      //console.log("Current Font: ", ctx.font);
      ctx.beginPath();
      ctx.font = "10px sans-serif";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#000000";

      ctx.setLineDash([3, 5]);
      ctx.lineWidth = 0.5;
      ctx.strokeStyle = '#bebebe';

      //Render Days
      //console.log("render day gridDayW: ", gridDayW);
      roadmapColumns.forEach((column: IRoadMapColumn) => {
        const colX = column.sX; //roadmapInitialPosX + 
        //Render Day
        //console.log("render day sX: ", colX);
        //console.log("render day textX: ", colX + gridDayW);
        ctx!.fillText(column.startDate.toLocaleString('en-GB', { day: '2-digit' }), colX + (gridDayW / 2), 150);

        ctx!.moveTo(colX, 140);
        ctx!.lineTo(colX, 750);
      });
      ctx.stroke();

      var prevMonthCol: IRoadMapColumn | undefined = undefined;
      var lastMonthCol: IRoadMapColumn | undefined = undefined;

      //Render Months
      ctx.beginPath();
      ctx.font = "30px sans-serif"; //Default font
      switch (totalMonths) {
        case 1:
          ctx.font = "30px sans-serif";
          break;
        case 3:
          ctx.font = "30px sans-serif";
          break;
        case 6:
          ctx.font = "30px sans-serif";
          break;
        case 12:
          ctx.font = "20px sans-serif";
          break;
      }

      ctx.setLineDash([]);
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000000';

      roadmapColumns.forEach((column: IRoadMapColumn) => {
        const colX = column.sX; //roadmapInitialPosX + 
        // console.log("column: ", column);
        if (!prevMonthCol) {
          //First Column
          ctx!.moveTo(colX, 100);
          ctx!.lineTo(colX, 750);

          prevMonthCol = column;
        }
        const currentMonth = column.startDate.toLocaleString('en-GB', { month: 'numeric' });
        const prevMonth = (prevMonthCol) ? prevMonthCol.startDate.toLocaleString('en-GB', { month: 'numeric' }) : "0";

        //when Month Changes draw Initial solid line
        if (currentMonth !== prevMonth) {
          const monthWidth = colX - prevMonthCol!.sX;

          ctx!.fillText(AppUtils.toPascalCase(prevMonthCol!.startDate.toLocaleString('en-GB', { month: 'long' })), prevMonthCol!.sX + (monthWidth / 2), 120);

          ctx!.moveTo(colX, 100);
          ctx!.lineTo(colX, 750);

          prevMonthCol = column;
        }

        lastMonthCol = column;
      });
      ctx.stroke();

      //Last Column
      const monthWidth = lastMonthCol!.sX - prevMonthCol!.sX;

      ctx.fillText(AppUtils.toPascalCase(lastMonthCol!.startDate.toLocaleString('en-GB', { month: 'long' })), prevMonthCol!.eX + (monthWidth / 2), 120);

      ctx.moveTo(lastMonthCol!.eX, 100);
      ctx.lineTo(lastMonthCol!.eX, 750);
      //Last Column

      //Separator Line
      //ctx.moveTo(firstMonthCol!.sX, 155);
      ctx.moveTo(70, 155);
      ctx.lineTo(lastMonthCol!.eX, 155);
      //Separator Line

      ctx.stroke();
      ctx.restore();
      /******* ROADMAP GRID *******/
    }
  }

  function drawCanvas(trackOrigin: string = "N/A"): void {
    //console.log("drawCanvas Origin: ", trackOrigin);
    var ctx = getNodesContext();

    //console.log("canvasContext: ", canvasContext);
    if (ctx) {
      //SETUP Zoom
      ctx.translate(canvasMap.planeWidth / 2, canvasMap.planeHeight / 2);
      ctx.scale(canvasMap.zoom, canvasMap.zoom);
      ctx.translate(-canvasMap.planeWidth / 2, -canvasMap.planeHeight / 2);
      //SETUP Zoom

      /******* CANVAS OBJECTS *******/
      var objectCurrentDrawAlreadyActivated = false;

      /******* ROADMAP *******/
      //console.log("drawCanvas roadmap:", roadmap);
      roadmapTimeline.forEach((roadMapItem: IRoadmapItem) => {
        var canvasObject = roadMapItem.initiative;
        //console.log(`drawCanvas roadMapItem.posY :`, roadMapItem.posY);
        //console.log(`drawCanvas canvasObject[${canvasObject.getName()}].options :`, canvasObject.getOptions());
        canvasObject.draw(canvasMap, ctx);

        /******** REDUX TRIGGERING ********/
        //Cannot trigger redux inside class so we need to do it here
        if (canvasObject.isActive()) {
          objectCurrentDrawAlreadyActivated = true;

          //Refresh options with Canvas Map before Dispatch
          var anchorOptions = { ...canvasObject.getOptions() };
          anchorOptions.x = anchorOptions.x + canvasMap.planeOriginX + canvasMap.originX;
          anchorOptions.y = anchorOptions.y + canvasMap.planeOriginY + canvasMap.originY;

          //console.log("REFRESH ANCHOR: ", anchorOptions);
          //Position the anchor element for the selected object
          refreshDetailBarNodePosition(anchorOptions.x, anchorOptions.y, anchorOptions.w, anchorOptions.h);
          //setOpenDetailBarNode(true);
        }
        else {
          //Only hide if in this round of drawing there is no object that already activated it!!
          if (objectCurrentDrawAlreadyActivated === false) {
            //setOpenDetailBarNode(false);
          }
        }
      });

      /******* BACKLOG *******/
      //console.log("drawCanvas roadmapBacklog:", roadmapBacklog);
      roadmapBacklog.forEach((roadMapItem: IRoadmapItem) => {
        var canvasObject = roadMapItem.initiative;
        //console.log(`drawCanvas roadMapItem.posY :`, roadMapItem.posY);
        //console.log(`drawCanvas canvasObject[${canvasObject.getName()}].options :`, canvasObject.getOptions());
        canvasObject.draw(canvasMap, ctx);

        /******** REDUX TRIGGERING ********/
        //Cannot trigger redux inside class so we need to do it here
        if (canvasObject.isActive()) {
          objectCurrentDrawAlreadyActivated = true;

          //Refresh options with Canvas Map before Dispatch
          var anchorOptions = { ...canvasObject.getOptions() };
          anchorOptions.x = anchorOptions.x + canvasMap.planeOriginX + canvasMap.originX;
          anchorOptions.y = anchorOptions.y + canvasMap.planeOriginY + canvasMap.originY;

          //console.log("REFRESH ANCHOR: ", anchorOptions);
          //Position the anchor element for the selected object
          refreshDetailBarNodePosition(anchorOptions.x, anchorOptions.y, anchorOptions.w, anchorOptions.h);
          //setOpenDetailBarNode(true);
        }
        else {
          //Only hide if in this round of drawing there is no object that already activated it!!
          if (objectCurrentDrawAlreadyActivated === false) {
            //setOpenDetailBarNode(false);
          }
        }
      });
      /******* CANVAS OBJECTS *******/

      //RESET Zoom
      ctx.translate(canvasMap.planeWidth / 2, canvasMap.planeHeight / 2);
      ctx.scale(1, 1);
      ctx.translate(-canvasMap.planeWidth / 2, -canvasMap.planeHeight / 2);
      ctx.save();
      //RESET Zoom
    }
  }

  function onMouseDown(event: React.MouseEvent<Element, MouseEvent>) {
    var eventMouseCodePressed = mouseEvents.getMouseEventEntry(event);
    setMouseCodePressed(eventMouseCodePressed);

    //console.log("onMouseDown event.pageX: ", event.pageX);
    //console.log("onMouseDown event.pageY: ", event.pageY);
    var mousePointer = sanetizeMouseEvent(event.pageX, event.pageY);
    //console.log("eventX: ", eventX);
    //console.log("eventY: ", eventY);

    const detectedObject = isPointerHoverObject(mousePointer.X, mousePointer.Y);
    if (selectedObject) {
      selectedObject.setActive(false);
      setSelectedObject(null);
    }

    if (detectedObject) {
      detectedObject.setActive(true);
      setSelectedObject(detectedObject);
    }
  }

  function onMouseMove(event: React.MouseEvent<Element, MouseEvent>) {
    // console.log("event.pageY: ", event.pageY);
    // console.log("event.movementY: ", event.movementY);
    var mousePointer = sanetizeMouseEvent(event.pageX, event.pageY);
    const isMovingUp = event.movementY < 0;
    const isMovingDown = event.movementY > 0;

    //console.log("onMouseMove Mouse Code: ", mouseCodePressed);
    //console.log("onMouseMove mousePointer: ", mousePointer);
    if (mouseCodePressed === MouseEventEntry.RIGHTCLICK) {
      // if(canvasElement !== null) {
      //   canvasElement.style.cursor = 'grabbing';
      // }
      canvasMap.move(event.movementX, event.movementY);
      setCanvasMap(canvasMap);
      drawCanvas("onMouseMove[RIGHTCLICK]");
    }

    if (mouseCodePressed === MouseEventEntry.LEFTCLICK) {
      if (selectedObject) {
        //Get current Roadmap Item
        var roadmapResult = getRoadmap(selectedObject.isInBacklog());
        var currentRoadmapItemIndex = roadmapResult.findIndex((item: IRoadmapItem) => item.key === selectedObject.getId());
        var currentRoadmapItem = roadmapResult[currentRoadmapItemIndex];
        var prevRoadmapItemIndex = currentRoadmapItemIndex - 1;
        var nextRoadmapItemIndex = currentRoadmapItemIndex + 1;

        //============= MOVE OBJECTS ==============

        //************* SELECTED ******************
        roadmapResult[currentRoadmapItemIndex].initiative.move(event.movementX, event.movementY);
        //************* SELECTED ******************

        //************* CHILDS ********************
        //Get Childs when selected Object is expanded
        //console.log("roadmapResult: ", roadmapResult);
        if (currentRoadmapItem.initiative.isExpanded()) {
          var currentLoopIndex = currentRoadmapItemIndex + 1;
          //console.log("currentLoopIndex Before Loop: ", currentLoopIndex);
          while (true) {
            //Move RoadmapItem as Child
            roadmapResult[currentLoopIndex].initiative.moveAsChild(currentRoadmapItem.initiative.getNodeAreaPointer(), event.movementX, event.movementY);

            //Next Item on RoadMap List
            currentLoopIndex++;

            //When there is no more Items on list
            //When next item found is a root Item (parentKey === null) then stop loop
            if (roadmapResult[currentLoopIndex] === undefined || roadmapResult[currentLoopIndex].parentKey === null) {
              break;
            }
          }
        }
        //************* CHILDS ********************

        //************* PARENTS ********************
        //Get Parents when selected Object is Child
        if (currentRoadmapItem.parentKey !== null) {
          var currentParentIndex = roadmapResult.findIndex((pItem) => pItem.key === currentRoadmapItem.parentKey);
          //console.log("currentParentIndex Before Loop: ", currentParentIndex);
          while (currentParentIndex >= 0) {

            //Move RoadmapItem as Parent
            roadmapResult[currentParentIndex].initiative.moveAsParent(
              currentRoadmapItem.initiative.getStartDate(),
              currentRoadmapItem.initiative.getEndDate()
            );

            //Find Next Parent
            currentParentIndex = roadmapResult.findIndex((pItem) => pItem.key === roadmapResult[currentParentIndex].parentKey);
          }
        }
        //************* PARENTS ********************

        //VERTICAL POSITIONING
        //Check if object changed order with previous and next object from list
        //Get index for next Object with same Parent
        var previousRoadmapItem = roadmapResult[prevRoadmapItemIndex];
        var nextRoadmapItem = roadmapResult[nextRoadmapItemIndex];

        //Current vs Previous order Check
        if (previousRoadmapItem) {
          console.log("previous moving up current.y: ", currentRoadmapItem.initiative.getOptions().y);
          console.log("previous moving up previous.y: ", (previousRoadmapItem.initiative.getOptions().y + previousRoadmapItem.initiative.getOptions().h));
          if (currentRoadmapItem.initiative.getOptions().y < (previousRoadmapItem.initiative.getOptions().y + previousRoadmapItem.initiative.getOptions().h)) {
            //Only changes if is moving Up
            if (isMovingUp) {
              //Find Previous Parent Index
              const previousParentIndex = findPreviousParentIndex(roadmapResult, prevRoadmapItemIndex, currentRoadmapItemIndex);

              var totalBlock = 0;
              //When moving up we can have CURRENT roadmap item expanded and so we move from CURRENT to NEXT PARENT
              if (currentRoadmapItem.initiative.isExpanded()) {
                //Find Next Parent Index
                const nextParentIndex = findNextParentIndex(roadmapResult, currentRoadmapItemIndex + 1, currentRoadmapItemIndex);
                totalBlock = nextParentIndex - currentRoadmapItemIndex;
              }

              //Change Positions for all items between current and the parent Item for the change
              for (let i = currentRoadmapItemIndex; i > previousParentIndex; i--) {
                //Move Up Calculated Block
                roadmapResult = moveUpRoadmapBlock(roadmapResult, i, i + totalBlock);
              }

              //Reorder Roadmap
              roadmapResult = reorderRoadmap(roadmapResult, selectedObject.isInBacklog(), currentRoadmapItem);
            }
          }
        }

        //Current vs Next order Check
        if (nextRoadmapItem) {
          var currentLastOfBlockIndex = currentRoadmapItemIndex;
          var currentNextParentIndex = nextRoadmapItemIndex;

          var currentLastOfBlock = currentRoadmapItem;
          var currentNextParent = nextRoadmapItem;

          if (currentRoadmapItem.initiative.isExpanded()) {
            currentNextParentIndex = findNextParentIndex(roadmapResult, currentRoadmapItemIndex + 1, currentRoadmapItemIndex);

            currentNextParent = roadmapResult[currentNextParentIndex];
            currentLastOfBlockIndex = currentNextParentIndex - 1;
            currentLastOfBlock = roadmapResult[currentNextParentIndex - 1];
          }

          if (
            (currentLastOfBlock.initiative.getOptions().y + currentLastOfBlock.initiative.getOptions().h)
            >
            currentNextParent.initiative.getOptions().y
          ) {
            //Only changes if is moving Down
            if (isMovingDown) {

              var totalBlock = 0;
              if (currentNextParent.initiative.isExpanded()) {
                const nextParentIndex = findNextParentIndex(roadmapResult, currentNextParentIndex + 1, currentNextParentIndex);
                totalBlock = nextParentIndex - currentNextParentIndex;
              }

              //Change Positions for all items between current and the parent Item for the change
              for (let i = currentLastOfBlockIndex; i >= currentRoadmapItemIndex; i--) {
                //Move Calculated Block
                roadmapResult = moveDownRoadmapBlock(roadmapResult, i, i + totalBlock);
              }

              //Reorder Roadmap
              roadmapResult = reorderRoadmap(roadmapResult, selectedObject.isInBacklog(), currentRoadmapItem);
            }
          }
        }

        setRoadmap(roadmapResult, selectedObject.isInBacklog());

        var options = selectedObject.getOptions();
        refreshDetailBarNodePosition(options.x, options.y, options.w, options.h);
        //console.log("drawCanvas('onMouseMove[LEFTCLICK-POINTER]')");
        drawCanvas("onMouseMove[LEFTCLICK-POINTER]");
      }
    }

    if (mouseCodePressed === MouseEventEntry.VOID) {
      const detectedObject = isPointerHoverObject(mousePointer.X, mousePointer.Y);

      if (detectedObject) {
        if (canvasNodes.current) {
          detectedObject.setCursor(canvasNodes);
        }
        drawCanvas("onMouseMove[VOID-POINTER] Detected");
        //detectedObject.initiative.draw(canvasMap, [], environment, getNodesContext());
      }
      else {
        if (canvasNodes.current) {
          if (canvasNodes.current.style.cursor !== "default") {
            canvasNodes.current.style.cursor = "default";
            drawCanvas("onMouseMove[VOID-POINTER] None");
          }
        }
      }
    }
  }

  function onMouseUp(event: React.MouseEvent<Element, MouseEvent>) {
    var mousePointer = sanetizeMouseEvent(event.pageX, event.pageY);

    //When we have a selected Object go throught procedures to correct position
    //console.log("[onMouseUp] selectedObject ", selectedObject);
    if (selectedObject) {
      if (mouseCodePressed === MouseEventEntry.LEFTCLICK) {

        var roadmapResult = getRoadmap(selectedObject.isInBacklog());
        var roadmapOtherResult = getRoadmap(!selectedObject.isInBacklog())
        var selectedHaschanged = selectedObject.hasChanged();
        var changeToFromBacklog = false;

        //Check if ACTION was triggered
        const areaForAction = selectedObject.setNodeAreaFromPointer(canvasMap, mousePointer.X, mousePointer.Y);
        if (areaForAction === CanvasNodeArea.ACTION) {
          const isInBacklog = selectedObject.isInBacklog();
          var roadmapCurrent = getRoadmap(isInBacklog);
          var roadmapNew = getRoadmap(!isInBacklog);
          selectedHaschanged = true;
          changeToFromBacklog = true;          

          //We need to swap from one roadmap to the other
          const roadmapItemIndex = roadmapCurrent.findIndex((item) => item.key === selectedObject.getId());
          const roadmapItem = roadmapCurrent[roadmapItemIndex];

          //Remove all childs from current list
          const actionNextParentIndex = findNextParentIndex(roadmapCurrent, roadmapItemIndex + 1, roadmapItemIndex);
          for (let currentIndex = roadmapItemIndex + 1; currentIndex < actionNextParentIndex; currentIndex++) {
            roadmapCurrent.splice(roadmapItemIndex, 1); //remove
          }

          //Swap
          roadmapCurrent.splice(roadmapItemIndex, 1); //remove
          roadmapNew.splice(0, 0, roadmapItem); //Add

          roadmapCurrent = reorderRoadmap(roadmapCurrent, isInBacklog);
          roadmapNew = reorderRoadmap(roadmapNew, !isInBacklog, roadmapItem);

          setRoadmap(roadmapCurrent, isInBacklog);
          setRoadmap(roadmapNew, !isInBacklog);

          //Switch inner props
          selectedObject.switchToFromBacklog(roadmapItem.posY); //This is a ref so it changes already inside the roadmap array of objects
          selectedObject.setActive(false);

          console.log("roadmapCurrent: ", roadmapCurrent)
          console.log("roadmapNew: ", roadmapNew)
          roadmapResult = [...roadmapNew];
          roadmapOtherResult = [...roadmapCurrent];
        }

        //Only Update when Object is changed
        if (selectedHaschanged) {

          //Update new position for Initiative
          //Reorder Roadmap
          roadmapResult = reorderRoadmap(roadmapResult, selectedObject.isInBacklog());
          // console.log("hasChanged reorder: ", [...roadmapResult]);

          //Normalize x and w to the dates from selected and its childs
          var versionsToUpdateRank: ASDtos.VersionUpdateRankDto[] = [];
          const roadmapItem = roadmapResult.find((item) => item.key === selectedObject.getId());
          if (roadmapItem) {

            //Get Itens at same level of Hierarchy in case we have sufered a change in Rank but not the one that is selected
            //Current roadmap List
            const roadmapItemSameLevel = roadmapResult.filter((item) => (item.parentKey === roadmapItem.parentKey && item.key !== roadmapItem.key));
            roadmapItemSameLevel.forEach(x => { versionsToUpdateRank.push({ id: x.key, rank: x.rank } as ASDtos.VersionUpdateRankDto) });

            //Other roadmap List
            //const roadmapOtherResult = getRoadmap(!selectedObject.isInBacklog());
            const roadmapOtherItemSameLevel = roadmapOtherResult.filter((item) => (item.parentKey === roadmapItem.parentKey && item.key !== roadmapItem.key));
            roadmapOtherItemSameLevel.forEach(x => { versionsToUpdateRank.push({ id: x.key, rank: x.rank } as ASDtos.VersionUpdateRankDto) });

            //console.log("roadmapItem: ", roadmapItem);
            //console.log("roadmapItemSameLevel: ", roadmapItemSameLevel);

            //Normalize Columns
            const roadmapItemIndex = roadmapResult.findIndex((item: IRoadmapItem) => item.key === roadmapItem.key);
            roadmapResult[roadmapItemIndex] = normalizeOptionsToColumns(roadmapResult[roadmapItemIndex]);

            //console.log("Update Version.");
            //Save data Updated
            ASGateway.Versions.Update(
              props.filters.teams[0],
              roadmapItem.initiative.getId(),
              roadmapItem.initiative.getName(),
              roadmapItem.rank,
              versionsToUpdateRank,
              changeToFromBacklog,
              roadmapItem.initiative.getStartDate(),
              roadmapItem.initiative.getEndDate(),
            )
              .then((data: ASDtos.VersionReadDto) => {
                //console.log("Version and its child Updated: ", data);

                //Refresh list of versions on roadmap to use correctly when expanding/colapsing versions
                fetchAllObjects(true); //Run On Background;
              })
              .catch((e: any) => {
                console.error(e);
              });
          }

          setRoadmap(roadmapResult, selectedObject.isInBacklog());

          //Only try to detect object after the selected objet is in place
          const detectedObject = isPointerHoverObject(mousePointer.X, mousePointer.Y);
          if (detectedObject) {
            if (selectedObject.getId() !== detectedObject.getId()) {
              selectedObject.setActive(false);
            }
            //Do not activate if action was triggered because the original object found is not the same
            if (areaForAction !== CanvasNodeArea.ACTION) {
              detectedObject.setActive(true);
              setSelectedObject(detectedObject);
            }
          }
        }
      }
      // selectedObject.setActive(false);
      // setSelectedObject(null);
    }
    else { //No Object Selected
      //console.log("[onMouseUp] RightClick pressed? ", mouseEvents.isRightClickCode(mouseCodePressed));
      if (mouseCodePressed === MouseEventEntry.LEFTCLICK) {

        const detectedObject = isPointerHoverObject(mousePointer.X, mousePointer.Y);
        //console.log("[onMouseUp] detectedObject ", detectedObject);
        if (detectedObject) {
          detectedObject.setActive(true);
          setSelectedObject(detectedObject);
        }
      }
    }

    if (mouseCodePressed !== MouseEventEntry.VOID) {
      setMouseCodePressed(MouseEventEntry.VOID);
    }
    drawCanvas("onMouseUp");
  }

  function onContextMenu(event: React.MouseEvent<Element, MouseEvent>) {
    //console.log("onContextMenu");
    event.preventDefault();
  }

  function onDoubleClick(event: React.MouseEvent<Element, MouseEvent>) {
    var mousePointer = sanetizeMouseEvent(event.pageX, event.pageY);
    const detectedObject = isPointerHoverObject(mousePointer.X, mousePointer.Y);

    //console.log("onDoubleClick detectedObject: ", detectedObject);
    if (detectedObject) {
      var roadmapResult = getRoadmap(detectedObject.isInBacklog());
      var initiativeId = detectedObject.getId();
      var roadmapIndex = roadmapResult.findIndex((item: IRoadmapItem) => item.key === initiativeId);
      var version = findVersion(initiativeId);
      //console.log("findVersion: ", version);
      //console.log("findVersion.childs: ", version!.childs);

      roadmapResult[roadmapIndex].initiative.switchExpansion();

      //Add all Version Childs
      if (version) {
        var posY = roadmapResult[roadmapIndex].posY;

        //console.log("onDoubleClick version: ", {...version});
        version.childs.forEach((child, childIndex) => {
          posY += roadmapLineHeight;

          if (roadmapResult[roadmapIndex].initiative.isExpanded()) {
            //console.log("DC versionChild: ", { id: child.id, name: child.name, sDate: child.startDate, eDate: child.endDate });

            //Add Child Version
            var roadmapItem = new InitiativeNode(
              child.id,
              child.name,
              posY,
              roadmapColumns,
              (child.startDate) ? child.startDate : roadmapResult[roadmapIndex].initiative.getStartDate(),
              (child.endDate) ? child.endDate : roadmapResult[roadmapIndex].initiative.getEndDate()
            );

            var itemToAdd = {
              key: child.id,
              parentKey: initiativeId,
              posY: 0,
              rank: child.rank,
              initiative: roadmapItem
            } as IRoadmapItem;

            //Normalize options to columns
            itemToAdd = normalizeOptionsToColumns(itemToAdd);

            //Add to roadmap
            roadmapResult.splice(roadmapIndex + childIndex + 1, 0, itemToAdd);
          }
          else {
            //Remove Child Version
            roadmapResult.splice(roadmapIndex + 1, 1);
          }
        });
      }

      //Reorder Roadmap
      //console.log("onDoubleClick roadmapResult: ", [...roadmapResult]);
      roadmapResult = reorderRoadmap(roadmapResult, detectedObject.isInBacklog());
      setRoadmap(roadmapResult, detectedObject.isInBacklog());
      //drawCanvas("onDoubleClick");
    }
  }

  function onWheel(event: React.WheelEvent<Element>) {
    //Only zoom if Shift is pressed
    if (event.shiftKey) {

      var wheel = event.deltaY / 120; //n or -n

      //console.log("onScroll wheel: ", wheel);
      //according to Chris comment
      var zoom = Math.pow(1 + Math.abs(wheel) / 2, wheel > 0 ? 1 : -1);
      //console.log("onScroll zoom: ", zoom);
      canvasMap.zoomInOut(zoom);
      setCanvasMap(canvasMap);
      drawCanvas("onWheel");
    }
  }


  function onDrag(event: React.DragEvent<Element>) {
    //console.log("onDrag");
  }
  function onDragStart(event: React.DragEvent<Element>) {
    //console.log("onDragStart");
  }
  function onDragEnd(event: React.DragEvent<Element>) {
    //console.log("onDragEnd");
  }

  return (
    <>
      <canvas
        ref={canvasNodes}
        id="background-layer"
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onContextMenu={onContextMenu}
        onDoubleClick={onDoubleClick}
        onWheel={onWheel}
        onDrag={onDrag}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      //onTouchStart={}
      />
      <canvas
        ref={canvasBackground}
        id="background-layer"
      />

      <div ref={detailBarNodeRef} style={{ position: "absolute" }} />
      <Popper
        id="canvas-view-node-update-popover"
        open={openDetailBarNode}
        anchorEl={detailBarNodeRef.current}
      >
        HERE GOES CanvasRoadmapDetailBarNode
        {/* <CanvasViewDetailBarNode
          agentViewId={agentViewId}
          canvasViewId={canvasViewId}
          versionId={version}
          node={selectedObject}
          onChangeView={(canvasViewId) => {
            //console.log('HOME::CanvasView::DetailBarNode::onChangeView: ', canvasViewId);
            setCanvasViewId(canvasViewId);
            props.onChangeView(canvasViewId);
          }}
          onChangeNode={(node: ASDtos.NodeReadDto) => {
            //FindNode to Update
            var objectsFromLevel = objects.get(canvasMap.level);
            if (objectsFromLevel === undefined) {
              objectsFromLevel = new Array<ICanvasInitiative>();
            }

            var objectToUpdate = objectsFromLevel.find(obj => obj.getId() === node.id);
            objectToUpdate?.setName(node.name);

            objects.set(canvasMap.level, objectsFromLevel);
            setObjects(objects);

            //DrawCanvas
            drawCanvas();
            setOpenDetailBarNode(false);
          }}
        /> */}
      </Popper>

      {/* <CanvasRoadmapBar
        canvasViewId={0}
        coordX={canvasMap.planeOriginX}
        coordY={canvasMap.planeOriginY}
        onChangeSelection={(selection: CanvasBarEntry) => {
          //setCanvasBarSelection(selection) 
        }}
        onChangeView={(canvasViewId) => {
          //console.log('HOME::CanvasView::CanvasBar::onChangeView: ', canvasViewId);
          //setCanvasViewId(canvasViewId);
          //props.onChangeView(canvasViewId);
        }}
        onChangeViewNodeAdded={(nodeAdded: ASDtos.NodeReadDto) => {
          // const options = {
          //   x: nodeAdded.nodeView.posX,
          //   y: nodeAdded.nodeView.posY,
          //   w: nodeAdded.nodeView.width,
          //   h: nodeAdded.nodeView.height
          // };
          // var objectsFromLevel = objects.get(canvasMap.level);
          // if (objectsFromLevel === undefined)
          //   objectsFromLevel = new Array<ICanvasInitiative>();

          // const product: ICanvasInitiative = new ProductNode(nodeAdded.id, nodeAdded.name, canvasMap.level, options, version);
          // objects.set(canvasMap.level, [...objectsFromLevel, product]);

          // setObjects(objects);
        }}
      /> */}
    </>
  );
}