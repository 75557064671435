//https://react-redux.js.org/tutorials/typescript-quick-start
//https://stackoverflow.com/questions/70378566/updating-redux-slice-with-multiple-values --> example with multiple variables

import { configureStore } from '@reduxjs/toolkit'

import boardDrawerReducer from 'reducers/boardDrawerReducer'
import canvasBarReducer from 'reducers/canvasBarReducer'
import systemBarReducer from 'reducers/systemBarReducer'
import detailDrawerReducer from 'reducers/detailDrawerReducer'
import appSessionReducer from 'reducers/appSessionReducer'

export const StoreRedux = configureStore({
  reducer: {
    boardDrawer: boardDrawerReducer,
    canvasBar: canvasBarReducer,
    systemBar: systemBarReducer,
    detailDrawer: detailDrawerReducer,
    appSession: appSessionReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof StoreRedux.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof StoreRedux.dispatch

