import { useEffect, useState, useMemo, ReactNode } from 'react';

import { useAppSelector } from 'hooks/StoreReduxHooks';
import useNotification, { INotification } from 'hooks/NotificationHooks';

import { ASGateway, ASDtos } from 'api/AppServices';
import { ContextSetup } from 'app/AppValues';

import FormNewAgent from 'components/Forms/FormNewAgent';
import FormAddAgentToTeam from 'components/Forms/FormAddAgentToTeam';
import SmartButton from 'components/SmartButton';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
} from '@mui/material';

import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowModesModel,
  GridRowParams,
  GridRowId,
  GridActionsCellItem,
} from '@mui/x-data-grid';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PasswordIcon from '@mui/icons-material/Password';

import 'App.css';

interface ISetupAgentsProps {
  label: ReactNode;
  context: ContextSetup;
  defaultExpanded: boolean;
  filterTeams: number[];
};

export default function SetupAgents(props: ISetupAgentsProps) {
  //Redux States
  const userSession = useAppSelector((state) => state.appSession.user);
  const companySession = useAppSelector((state) => state.appSession.company);
  //Redux States
  const notification = useNotification(); //Miliseconds
  const [loading, setLoading] = useState(true);

  const [agents, setAgents] = useState<ASDtos.AgentReadDto[]>([]);

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel | undefined>(undefined);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({ actions: true });

  const [showAddAgentToTeamForm, setShowAddAgentToTeamForm] = useState(false);
  const [showCreateAgent, setShowCreateAgent] = useState(false);

  function fetchAgents(): void {
    if (props.context === ContextSetup.TEAM) {
      if (props.filterTeams[0]) {
        ASGateway.Teams.GetAgents(props.filterTeams[0])
          .then((data: ASDtos.AgentReadDto[]) => {
            setAgents(data);
          })
          .catch((e: any) => {
            console.error(e);
          });
      }
    } else {
      ASGateway.Agents.GetAll()
        .then((data: ASDtos.AgentReadDto[]) => {
          setAgents(data);
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }

  useEffect(() => {
    fetchAgents();
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchAgents();
  }, [props]);

  const columns: GridColDef<ASDtos.AgentReadDto>[] = useMemo(() => [
    { field: 'id', headerName: 'Agent Id', headerClassName: 'sx-grid-header', width: 100, editable: false },
    { field: 'account', headerName: 'Account', headerClassName: 'sx-grid-header', width: 200, editable: false },
    { field: 'name', headerName: 'Name', headerClassName: 'sx-grid-header', width: 300, editable: false },
    { field: 'email', headerName: 'E-Mail', headerClassName: 'sx-grid-header', width: 300, editable: false },
    {
      field: 'actions', headerName: 'Actions', headerClassName: 'sx-grid-header', type: 'actions', 
      getActions: (row: GridRowParams<ASDtos.AgentReadDto>) => {
        var result: React.JSX.Element[] = [];

        if (props.context === ContextSetup.GLOBAL) {
          result.push(<GridActionsCellItem label="Delete" icon={<Tooltip title="Delete"><DeleteForeverIcon /></Tooltip>} onClick={() => handleDeleteClick(row.id)} />);
          result.push(<GridActionsCellItem label="Reset Password" icon={<Tooltip title="Reset Password"><PasswordIcon /></Tooltip>} onClick={() => handleResetPwdClick(row.id)} />);
        }

        if (props.context === ContextSetup.TEAM) {
          result.push(<GridActionsCellItem label="Remove from Team" icon={<Tooltip title="Remove from Team"><CancelIcon /></Tooltip>} onClick={() => handleRemoveClick(props.filterTeams[0], row.id)} />);
        }

        return result;
      }
    }
  ], [props.filterTeams]);

  function handleRemoveClick(teamId: number, id: GridRowId) {
    ASGateway.Teams.RemoveAgent(teamId, Number(id))
      .then((data: ASDtos.AgentReadDto) => {
        notification.addNotification({
          severity: "success",
          message: "Agent has been sucessfully removed.",
        } as INotification);
        fetchAgents();
      })
      .catch((e: any) => {
        console.error(e);
        notification.addNotification({
          severity: "error",
          message: e.message,
        } as INotification);
      });
  }

  function handleDeleteClick(id: GridRowId) {
    ASGateway.Agents.Delete(Number(id))
      .then((data: ASDtos.AgentReadDto) => {
        notification.addNotification({
          severity: "success",
          message: "Agent has been sucessfully deleted.",
        } as INotification);
        fetchAgents();
      })
      .catch((e: any) => {
        console.error(e);
        notification.addNotification({
          severity: "error",
          message: e.message,
        } as INotification);
      });
  }

  function handleResetPwdClick(id: GridRowId) {
    ASGateway.Agents.RequestCredentialsReset(Number(id))
      .then((data: ASDtos.AgentReadDto) => {
        notification.addNotification({
          severity: "success",
          message: "Agent's password has been sucessfully reseted.",
        } as INotification);
      })
      .catch((e: any) => {
        console.error(e);
        notification.addNotification({
          severity: "error",
          message: e.message,
        } as INotification);
      });
  }

  return (
    <Box sx={{ p: 1 }}>
      <Accordion disableGutters defaultExpanded={props.defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="node-detail-summary"
          sx={{ backgroundColor: "rgba(212, 219, 245, 0.50)" }} //#D4DBF5 with 50% transparency
        >
          Agents
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
            sx={{ p: 1 }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <SmartButton onSubmit={() => {
                if (props.context === ContextSetup.TEAM) {
                  setShowAddAgentToTeamForm(true);
                } else {
                  setShowCreateAgent(true);
                }
              }}
              >
                {(props.context === ContextSetup.TEAM) ? "Add Agent" : "New Agent"}
              </SmartButton>
            </Stack>
            {loading === false &&
              <DataGrid
                editMode="cell"
                getRowId={(row) => row.id}
                getRowHeight={() => 'auto'}
                rows={agents}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                rowModesModel={rowModesModel}

                slotProps={{
                  toolbar: { setAgents, setRowModesModel },
                }}

                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 20]}
                sx={{
                  '& .sx-grid-header': {
                    backgroundColor: '#F3F3F3',
                  },
                  '& .MuiDataGrid-filler': {
                    backgroundColor: '#F3F3F3',
                  },                  
                  '& .MuiDataGrid-filler.css-8yphpr': {
                    backgroundColor: '#FFFFFF',
                  },
                  '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '&.MuiDataGrid-root': {
                    border: 'none',
                  },
                }}
              />
            }
          </Stack>
        </AccordionDetails>
      </Accordion>


      {/* DIALOG FOR ADD AGENT */}
      <Dialog fullScreen={false} open={showAddAgentToTeamForm} onClose={() => setShowAddAgentToTeamForm(false)}>
        <DialogTitle>Add Agent to Team</DialogTitle>
        <DialogContent sx={{ p: 2, width: "500px", maxHeight: "500px" }}>
          <FormAddAgentToTeam
            teamId={props.filterTeams[0]}
            formIsDone={(agentId) => { setShowAddAgentToTeamForm(false); fetchAgents(); }}
          />
        </DialogContent>
      </Dialog>
      {/* DIALOG FOR CREATE AGENT */}
      <Dialog fullScreen={false} open={showCreateAgent} onClose={() => setShowCreateAgent(false)}>
        <DialogTitle>New Agent</DialogTitle>
        <DialogContent sx={{ p: 2, width: "500px", maxHeight: "500px" }}>
          {/* <FormAddAgentToTeam
            teamId={props.filterTeams[0]}
            formIsDone={(agentId) => { setShowCreateAgent(false); fetchAgents(); }}
          /> */}
          <FormNewAgent
            formIsDone={(agentId) => { setShowCreateAgent(false); fetchAgents(); }}
          />
        </DialogContent>
      </Dialog>

    </Box>
  );
}
