import { MouseEventEntry } from 'app/AppValues';

export class MouseEvents
{
  constructor() {
  }

  getMouseEventEntry(event: React.MouseEvent<Element, MouseEvent>): MouseEventEntry {
    var  mouseCode = event.button;

    switch (mouseCode) {
      case 0:
        return MouseEventEntry.LEFTCLICK;
      case 1:
        return MouseEventEntry.MIDDLECLICK;
      case 2:
        return MouseEventEntry.RIGHTCLICK;
      default:
        return MouseEventEntry.VOID;
    }
  }

  // getVoidMouseCode() :number {
  //   return -1;
  // }

  // isRightClickEvent(event: React.MouseEvent<Element, MouseEvent>) {
  //   var mouseCode = this.getMouseButtonCode(event);
  //   return this.isRightClickCode(mouseCode);
  // }
  // isLeftClickCode(mouseCode: number) {
  //   return mouseCode === 0;
  // }
  // isMiddleClickCode(mouseCode: number) {
  //   return mouseCode === 1;
  // }
  // isRightClickCode(mouseCode: number) {
  //   return mouseCode === 2;
  // }

  // isClicked(mouseCode: number) {
  //   return mouseCode !== -1;
  // }
/*
    switch (mouseCode) {
      case 0:
        console.log('Left button clicked.');
        break;

      case 1:
        console.log('Middle button clicked.');
        break;

      case 2:
        console.log('Right button clicked.');
        return true;

      default:
          console.log('Unexpected code: ', mouseCode);
    }
*/

}