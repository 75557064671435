import React, { useEffect, useState, useRef } from 'react'; // let's also import Component
import { useForm, FormProvider } from "react-hook-form"

import { ASGateway, ASDtos } from 'api/AppServices';
//import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import { ICanvasNode } from 'middleware/canvas/ICanvasNode';
import FormAddNodeToTask from 'components/Forms/FormAddNodeToTask';
import CanvasViewNetworkSelector from 'components/Canvas/CanvasView/CanvasViewNetworkSelector';

import {
  Stack,
  Paper,
  Box,
  Divider,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Popover
} from '@mui/material';

import EditTextIcon from '@mui/icons-material/TextFields';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

type ICanvasViewDetailBarNodeProps = {
  agentViewId: number,
  canvasViewId: number,
  versionId: number,
  node: ICanvasNode | null,
  onChangeNode: (node: ASDtos.NodeReadDto) => void;
  onChangeView: (canvasViewId: number) => void;
};


export default function CanvasViewDetailBarNode(props: ICanvasViewDetailBarNodeProps) {
  //Redux States
  //const dispatch = useAppDispatch();
  //Redux States

  const { register: registerNode, handleSubmit: handleSubmitNode, reset: resetNode, formState: { errors: errorsNode } } = useForm({ mode: 'all' });

  const [detailBarSelection, setDetailBarSelection] = useState<string>("");
  const [showAddNodeToTaskForm, setShowAddNodeToTaskForm] = useState<boolean>(false);
  const [showEditNodeName, setShowEditNodeName] = useState<boolean>(false);

  const [anchorNodeChildSelector, setAnchorNodeChildSelector] = useState<HTMLElement | null>(null);
  const openNodeChildSelector = Boolean(anchorNodeChildSelector);

  const [fieldNodeTitle, setFieldNodeTitle] = useState<string>("");

  // function getDifferenceBetweenCenters(anchorWidth: number, current: HTMLInputElement | null) {
  //   var detailBarWidth = barWidth;

  //   if (current) {
  //     detailBarWidth = current.offsetWidth;
  //     if (current.style.display === 'none') {
  //       //Need to unhide and get Width otherwise it will be zero
  //       current.style.display = "";
  //       detailBarWidth = current.offsetWidth;
  //       current.style.display = "none";
  //     }
  //   }

  //   var centerAnchor = anchorWidth / 2;
  //   var centerBar = detailBarWidth / 2;
  //   return centerBar - centerAnchor;
  // }

  function canCreateTask(): boolean {
    return props.versionId !== 0
  }

  function handleNodeChildSelectorOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorNodeChildSelector(event.currentTarget);
  }
  function handleNodeChildSelectorClose() {
    setAnchorNodeChildSelector(null);
  }

  function handleNodeTitleUpdate() {
    //console.log('Update Node');
    ASGateway.Nodes.Update(
      props.agentViewId,
      props.canvasViewId,
      props.versionId,
      (props.node) ? props.node.getId() : -1,
      fieldNodeTitle
    )
      .then((node: ASDtos.NodeReadDto) => {
        //console.log('Updated Node: ', node);
        props.onChangeNode(node);
        setShowEditNodeName(false);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  // OnLoad
  useEffect(() => {
    //Load Node Name
    setFieldNodeTitle((props.node) ? props.node.getName() : "");
  }, []);


  // OnRender
  return (
    <>
      <Paper
        sx={{
          backgroundColor: "#FFFFFF",
          border: 0,
          padding: 0.5,
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
        >
          {canCreateTask() &&
            <>
              <Paper
                sx={{
                  p: 1,
                  boxShadow: "none",
                  //backgroundColor: (detailBarSelection === "pointer")?"#D4DBF5":"#FFFFFF",
                  ":hover": {
                    backgroundColor: "#D4DBF5",
                  }
                }}
                onClick={() => setShowAddNodeToTaskForm(true)}
              >
                <AddCircleOutlineIcon />
              </Paper>
              <Divider orientation="vertical" flexItem />
            </>
          }

          <Paper
            sx={{
              p: 1,
              boxShadow: "none",
              backgroundColor: (detailBarSelection === "pointer") ? "#D4DBF5" : "#FFFFFF",
              ":hover": {
                backgroundColor: "#D4DBF5",
              }
            }}
            //onClick={() => dispatch(setOpenDetailDrawer(true))}
            onClick={() => setShowEditNodeName(true)}
          >
            <EditTextIcon />
          </Paper>
          {showEditNodeName &&
            <Box id='nodeForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmitNode(handleNodeTitleUpdate)} sx={{ padding: 0, width: "100%", height: "100%" }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0.5}
              >
                <TextField
                  required
                  id="nodeTitle"
                  key="nodeTitle"
                  label="Node Name"
                  fullWidth autoFocus margin="dense" variant='filled' size='small'
                  //disabled={!showEditNodeName}
                  {...registerNode('nodeTitle', {
                    required: { value: true, message: 'Field is Required.' },
                    maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
                    onChange: (e) => setFieldNodeTitle(e.target.value),
                  })}
                  value={fieldNodeTitle}
                  error={errorsNode?.nodeTitle ? true : false}
                  helperText={errorsNode?.nodeTitle?.message?.toString()}
                />
                <Button
                  variant="outlined"
                  sx={{
                    maxWidth: "30px",
                    minWidth: "30px",
                    "& .MuiButton-startIcon": { marginRight: "0px", marginLeft: "0px" },
                  }}
                  startIcon={<CloseIcon />}
                  onClick={() => { resetNode(); setShowEditNodeName(false); }}
                />
                <Button
                  variant="outlined"
                  sx={{
                    maxWidth: "30px",
                    minWidth: "30px",
                    "& .MuiButton-startIcon": { marginRight: "0px", marginLeft: "0px" },
                  }}
                  startIcon={<SaveIcon />}
                  onClick={handleSubmitNode(handleNodeTitleUpdate)}
                />
              </Stack>
            </Box>
          }
          <Paper
            sx={{
              p: 1,
              boxShadow: "none",
              backgroundColor: (detailBarSelection === "pointer") ? "#D4DBF5" : "#FFFFFF",
              ":hover": {
                backgroundColor: "#D4DBF5",
              }
            }}
            //onClick={() => { }}
            onClick={(e) => { handleNodeChildSelectorOpen(e) }}
          >
            <AutoAwesomeMotionIcon />
          </Paper>
        </Stack>
      </Paper>
      {/* <DetailDrawer/> */}

      {/* Node Child Selector */}
      <Popover
        id="node-child-selector-popover"
        open={openNodeChildSelector}
        anchorEl={anchorNodeChildSelector}
        onClose={handleNodeChildSelectorClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CanvasViewNetworkSelector
          networkSelectedId={props.canvasViewId}
          parentNodeId={(props.node) ? props.node.getId() : undefined}
          onChangeSelection={(networkSelectedId) => {
            //console.log("onChangeSelection networkSelectedId: ", networkSelectedId);
            handleNodeChildSelectorClose();
            props.onChangeView(networkSelectedId);
          }}
        />
      </Popover>
      {/* Node Child Selector */}

      {/* DIALOG FOR ADD NODE TO TASK */}
      <Dialog fullScreen={false} open={showAddNodeToTaskForm} onClose={() => setShowAddNodeToTaskForm(false)}>
        <DialogTitle>Add Node to Task</DialogTitle>
        <DialogContent sx={{ p: 2, width: "500px", maxHeight: "500px" }}>
          <FormAddNodeToTask
            nodeId={props.node?.getId()!}
            formIsDone={(taskId) => { setShowAddNodeToTaskForm(false); }}
          />
        </DialogContent>
      </Dialog>
   </>
  );
}