import React, { useEffect, useState } from 'react'; // let's also import Component
import { useFormContext } from 'react-hook-form';

import { ASGateway, ASDtos } from 'api/AppServices';

import {
  Stack,
  Box,
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormHelperText
} from '@mui/material';

import InboxIcon from '@mui/icons-material/Inbox';
import TaskType from 'components/TaskType';
import TaskPriority from 'components/TaskPriority';

type IFormNewTaskProps = {
  onChange(task: ASDtos.TaskReadDto): void;
};

export default function FormNewTask(props: IFormNewTaskProps) {
  const formContext = useFormContext()

  const [fieldTitle, setFieldTitle] = useState<string>('');
  const [fieldType, setFieldType] = useState<string>('');
  const [fieldPriority, setFieldPriority] = useState<string>('');

  const [types, setTypes] = useState<ASDtos.TaskTypeDto[]>([]);
  const [priorities, setPriorities] = useState<ASDtos.TaskPriorityDto[]>([]);

  //Handles
  function getTaskValues(): ASDtos.TaskReadDto {
    return {
      type: fieldType as unknown as ASDtos.TaskTypeDto,
      priority: fieldPriority as unknown as ASDtos.TaskPriorityDto,
      title: fieldTitle,
    } as ASDtos.TaskReadDto
  }

  function handleChangeType(e: any) {
    var task = getTaskValues();
    task.type = e.target.value.toString() as unknown as ASDtos.TaskTypeDto;

    setFieldType(task.type.toString());
    //setFieldType(e.target.value);

    //call the parent function
    props.onChange(task);
  }

  function handleChangePriority(e: any) {
    var task = getTaskValues();
    task.priority = e.target.value.toString() as unknown as ASDtos.TaskPriorityDto;

    setFieldPriority(task.priority.toString());
    //setFieldPriority(e.target.value);

    //call the parent function
    props.onChange(task);
  }

  function handleChangeTitle(e: any) {
    var task = getTaskValues();
    task.title = e.target.value;

    setFieldTitle(task.title);

    //call the parent function
    props.onChange(task);
  }

  // OnLoad
  useEffect(() => {
    setTypes(Object.values(ASDtos.TaskTypeDto).filter((v) => !isNaN(Number(v))).map(key => key as ASDtos.TaskTypeDto));
    setPriorities(Object.values(ASDtos.TaskPriorityDto).filter((v) => isNaN(Number(v))).map(key => key as ASDtos.TaskPriorityDto));
  }, []);

  // OnRender
  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={0}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <FormControl 
            fullWidth
            error={formContext.formState.errors?.taskType ? true : false}
          >
            <InputLabel id="task-type-label">Type</InputLabel>
            <Select
              labelId="task-type-select-label"
              id="task-type-select"
              input={<OutlinedInput id="select-multiple-chip" label="Type" />}
              renderValue={(selectedType) => {
                var selectedTypeEnum = ASDtos.TaskTypeDto[Number(selectedType)] as unknown as ASDtos.TaskTypeDto;
                var foundType = types?.find((item) => item === selectedTypeEnum);
                if (foundType === undefined) {
                  return (<></>);
                }

                return (
                  <Box key={selectedType} sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    <TaskType type={foundType} />
                  </Box>
                )
              }}
              {...formContext.register('taskType', {
                required: { value: true, message: 'Field is Required.' },
                onChange: (e) => handleChangeType(e),
              })}
              value={fieldType}
            >
              {types?.map((o, index) => (
                <MenuItem key={index} value={ASDtos.TaskTypeDto[o]}>
                  <TaskType type={o} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formContext.formState.errors?.taskType?.message?.toString()}</FormHelperText>
          </FormControl>
          <FormControl 
            fullWidth
            error={formContext.formState.errors?.taskPriority ? true : false}            
          >
            <InputLabel id="task-priority-label">Priority</InputLabel>
            <Select
              labelId="task-priority-select-label"
              id="task-priority-select"
              input={<OutlinedInput id="select-multiple-chip" label="Priority" />}
              renderValue={(selectedPriority) => {
                var selectedPriorityEnum = ASDtos.TaskPriorityDto[Number(selectedPriority)] as unknown as ASDtos.TaskPriorityDto;

                var foundPriority = priorities?.find((item) => item === selectedPriorityEnum);
                if (foundPriority === undefined) {
                  return (<></>);
                }

                return (
                  <Box key={selectedPriority} sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    <TaskPriority priority={foundPriority.toString()} />
                  </Box>
                )
              }}
              {...formContext.register('taskPriority', {
                required: { value: true, message: 'Field is Required.' },
                onChange: (e) => handleChangePriority(e),
              })}
              value={fieldPriority}
            >
              {priorities?.map((o, index) => (
                <MenuItem key={index} value={ASDtos.TaskPriorityDto[o]}>
                  <TaskPriority priority={o.toString()} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formContext.formState.errors?.taskPriority?.message?.toString()}</FormHelperText>
          </FormControl>
        </Stack>
      </Stack>
      <TextField
        required
        id="taskTitle"
        key="taskTitle"
        label="Title"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...formContext.register('taskTitle', {
          required: { value: true, message: 'Field is Required.' },
          maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
          onChange: (e) => handleChangeTitle(e),
        })}
        value={fieldTitle}
        error={formContext.formState.errors?.taskTitle ? true : false}
        helperText={formContext.formState.errors?.taskTitle?.message?.toString()}
      />
    </Box>
  );
}