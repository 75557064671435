import React, { useEffect } from 'react';

import { ASDtos } from 'api/AppServices';

import { Chip, CssBaseline } from '@mui/material';

import BugReportIcon from '@mui/icons-material/BugReport';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AssignmentIcon from '@mui/icons-material/Assignment';

import IconSvg from 'components/IconSvg';


type ITaskTypeProps = {
  type: ASDtos.TaskTypeDto
};

export default function TaskType(props: ITaskTypeProps) {

  // function getColorFromTaskType(type: ASDtos.TaskTypeDto): any {
  //   var switchVal = ASDtos.TaskTypeDto[type as unknown as keyof typeof ASDtos.TaskTypeDto];
  //   switch (switchVal) {
  //     case ASDtos.TaskTypeDto.Story:
  //       return 'success';
  //     case ASDtos.TaskTypeDto.Task:
  //       return 'info';
  //     case ASDtos.TaskTypeDto.Bug:
  //       return 'error';
  //     case ASDtos.TaskTypeDto.Spike:
  //       return 'action';
  //     default:
  //       return 'default';
  //   }
  // }
  function getIconFromTaskType(type: ASDtos.TaskTypeDto): any {
    var switchVal = ASDtos.TaskTypeDto[type as unknown as keyof typeof ASDtos.TaskTypeDto];
    switch (switchVal) {
      case ASDtos.TaskTypeDto.Story:
        return <HistoryEduIcon sx={{ "&&": { color: "success.main" } }} />;
      case ASDtos.TaskTypeDto.Task:
        return <AssignmentIcon sx={{ "&&": { color: "info.main" } }} />;
      case ASDtos.TaskTypeDto.Bug:
        return <BugReportIcon sx={{ "&&": { color: "error.main" } }} />;
      case ASDtos.TaskTypeDto.Spike:
        return <IconSvg icon='Spike' color="action" />
      default:
        return <></>
    }
  }

  // OnRender
  return (
    <>
      <CssBaseline />
      <Chip
        variant='outlined'
        size="small"
        label={ASDtos.TaskTypeDto[props.type as unknown as keyof typeof ASDtos.TaskTypeDto]}
        icon={getIconFromTaskType(ASDtos.TaskTypeDto[props.type as unknown as keyof typeof ASDtos.TaskTypeDto])}
      />
    </>
  );
}