//https://reactscript.com/react-component-highlights-differences-two-strings-react-diff/
//https://github.com/cezary/react-diff

import React, { useRef, useEffect, useState } from 'react';

import { ASDtos } from 'api/AppServices';
import { IEditorContentData, IEditorExtendedDataFields } from 'app/AppInterfaces';

import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import EditorJS, { API, OutputBlockData, BlockMutationEvent, OutputData } from '@editorjs/editorjs';
import { EDITOR_JS_TOOLS } from "app/EditorTools";

import './Editor.css';

// @ts-ignore
import jsdiff from 'diff';
const fnMap: { [key: string]: (a: string | object, b: string | object) => any } = {
  'chars': jsdiff.diffChars,
  'words': jsdiff.diffWords,
  'sentences': jsdiff.diffSentences,
  'json': jsdiff.diffJson
};

interface IEditorProps {
  editorId: string;
  contentId: number;
  readOnly: boolean;
  markChanges: boolean;
  showActions: boolean;
  data: ASDtos.DocumentBlockReadDto[];
  onSave: (data: ASDtos.DocumentBlockUpdateDto[]) => void;
};

export default function Editor(props: IEditorProps) {
  const editorRef = useRef<EditorJS>();

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [originalEditorData, setOriginalEditorData] = useState<IEditorExtendedDataFields[]>([]);

  // if(props.showActions) {
  //   require('./Editor.css');
  // }
  // else{
  //   require('./Editor-Viewer.css');
  // }


  async function initEditor(initialLoadData: IEditorContentData) {
    const editor = new EditorJS({
      holder: props.editorId,
      autofocus: true,
      minHeight: 20,
      hideToolbar: true,
      readOnly: props.readOnly,
      // style: { nonce: "" },
      data: initialLoadData,

      inlineToolbar: ['bold', 'italic', 'marker'],
      tools: EDITOR_JS_TOOLS,
      onReady: async () => {
        editorRef.current = editor;

        if (!props.readOnly) {
          var outputData = await editorRef.current?.save();

          //console.log("[EDITOR onReady] outputData.blocks: ", outputData.blocks);
          //Get APIRef for each Block 
          var outputDataWithApiFields: IEditorExtendedDataFields[] = [];
          outputData.blocks.forEach((outputBlock: OutputBlockData<string, any>, index: number) => {

            //Update CSS Class with Specific props depending on state
            let cssForTag = "";
            if (props.markChanges) {
              if (props.data[index].isNew) {
                cssForTag = 'skalo-app-editor-change-new';
              }
              if (props.data[index].isChanged) {
                cssForTag = 'skalo-app-editor-change-edit';
              }
              if (props.data[index].isDeleted) {
                cssForTag = 'skalo-app-editor-change-delete';
              }
              if (cssForTag !== "") {
                const divBlockElem = document.querySelectorAll(`[data-id='${outputBlock.id}']`);
                divBlockElem[0].classList.add(cssForTag);
              }
            }

            outputDataWithApiFields = [...outputDataWithApiFields,
            {
              //Editor Block Data fields
              id: outputBlock.id,
              data: outputBlock.data,
              type: outputBlock.type,

              //Extended API fields
              apiRef: props.data[index].apiRef,
              isNew: props.data[index].isNew,
              isDeleted: props.data[index].isDeleted,
              isChanged: props.data[index].isChanged,
            } as IEditorExtendedDataFields];
          })
          //console.log("[EDITOR onReady] outputDataWithApiFields: ", outputDataWithApiFields);

          setOriginalEditorData(outputDataWithApiFields);
        }
      },

      onChange: async (api: API, event: BlockMutationEvent | BlockMutationEvent[]) => {
        return;
      },
    });
  }

  // function parseDocumentBlocks(documentBlocks: ASDtos.DocumentBlockReadDto[]): ASDtos.DocumentBlockReadDto[] {
  //   var parsedBlocks = [] as ASDtos.DocumentBlockReadDto[];
  //   if (documentBlocks && Array.isArray(documentBlocks)) {
  //     parsedBlocks = [...documentBlocks];
  //     //console.log("parseDocumentBlocks blocksToParse: ", parsedBlocks);
  //     parsedBlocks.forEach((block: ASDtos.DocumentBlockReadDto) => {
  //       //console.log("parseDocumentBlocks Parse Block: ", block);
  //         block.data = parseData(block.data);
  //     });
  //   }
  //   //console.log("parseDocumentBlocks parsedBlocks: ", parsedBlocks);
  //   return parsedBlocks;
  // }

  function parseData(data: string): any {
    if (typeof data === 'string') { //Only Parse if it has a String to Parse
      return JSON.parse(data);
    }
    return data;
  }

  function parseFromDtoToEditorBlock(blocks: ASDtos.DocumentBlockReadDto[]): OutputBlockData[] {
    return blocks.map<OutputBlockData>((block: ASDtos.DocumentBlockReadDto) => {
      let blockType = block.type;
      let blockData = block.data;

      //TEMP HACK: Remove mark tags
      blockData = blockData.replaceAll("<mark>", "");
      blockData = blockData.replaceAll("</mark>", "");
      blockData = blockData.replaceAll("<s>", "");
      blockData = blockData.replaceAll("</s>", "");
      //TEMP HACK: Remove mark tags

      //console.log("parseFromDtoToEditorBlock: ", blockData)
      //HACK
      if (blockData === "") {
        blockType = "paragraph";
        blockData = `{"text": "--"}`;
      }
      let outBlock = parseData(blockData);
      return {
        type: blockType,
        data: outBlock,
      } as OutputBlockData;
    });
  }
  //Handles
  async function handleSaveData(): Promise<ASDtos.DocumentBlockUpdateDto[]> {
    //console.log("handleSaveData setIsButtonSubmiting(true)");
    setIsButtonSubmiting(true);

    let result: ASDtos.DocumentBlockUpdateDto[] = [];
    let dataToSave = await editorRef.current?.save();
    if (dataToSave) {
      console.log("dataToSave: ", dataToSave);

      //First go foreach Saved Block and find if any block has been added or changed
      dataToSave.blocks.forEach((savedBlock: OutputBlockData<string, any>, index: number) => {

        let originalEditorBlock = originalEditorData.find((originalEditorDataBlock) => originalEditorDataBlock.id === savedBlock.id);
        if (!originalEditorBlock) {
          //NOT FOUND = NEW Block ADDED
          result = [...result,
          {
            //Editor Block Data fields
            data: savedBlock.data,
            type: savedBlock.type,

            //Extended API fields
            apiRef: -1,
            isNew: true,
            isDeleted: false,
            isChanged: false,
            order: index
          } as ASDtos.DocumentBlockUpdateDto];
        }
        else {
          //BLOCK FOUND = Check if was edited
          //console.log(`diff savedBlock[${savedBlock.id}] originalEditorBlock: `, originalEditorBlock);
          //console.log(`diff savedBlock[${savedBlock.id}] savedBlock: `, savedBlock);
          const diff = fnMap['chars'](
            JSON.stringify(originalEditorBlock.data),
            JSON.stringify(savedBlock.data)
          );
          // console.log(`diff savedBlock[${savedBlock.id}] result: `, diff);

          const isBlockChanged = diff.length > 1;
          result = [...result,
          {
            //Editor Block Data fields
            data: savedBlock.data,
            type: savedBlock.type,

            //Extended API fields
            apiRef: originalEditorBlock.apiRef,
            isNew: false,
            isDeleted: false,
            isChanged: isBlockChanged,
            order: index
          } as ASDtos.DocumentBlockUpdateDto];
        }
      });

      //Finally go foreach Original and find if any block has been removed
      originalEditorData.forEach((originalDataBlock: IEditorExtendedDataFields, index: number) => {
        var savedEditorBlock = dataToSave!.blocks.find((savedBlock) => savedBlock.id === originalDataBlock.id);
        if (!savedEditorBlock) {
          //NOT FOUND = ORIGINAL Block REMOVED
          //console.log("NOT FOUND = ORIGINAL Block REMOVED: ", originalDataBlock);
          result = [...result,
          {
            //Editor Block Data fields
            data: originalDataBlock.data,
            type: originalDataBlock.type,

            //Extended API fields
            apiRef: originalDataBlock.apiRef,
            isNew: false,
            isDeleted: true,
            isChanged: false,
            order: index + 1000
          } as ASDtos.DocumentBlockUpdateDto];
        }
      });
    }

    //console.log("Data to Save result: ", result);
    //console.log("Data to Save: ", dataToSave);
    //console.log("handleSaveData setIsButtonSubmiting(false)");
    setIsButtonSubmiting(false);
    return result;
  }

  // OnLoad
  useEffect(() => {
    //console.log("[EDITOR useEffect] props.data: ", props.data);
    if (editorRef.current) {  //We have a editor mounted
      //Destroy Editor to Build a new one      
      editorRef.current.clear();
      editorRef.current.destroy();
      editorRef.current = undefined;
    }

    const dataToLoad = parseFromDtoToEditorBlock(props.data);
    initEditorCaller({
      time: new Date().getTime(),
      blocks: dataToLoad,
      version: "2.1.0"
    } as IEditorContentData);

    async function initEditorCaller(initialLoadData: IEditorContentData) {
      await initEditor(initialLoadData);
    }
  }, [props.data]);

  useEffect(() => {

  }, []);

  // OnRender
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={1}
    >
      {!props.readOnly && props.markChanges && //On readonly mode we never show labels of edition
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          sx={{ p: 0.5, border: "solid 1px #F3F3F3", backgroundColor: "#F3F3F3"}}
        >
          <Box sx={{ p: 0.5, backgroundColor: "#FFFFFF", fontSize: 10 }} className="skalo-app-editor-change-new">New</Box>
          <Box sx={{ p: 0.5, backgroundColor: "#FFFFFF", fontSize: 10 }} className="skalo-app-editor-change-edit">Changed</Box>
          <Box sx={{ p: 0.5, backgroundColor: "#FFFFFF", fontSize: 10 }} className="skalo-app-editor-change-delete">Deleted</Box>
        </Stack>
      }
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {/* Editor Container */}
        <Box
          id="skalo-app-editor-container"
          sx={{
            width: "100%",
            height: "100%",
            textAlign: "justify",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <div id={(props.showActions)?"skalo-app-editor-mode-edit":"skalo-app-editor-mode-view"}>
            <div id={props.editorId} />
          </div>
        </Box>
        {/* Editor Container */}
      </Stack>
      {props.showActions &&
        <SmartButton
          color='primary'
          fullWidth
          disabled={props.readOnly}
          endIcon={<SaveIcon />}
          isSubmiting={isButtonSubmiting}
          onSubmit={async () => {
            var result = await handleSaveData();
            console.log("Awaited for Saved Data. Editor is Done! Result: ", result);
            props.onSave(result);
          }}
        >Save</SmartButton>
      }
    </Stack>
  );
}
