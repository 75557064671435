import React, { useEffect, useState } from 'react'; // let's also import Component
import { useForm } from 'react-hook-form';

import { ASGateway, ASDtos } from 'api/AppServices';

import SmartButton from 'components/SmartButton';

import {
  Stack,
  Box,
  TextField,
} from '@mui/material';

interface IFormNewEnvironmentProps {
  teamId: number;
  formIsDone: (newEnvironmentCreated: ASDtos.EnvironmentReadDto) => void;
};

export default function FormNewEnvironment(props: IFormNewEnvironmentProps) {

  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'all' });
  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  
  const [fieldName, setFieldName] = useState<string>('');

  //Handles
  async function handleNewEnvironmentSubmit() {
    setIsButtonSubmiting(true);
    ASGateway.Environments.Create(
      props.teamId,
      fieldName
    )
      .then((data: ASDtos.EnvironmentReadDto) => {
        //console.log('CreateEnvironment: ', data);
        props.formIsDone(data);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      })
      ;
  }

  // OnLoad
  // useEffect(() => {
  //   //console.log("useEffect[FormNewEnvironment]");
  // }, []);

  // OnRender
  return (
    <Box id='environmentForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleNewEnvironmentSubmit)} sx={{ padding: 0, width: "100%", height: "100%" }}>
      <TextField
        required
        id="environmentName"
        key="environmentName"
        label="Environment Name"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('environmentName', {
          required: { value: true, message: 'Field is Required.' },
          maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
          onChange: (e) => setFieldName(e.target.value),
        })}
        value={fieldName}
        error={errors?.environmentName ? true : false}
        helperText={errors?.environmentName?.message?.toString()}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <br /><br /><br />
        <SmartButton
          isSubmiting={isButtonSubmiting}
          onSubmit={handleSubmit(handleNewEnvironmentSubmit)} 
        >Create</SmartButton>
      </Stack>
    </Box>
  );
}