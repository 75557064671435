import { ICanvasLoader } from 'middleware/canvas/ICanvasLoader';

interface ICircle {
  center: {
    x: number,
    y: number
  },
  radius: number,
  speed: number
}

export class CanvasLoader implements ICanvasLoader {
  private ctx: CanvasRenderingContext2D | null;
  private animationId: number;
  private animationProgress: number;
  private planeWidth: number;
  private planeHeight: number;

  private bigCircle: ICircle;
  private midCircle: ICircle;
  private smallCirlce: ICircle;

  constructor(ctx: CanvasRenderingContext2D | null, planeWidth: number, planeHeight: number) {
    this.ctx = ctx;

    this.animationId = -1;
    this.animationProgress = 0;

    this.planeWidth = planeWidth;
    this.planeHeight = planeHeight;

    this.bigCircle = { center: { x: this.planeWidth / 2, y: this.planeHeight / 2 }, radius: 40, speed: 4 };
    this.midCircle = { center: { x: this.planeWidth / 2, y: this.planeHeight / 2 }, radius: 30, speed: 3 };
    this.smallCirlce = { center: { x: this.planeWidth / 2, y: this.planeHeight / 2 }, radius: 20, speed: 2 };
  }

  stopLoading() {
    if (this.animationId !== -1) {
      cancelAnimationFrame(this.animationId);
      this.animationId = -1;
    }
  }

  startLoading() {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.planeWidth, this.planeHeight);


      this.animationProgress += 0.01;
      if (this.animationProgress > 1) {
        this.animationProgress = 0;
      }

      this.drawCircle(this.bigCircle, this.animationProgress);
      this.drawCircle(this.midCircle, this.animationProgress);
      this.drawCircle(this.smallCirlce, this.animationProgress);

      this.animationId = requestAnimationFrame(() => { this.startLoading() });
    }
  }

  private drawCircle(circle: ICircle, progress: number) {
    if (this.ctx) {
      this.ctx.save();

      var start = this.accelerateInterpolator(progress) * circle.speed;
      var end = this.decelerateInterpolator(progress) * circle.speed;

      this.ctx.beginPath();

      this.ctx.lineWidth = 3;
      this.ctx.strokeStyle = "#FFFFFF";
      this.ctx.fillStyle = "#D4DBF5";
      //this.ctx.fillStyle = "#EDEDED";

      this.ctx.arc(circle.center.x, circle.center.y, circle.radius, (start - 0.5) * Math.PI, (end - 0.5) * Math.PI);
      this.ctx.fill();
      this.ctx.stroke();

      this.ctx.restore();
    }
  }
  private accelerateInterpolator(x: number) {
    return x * x;
  }
  private decelerateInterpolator(x: number) {
    return 1 - ((1 - x) * (1 - x));
  }
}